import styles from "./SoftDrinksDesktop.module.css";

const SoftDrinksDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} data-index='0'>
        Fresh Apple ............................................................................. 3.50
      </li>
      <li className={styles.item} data-index='1'>
        Fresh Carrot ............................................................................ 3.50
      </li>
      <li className={styles.item} data-index='5'>
        Fresh Orange ............................................................................ 3.50
      </li>
      <li className={styles.item} data-index='6'>
        Fresh Lemonade .......................................................................... 3.00
      </li>
      <li className={styles.item} data-index='8'>
        Fresh Mix ............................................................................... 3.50
      </li>
      <li className={styles.item} >
        Homemade Ice Tea ........................................................................ 2.00
      </li>
    </ul>
  );
};

export default SoftDrinksDesktop;
