import { NavLink, Routes, Route, Navigate } from "react-router-dom";

import CreateBookForm from "./CreateBook/CreateBookForm";

import RecentlyAddedBooks from "./RecentlyAdded/RecentlyAddedBooks";
import UpdateBook from "./UpdateBook/UpdateBook";
import DeleteBook from "./DeleteBook/DeleteBook";

import styles from "./LibraryCrud.module.css";

const LibraryCrud = () => {
  return (
    <div className={styles.container}>
      <nav className={styles.nav}>
        <ul>
          <NavLink
            to='/admin/books/libraryCrud/createBook'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <span>Create Book</span>
          </NavLink>
          <NavLink
            to='/admin/books/libraryCrud/recentlyAdded'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <span>Recently Added</span>
          </NavLink>
          <NavLink
            to='/admin/books/libraryCrud/updateBook'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <span>Update Book</span>
          </NavLink>
          <NavLink
            to='/admin/books/libraryCrud/deleteBook'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <span>Delete Book</span>
          </NavLink>
        </ul>
      </nav>
      <main className={styles.main}>
        <Routes>
          <Route path='/createBook' element={<CreateBookForm />} />
          <Route path='/recentlyAdded/*' element={<RecentlyAddedBooks />} />
          <Route path='/recentlyAdded' element={<Navigate replace to='createRecentlyAddedBook' />} />
          <Route path='/updateBook' element={<UpdateBook />} />
          <Route path='/deleteBook' element={<DeleteBook />} />
        </Routes>
      </main>
    </div>
  );
};

export default LibraryCrud;
