import Trash from "../../../../assets/icons/Trash";
import ConfirmReservationIcon from "../../../../assets/icons/ConfirmReservationIcon";
import CapitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import Edit from "../../../../assets/icons/Edit";
import styles from "./ReservationRow.module.css";

const ReservationRow = (props) => {
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  let reservationDate = props.date.toDate().toLocaleString("default", options);

  return (
    <tr className={props.flagged ? `${styles.tr} ${styles.flagged}` : styles.tr}>
      <td data-label='NAME'>{CapitalizeFirstLetter(props.name)}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='DATE/TIME'>{reservationDate + " at " + props.time}</td>
      <td data-label='NUMBER OF PEOPLE'>{props.numberOfPeople}</td>
      <td data-label='SPECIAL REQUESTS'>{props.specialReq}</td>
      <td data-label='TYPE OF SERVICE'>{props.type}</td>
      <td data-label='TABLE NUMBER'>
        {!props.tableNumber ? (
          <button className={styles.btn} onClick={() => props.onAddTableNumber(props.id, "add")}>
            <PlusIcon />
          </button>
        ) : (
          <div className={styles["table-number-cell"]}>
            <span>{props.tableNumber}</span>
            <button className={styles.btn} onClick={() => props.onAddTableNumber(props.id, "edit")}>
              <Edit />
            </button>
          </div>
        )}
      </td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onConfirmReservation(props.id)}>
          <ConfirmReservationIcon />
        </button>
        <button className={styles.btn} type='button' onClick={() => props.onDeleteReservation(props.id)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default ReservationRow;
