const Sun = () => {
  return (
    <svg
      width='30px'
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 100 100'
      enableBackground='new 0 0 100 100'>
      <circle
        fill='#FEE1C5'
        stroke='#323334'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        cx='50'
        cy='50'
        r='23'
      />
      <line
        fill='none'
        stroke='#323334'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        x1='50'
        y1='9'
        x2='50'
        y2='18'
      />
      <line
        fill='none'
        stroke='#323334'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        x1='21.01'
        y1='21.01'
        x2='27.37'
        y2='27.37'
      />
      <line
        fill='none'
        stroke='#323334'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        x1='9'
        y1='50'
        x2='18'
        y2='50'
      />
      <line
        fill='none'
        stroke='#323334'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        x1='21.01'
        y1='78.99'
        x2='27.37'
        y2='72.63'
      />
      <line
        fill='none'
        stroke='#323334'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        x1='50'
        y1='91'
        x2='50'
        y2='82'
      />
      <line
        fill='none'
        stroke='#323334'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        x1='78.99'
        y1='78.99'
        x2='72.63'
        y2='72.63'
      />
      <line
        fill='none'
        stroke='#323334'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        x1='91'
        y1='50'
        x2='82'
        y2='50'
      />
      <line
        fill='none'
        stroke='#323334'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        x1='78.99'
        y1='21.01'
        x2='72.63'
        y2='27.37'
      />
    </svg>
  );
};

export default Sun;
