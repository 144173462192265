import { where, query, orderBy, limit, getFirestore, collection } from "firebase/firestore";

const getQueryType = (checkListValues, selectedMonth, year) => {
  const db = getFirestore();
  const eventsRef = collection(db, "events");
  let currentDate = new Date();
  let q = {};
  if (checkListValues.length && selectedMonth !== "all" && year === "upcoming") {
    q.query = query(
      eventsRef,
      where("date", ">=", new Date(currentDate.getFullYear(), selectedMonth - 1, 1)),
      where("date", "<", new Date(currentDate.getFullYear(), selectedMonth, 1)),
      where("date", ">=", currentDate),
      where("type", "in", checkListValues),
      orderBy("date", "asc"),
      limit(6)
    );
    q.querynumber = 1;
  }
  if (!checkListValues.length && selectedMonth !== "all" && year === "upcoming") {
    q.query = query(
      eventsRef,
      where("date", ">=", new Date(currentDate.getFullYear(), selectedMonth - 1, 1)),
      where("date", "<", new Date(currentDate.getFullYear(), selectedMonth, 1)),
      where("date", ">=", currentDate),
      orderBy("date", "asc"),
      limit(6)
    );
    q.querynumber = 2;
  }

  if (checkListValues.length && selectedMonth === "all" && year === "upcoming") {
    q.query = query(
      eventsRef,
      where("date", ">=", currentDate),
      where("type", "in", checkListValues),
      orderBy("date", "asc"),
      limit(6)
    );
    q.querynumber = 3;
  }
  if (!checkListValues.length && selectedMonth === "all" && year === "upcoming") {
    q.query = query(eventsRef, where("date", ">=", currentDate), orderBy("date", "asc"), limit(6));
    q.querynumber = 4;
  }

  if (checkListValues.length && selectedMonth !== "all" && year !== "upcoming" && year !== "past") {
    q.query = query(
      eventsRef,
      where("date", ">=", new Date(year, selectedMonth - 1, 1)),
      where("date", "<", new Date(year, selectedMonth, 1)),
      where("date", "<=", currentDate),
      where("type", "in", checkListValues),
      orderBy("date", "asc"),
      limit(6)
    );
    q.querynumber = 5;
  }

  if (!checkListValues.length && selectedMonth !== "all" && year !== "upcoming" && year !== "past") {
    q.query = query(
      eventsRef,
      where("date", ">=", new Date(year, selectedMonth - 1, 1)),
      where("date", "<", new Date(year, selectedMonth, 1)),
      where("date", "<=", currentDate),
      orderBy("date", "asc"),
      limit(6)
    );
    q.querynumber = 6;
  }

  if (checkListValues.length && selectedMonth === "all" && year !== "upcoming" && year !== "past") {
    q.query = query(
      eventsRef,
      where("date", ">=", new Date(year, 0, 1)),
      where("date", "<", new Date(parseInt(year) + 1, 0, 1)),
      where("date", "<=", currentDate),
      where("type", "in", checkListValues),
      orderBy("date", "asc"),
      limit(6)
    );
    q.querynumber = 7;
  }
  if (!checkListValues.length && selectedMonth === "all" && year !== "upcoming" && year !== "past") {
    q.query = query(
      eventsRef,
      where("date", ">=", new Date(year, 0, 1)),
      where("date", "<", new Date(parseInt(year) + 1, 0, 1)),
      where("date", "<=", currentDate),
      orderBy("date", "asc"),
      limit(6)
    );
    q.querynumber = 8;
  }

  return q;
};

export default getQueryType;
