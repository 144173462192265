import styles from "./DessertsDesktop.module.css";

const DessertsDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <h3 className={styles["category-title"]}>DESSERTS</h3>
      <div className={styles.section}>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='desserts'>
          Cheesecake .............................................................................. 3.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='desserts'>
          Brownie ................................................................................. 3.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='desserts'>
          Chocolate Fondue ........................................................................ 3.50
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='desserts'>
          Apple Crumble ........................................................................... 3.50
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='desserts'>
          Krem Katalana ........................................................................... 2.50
        </li>
      </div>
      <h3 className={styles["category-title"]}>MILKSHAKES</h3>
      <div className={styles.section}>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='desserts'>
          Oreo Milkshake .......................................................................... 3.50
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='desserts'>
          Salty Caramel Pretzel ................................................................... 3.50
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='desserts'>
          Vanilla Milkshake ....................................................................... 3.50
        </li>
      </div>
    </ul>
  );
};

export default DessertsDesktop;
