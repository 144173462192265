import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import DeleteModal from "../../../../Modals/DeleteModal";
import EditFlaggedGuestModal from "../../../../Modals/EditFlaggedGuestModal";
import AddFlaggedGuestModal from "../../../../Modals/AddFlaggedGuestModal";

import NextButton from "../../../../components/UI/NextButton";

import FlashMessage from "../../../../components/UI/FlashMessage";
import FlaggedGuestsToolbar from "./FlaggedGuestsToolbar";
import FlaggedGuestsRow from "./FlaggedGuestsRow";
import styles from "./FlaggedGuests.module.css";

// FIREBASE
import {
  getFirestore,
  collection,
  where,
  getDocs,
  query,
  limit,
  startAfter,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";

const FlaggedGuests = () => {
  const [alert, setAlert] = useState("");
  const [flaggedGuests, setFlaggedGuests] = useState([]);
  const [guestId, setGuestId] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("fullName");

  const [isPending, setIsPending] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddGuestModal, setShowAddGuestModal] = useState(false);
  const [searchObj, setSearchObj] = useState(null);

  const [last, setLast] = useState();

  const [guestToEdit, setGuestToEdit] = useState({
    fullName: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowEditModal(false);
        setShowDeleteModal(false);
        setShowAddGuestModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  useEffect(() => {
    const getFlaggedGuests = async () => {
      setIsPending(true);
      setFlaggedGuests([]);

      const db = getFirestore();
      const q = query(collection(db, "flaggedGuests"), limit(5));

      const querySnapshot = await getDocs(q);

      // FOR PAGINATION
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLast(lastVisible);

      querySnapshot.forEach((doc) => {
        setFlaggedGuests((prevState) => {
          return [...prevState, { ...doc.data(), id: doc.id }];
        });
      });

      setIsPending(false);
    };
    getFlaggedGuests();
  }, []);

  useEffect(() => {
    const searchFlaggedGuests = async (obj) => {
      if (obj === null) {
        return;
      }
      let { searchCriteria, searchValue } = obj;

      if (searchValue === "") {
        return;
      }
      setFlaggedGuests([]);
      const db = getFirestore();
      const q = query(
        collection(db, "flaggedGuests"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff")
      );
      setIsPending(true);

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setIsPending(false);
          return;
        }

        querySnapshot.forEach((doc) => {
          setFlaggedGuests((prevState) => {
            return [...prevState, { ...doc.data(), docId: doc.id }];
          });
        });
      } catch (error) {
        console.log(error);
      }

      setIsPending(false);
    };
    searchFlaggedGuests(searchObj);
  }, [searchObj]);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  //  ADD GUEST
  const onAddGuestHandler = async (guest) => {
    const db = getFirestore();

    const doc = await addDoc(collection(db, "flaggedGuests"), guest);

    setFlaggedGuests((prevState) => {
      return [...prevState, { ...guest, id: doc.id }];
    });
    setShowAddGuestModal(false);
    flashMessageHandler({ message: "Guest Added ", success: true });
  };
  const onCancelAddFlaggedGuest = () => {
    setShowAddGuestModal(false);
  };

  // *** EDIT FLAGGED GUEST ***
  const onEditFlaggedCustomerHandler = (guestId) => {
    setGuestId(guestId);
    setShowEditModal(true);
    const guest = flaggedGuests.filter((guest) => guest.id === guestId)[0];
    setGuestToEdit(guest);
  };

  const onCancelEditFlaggedGuest = () => {
    setShowEditModal(false);
  };

  const onSubmitEditHandler = async (ev) => {
    ev.preventDefault();
    const db = getFirestore();
    await updateDoc(doc(db, "flaggedGuests", guestId), guestToEdit);

    const indexOfGuest = flaggedGuests.findIndex((res) => res.id === guestId);
    setFlaggedGuests((prevState) => {
      return [...prevState].map((guest, i, arr) => (i === indexOfGuest ? guestToEdit : guest));
    });
    setShowEditModal(false);

    flashMessageHandler({ message: "Flagged Guest Edited", success: true });
  };

  // DELETE FLAGGED CUSTOMER

  const onConfirmDeleteHandler = (guestId) => {
    setShowDeleteModal(true);
    setGuestId(guestId);
  };
  // Cancel deletion of reservation by removing modal
  const onCancelDeleteGuestHandler = () => {
    setShowDeleteModal(false);
  };
  // Delete reservation and remove modal
  const onDeleteGuestHandler = async () => {
    const db = getFirestore();

    await deleteDoc(doc(db, "flaggedGuests", guestId));

    setFlaggedGuests((prevState) => {
      return [...prevState].filter((guest) => guest.id !== guestId);
    });

    flashMessageHandler({ message: "Guest Deleted ", success: true });
    setShowDeleteModal(false);
    setGuestId(null);
  };

  // NEXT SET OF DATA - When we press More Button
  const showMoreItemsHandler = async () => {
    const db = getFirestore();

    const next = query(collection(db, "flaggedGuests"), startAfter(last), limit(5));
    const querySnapshot = await getDocs(next);

    if (querySnapshot.empty) {
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLast(lastVisible);

    querySnapshot.forEach((doc) => {
      setFlaggedGuests((prevState) => {
        return [...prevState, { ...doc.data(), id: doc.id }];
      });
    });
  };

  return (
    <div>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <FlaggedGuestsToolbar
        onShowAddGuestModal={() => setShowAddGuestModal(true)}
        onChangeSearchCriteria={setSearchCriteria}
        searchCriteria={searchCriteria}
        onSearch={setSearchObj}
      />
      <DeleteModal
        showModal={showDeleteModal}
        onConfirm={onDeleteGuestHandler}
        onCancel={onCancelDeleteGuestHandler}
        message={"Are you sure you want to delete this guest?"}
      />
      <EditFlaggedGuestModal
        showModal={showEditModal}
        onCancel={onCancelEditFlaggedGuest}
        editGuest={setGuestToEdit}
        guest={guestToEdit}
        onSubmit={onSubmitEditHandler}
      />
      <AddFlaggedGuestModal
        showModal={showAddGuestModal}
        onCancel={onCancelAddFlaggedGuest}
        onSubmit={onAddGuestHandler}
      />
      {isPending ? (
        "Loading..."
      ) : flaggedGuests.length > 0 ? (
        <table className={styles["flagged-guests"]}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {flaggedGuests.map((data) => {
              return (
                <FlaggedGuestsRow
                  key={uuidv4()}
                  id={data.id}
                  name={data.fullName}
                  phone={data.phone}
                  email={data.email}
                  onEditFlagCustomer={onEditFlaggedCustomerHandler}
                  onDeleteCustomer={onConfirmDeleteHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>There are no flagged guests</p>
      )}
      <NextButton onClick={showMoreItemsHandler} />
    </div>
  );
};

export default FlaggedGuests;
