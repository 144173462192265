import styles from "./SoftDrinksDesktop.module.css";

const SoftDrinksDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} data-index='0'>
        Mollë e shtrydhur ....................................................................... 3.50
      </li>
      <li className={styles.item} data-index='1'>
        Karrotë e shtrydhur ..................................................................... 3.50
      </li>
      <li className={styles.item} data-index='5'>
        Portokall i shtrydhur ................................................................... 3.50
      </li>
      <li className={styles.item} data-index='6'>
        Limonadë ................................................................................ 3.00
      </li>
      <li className={styles.item} data-index='8'>
        Mix i shtrydhur ......................................................................... 3.50
      </li>
      <li className={styles.item}>
        Ice Tea Shtëpie ......................................................................... 2.00
      </li>
    </ul>
  );
};

export default SoftDrinksDesktop;
