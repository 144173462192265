import styles from "./PastaAndRisottoDesktop.module.css";

const PastaAndRisottoDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <section className={styles.section}>
        <h3 className={styles["category-title"]}>RICE</h3>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='pastaAndRice'>
          Mushroom Risotto w. creamy truffle rissoto, seasonal mushrooms, parmesan and butter ..... 6.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='pastaAndRice'>
          Shrimp Risotto w. creamy shrimp risotto, diced tomatoes, zucchini and parsley ........... 7.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='pastaAndRice'>
          Chicken Soya with Basmati Rice w. spring onions ......................................... 6.00
        </li>
      </section>
      <section className={styles.section}>
        <h3 className={styles["category-title"]}>PASTA</h3>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='pastaAndRice'>
          Stuffed Ravioli w. ricotta on brown butter, sage and parmesan ........................... 5.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='pastaAndRice'>
          Truffle Spaghetti w. truffle paste, parmesan and clarified brown butter ................. 5.50
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='pastaAndRice'>
          Shrimp Spaghetti w. shrimps, diced tomatoes, zucchini and parsley ....................... 8.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='pastaAndRice'>
          Chicken Fusilli w. chicken on tomato sauce, sundried tomatoes and parmessan ............. 6.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='pastaAndRice'>
          Mac & Cheese w. grilled macaroni, homemade cheese sauce and bread crumbs ................ 4.00
        </li>
      </section>
    </ul>
  );
};

export default PastaAndRisottoDesktop;
