import CocktailsMobile from "./CocktailsMobile";
import CocktailsDesktop from "./CocktailsDesktop";
import styles from "./Cocktails.module.css";

const Cocktails = (props) => {
  return (
    <div className={styles.container}>
      <CocktailsMobile />
      <CocktailsDesktop />
    </div>
  );
};

export default Cocktails;
