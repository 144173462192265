import React, { useEffect, useState } from "react";
import { auth } from "../Firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const AuthContext = React.createContext({
  authUser: null,
  isAdmin: false,
  isAdminHandler: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unlisten = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        const db = getFirestore();
        const uid = user.uid;
        const userRef = doc(db, "users", uid);
        const userSnap = await getDoc(userRef);
        const userData = userSnap.data();

        if (userData.roles !== undefined) {
          let admin = userData.roles.isAdmin;
          setIsAdmin(admin);
        }

        setAuthUser(user);
        localStorage.setItem("authUser", JSON.stringify(authUser));
      } else {
        // User is signed out
        // ...
        setAuthUser(null);
        setIsAdmin(false);
        localStorage.removeItem("authUser");
      }
      return () => {
        unlisten();
      };
    });
  }, [authUser]);
  //   for some reason adding authUser dependency solved the problem of flickering icon on the header

  // const isAdminHandler = () => {
  //   setIsAdmin(true);
  // };

  return <AuthContext.Provider value={{ authUser, isAdmin }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
