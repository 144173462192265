import styles from "./CocktailsDesktop.module.css";

const CocktailsDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0'>
        Mimosa - Prosecco, Orange Juice ......................................................... 5.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1'>
        Vodka Sour - Vodka, Passion Fruit, Lime, Orange Juice ................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='5'>
        Basil Smash - Gin, Lime, Sugar, Basil ................................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='6'>
        Amaretto Sour - Amareto, Lime, Sugar .................................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='8'>
        Whiskey Sour - Scotch Whiskey, Lime, Sugar .............................................. 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler}>
        Espresso Martini - Vodka, Kahlua, Espresso .............................................. 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2'>
        Vodka Martini - Vodka, Dry Martini ...................................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Dry Martini - Gin, Dry Martini .......................................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Margarita - Tequila, Lime, Triple Sec ................................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Manhattan - Whiskey, Martini Rosso, Angostura Bitters ................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Cosmopolitan - Vodka, Lime, Triple Sec, Cranberry Juice ................................. 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Tom Collins - Gin, Lime, Sugar, Sparkling Water ......................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Bitter Almond - Amaretto, Lime, Sugar, Old Fashioned Bitters ............................ 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Mojito - Rum, Lime, Sugar, Mint ......................................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Negroni - Gin, Campari, Martini Rosso ................................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        White Russian - Vodka, Kahlua, Heavy Cream .............................................. 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Aperol Spritz - Aperol, Prosecco, Sparkling Water ....................................... 6.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Long Island Ice Tea - Vodka, Gin, Tequila, Rum, Triple Sec, Lime, Cola .................. 7.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Old Fashioned - Bourbon, Sugar, Old Fashioned Bitters ................................... 7.00
      </li>
    </ul>
  );
};

export default CocktailsDesktop;
