import React from "react";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import Google from "../../assets/icons/Google";
import styles from "./GoogleLogInButton.module.css";

const GoogleLogInButton = (props) => {
  return (
    <button type='button' className={styles["btn-signin"]} onClick={props.onClick}>
      <i>
        <Google />
      </i>
      <span>
        <MultiLingualContent contentId='authentication' prop='button1' />
      </span>
    </button>
  );
};

export default GoogleLogInButton;
