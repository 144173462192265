import React from "react";
import styles from "./ButtonPrimary.module.css";
const ButtonPrimary = (props) => {
  return (
    <button
      type={props.type}
      className={`${styles["btn-primary"]} ${props.className}`}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export default ButtonPrimary;
