import styles from "./JobApplicants.module.css";

const JobApplicants = () => {
  return (
    <svg className={styles.svg} version='1.1' id='圖層_1' x='0' y='0' viewBox='0 0 100 100'>
      <g>
        <path
          d='M60.27 43.674A23.131 23.131 0 0 0 50 41.277c-3.704 0-7.189.875-10.27 2.397C32.473 47.259 27.5 54.505 27.5 62.88v14.467c0 2.984 2.52 5.403 5.628 5.403h33.745c3.108 0 5.628-2.419 5.628-5.403V62.88c-.001-8.375-4.974-15.621-12.231-19.206z'
          fill='#c33737'
        />
        <path
          d='M60.27 43.674a23.23 23.23 0 0 0-3.261-1.307L50 52.445l-7.009-10.078a23.23 23.23 0 0 0-3.261 1.307c-4.241 2.095-7.691 5.446-9.836 9.537A45.94 45.94 0 0 0 50 57.815a45.94 45.94 0 0 0 20.106-4.604c-2.144-4.091-5.595-7.442-9.836-9.537z'
          fill='#333'
        />
        <path
          d='M57.009 42.368A23.128 23.128 0 0 0 50 41.277c-2.451 0-4.803.39-7.009 1.091L50 52.445l7.009-10.077z'
          fill='#666'
        />
      </g>
      <circle cx='50' cy='31.763' r='14.513' fill='#c7b299' />
      <g>
        <ellipse cx='58.833' cy='64.377' rx='3.623' ry='2.345' fill='#f47e60' />
        <path
          d='M58.833 60.816s-2.352 1.458-2.352 6.467c1.894-1.589 3.779-3.03 4.704 0 0-5.083-2.352-6.467-2.352-6.467z'
          fill='#f8b26a'
        />
      </g>
    </svg>
  );
};

export default JobApplicants;
