import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import styles from "./MenuItemModal.module.css";

const Backdrop = (props) => {
  const cssClasses = [styles.backdrop, props.show ? styles.backdropOpen : styles.backdropClosed];
  return <div className={cssClasses.join(" ")} />;
};

const Modal = (props) => {
  const cssClasses = [styles.modal, props.show ? styles.modalOpen : styles.modalClosed];
  return (
    <div className={cssClasses.join(" ")}>
      <div className={styles["close-modal"]} onClick={props.onCancel}>
        <svg width='20' height='21' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <line x1='1.35355' y1='0.646447' x2='25.3536' y2='24.6464' stroke='black'></line>
          <line x1='0.646447' y1='24.6464' x2='24.6464' y2='0.646448' stroke='black'></line>
        </svg>
      </div>
      <div className={styles["image-container"]}>
        <img src={props.image.url} alt={props.image.alt} />
      </div>
    </div>
  );
};

const MenuItemModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop show={props.showModal} />, document.getElementById("backdrop-root"))}
      {ReactDOM.createPortal(
        <Modal show={props.showModal} onCancel={props.onCancel} image={props.image} />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default MenuItemModal;
