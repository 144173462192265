import DessertsDesktop from "./DessertsDesktop";
import DessertsMobile from "./DessertsMobile";
import styles from "./Desserts.module.css";

const Desserts = (props) => {
  return (
    <div className={styles.container}>
      <DessertsDesktop onShowModalHandler={props.showModal} />
      <DessertsMobile onShowModalHandler={props.showModal} />
    </div>
  );
};

export default Desserts;
