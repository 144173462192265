import styles from "./MagnifyingGlass.module.css";
const MagnifyingGlass = () => {
  return (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 205.505 205.505' className={styles.svg}>
      <g>
        <g>
          <path
            fill='#010002'
            d='M118.328,121.076c11.474-12.598,17.783-28.81,17.783-45.713c0-37.531-30.524-68.063-68.052-68.063
              C30.528,7.301,0,37.832,0,75.363c0,37.524,30.528,68.059,68.059,68.059c16.957,0,33.212-6.342,45.827-17.88l87.217,72.332
              l0.401,0.329l4.001-4.824L118.328,121.076z M68.055,137.145c-34.074,0-61.785-27.711-61.785-61.785s27.715-61.782,61.785-61.782
              c34.067,0,61.785,27.711,61.785,61.785S102.123,137.145,68.055,137.145z'
          />
          <path
            fill='#010002'
            opacity='0'
            d='M68.045,57.523c-17.719,0-31.748,16.141-32.349,16.828l-0.863,1.013l0.863,1.013
              c0.601,0.687,14.63,16.828,32.349,16.828c17.722,0,31.762-16.137,32.353-16.828l0.87-1.013l-0.87-1.013
              C99.807,73.663,85.767,57.523,68.045,57.523z M97.109,75.363c-3.053,3.167-15.131,14.738-29.064,14.738
              c-13.897,0-26-11.567-29.053-14.738c3.751-3.901,15.389-14.734,29.053-14.734C81.959,60.626,94.056,72.193,97.109,75.363z'
          />
          <path
            opacity='0'
            fill='#010002'
            d='M62.132,75.363c0,3.267,2.648,5.923,5.912,5.923c3.275,0,5.93-2.656,5.93-5.923
              c0-3.26-2.656-5.923-5.93-5.923C64.784,69.44,62.132,72.103,62.132,75.363z'
          />
        </g>
      </g>
    </svg>
  );
};

export default MagnifyingGlass;
