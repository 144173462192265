import AppetizersMobile from "./AppetizersMobile";
import AppetizersDesktop from "./AppetizersDesktop";
import styles from "./AppetizersALB.module.css";

const AppetizersALB = (props) => {
  return (
    <div className={styles.container}>
      <AppetizersMobile onShowModalHandler={props.showModal} />
      <AppetizersDesktop onShowModalHandler={props.showModal} />
    </div>
  );
};

export default AppetizersALB;
