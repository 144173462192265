import React, { Fragment, useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import isDateSupported from "../utils/isDateSupported";
import styles from "./DateFilterModal.module.css";

const Backdrop = (props) => {
  const cssClasses = [styles.backdrop, props.show ? styles.backdropOpen : styles.backdropClosed];
  return <div className={cssClasses.join(" ")} />;
};

const Modal = (props) => {
  // For older versions of Safari that don't support date and time inputs
  const [dateSupported, setDateSupported] = useState(false);
  const cssClasses = [styles.modal, props.show ? styles.modalOpen : styles.modalClosed];
  const dateRef = useRef();

  useEffect(() => {
    if (isDateSupported()) {
      setDateSupported(true);
    }
  }, []);

  return (
    <div className={cssClasses.join(" ")}>
      <div className={styles["modal-header"]}>
        <h2>Filter by Date</h2>
        <div className={styles["close-modal"]} onClick={props.onCancel}>
          <svg width='20' height='21' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <line x1='1.35355' y1='0.646447' x2='25.3536' y2='24.6464' stroke='black'></line>
            <line x1='0.646447' y1='24.6464' x2='24.6464' y2='0.646448' stroke='black'></line>
          </svg>
        </div>
      </div>
      <div className={styles["modal-body"]}>
        <form onSubmit={(ev) => props.onSubmit(ev, dateRef)}>
          <div className={styles.control}>
            <input
              type='date'
              className={styles["date-input"]}
              placeholder={!dateSupported ? "YYYY-DD-MM" : undefined}
              min={!props.minLimit ? "2000-01-02" : new Date().toISOString().split("T")[0]}
              max={props.maxLimit ? new Date().toISOString().split("T")[0] : null}
              ref={dateRef}></input>
          </div>
          <div className={styles["modal-actions"]}>
            <button type='button' className={styles["btn-secondary"]} onClick={props.onCancel}>
              Cancel
            </button>
            <button type='submit' className={styles["btn-primary"]}>
              Filter by Date
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const DateFilterModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop show={props.show} />, document.getElementById("backdrop-root"))}
      {ReactDOM.createPortal(
        <Modal
          minLimit={props.minLimit}
          maxLimit={props.maxLimit}
          show={props.show}
          onCancel={props.onCancel}
          onSubmit={props.onSubmit}
        />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default DateFilterModal;
