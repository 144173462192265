import { v4 as uuid } from "uuid";
import EventCard from "./EventCard";
import PaginationButtons from "../../components/UI/PaginationButtons";
import styles from "./EventsList.module.css";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";

const EventsList = ({ events, year, buttonState, prevPage, showMoreItems }) => {
  return (
    <div className={styles["events-wrapper"]}>
      <h1 className={styles.title}>
        {year === "upcoming" ? (
          <MultiLingualContent contentId='events' prop='label1' />
        ) : (
          <MultiLingualContent contentId='events' prop='label2' />
        )}
      </h1>
      <div className={styles["events-container"]}>
        {events.length > 0
          ? events.map((event) => {
              return (
                <EventCard
                  key={uuid()}
                  id={event.id}
                  name={event.name}
                  date={event.date}
                  imageRef={event.imageRef}
                  djGenres={event.djGenres}
                  type={event.type}
                  description={event.description}
                />
              );
            })
          : ""}
      </div>
      {year === "past" ||
        (events.length === 0 && (
          <p className={styles.noResultNote}>
            {year === "upcoming" ? (
              <MultiLingualContent contentId='events' prop='note1' />
            ) : (
              <MultiLingualContent contentId='events' prop='note2' />
            )}
          </p>
        ))}

      {events.length ? (
        <PaginationButtons buttonState={buttonState} prevPage={prevPage} showMoreItems={showMoreItems} />
      ) : (
        ""
      )}
    </div>
  );
};

export default EventsList;
