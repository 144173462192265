import { useState, useEffect, useContext } from "react";
import { MultiLingualContent } from "../../../../contexts/Language/MultiLingualContent";
import { LanguageContext } from "../../../../contexts/Language/LanguageContext";
import getTranslatedMonth from "../../../../utils/getTranslatedMonth";
import getTranslatedWeekday from "../../../../utils/getTranslatedWeekday";
import noImagePlaceHolder from "../../../../assets/images/No-Image-Placeholder.png";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import styles from "./HistoryItem.module.css";
// FIREBASE
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const HistoryItem = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const { language } = useContext(LanguageContext);
  // Get image reference from storage
  useEffect(() => {
    if (props.imageRef) {
      const storage = getStorage();
      const pathReference = ref(storage, props.imageRef);

      getDownloadURL(pathReference)
        .then((url) => {
          // `url` is the download URL for 'bookcovers/*'
          setImageUrl(url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
          setImageUrl(noImagePlaceHolder);
        });
    } else {
      setImageUrl(noImagePlaceHolder);
    }
  }, [props.imageRef]);

  let dateBorrowedWeekday = props.dateBorrowed.toDate().toLocaleString("default", { weekday: "long" });
  let dateBorrowedMonth = props.dateBorrowed.toDate().toLocaleString("default", { month: "long" });
  let dateBorrowedDay = props.dateBorrowed.toDate().toLocaleString("default", { day: "numeric" });
  let dateBorrowedYear = props.dateBorrowed.toDate().toLocaleString("default", { year: "numeric" });

  let dateReturnedWeekday = props.dateReturned.toDate().toLocaleString("default", { weekday: "long" });
  let dateReturnedMonth = props.dateReturned.toDate().toLocaleString("default", { month: "long" });
  let dateReturnedDay = props.dateReturned.toDate().toLocaleString("default", { day: "numeric" });
  let dateReturnedYear = props.dateReturned.toDate().toLocaleString("default", { year: "numeric" });

  if (language === "albanian") {
    dateBorrowedWeekday = getTranslatedWeekday(dateBorrowedWeekday);
    dateBorrowedMonth = getTranslatedMonth(dateBorrowedMonth);
    dateReturnedWeekday = getTranslatedWeekday(dateReturnedWeekday);
    dateReturnedMonth = getTranslatedMonth(dateReturnedMonth);
  }

  return (
    <li className={styles["history-item"]}>
      <img src={imageUrl} className='skeleton' alt='' />
      <div className={styles["book-data"]}>
        <div>
          <h3 className={styles.title}>{capitalizeFirstLetter(props.title)}</h3>
          <span className={styles.author}>{capitalizeFirstLetter(props.author)}</span>
          <div className={styles.date}>
            <MultiLingualContent contentId='myLibrary' prop='label11' /> {dateBorrowedWeekday},{" "}
            {dateBorrowedDay} {dateBorrowedMonth} {dateBorrowedYear}
          </div>
          <div className={styles.date}>
            <MultiLingualContent contentId='myLibrary' prop='label12' /> {dateReturnedWeekday},{" "}
            {dateReturnedDay} {dateReturnedMonth} {dateReturnedYear}
          </div>
        </div>
      </div>
    </li>
  );
};

export default HistoryItem;
