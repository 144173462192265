const Moon = () => {
  return (
    <svg
      width='30px'
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 100 100'
      enableBackground='new 0 0 100 100'>
      <path
        fill='#FEE1C5'
        stroke='#323334'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d=' M71,62c-18.23,0-33-14.77-33-33c0-3.61,0.59-7.09,1.66-10.34C26.5,23,17,35.39,17,50c0,18.23,14.77,33,33,33 c14.61,0,27-9.5,31.34-22.66C78.09,61.41,74.61,62,71,62z'
      />
    </svg>
  );
};

export default Moon;
