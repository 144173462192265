const roles = [
  "Bartender",
  "Waiter",
  "Librarian",
  "Events Manager",
  "Kitchen",
  "Cleaner",
  "Finances",
  "Warehouse",
  "Other",
];

export default roles;
