import styles from "./Bowl.module.css";

const Bowl = () => {
  return (
    <svg className={styles.svg} version='1.1' id='Layer_1' x='0' y='0' viewBox='0 0 100 100'>
      <path
        d='M32.2 49.9c4.8-4.1 11-6.6 17.7-6.6 6.5 0 12.5 2.3 17.2 6.2'
        fill='none'
        stroke='#f4e6c8'
        strokeWidth='1.649'
        strokeMiterlimit='10'
      />
      <path
        d='M32.2 52.4c4.8-4.1 11-6.6 17.7-6.6 6.5 0 12.5 2.3 17.2 6.2'
        fill='none'
        stroke='#f4e6c8'
        strokeWidth='1.649'
        strokeMiterlimit='10'
      />
      <path
        d='M32.2 54.9c4.8-4.1 11-6.6 17.7-6.6 6.5 0 12.5 2.3 17.2 6.2'
        fill='none'
        stroke='#f4e6c8'
        strokeWidth='1.649'
        strokeMiterlimit='10'
      />
      <path
        d='M27.4 50.5c6.1-6.3 14-10.2 22.6-10.2 8.3 0 16 3.6 22 9.5'
        strokeWidth='2.303'
        fill='none'
        stroke='#f4e6c8'
        strokeMiterlimit='10'
      />
      <path
        d='M24.7 49c6.8-7.6 15.7-12.2 25.3-12.2 9.3 0 17.9 4.3 24.7 11.4'
        strokeWidth='2.666'
        fill='none'
        stroke='#f4e6c8'
        strokeMiterlimit='10'
      />
      <path
        d='M81.9 50.7c.2-1.9-1.1-3.5-2.8-3.5H21c-1.7 0-3 1.6-2.8 3.5 1.2 13.6 9.6 25 20.9 29.5l-.3 3.6c-.2 2 1.4 3.6 3.3 3.6h16c2 0 3.5-1.7 3.3-3.6l-.4-3.6c11.3-4.5 19.7-15.9 20.9-29.5z'
        fill='#e0e0e0'
      />
      <g>
        <path
          d='M50.5 12.5c-.2 1.5-.4 3-.4 4.4 0 1.4.4 2.7.7 4.1.4 1.4.8 3 .6 4.7-.2 1.6-.7 3.2-1.8 4.3.3-1.5.3-3 .2-4.3-.1-1.4-.6-2.7-1-4.1-.4-1.4-.7-3.1-.4-4.8.4-1.6 1.1-3 2.1-4.3z'
          fill='#333'
        />
      </g>
      <g>
        <path
          d='M40.5 14.5c-.2 1.5-.4 3-.4 4.4 0 1.4.4 2.7.7 4.1.4 1.4.8 3 .6 4.7-.2 1.6-.7 3.2-1.8 4.3.3-1.5.3-3 .2-4.3-.1-1.4-.6-2.7-1-4.1-.4-1.4-.7-3.1-.4-4.8.4-1.6 1.1-3 2.1-4.3z'
          fill='#333'
        />
      </g>
      <g>
        <path
          d='M60.5 14.5c-.2 1.5-.4 3-.4 4.4 0 1.4.4 2.7.7 4.1.4 1.4.8 3 .6 4.7-.2 1.6-.7 3.2-1.8 4.3.3-1.5.3-3 .2-4.3-.1-1.4-.6-2.7-1-4.1-.4-1.4-.7-3.1-.4-4.8.4-1.6 1.1-3 2.1-4.3z'
          fill='#333'
        />
      </g>
      <path d='M18.4 53.4h63.1c.1-.7.2-1.3.3-2H18.2c.1.6.1 1.3.2 2z' fill='#d65a62' />
      <path d='M19.3 57.4h61.3c.2-.7.3-1.3.5-2H18.8c.2.6.3 1.3.5 2z' fill='#d65a62' />
    </svg>
  );
};

export default Bowl;
