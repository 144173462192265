import styles from "./Kitchen.module.css";

const Kitchen = () => {
  return (
    <svg
      className={styles.svg}
      version='1.1'
      id='圖層_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 128 128'
      enableBackground='new 0 0 128 128'>
      <path fill='none' />
      <g>
        <path
          fill='#E15C64'
          stroke='#333435'
          strokeWidth='4.48'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='12.8'
          d='M53.82,68.94c0-6.22,4.56-11.28,10.18-11.28c5.62,0,10.18,5.04,10.18,11.28H53.82z'
        />
        <path
          fill='#E15C64'
          stroke='#333435'
          strokeWidth='4.48'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='12.8'
          d='M97.28,81.18c0-20.13-66.56-20.13-66.56,0H97.28z'
        />
      </g>
      <path
        fill='#C33837'
        stroke='#333435'
        strokeWidth='4.48'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='12.8'
        d='M84.4,109.15H43.6c-9.48,0-17.16-7.68-17.16-17.16V75.66c0-0.86,0.83-1.46,1.65-1.2l5.02,1.64c8.78,2.87,17.97,4.33,27.21,4.33h7.39 c9.24,0,18.42-1.46,27.21-4.31l5.02-1.64c0.82-0.27,1.65,0.35,1.65,1.2v16.31C101.57,101.45,93.89,109.15,84.4,109.15z'
      />
      <line
        fill='none'
        stroke='#333435'
        strokeWidth='4.48'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='12.8'
        x1='101.57'
        y1='86.86'
        x2='115.2'
        y2='78.09'
      />
      <line
        fill='none'
        stroke='#333435'
        strokeWidth='4.48'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='12.8'
        x1='26.43'
        y1='86.86'
        x2='12.8'
        y2='78.09'
      />
      <path
        fill='none'
        stroke='#333435'
        strokeWidth='4.48'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='12.8'
        d='M99.29,37.88c-3.99,3.01-5.13,8.52-2.68,12.88l0,0c2.47,4.35,1.33,9.86-2.68,12.88'
      />
      <path
        fill='none'
        stroke='#333435'
        strokeWidth='4.48'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='12.8'
        d='M84.76,18.85c4.84,5.38,4.94,13.52,0.23,19.02l0,0c-4.71,5.49-4.61,13.63,0.23,19.02'
      />
      <path fill='none' />
    </svg>
  );
};

export default Kitchen;
