import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../contexts/Language/LanguageContext";
import getTranslatedMonth from "../../utils/getTranslatedMonth";
import getTranslatedWeekday from "../../utils/getTranslatedWeekday";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";

import ReservationMessage from "../../assets/icons/ReservationMessage";
import styles from "./ApplicationMessagePage.module.css";

const ApplicationMessagePage = () => {
  const navigate = useNavigate();
  const [interviewDay, setInterviewDay] = useState();

  const { language } = useContext(LanguageContext);

  const onGoBackHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    const now = new Date();
    const day = now.getDay();

    if (language === "albanian") {
      if (day === 1 || (day > 1 && day < 6)) {
        now.setDate(now.getDate() + (6 - day));

        let weekday = now.toLocaleString("default", { weekday: "long" });
        let month = now.toLocaleString("default", { month: "long" });
        let dayNumber = now.toLocaleString("default", { day: "numeric" });
        let year = now.toLocaleString("default", { year: "numeric" });
        const nextAllocatedDate = `${getTranslatedWeekday(weekday)}, ${dayNumber} ${getTranslatedMonth(
          month
        )} ${year}`;

        setInterviewDay(nextAllocatedDate);
      }

      if (day === 6 || day === 0) {
        now.setDate(now.getDate() + (day === 6 ? 2 : 1));

        let weekday = now.toLocaleString("default", { weekday: "long" });
        let month = now.toLocaleString("default", { month: "long" });
        let dayNumber = now.toLocaleString("default", { day: "numeric" });
        let year = now.toLocaleString("default", { year: "numeric" });
        const nextAllocatedDate = `${getTranslatedWeekday(weekday)}, ${dayNumber} ${getTranslatedMonth(
          month
        )} ${year}`;

        setInterviewDay(nextAllocatedDate);
      }
    } else {
      if (day === 1 || (day > 1 && day < 6)) {
        now.setDate(now.getDate() + (6 - day));
        let options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

        const nextAllocatedDate = now.toLocaleString("default", options);

        setInterviewDay(nextAllocatedDate);
      }

      if (day === 6 || day === 0) {
        now.setDate(now.getDate() + (day === 6 ? 2 : 1));
        let options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
        const nextAllocatedDate = now.toLocaleString("default", options);

        setInterviewDay(nextAllocatedDate);
      }
    }
  }, [language]);
  return (
    <div className={styles["page-wrapper"]}>
      <div className={styles.container}>
        <nav className={styles.nav}>
          &lt;
          <span onClick={onGoBackHandler}>
            <MultiLingualContent contentId='careers' prop='button2' />
          </span>
        </nav>
        <div className={styles.body}>
          <div className={styles.content}>
            <ReservationMessage />
            <p>
              <MultiLingualContent contentId='careers' prop='messageP1' />
            </p>
            <p className={styles["interview-details"]}>
              <span>{interviewDay}</span>{" "}
              <span>
                <MultiLingualContent contentId='careers' prop='messageP2span1' />
              </span>{" "}
              <span>
                <MultiLingualContent contentId='careers' prop='messageP2span2' />
              </span>
            </p>
            <p>
              <MultiLingualContent contentId='careers' prop='messageP3' />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationMessagePage;
