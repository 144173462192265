import img1 from "../../../../assets/images/foodAndDrinks/menuPhotos/salads/1.jpg";
import img2 from "../../../../assets/images/foodAndDrinks/menuPhotos/salads/2.jpg";
import img3 from "../../../../assets/images/foodAndDrinks/menuPhotos/salads/3.jpg";
import img4 from "../../../../assets/images/foodAndDrinks/menuPhotos/salads/4.jpg";

export const SaladsImages = [
  {
    url: img1,
    alt: "Green and purple leaves with a dressing on a gray plate and stick of bread placed on the side of the plate",
  },
  {
    url: img2,
    alt: "A plate seen from above with cubes of red beetroot in the center, surrounden by green rucola leaves and sprinkled with pieces of white cheese and walnuts",
  },
  {
    url: img3,
    alt: "Slices of fresh tuna with sesame seeds on top of spinach leaves and whole roasted potatoes",
  },
  {
    url: img4,
    alt: "A shallow plate with a mixture of leaves, crutons, cherry tomatoes, chicken and garted parmesan on top. A hand holding a stainless steel vinegar bottle and pouring dark balsmaic vinegar on the salad",
  },
];
