import styles from "./PrintableJobApplicationsRow.module.css";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
const PrintableJobApplicationsRow = (props) => {
  let options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let interviewDate = props.date.toDate().toLocaleString("default", options);
  return (
    <tr className={styles.tr}>
      <td data-label='NAME'>{capitalizeFirstLetter(props.name)}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='TYPE OF ROLE'>{props.typeOfRole}</td>
      <td data-label='APPLYING FOR'>{props.role}</td>
      <td data-label='INTERVIEW DATE'>{interviewDate}</td>
    </tr>
  );
};

export default PrintableJobApplicationsRow;
