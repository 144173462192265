import { Fragment, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import FlashMessage from "../../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../../Modals/DeleteModal";
import DeleteRecordToolbar from "./DeleteRecordToolbar";
import SearchResultRow from "./SearchResultRow";
import NextButton from "../../../../../components/UI/NextButton";
import styles from "./DeleteRecord.module.css";

// FIREBASE
import {
  getDocs,
  getFirestore,
  collection,
  doc,
  query,
  where,
  deleteDoc,
  limit,
  startAfter,
  getDoc,
  setDoc,
} from "firebase/firestore";

import { deleteObject, ref, getStorage } from "firebase/storage";
const DeleteRecord = () => {
  const [alert, setAlert] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [searchObj, setSearchObj] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [recordId, setRecordId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [last, setLast] = useState();

  useEffect(() => {
    const findRecordToDelete = async (obj) => {
      if (obj === null) {
        return;
      }

      let { searchCriteria, searchValue } = obj;

      if (searchValue === "") {
        return;
      }
      setSearchData([]);
      const db = getFirestore();
      const q = query(
        collection(db, "records"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff"),
        limit(5)
      );
      setIsPending(true);

      try {
        const querySnapshot = await getDocs(q);

        // FOR PAGINATION
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLast(lastVisible);

        if (querySnapshot.empty) {
          setIsPending(false);
          return;
        }

        querySnapshot.forEach((doc) => {
          setSearchData((prevState) => {
            return [...prevState, { ...doc.data(), docId: doc.id }];
          });
        });
      } catch (error) {
        console.log(error);
      }

      setIsPending(false);
    };
    findRecordToDelete(searchObj);
  }, [searchObj]);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const deleteRecordHandler = (id) => {
    setRecordId(id);
    setShowDeleteModal(true);
  };

  const onConfirmDeleteRecordHandler = async () => {
    const db = getFirestore();
    const storage = getStorage();

    let recordRef = doc(db, "records", recordId);
    let recordSnap = await getDoc(recordRef);

    let imgRef = recordSnap.data().imageRef;

    if (imgRef) {
      // Delete bookcover image associate with the book doc in books collection
      // Create a reference to the file to delete
      const storedImgRef = ref(storage, imgRef);

      // Delete the file
      deleteObject(storedImgRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error);
        });
    }

    // Decrement counters
    const docRef = doc(db, "recordCategoryCounters", "counters");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let genreArr = recordSnap.data().genre;
      let countersObj = docSnap.data();

      genreArr.forEach((category) => {
        countersObj[category] -= 1;
      });
      await setDoc(docRef, countersObj);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

    await deleteDoc(recordRef);

    setSearchData((prevState) => {
      return [...prevState].filter((record) => record.docId !== recordId);
    });
    setShowDeleteModal(false);
    flashMessageHandler({ message: "Record Deleted from Library", success: true });
  };

  const onCancelDeleteRecordHandler = () => {
    setShowDeleteModal(false);
    setRecordId("");
  };

  const showMoreItemsHandler = async () => {
    const db = getFirestore();
    let { searchCriteria, searchValue } = searchObj;
    const next = query(
      collection(db, "records"),
      where(searchCriteria, ">=", searchValue.toLowerCase()),
      where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff"),
      startAfter(last),
      limit(5)
    );
    const querySnapshot = await getDocs(next);

    if (querySnapshot.empty) {
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLast(lastVisible);

    querySnapshot.forEach((doc) => {
      setSearchData((prevState) => {
        return [...prevState, { ...doc.data(), docId: doc.id }];
      });
    });
  };
  return (
    <Fragment>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <DeleteModal
        showModal={showDeleteModal}
        onConfirm={onConfirmDeleteRecordHandler}
        onCancel={onCancelDeleteRecordHandler}
        message={"Are you sure you want to delete this record?"}
      />
      <DeleteRecordToolbar onChangeSearchCriteria={setSearchObj} />
      {isPending ? (
        "Loading..."
      ) : searchData.length > 0 ? (
        <table className={styles.books}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Artist</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {searchData.map((data) => {
              return (
                <SearchResultRow
                  key={uuid()}
                  id={data.id}
                  docId={data.docId}
                  title={data.title}
                  artist={data.artist}
                  onDeleteRecord={deleteRecordHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : !searchObj ? (
        <p>Search for a record...</p>
      ) : (
        <p>No results</p>
      )}
      {searchData.length > 0 ? <NextButton onClick={showMoreItemsHandler} /> : ""}
    </Fragment>
  );
};

export default DeleteRecord;
