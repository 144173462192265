import img1 from "../../../../assets/images/foodAndDrinks/menuPhotos/toshare/1.jpg";
import img2 from "../../../../assets/images/foodAndDrinks/menuPhotos/toshare/2.jpg";
import img3 from "../../../../assets/images/foodAndDrinks/menuPhotos/toshare/3.jpg";
import img4 from "../../../../assets/images/foodAndDrinks/menuPhotos/toshare/4.jpg";
import img5 from "../../../../assets/images/foodAndDrinks/menuPhotos/toshare/5.jpg";
import img6 from "../../../../assets/images/foodAndDrinks/menuPhotos/toshare/6.jpg";
import img7 from "../../../../assets/images/foodAndDrinks/menuPhotos/toshare/7.jpg";
import img8 from "../../../../assets/images/foodAndDrinks/menuPhotos/toshare/8.jpg";

export const ToShareImages = [
  {
    url: img1,
    alt: "A wooden board filled with beautifully arranged slices of cured meats, green shiny olives, white cheese and two bread sticks on the side.In the background is a plate with two roasted peppers.",
  },
  {
    url: img2,
    alt: "Three roasted sausages of different sizes and thicknesess on a wooden board. On the side aplate with thin quarters of griled pita bread, red sauce and white cheese",
  },
  {
    url: img3,
    alt: "Deep fried wedges on a long plate covered with yellow melted cheese and grated cheese",
  },
  {
    url: img4,
    alt: "A handful of deep fried whole shrimps on a plate, with a ramiken on the side. A galss of white wine, a pot with a plant and a bottle in the background",
  },
  {
    url: img5,
    alt: "Fingers holding a golden zuchinni ball that has been dipped in a white sauce. Four other zcuchinni balls are on the plate",
  },
  {
    url: img6,
    alt: "Triangles of golden pastry filled with minced beef and corn. A ramiken filled with red sauce on the side",
  },
  {
    url: img7,
    alt: "Deep fried crunchy chicken on a plastic bowl on top of a wrapping paper, placed on a plate with a side of deep fried potatoes. Two ramikens on the side filled with tomato sauce and mayonaisse. A book in the background",
  },
  {
    url: img8,
    alt: "Golden deep fried sticks layered on a plate with a ramiken filled with red sauce. A hand hovers above holding already dipped cheese stick on the sauce",
  },
];
