import React, { Fragment } from "react";
import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import styles from "./Kitchen.module.css";
import image1 from "../../../assets/images/collages_kitchen/pastaDough1.jpg";
import image2 from "../../../assets/images/collages_kitchen/burgerWithHandeCutFries.jpg";
import image3 from "../../../assets/images/collages_kitchen/homemadeSausages.jpg";
import image4 from "../../../assets/images/collages_kitchen/sharrcheese.jpg";
import image5 from "../../../assets/images/collages_kitchen/rissoto.jpg";
import image6 from "../../../assets/images/collages_kitchen/homemadeBrioche.jpg";
import image7 from "../../../assets/images/collages_kitchen/bar.jpg";
import image8 from "../../../assets/images/collages_kitchen/scotchWhiskeys.jpg";
import image9 from "../../../assets/images/collages_kitchen/sabajaBeer.jpg";
import image10 from "../../../assets/images/collages_kitchen/gin.jpg";
import image11 from "../../../assets/images/collages_kitchen/cocktailSignature.jpg";
import image12 from "../../../assets/images/collages_kitchen/kraken.jpg";
const Kitchen = () => {
  return (
    <Fragment>
      <section className={styles.kitchen}>
        <h1>
          <MultiLingualContent contentId='foodAndDrinks' prop='title1' />
        </h1>
        <div className={styles["collage-container"]}>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image1} alt='' />
          </div>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image2} alt='' />
          </div>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image3} alt='' />
          </div>
        </div>
        <p>
          <MultiLingualContent contentId='foodAndDrinks' prop='kitchenAndBarP1' />
        </p>
        <div className={styles["collage-container"]}>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image4} alt='Two chairs a table and a sofa' />
          </div>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image5} alt='Restaurant table with a laptop' />
          </div>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img
              src={image6}
              alt='A bottle of Kraken Black Spiced Rum and a glass filled one third with it '
            />
          </div>
        </div>
        <p>
          <MultiLingualContent contentId='foodAndDrinks' prop='kitchenAndBarP2' />
        </p>

        <p>
          <MultiLingualContent contentId='foodAndDrinks' prop='kitchenAndBarP3' />
        </p>

        <p>
          {" "}
          <MultiLingualContent contentId='foodAndDrinks' prop='kitchenAndBarP4' />
        </p>
      </section>
      <section className={styles.bar}>
        <h1>
          <MultiLingualContent contentId='foodAndDrinks' prop='title2' />
        </h1>
        <div className={styles["collage-container"]}>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image7} alt='' />
          </div>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image8} alt='' />
          </div>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image9} alt='' />
          </div>
        </div>

        <p>
          <MultiLingualContent contentId='foodAndDrinks' prop='kitchenAndBarP5' />
        </p>

        <p>
          <MultiLingualContent contentId='foodAndDrinks' prop='kitchenAndBarP6' />
        </p>

        <div className={styles["collage-container"]}>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image10} alt='' />
          </div>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image11} alt='' />
          </div>
          <div className={`${styles["collage-image"]} skeleton`}>
            <img src={image12} alt='' />
          </div>
        </div>

        <p>
          <MultiLingualContent contentId='foodAndDrinks' prop='kitchenAndBarP7' />
        </p>
        <p>
          <MultiLingualContent contentId='foodAndDrinks' prop='kitchenAndBarP8' />
        </p>
      </section>
    </Fragment>
  );
};

export default Kitchen;
