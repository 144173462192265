import Trash from "../../../../../assets/icons/Trash";
import styles from "./RecentlyAddedBooksRow.module.css";

const RecentlyAddedBooksRow = (props) => {
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  let dateAdded = props.dateAdded.toDate().toLocaleString("default", options);
  let expires = props.expires.toDate().toLocaleString("default", options);
  return (
    <tr className={styles.tr}>
      <td data-label='TITLE'>{props.title}</td>
      <td data-label='AUTHOR'>{props.author}</td>
      <td data-label='DATE ADDED'>{dateAdded}</td>
      <td data-label='EXPIRES'>{expires}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onDeleteEvent(props.docId)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default RecentlyAddedBooksRow;
