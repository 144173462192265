import { useState, useEffect } from "react";
import isDateSupported from "../../../../utils/isDateSupported";

import { reservationTimes } from "../../../Reservations/reservationTimes";
import styles from "./EditReservationForm.module.css";

// FIREBASE

import { Timestamp } from "firebase/firestore";

const EditReservationForm = (props) => {
  // For older versions of Safari that don't support date and time inputs
  const [dateSupported, setDateSupported] = useState(false);
  const [partySize] = useState(Array.from({ length: 20 }));

  useEffect(() => {
    if (isDateSupported()) {
      setDateSupported(true);
    }
  }, []);

  const onChangeNameHandler = (ev) => {
    props.editReservation((prevState) => {
      return {
        ...prevState,
        fullName: ev.target.value,
      };
    });
  };
  const onChangePhoneHandler = (ev) => {
    props.editReservation((prevState) => {
      return {
        ...prevState,
        phone: ev.target.value,
      };
    });
  };

  const onChangeDateHandler = (ev) => {
    props.editReservation((prevState) => {
      return {
        ...prevState,
        date: Timestamp.fromDate(new Date(ev.target.value)),
      };
    });
  };

  const onChangeTimeHandler = (ev) => {
    props.editReservation((prevState) => {
      return {
        ...prevState,
        time: ev.target.value,
      };
    });
  };

  const onChangeNumberOfPeopleHandler = (ev) => {
    props.editReservation((prevState) => {
      return {
        ...prevState,
        numberOfPeople: ev.target.value.trim(),
      };
    });
  };
  const onChangeEmailHandler = (ev) => {
    props.editReservation((prevState) => {
      return {
        ...prevState,
        email: ev.target.value.trim(),
      };
    });
  };
  const onChangeSpecialRequirementsHandler = (ev) => {
    props.editReservation((prevState) => {
      return {
        ...prevState,
        specialRequest: ev.target.value,
      };
    });
  };

  const onChangeTypeOfServiceHandler = (ev) => {
    props.editReservation((prevState) => {
      return {
        ...prevState,
        typeOfService: ev.target.value,
      };
    });
  };
  const onChangeTableNumberHandler = (ev) => {
    props.editReservation((prevState) => {
      return {
        ...prevState,
        tableNumber: ev.target.value,
      };
    });
  };

  return (
    <form onSubmit={props.onSubmit}>
      <div className={styles.controls}>
        <label htmlFor='customerName'>Full Name</label>
        <input
          type='text'
          id='customerName'
          placeholder='Customer Name'
          autoComplete='off'
          aria-label='Customer Name'
          required
          value={props.reservation.fullName}
          onChange={onChangeNameHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='customerPhone'>Phone Number</label>
        <input
          type='tel'
          id='customerPhone'
          placeholder='Phone Number'
          autoComplete='off'
          aria-label='Customer Phone'
          required
          value={props.reservation.phone}
          onChange={onChangePhoneHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='customerEmail'>Email</label>
        <input
          type='email'
          id='customerEmail'
          placeholder='Email'
          autoComplete='off'
          aria-label='Customer Email'
          required
          value={props.reservation.email}
          onChange={onChangeEmailHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='reservationDate'>Date</label>
        <label htmlFor='date'>
          {!dateSupported && (
            <span className={styles["description-date"]}>Please use the YYYY-DD-MM format.</span>
          )}
        </label>
        <input
          type='date'
          id='reservationDate'
          pattern='[0-9]{4}(/|-)[0-9]{1,2}(/|-)[0-9]{1,2}'
          title='Please type in the date in this format'
          placeholder={!dateSupported ? "YYYY-DD-MM" : "Date"}
          autoComplete='off'
          min={new Date().toISOString().split("T")[0]}
          aria-label='Reservation Date'
          required
          value={props.reservation.date ? props.reservation.date.toDate().toISOString().split("T")[0] : ""}
          onChange={onChangeDateHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='reservationTime'>Time</label>
        <select
          id='reservationTime'
          value={props.reservation.time ? props.reservation.time : ""}
          onChange={onChangeTimeHandler}>
          {reservationTimes.map((time, i) => {
            return <option key={i}>{time.label}</option>;
          })}
        </select>
      </div>
      <div className={styles.controls}>
        <label htmlFor='numberOfPeople'>Number of People</label>
        <select
          className={styles.select}
          id='numberOfPeople'
          value={props.reservation.numberOfPeople ? props.reservation.numberOfPeople : ""}
          onChange={onChangeNumberOfPeopleHandler}>
          {partySize.map((v, i) => {
            return (
              <option key={i} value={i + 1} label={`${i + 1} ${i + 1 === 1 ? "person" : "people"}`}>
                {i + 1} {i + 1 === 1 ? "person" : "people"}
              </option>
            );
          })}
        </select>
      </div>
      <div className={styles.controls}>
        <label htmlFor='specialRequest'>Special Request</label>
        <input
          type='text'
          placeholder='Special request (optional)'
          id='specialRequest'
          value={props.reservation.specialRequest}
          onChange={onChangeSpecialRequirementsHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='typeOfService'>Type of Service</label>
        <select
          className={styles.select}
          id='typeOfService'
          value={props.reservation.typeOfService}
          onChange={onChangeTypeOfServiceHandler}
          required>
          <option value='food and drinks'>Food and Drinks</option>
          <option value='drinks only'>Drinks Only</option>
        </select>
      </div>
      <div className={styles.controls}>
        <label htmlFor='tableNumber'>Table Number</label>
        <input
          type='text'
          placeholder='Table Number'
          id='tableNumber'
          value={props.reservation.tableNumber}
          onChange={onChangeTableNumberHandler}
          required
        />
      </div>
      <div className={styles["modal-actions"]}>
        <button type='button' className={styles["btn-secondary"]} onClick={props.onCancel}>
          Cancel Edit
        </button>
        <button type='submit' className={styles["btn-primary"]}>
          Edit Reservation
        </button>
      </div>
    </form>
  );
};

export default EditReservationForm;
