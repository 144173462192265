import styles from "./FriedDesktop.module.css";

const FriedDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='toShare'>
        Pjatë e Ftohtë - Djath Rugove, sienice, përshutë viçi, derri, ullinjë, fruta, shkopinjë, buke 12.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='toShare'>
        Përzgjedhje Suxhuku - Suxhuk viçi dhe derri, kajmak, Tzatziki, dhe bukë petë ................. 7.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='toShare'>
        Patate - Patate me majonez të djegëst dhe parmezan ........................................... 3.50
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='toShare'>
        Karkaleca në Friturë - karkaleca dhe majonez i djegëst ....................................... 8.50
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='toShare'>
        Kungulleshka të Fërguara - me djath të bardhë, shërbehet me Tzatziki ......................... 3.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='toShare'>
        Pachanga - Mish viçi, misër, kaçkavall në petë të fërguara, shërbehet me majonez të djegëst .. 5.50
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='toShare'>
        Copëza Pule Krokante - me patate, shërbehet me salcë BBQ dhe majonez të djegëst .............. 6.50
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='toShare'>
        Kaçkavall në Friturë - kaçkavall i mbështjellun në copëza buke, shërbehet me salce domatesh .. 6.00
      </li>
    </ul>
  );
};

export default FriedDesktop;
