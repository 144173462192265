import SaladsMobile from "./SaladsMobile";
import SaladsDesktop from "./SaladsDesktop";
import styles from "./SaladsALB.module.css";

const SaladsALB = (props) => {
  return (
    <div className={styles.container}>
      <SaladsMobile onShowModalHandler={props.showModal} />
      <SaladsDesktop onShowModalHandler={props.showModal} />
    </div>
  );
};

export default SaladsALB;
