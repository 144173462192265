import React from "react";
import { v4 as uuidv4 } from "uuid";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import styles from "./OpeningHoursContact.module.css";

const OpeningHoursContact = () => {
  // Add id's to days

  let weekdays = ["m", "t", "w", "th", "f", "sat", "sun"];
  return (
    <div className={styles["opening-hours-outter"]}>
      <h1>
        <MultiLingualContent contentId='contact' prop='title3' />
      </h1>
      <div className={styles["opening-hours-inner"]}>
        {weekdays.map((d) => {
          if (d === "sun") {
            return (
              <p key={uuidv4()} className={styles.item}>
                <MultiLingualContent contentId='reservations' prop={d} />
                <br />
                <MultiLingualContent contentId='reservations' prop='label2' />
              </p>
            );
          } else {
            return (
              <p key={uuidv4()} className={styles.item}>
                <MultiLingualContent contentId='reservations' prop={d} />
                <br />
                08:00-23:00
              </p>
            );
          }
        })}
      </div>
    </div>
  );
};

export default OpeningHoursContact;
