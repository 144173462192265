import { useRef, useState } from "react";
import SearchGlassIcon from "../../../../assets/icons/SearchGlassIcon";

import styles from "./FlaggedGuestsToolbar.module.css";

const FlaggedGuestsToolbar = (props) => {
  const [searchCriteria, setSearchCriteria] = useState("fullName");
  const searchValueRef = useRef();
  const onSubmitHandler = (ev) => {
    ev.preventDefault();

    let obj = {
      searchCriteria,
      searchValue: searchValueRef.current.value.trim(),
    };
    props.onSearch(obj);
    searchValueRef.current.value = "";
  };
  return (
    <div>
      <hr className={styles.hr} />
      <div className={styles["filters-toolbar"]}>
        <form className={styles.form} onSubmit={onSubmitHandler}>
          <div className={styles.control}>
            <label className={styles.label} htmlFor='SortBy'>
              Search by:
            </label>
            <div className={styles["select-group-wrapper"]}>
              <select
                name='searchFilter'
                id='searchFilter'
                className={styles.select}
                defaultValue={props.searchCriteria}
                onChange={(ev) => setSearchCriteria(ev.target.value)}>
                <option value='fullName'>Name</option>
                <option value='email'>Email</option>
                <option value='phone'>Phone</option>
              </select>
              <input ref={searchValueRef} type='text' placeholder='Search...'></input>
              <button className={styles["search-btn"]}>
                <SearchGlassIcon />
              </button>
            </div>
          </div>
          <div className={styles["flag-control"]}>
            <button type='button' className={styles.primary} onClick={props.onShowAddGuestModal}>
              FLAG A GUEST
            </button>
          </div>
        </form>
      </div>

      <hr className={styles.hr} />
    </div>
  );
};

export default FlaggedGuestsToolbar;
