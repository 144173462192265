import img1 from "../../assets/images/gallery/somaAtNight/entrance.jpg";
import img2 from "../../assets/images/gallery/somaAtNight/records_soma.jpg";
import img3 from "../../assets/images/gallery/somaAtNight/tapbeer.jpg";
import img4 from "../../assets/images/gallery/somaAtNight/bar1.jpg";
import img5 from "../../assets/images/gallery/somaAtNight/umbrella.jpg";

import img6 from "../../assets/images/gallery/somaAtNight/discoball.jpg";
import img7 from "../../assets/images/gallery/somaAtNight/framedpicture.jpg";
import img8 from "../../assets/images/gallery/somaAtNight/cocktail1.jpg";
import styles from "./SomaAtNight.module.css";

const SomaAtNight = () => {
  return (
    <div className={styles["image-container"]}>
      <div className={styles.image}>
        <img src={img1} alt='' className='skeleton' />
      </div>
      <div className={styles.image}>
        <img src={img2} alt='' className='skeleton' />
      </div>
      <div className={styles.image}>
        <img src={img3} alt='' className='skeleton' />
      </div>
      <div className={styles.image}>
        <img src={img4} alt='' className='skeleton' />
      </div>
      <div className={styles.image}>
        <img src={img5} alt='' className='skeleton' />
      </div>
      <div className={styles.image}>
        <img src={img6} alt='' className='skeleton' />
      </div>
      <div className={styles.image}>
        <img src={img7} alt='' className='skeleton' />
      </div>
      <div className={styles.image}>
        <img src={img8} alt='' className='skeleton' />
      </div>
    </div>
  );
};

export default SomaAtNight;
