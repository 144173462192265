import { Fragment, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import FlashMessage from "../../../../../components/UI/FlashMessage";
import styles from "./RecentlyAddedBookForm.module.css";

// FIREBASE
import { getFirestore, addDoc, collection, Timestamp } from "firebase/firestore";
import { getStorage, uploadString, ref } from "firebase/storage";

const RecentlyAddedBookForm = (props) => {
  const bookIdRef = useRef();
  const bookTitleRef = useRef();
  const bookAuthorRef = useRef();
  const uploadedImageRef = useRef();

  const [alert, setAlert] = useState("");

  const [imgUrl, setImgUrl] = useState("");
  const [imgName, setImgName] = useState("");

  // ****** Use expire as timestamp to remove recent books from the collection after a month

  const onSubmitHandler = async (ev) => {
    ev.preventDefault();

    // Expires
    let now = new Date();
    now.setDate(now.getDate() + 31);

    // The book object is stored in firestore collection of books
    const recentlyAddedBook = {
      id: bookIdRef.current.value.trim(),
      title: bookTitleRef.current.value.toLowerCase().trim(),
      author: bookAuthorRef.current.value.toLowerCase().trim(),
      imageRef: imgName ? `recentlyAddedBooks/${imgName}` : "",
      dateAdded: Timestamp.now(),
      expires: Timestamp.fromDate(now),
    };
    if (imgUrl) {
      const storage = getStorage();
      let storageRef = ref(storage, `recentlyAddedBooks/${imgName}`);
      uploadString(storageRef, imgUrl, "data_url").then((snapshot) => {
        console.log("Uploaded a data_url string!");
      });
    }

    const db = getFirestore();
    await addDoc(collection(db, "recentlyAddedBooks"), recentlyAddedBook);

    // Clear form after submission
    bookIdRef.current.value = "";
    bookTitleRef.current.value = "";
    bookAuthorRef.current.value = "";
    uploadedImageRef.current.value = "";

    flashMessageHandler({ message: "Book added to recently added books", success: true });
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onChangeImageHandler = (event) => {
    // As an added challenge see if you can also compress the image further

    //***** */ You have to  figure out how to resize the image before sending it to firestore by using the name of the file and jpeg format
    // You are not updating UI instantly so state change will not be needed here.
    //define the width to resize e.g 450px
    const resize_width = 450; //without px

    //get the image selected
    let item = event.target.files[0];
    if (item) {
      //create a FileReader
      const reader = new FileReader();

      //image turned to base64-encoded Data URI.
      reader.readAsDataURL(item);
      reader.name = item.name; //get the image's name
      reader.size = item.size; //get the image's size
      setImgName(uuidv4() + ".jpeg");
      reader.onload = function (event) {
        let img = new Image(); //create an image
        img.src = event.target.result; //result is base64-encoded Data URI

        img.size = event.target.size; //set size (optional)
        img.onload = function (el) {
          var elem = document.createElement("canvas"); //create a canvas

          //scale the image to 600 (width) and keep aspect ratio
          var scaleFactor = resize_width / el.target.width;
          elem.width = resize_width;
          elem.height = el.target.height * scaleFactor;

          //draw in canvas
          var ctx = elem.getContext("2d");
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          //get the base64-encoded Data URI from the resize image
          var srcEncoded = ctx.canvas.toDataURL("image/jpeg", 0.5);
          //assign it to thumb src
          setImgUrl(srcEncoded);
          /* Now you can send "srcEncoded" to the server and
      convert it to a png o jpg. Also can send
      "el.target.name" that is the file's name.*/
        };
      };
    }
  };

  return (
    <Fragment>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <form onSubmit={onSubmitHandler} className={styles.form}>
        <div className={styles.controls}>
          <label htmlFor='bookID'>Book ID</label>
          <input
            type='text'
            id='bookID'
            placeholder='Book ID'
            autoComplete='off'
            aria-label='Book ID'
            required
            ref={bookIdRef}
          />
        </div>
        <div className={styles.controls}>
          <label htmlFor='bookTitle'>Book Title</label>
          <input
            type='text'
            id='bookTitle'
            placeholder='Book Title'
            autoComplete='off'
            aria-label='Book Title'
            required
            ref={bookTitleRef}
          />
        </div>
        <div className={styles.controls}>
          <label htmlFor='author'>Author</label>
          <input
            type='text'
            id='author'
            placeholder='Book Author/s'
            autoComplete='off'
            aria-label='Book Author'
            required
            ref={bookAuthorRef}
          />
        </div>
        <div className={styles["file-control"]}>
          <label htmlFor='bookCover'>Book Cover</label>
          <input
            type='file'
            id='bookCover'
            aria-label='Upload Image'
            accept='image/*'
            ref={uploadedImageRef}
            onChange={onChangeImageHandler}
          />
        </div>
        <div className={styles.action}>
          <button type='submit' className={styles["button-24"]}>
            Add Book
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default RecentlyAddedBookForm;
