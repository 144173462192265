import { Routes, Route } from "react-router-dom";

import RecentlyAddedBookForm from "./RecentlyAddedBookForm";
import RecentlyAddedBooksList from "./RecentlyAddedBooksList";
import RecentlyAddedBooksTab from "../../../../../components/UI/RecentlyAddedBookTab";

import styles from "./RecentlyAddedBooks.module.css";

const RecentlyAddedBooks = () => {
  return (
    <div className={styles.container}>
      <RecentlyAddedBooksTab />
      <div className={styles["tab-body"]}>
        <Routes>
          <Route path='/createRecentlyAddedBook' element={<RecentlyAddedBookForm />}></Route>
          <Route path='recentBooks' element={<RecentlyAddedBooksList />}></Route>
        </Routes>
      </div>
    </div>
  );
};

export default RecentlyAddedBooks;
