import React from "react";
import PrintableJobApplicationsRow from "./PrintableJobApplicationsRow";
import { v4 as uuidv4 } from "uuid";
import styles from "./PrintableJobApplications.module.css";

const PrintableJobApplications = React.forwardRef((props, ref) => {
  return (
    <div className={styles["job-applications-container"]} ref={ref}>
      {props.jobApplications.length > 0 ? (
        <table className={styles["job-applications"]}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Type of Role</th>
              <th>Applying for</th>
              <th>Interview Date</th>
            </tr>
          </thead>
          <tbody>
            {props.jobApplications.map((data) => {
              return (
                <PrintableJobApplicationsRow
                  key={uuidv4()}
                  id={data.id}
                  name={data.fullName}
                  phone={data.phone}
                  email={data.email}
                  typeOfRole={data.typeOfRole}
                  role={data.role}
                  date={data.interviewDate}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>Currently there are no job applications.</p>
      )}
    </div>
  );
});
export default PrintableJobApplications;
