import styles from "./MemberDetails.module.css";

const MemberDetails = (props) => {
  return (
    <div className={styles["member-details"]}>
      <div className={styles["member-data"]}>
        <span className={styles.label}>Borrower</span>
        <p>{props.member.fullName}</p>
      </div>
      <div className={styles["member-data"]}>
        <span className={styles.label}>Email</span>
        <p>{props.member.email}</p>
      </div>
    </div>
  );
};

export default MemberDetails;
