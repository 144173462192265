import React, { useState } from "react";

const LastSnapshotContext = React.createContext({
  lastQuerySnapshot: null,
  saveLastQuerySnapshot: () => {},
  lastSearchQuerySnapshot: null,
  saveLastSearchQuerySnapshot: () => {},
  lastQuerySnapshotRecords: null,
  saveLastQuerySnapshotRecords: () => {},
  lastSearchQuerySnapshotRecords: null,
  saveLastSearchQuerySnapshotRecords: () => {},
});

export const LastSnapshotContextProvider = ({ children }) => {
  const [lastQuerySnapshot, setLastQuerySnapshot] = useState(null);
  const [lastSearchQuerySnapshot, setLastSearchQuerySnapshot] = useState(null);
  const [lastQuerySnapshotRecords, setLastQuerySnapshotRecords] = useState(null);
  const [lastSearchQuerySnapshotRecords, setLastSearchQuerySnapshotRecords] = useState(null);

  const saveLastQuerySnapshot = (snapshot) => {
    setLastQuerySnapshot(snapshot);
  };
  const saveLastSearchQuerySnapshot = (snapshot) => {
    setLastSearchQuerySnapshot(snapshot);
  };

  const saveLastQuerySnapshotRecords = (snapshot) => {
    setLastQuerySnapshotRecords(snapshot);
  };
  const saveLastSearchQuerySnapshotRecords = (snapshot) => {
    setLastSearchQuerySnapshotRecords(snapshot);
  };

  return (
    <LastSnapshotContext.Provider
      value={{
        lastQuerySnapshot,
        saveLastQuerySnapshot,
        lastSearchQuerySnapshot,
        saveLastSearchQuerySnapshot,
        lastQuerySnapshotRecords,
        lastSearchQuerySnapshotRecords,
        saveLastQuerySnapshotRecords,
        saveLastSearchQuerySnapshotRecords,
      }}>
      {children}
    </LastSnapshotContext.Provider>
  );
};

export default LastSnapshotContext;
