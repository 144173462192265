// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqnDy8lUTHrdw2ujaq8JE0ckQ4Hd3tpuU",
  authDomain: "somawebapp-bca14.firebaseapp.com",
  projectId: "somawebapp-bca14",
  storageBucket: "somawebapp-bca14.appspot.com",
  messagingSenderId: "141021229759",
  appId: "1:141021229759:web:aa2d85465c89ae37ee4c0a",
  measurementId: "G-3HV5EYJNC3",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const secondaryApp = initializeApp(firebaseConfig, "secondaryApp");

// Authentication Service
export const auth = getAuth(app);
// Firestore Service
export const firestore = getFirestore(app);
// Storage Service
export const storage = getStorage(app);

// App is a container-like object that stores common configuration and shares authentication across Firebase services. After you initialize a Firebase App object in your code, you can add and start using Firebase services.

const analytics = getAnalytics(app);
