import React, { useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import PotsOpenKitchen from "../../assets/images/foodAndDrinks/pots_open_kitchen.jpg";
import Appetizer from "../../assets/images/foodAndDrinks/food_drinks.jpg";
import Salad from "../../assets/images/foodAndDrinks/salad.jpg";
import MainDish from "../../assets/images/foodAndDrinks/fromthesea.jpg";
import Pasta from "../../assets/images/foodAndDrinks/pasta.jpg";
import Burger from "../../assets/images/foodAndDrinks/burgers.jpg";
import Fried from "../../assets/images/foodAndDrinks/fried1.jpg";
import Dessert from "../../assets/images/foodAndDrinks/dessert.jpg";
import SoftDrink from "../../assets/images/foodAndDrinks/softdrink.jpg";
import Beers from "../../assets/images/foodAndDrinks/beer.jpg";
import Cocktail from "../../assets/images/foodAndDrinks/cocktail.jpg";
import MenusAndDrinksNav from "./MenusAndDrinksNav";
import Kitchen from "./Kitchen/Kitchen";
import Food from "./Food/Food";
import Drinks from "./Drinks/Drinks";
import PageNotFound from "../404/404";
import styles from "./MenusAndDrinks.module.css";

const MenusAndDrinks = () => {
  const [skeleton, setSkeleton] = useState(true);
  useEffect(() => {
    if (window.innerWidth < 540) {
      setSkeleton(false);
    } else {
      setSkeleton(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 540) {
        setSkeleton(false);
      } else {
        setSkeleton(true);
      }
    });
  }, []);
  const params = useParams();

  let headerImage =
    params["*"] === "kitchen"
      ? PotsOpenKitchen
      : params["*"] === "food/appetizers"
      ? Appetizer
      : params["*"] === "food/salads"
      ? Salad
      : params["*"] === "food/mains"
      ? MainDish
      : params["*"] === "food/pasta&rissoto"
      ? Pasta
      : params["*"] === "food/burgers&wraps"
      ? Burger
      : params["*"] === "food/fried"
      ? Fried
      : params["*"] === "food/desserts"
      ? Dessert
      : params["*"] === "drinks/softdrinks"
      ? SoftDrink
      : params["*"] === "drinks/beers"
      ? Beers
      : params["*"] === "drinks/cocktails"
      ? Cocktail
      : "";

  return (
    <div className={styles.container}>
      <header>
        <div className={`${styles.background} ${skeleton ? "skeleton" : ""}`}>
          <div className={`${styles["header-image"]} skeleton`}>
            <img src={headerImage} alt='Dishes representing each category of the menu' className='skeleton' />
          </div>
        </div>
      </header>
      <div className={styles.body}>
        <MenusAndDrinksNav />
        <Routes>
          <Route path='/kitchen' element={<Kitchen />} />
          <Route path='/food' element={<Food />} />
          <Route path='/food/*' element={<Food />} />
          <Route path='/drinks' element={<Drinks />} />
          <Route path='/drinks/*' element={<Drinks />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
};

export default MenusAndDrinks;
