export const bookCategoriesData = [
  {
    buttonLabel: "ENGLISH",
    category: "ANG",

    queryString: "ang",
  },
  {
    buttonLabel: "ALBANIAN",
    category: "SHQIP",

    queryString: "shqip",
  },
  {
    buttonLabel: "NOVELS",
    category: "ROMAN",

    queryString: "roman",
  },
  {
    buttonLabel: "HISTORY",
    category: "HISTORI",

    queryString: "histori",
  },
  {
    buttonLabel: "POETRY",
    category: "POEZI",

    queryString: "poezi",
  },
  {
    buttonLabel: "PHILOSOPHY",
    category: "FILOZOFI",

    queryString: "filozofi",
  },
  {
    buttonLabel: "BIOGRAPHY",
    category: "BIOGRAFI",

    queryString: "biografi",
  },
  {
    buttonLabel: "ART",
    category: "ART",

    queryString: "art",
  },
  {
    buttonLabel: "SHORT STORIES",
    category: "TREGIME",

    queryString: "tregime",
  },
  {
    buttonLabel: "PLAYS",
    category: "DRAME",

    queryString: "drame",
  },
  {
    buttonLabel: "ESSAYS",
    category: "ESE",

    queryString: "ese",
  },
  {
    buttonLabel: "POLITICS",
    category: "POLITIKË",

    queryString: "politikë",
  },
  {
    buttonLabel: "SCIENCE",
    category: "SHKENCE",

    queryString: "shkence",
  },
  {
    buttonLabel: "AUTOBIOGRAPHY",
    category: "AUTOBIOGRAFI",

    queryString: "autobiografi",
  },
  {
    buttonLabel: "SOCIAL SCIENCES",
    category: "SHKENCA SOCIALE",

    queryString: "shkenca sociale",
  },
  {
    buttonLabel: "MAGAZINES",
    category: "REVISTA KULTURORE",

    queryString: "revista kulturore",
  },
  {
    buttonLabel: "TRAVEL GUIDES",
    category: "UDHERREFYES",

    queryString: "udherrefyes",
  },
  {
    buttonLabel: "MUSIC",
    category: "MUZIKE",

    queryString: "muzike",
  },
  {
    buttonLabel: "RELIGION",
    category: "RELIGJION",

    queryString: "religjion",
  },
  {
    buttonLabel: "PHOTOGRAPHY",
    category: "FOTOGRAFI",

    queryString: "fotografi",
  },
  {
    buttonLabel: "DICTIONARIES",
    category: "FJALOR",

    queryString: "fjalor",
  },
  {
    buttonLabel: "ECONOMY",
    category: "EKONOMI",

    queryString: "ekonomi",
  },
  {
    buttonLabel: "PSYCHOLOGY",
    category: "PSIKOLOGJI",

    queryString: "psikologji",
  },
  {
    buttonLabel: "PROSE",
    category: "PROZE",

    queryString: "proze",
  },
  {
    buttonLabel: "ARCHITECTURE",
    category: "ARKITEKTURE",

    queryString: "arkitekture",
  },
  {
    buttonLabel: "FILM",
    category: "FILM",

    queryString: "film",
  },
  {
    buttonLabel: "MONOGRAPHY",
    category: "MONOGRAFI",

    queryString: "monografi",
  },
  {
    buttonLabel: "ANTHROPOLOGY",
    category: "ANTROPOLOGJI",

    queryString: "antropologji",
  },
  {
    buttonLabel: "BUSINESS",
    category: "BIZNES",

    queryString: "biznes",
  },
  {
    buttonLabel: "FRENCH",
    category: "FRA",

    queryString: "fra",
  },
  {
    buttonLabel: "ANTHOLOGIES",
    category: "ANTALOGJI",

    queryString: "antalogji",
  },
  {
    buttonLabel: "FOR CHILDREN",
    category: "PER FEMIJE",

    queryString: "per femije",
  },
  {
    buttonLabel: "LITERARY CRITICISM",
    category: "KRITIKË LETRARE",

    queryString: "kritikë letrare",
  },
  {
    buttonLabel: "INTERVIEWS",
    category: "INTERVISTA",

    queryString: "intervista",
  },
  {
    buttonLabel: "POLITICAL SCIENCE",
    category: "SHKENCA POLITIKE",

    queryString: "shkenca politike",
  },
  {
    buttonLabel: "FOOD",
    category: "USHQIM",

    queryString: "ushqim",
  },
  {
    buttonLabel: "LITERARY THEORY",
    category: "TEORI LETRARE",

    queryString: "teori letrare",
  },
  {
    buttonLabel: "GENERAL GUIDANCE",
    category: "UDHEPERSHKRIM",

    queryString: "udhepershkrim",
  },

  {
    buttonLabel: "ARCHEOLOGY",
    category: "ARKEOLOGJI",

    queryString: "arkeologji",
  },

  {
    buttonLabel: "OTHER LANGUAGES",
    category: "GJUHE TJERA",

    queryString: "gjuhe tjera",
  },
];

export const bookCategoriesDataALB = [
  {
    buttonLabel: "ANGLISHT",
    category: "ANG",

    queryString: "ang",
  },
  {
    buttonLabel: "SHQIP",
    category: "SHQIP",

    queryString: "shqip",
  },
  {
    buttonLabel: "ROMANE",
    category: "ROMAN",

    queryString: "roman",
  },
  {
    buttonLabel: "HISTORI",
    category: "HISTORI",

    queryString: "histori",
  },
  {
    buttonLabel: "POEZI",
    category: "POEZI",

    queryString: "poezi",
  },
  {
    buttonLabel: "FILOZOFI",
    category: "FILOZOFI",

    queryString: "filozofi",
  },
  {
    buttonLabel: "BIOGRAFI",
    category: "BIOGRAFI",

    queryString: "biografi",
  },
  {
    buttonLabel: "ART",
    category: "ART",

    queryString: "art",
  },
  {
    buttonLabel: "TREGIME",
    category: "TREGIME",

    queryString: "tregime",
  },
  {
    buttonLabel: "DRAMA",
    category: "DRAME",

    queryString: "drame",
  },
  {
    buttonLabel: "ESE",
    category: "ESE",

    queryString: "ese",
  },
  {
    buttonLabel: "POLITIKË",
    category: "POLITIKË",

    queryString: "politikë",
  },
  {
    buttonLabel: "SHKENCË",
    category: "SHKENCE",

    queryString: "shkence",
  },
  {
    buttonLabel: "AUTOBIOGRAFI",
    category: "AUTOBIOGRAFI",

    queryString: "autobiografi",
  },
  {
    buttonLabel: "SHKENCA SOCIALE",
    category: "SHKENCA SOCIALE",

    queryString: "shkenca sociale",
  },
  {
    buttonLabel: "REVISTA KULTURORE",
    category: "REVISTA KULTURORE",

    queryString: "revista kulturore",
  },
  {
    buttonLabel: "UDHËRRËFYES",
    category: "UDHERREFYES",

    queryString: "udherrefyes",
  },
  {
    buttonLabel: "MUZIKË",
    category: "MUZIKE",

    queryString: "muzike",
  },
  {
    buttonLabel: "RELIGJION",
    category: "RELIGJION",

    queryString: "religjion",
  },
  {
    buttonLabel: "FOTOGRAFI",
    category: "FOTOGRAFI",

    queryString: "fotografi",
  },
  {
    buttonLabel: "FJALOR",
    category: "FJALOR",

    queryString: "fjalor",
  },
  {
    buttonLabel: "EKONOMI",
    category: "EKONOMI",

    queryString: "ekonomi",
  },
  {
    buttonLabel: "PSIKOLOGJI",
    category: "PSIKOLOGJI",

    queryString: "psikologji",
  },
  {
    buttonLabel: "PROZË",
    category: "PROZE",

    queryString: "proze",
  },
  {
    buttonLabel: "ARKITEKTURË",
    category: "ARKITEKTURE",

    queryString: "arkitekture",
  },
  {
    buttonLabel: "FILM",
    category: "FILM",

    queryString: "film",
  },
  {
    buttonLabel: "MONOGRAFI",
    category: "MONOGRAFI",

    queryString: "monografi",
  },
  {
    buttonLabel: "ANTROPOLOGJI",
    category: "ANTROPOLOGJI",

    queryString: "antropologji",
  },
  {
    buttonLabel: "BIZNES",
    category: "BIZNES",

    queryString: "biznes",
  },
  {
    buttonLabel: "FRËNGJISHT",
    category: "FRA",

    queryString: "fra",
  },
  {
    buttonLabel: "ANTALOGJI",
    category: "ANTALOGJI",

    queryString: "antalogji",
  },
  {
    buttonLabel: "PËR FËMIJË",
    category: "PER FEMIJE",

    queryString: "per femije",
  },
  {
    buttonLabel: "KRITIKË LETRARE",
    category: "KRITIKË LETRARE",

    queryString: "kritikë letrare",
  },
  {
    buttonLabel: "INTERVISTA",
    category: "INTERVISTA",

    queryString: "intervista",
  },
  {
    buttonLabel: "SHKENCA POLITIKE",
    category: "SHKENCA POLITIKE",

    queryString: "shkenca politike",
  },
  {
    buttonLabel: "USHQIM",
    category: "USHQIM",

    queryString: "ushqim",
  },
  {
    buttonLabel: "TEORI LETRARE",
    category: "TEORI LETRARE",

    queryString: "teori letrare",
  },
  {
    buttonLabel: "UDHËPËRSHKRIM",
    category: "UDHEPERSHKRIM",

    queryString: "udhepershkrim",
  },
  {
    buttonLabel: "ARKEOLOGJI",
    category: "ARKEOLOGJI",

    queryString: "arkeologji",
  },
  {
    buttonLabel: "GJUHË TJERA",
    category: "GJUHE TJERA",

    queryString: "gjuhe tjera",
  },
];
