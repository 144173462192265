import React, { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";

import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import { LanguageContext } from "../../../contexts/Language/LanguageContext";
import styles from "./FoodNav.module.css";

import MenuANG from "../../../assets/pdfs/SOMAMenuANG.pdf";
import MenuALB from "../../../assets/pdfs/SOMAMenuALB.pdf";
import Download from "../../../assets/icons/Download";

const FoodNav = () => {
  const { language } = useContext(LanguageContext);
  return (
    <Fragment>
      <div className={styles["nav-container"]}>
        <nav className={styles.navigation}>
          <NavLink
            to='/menusAndDrinks/food/appetizers'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <button className={styles["nav-btn"]}>
              <span>
                <MultiLingualContent contentId='foodAndDrinks' prop='navLink4' />
              </span>
            </button>
          </NavLink>
          <NavLink
            to='/menusAndDrinks/food/salads'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <button className={styles["nav-btn"]}>
              <span>
                <MultiLingualContent contentId='foodAndDrinks' prop='navLink5' />
              </span>
            </button>
          </NavLink>
          <NavLink
            to='/menusAndDrinks/food/mains'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <button className={styles["nav-btn"]}>
              <span>
                <MultiLingualContent contentId='foodAndDrinks' prop='navLink6' />
              </span>
            </button>
          </NavLink>
          <NavLink
            to='/menusAndDrinks/food/pasta&rissoto'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <button className={styles["nav-btn"]}>
              <span>
                <MultiLingualContent contentId='foodAndDrinks' prop='navLink7' />
              </span>
            </button>
          </NavLink>
          <NavLink
            to='/menusAndDrinks/food/burgers&wraps'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <button className={styles["nav-btn"]}>
              <span>
                <MultiLingualContent contentId='foodAndDrinks' prop='navLink8' />
              </span>
            </button>
          </NavLink>
          <NavLink
            to='/menusAndDrinks/food/fried'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <button className={styles["nav-btn"]}>
              <span>
                <MultiLingualContent contentId='foodAndDrinks' prop='navLink9' />
              </span>
            </button>
          </NavLink>
          <NavLink
            to='/menusAndDrinks/food/desserts'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <button className={styles["nav-btn"]}>
              <span>
                <MultiLingualContent contentId='foodAndDrinks' prop='navLink10' />
              </span>
            </button>
          </NavLink>
        </nav>
        <small className={styles["small-text"]}>
          <MultiLingualContent contentId='foodAndDrinks' prop='note1' />
        </small>
        <small className={`${styles["small-text"]} ${styles["latest-update"]}`}>
          <MultiLingualContent contentId='foodAndDrinks' prop='note2' />
        </small>
        <a className={styles["download-link"]} href={language === "albanian" ? MenuALB : MenuANG} download>
          <button className={styles["download-btn"]}>
            <Download />
            <span>
              <MultiLingualContent contentId='foodAndDrinks' prop='button1' />
            </span>
          </button>
        </a>
      </div>
    </Fragment>
  );
};

export default FoodNav;
