import ConfirmReservationIcon from "../../../../assets/icons/ConfirmReservationIcon";
import Trash from "../../../../assets/icons/Trash";
import UserPlusIcon from "../../../../assets/icons/UserPlusIcon";

import styles from "./BookHoldRow.module.css";

const BookHoldRow = (props) => {
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const holdExpires = props.holdUntil.toDate().toLocaleString("default", options);
  return (
    <tr className={styles.tr}>
      <td data-label='BOOK TITLE'>{props.title}</td>
      <td data-label='BOOK ID'>{props.id}</td>
      <td data-label='NAME'>{props.fullName}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='MEMBER'>{props.member ? "Yes" : "No"}</td>
      <td data-label='HOLD UNTIL'>{holdExpires}</td>
      <td data-label='OPERATIONS'>
        {props.member ? (
          <button className={styles.btn} type='button' onClick={() => props.onConfirmPickUp(props.docId)}>
            {<ConfirmReservationIcon />}
          </button>
        ) : (
          <button className={styles.btn} type='button' onClick={() => props.showAddMemberModal(props.docId)}>
            <UserPlusIcon />
          </button>
        )}
        {}
        <button className={styles.btn} type='button' onClick={() => props.showDeleteModal(props.docId)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default BookHoldRow;
