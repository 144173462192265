import { useEffect, useState, useContext } from "react";
import { MultiLingualContent } from "../../../../contexts/Language/MultiLingualContent";
import { LanguageContext } from "../../../../contexts/Language/LanguageContext";
import { v4 as uuid } from "uuid";
import AuthContext from "../../../../contexts/AuthContext";
import HistoryItem from "./HistoryItem";
import styles from "./History.module.css";

// FIREBASE
import { getFirestore, doc, getDoc } from "firebase/firestore";

const History = () => {
  const [books, setBooks] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const authCtx = useContext(AuthContext);
  const { uid } = authCtx.authUser;
  const { language } = useContext(LanguageContext);
  useEffect(() => {
    const getBorrowingHistory = async () => {
      const db = getFirestore();
      const userRef = doc(db, "users", uid);
      setIsPending(true);
      const userSnap = await getDoc(userRef);
      let borrowingHistoryArr = userSnap.data().borrowingHistory;

      if (borrowingHistoryArr) {
        setBooks(borrowingHistoryArr);
        setIsPending(false);
      }

      setIsPending(false);
    };
    getBorrowingHistory();
  }, [uid]);

  return (
    <div className={styles.container}>
      <ul className={styles["history-list"]}>
        {isPending ? (
          language === "albanian" ? (
            "Duke shkarkuar..."
          ) : (
            "Loading..."
          )
        ) : books.length > 0 ? (
          books.map((book) => {
            return (
              <HistoryItem
                key={uuid()}
                title={book.title}
                author={book.author}
                imageRef={book.imageRef}
                dateBorrowed={book.dateBorrowed}
                dateReturned={book.dateReturned}
              />
            );
          })
        ) : (
          <p className={styles.note}>
            <MultiLingualContent contentId='myLibrary' prop='note3' />
          </p>
        )}
      </ul>
    </div>
  );
};

export default History;
