export let checkboxOptions = [
  {
    label: "ALBANIAN",
    value: "shqip",
    checked: false,
  },
  {
    label: "ANTHOLOGIES",
    value: "antalogji",
    checked: false,
  },
  {
    label: "ANTHROPOLOGY",
    value: "antropologji",
    checked: false,
  },
  {
    label: "ARCHEOLOGY",
    value: "arkeologji",
    checked: false,
  },
  {
    label: "ARCHITECTURE",
    value: "arkitekture",
    checked: false,
  },
  {
    label: "ART",
    value: "art",
    checked: false,
  },
  {
    label: "AUTOBIOGRAPHY",
    value: "autobiografi",
    checked: false,
  },
  {
    label: "BIOGRAPHY",
    value: "biografi",
    checked: false,
  },
  {
    label: "BUSINESS",
    value: "biznes",
    checked: false,
  },
  {
    label: "DICTIONARIES",
    value: "fjalor",
    checked: false,
  },
  {
    label: "ECONOMY",
    value: "ekonomi",
    checked: false,
  },
  {
    label: "ENGLISH",
    value: "ang",
    checked: false,
  },
  {
    label: "ESSAYS",
    value: "ese",
    checked: false,
  },
  {
    label: "FILM",
    value: "film",
    checked: false,
  },
  {
    label: "FOOD",
    value: "ushqim",
    checked: false,
  },
  {
    label: "FOR CHILDREN",
    value: "per femije",
    checked: false,
  },
  {
    label: "FRENCH",
    value: "fra",
    checked: false,
  },
  {
    label: "GENERAL GUIDANCE",
    value: "udhepershkrim",
    checked: false,
  },
  {
    label: "HISTORY",
    value: "histori",
    checked: false,
  },
  {
    label: "INTERVIEWS",
    value: "intervista",
    checked: false,
  },
  {
    label: "LITERARY CRITICISM",
    value: "kritikë letrare",
    checked: false,
  },
  {
    label: "LITERARY THEORY",
    value: "teori letrare",
    checked: false,
  },
  {
    label: "MAGAZINES",
    value: "revista kulturore",
    checked: false,
  },
  {
    label: "MONOGRAPHY",
    value: "monografi",
    checked: false,
  },
  {
    label: "MUSIC",
    value: "muzike",
    checked: false,
  },
  {
    label: "NOVELS",
    value: "roman",
    checked: false,
  },
  {
    label: "OTHER LANGUAGES",
    value: "gjuhe tjera",
    checked: false,
  },
  {
    label: "PHILOSOPHY",
    value: "filozofi",
    checked: false,
  },
  {
    label: "PHOTOGRAPHY",
    value: "fotografi",
    checked: false,
  },
  {
    label: "PLAYS",
    value: "drame",
    checked: false,
  },
  {
    label: "POETRY",
    value: "poezi",
    checked: false,
  },
  {
    label: "POLITICAL SCIENCE",
    value: "shkenca politike",
    checked: false,
  },
  {
    label: "POLITICS",
    value: "politikë",
    checked: false,
  },
  {
    label: "PROSE",
    value: "proze",
    checked: false,
  },
  {
    label: "PSYCHOLOGY",
    value: "psikologji",
    checked: false,
  },
  {
    label: "RELIGION",
    value: "religjion",
    checked: false,
  },
  {
    label: "SCIENCE",
    value: "shkence",
    checked: false,
  },
  {
    label: "SHORT STORIES",
    value: "tregime",
    checked: false,
  },
  {
    label: "SOCIAL SCIENCES",
    value: "shkenca sociale",
    checked: false,
  },
  {
    label: "TRAVEL GUIDES",
    value: "udherrefyes",
    checked: false,
  },
];
