import styles from "./AppetizersMobile.module.css";

const AppetizersMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='appetizers'>
        Ullinjë të Ngrohtë - Ullinjë nga Berati, djath i bardhë, patate në furrë dhe bukë petë
        <span>4.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='appetizers'>
        Brusketa - Spanak, trosha djathi, dardhë dhe krem uthulle (balsamiko) <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='appetizers'>
        Djath në Furrë - Djath i bardhë vendor, majltë pishe dhe bukë petë <span>4.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='appetizers'>
        Krem Djathi - Krem djathi vendor me shegë, arra, vaj ullini dhe bukë petë <span>3.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='appetizers'>
        Hummus Dip - Pire nga qiqrat, hudër, limon, fara të zeza, çarragat dhe bukë petë <span>4.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='appetizers'>
        Speca të Mbushur - Speca të vegjël turrshi të mbushur me krem djath dhe trumëz <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='appetizers'>
        Saganaki me Karkaleca - Karkaleca në salcë të domateve, djath i bardhë dhe Ouzo <span>9.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='appetizers'>
        Patate të Vogla në Furrë - Patate të vogla me tarator gjizë të thartë <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='8' category='appetizers'>
        Tzatziki - Kos i kulluar, tranguj, hudër, krip, uthull e bardhë, vaj ulliri <span>3.50</span>
      </li>
    </ul>
  );
};

export default AppetizersMobile;
