const getTranslatedTypeOfEvent = (type) => {
  let typeALB;
  switch (type) {
    case "CONCERT":
      typeALB = "KONCERT";
      break;
    case "EXHIBITION":
      typeALB = "EKSPOZITË";
      break;
    case "SCREENING":
      typeALB = "EKRANIZIM";
      break;
    case "WORKSHOP":
      typeALB = "PUNËTORI";
      break;
    case "BOOK SIGNING":
      typeALB = "NËNSHKRIM LIBRI";
      break;
    case "FOOD TASTING":
      typeALB = "DEGUSTIM USHQIMI";
      break;
    case "WINE TASTING":
      typeALB = "DEGUSTIM VENE";
      break;
    case "DJ":
      typeALB = "DJ";
      break;
    case "OTHER":
      typeALB = "TJERA";
      break;
  }
  return typeALB;
};

export default getTranslatedTypeOfEvent;
