import SaladsMobile from "./SaladsMobile";
import SaladsDesktop from "./SaladsDesktop";
import styles from "./Salads.module.css";

const Salads = (props) => {
  return (
    <div className={styles.container}>
      <SaladsMobile onShowModalHandler={props.showModal} />
      <SaladsDesktop onShowModalHandler={props.showModal} />
    </div>
  );
};

export default Salads;
