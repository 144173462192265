import { useEffect, useState, useContext } from "react";
import { MultiLingualContent } from "../../../../contexts/Language//MultiLingualContent";

import { LanguageContext } from "../../../../contexts/Language/LanguageContext";
import WishListItem from "./WishListItem";
import AuthContext from "../../../../contexts/AuthContext";
import FlashMessage from "../../../../components/UI/FlashMessage";

// FIREBASE
import { getFirestore, doc, getDoc, updateDoc, Timestamp, addDoc, collection } from "firebase/firestore";

import styles from "./Wishlist.module.css";

const Wishlist = () => {
  const [books, setBooks] = useState([]);
  const [alert, setAlert] = useState("");
  const [isPending, setIsPending] = useState(true);

  const authCtx = useContext(AuthContext);

  const { uid } = authCtx.authUser;

  const { language } = useContext(LanguageContext);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 5000);
  };

  //  Get wishlisted books and display them if there are any

  useEffect(() => {
    const getWishlistBooks = async () => {
      const db = getFirestore();
      const userRef = doc(db, "users", uid);
      const userSnap = await getDoc(userRef);
      let books = userSnap.data().wishlist;
      if (!books.length) {
        setIsPending(false);
        return;
      }
      setBooks(books);

      setIsPending(false);
    };
    getWishlistBooks();
  }, [uid]);

  const onDeleteHandler = async (docId) => {
    const db = getFirestore();
    // Remove doc from user.wishlist array
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    const wishlist = userSnap.data().wishlist;
    const updatedWishList = wishlist.filter((book) => book.docId !== docId);

    await updateDoc(userRef, { wishlist: updatedWishList });

    // Update UI
    setBooks((prevState) => {
      return [...prevState].filter((book) => book.docId !== docId);
    });
  };

  const onHoldBookHandler = async (docId) => {
    const db = getFirestore();
    // One last time check if books is available in books collection, if not let user know
    const bookRef = doc(db, "books", docId);
    const bookSnap = await getDoc(bookRef);

    // check to see if book available in case it became unavailable in the meantime (because we're not using real-time updates)

    let isAvailable = bookSnap.data().available;

    if (!isAvailable) {
      setBooks((prevState) => {
        return [...prevState].map((book) => (book.docId === docId ? { ...book, available: false } : book));
      });
      return flashMessageHandler({
        message:
          language === "albanian"
            ? "Na vjen keq, duket se dikush e huazoi librin pikërisht tani! Ju lutemi kontrolloni përsëri më vonë."
            : "Oops! So sorry, it seem that someone borrowed the book just now! Please check back later.",
        success: false,
      });
    }
    // Access user wishlist array and remove book that's was placed on hold

    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    const userWishlist = userSnap.data().wishlist;
    const updatedUserWishlist = userWishlist.filter((book) => book.docId !== docId);
    await updateDoc(userRef, {
      wishlist: updatedUserWishlist,
    });

    let copies = bookSnap.data().copies;

    // copies is a string representing a number. I'm accesing the last item in the id array, depending on how many copies ara available
    let id = bookSnap.data().id;
    let bookId = id[Number(copies) - 1];

    // Update onHold array in user doc with book
    let book = userWishlist.find((book) => book.docId === docId);
    // Define when hold was placed and when it will expire
    let now = new Date();
    let day = now.getDay();
    // HoldExpires
    let nextDay = new Date(
      day === 6 ? now.getTime() + 48 * 60 * 60 * 1000 : now.getTime() + 24 * 60 * 60 * 1000
    );

    let updatedBook = {
      ...book,
      holdPlaced: Timestamp.now(),
      holdExpires: Timestamp.fromDate(nextDay),
      id: bookId,
    };

    // Update holdRequests
    const { fullName, email, phone, member } = userSnap.data();
    let docRef = await addDoc(collection(db, "holdRequests"), {
      ...updatedBook,
      user: { fullName, email, phone, member, docId: userSnap.id },
    });

    await updateDoc(userRef, {
      onHold: [{ ...updatedBook, holdRequestId: docRef.id }],
    });

    // update number of copies available on the book document in books collection
    // Update book in books collection by setting available:false if it is the last copy

    await updateDoc(bookRef, { copies: Number(copies) - 1 });

    if (copies === 1) {
      await updateDoc(bookRef, { available: false });
    }

    // Update UI
    setBooks(updatedUserWishlist);
    // This taught me a lesson about how react renders DOM. I thought that everytime we change the parent state every child component re-renders. NOT THE CASE!
  };

  return (
    <div className={styles.container}>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <ul className={styles.wishlist}>
        {isPending ? (
          language === "albanian" ? (
            "Duke shkarkuar..."
          ) : (
            "Loading..."
          )
        ) : books.length > 0 ? (
          books.map((book) => {
            return (
              <WishListItem
                key={book.docId}
                docId={book.docId}
                title={book.title}
                author={book.author}
                imageRef={book.imageRef}
                onHold={onHoldBookHandler}
                onDelete={onDeleteHandler}
              />
            );
          })
        ) : (
          <p className={styles.note}>
            <MultiLingualContent contentId='myLibrary' prop='note4' />
          </p>
        )}
      </ul>
    </div>
  );
};

export default Wishlist;
