import styles from "./PastaAndRisottoMobile.module.css";

const PastaAndRisottoMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <section className={styles.section}>
        <h3 className={styles["category-title"]}>ORIZ</h3>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='pastaAndRice'>
          Rizoto me Këpurdha - Oriz karnarolli, këpurdha, pastë tartufi, parmezan dhe gjalpë
          <span>6.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='pastaAndRice'>
          Rizoto me Karkaleca - karnarolli, karkaleca, salcë bisk, tomatina, parmezan dhe kungulleshka
          <span>7.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='pastaAndRice'>
          Pulë Soja me oriz Basmati - Oriz basmati, pulë, soya sos dhe qepë e re<span>6.00</span>
        </li>
      </section>
      <section className={styles.section}>
        <h3 className={styles["category-title"]}>SHPAGETA</h3>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='pastaAndRice'>
          Ravioli - Të mbushura me rikota dhe spanak në salcë gjalpe, sherebele dhe parmezan <span>5.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='pastaAndRice'>
          Shpageta me Tartuf - Shpageta me pastë tartufi, parmezan dhe gjalpë <span>5.50</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='pastaAndRice'>
          Shpageta me Karkaleca - salcë bisk të karkalecave, domate, kungulleshka dhe parmezan{" "}
          <span>8.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='pastaAndRice'>
          Pasta me Pulë - fusilli, salcë të domateve me hudër, domate të thata dhe parmezan <span>6.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='pastaAndRice'>
          Makarona me Djath - Pasta ditalini, përzierje djathnash dhe trosha të bukës në furrë{" "}
          <span>4.00</span>
        </li>
      </section>
    </ul>
  );
};

export default PastaAndRisottoMobile;
