import { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";

// FIREBASE
import { getFirestore, doc, getDoc } from "firebase/firestore";
const AdminRoute = ({ children }) => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [authPending, setAuthPending] = useState(true);
  useEffect(() => {
    const checkIfUserIsAdmin = async () => {
      if (!authCtx.authUser) {
        return navigate("/welcome", { replace: true });
      }

      const db = getFirestore();
      const uid = authCtx.authUser.uid;
      const userRef = doc(db, "users", uid);

      const userSnap = await getDoc(userRef);
      const user = userSnap.data();

      setAuthPending(false);

      if (!user.roles) {
        return navigate("/welcome", { replace: true });
      }
    };
    checkIfUserIsAdmin();
  }, [authCtx.authUser, navigate]);

  return !authPending && <Fragment>{children}</Fragment>;
};

export default AdminRoute;
