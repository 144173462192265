import PastaAndRisottoDesktop from "./PastaAndRisottoDesktop";
import PastaAndRisottoMobile from "./PastaAndRisottoMobile";
import styles from "./PastaAndRissoto.module.css";

const PastaAndRissoto = (props) => {
  return (
    <div className={styles.container}>
      <PastaAndRisottoDesktop onShowModalHandler={props.showModal} />
      <PastaAndRisottoMobile onShowModalHandler={props.showModal} />
    </div>
  );
};

export default PastaAndRissoto;
