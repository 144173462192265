export let checkboxOptions = [
  {
    label: "ROCK",
    value: "rock",
    checked: false,
  },
  {
    label: "POP",
    value: "pop",
    checked: false,
  },
  {
    label: "FOLK",
    value: "folk",
    checked: false,
  },
  {
    label: "WORLD",
    value: "world",
    checked: false,
  },
  {
    label: "COUNTRY",
    value: "country",
    checked: false,
  },
  {
    label: "ELECTRONIC",
    value: "electronic",
    checked: false,
  },
  {
    label: "JAZZ",
    value: "jazz",
    checked: false,
  },
  {
    label: "FUNK/SOUL",
    value: "funk/soul",
    checked: false,
  },
  {
    label: "LATIN",
    value: "latin",
    checked: false,
  },
  {
    label: "BLUES",
    value: "blues",
    checked: false,
  },
  {
    label: "CLASSICAL",
    value: "classical",
    checked: false,
  },
  {
    label: "REGGAE",
    value: "reggae",
    checked: false,
  },
  {
    label: "ALTERNATIVE",
    value: "alternative",
    checked: false,
  },
  {
    label: "STAGE&SCREEN",
    value: "stage&screen",
    checked: false,
  },
  {
    label: "DANCE",
    value: "dance",
    checked: false,
  },
];
