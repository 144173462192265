import { useState, useEffect, useRef } from "react";
import isDateSupported from "../../../../utils/isDateSupported";

import { reservationTimes } from "../../../Reservations/reservationTimes";
import styles from "./AddReservationForm.module.css";

// FIREBASE

import { Timestamp } from "firebase/firestore";

const AddReservationForm = (props) => {
  // For older versions of Safari that don't support date and time inputs
  const [dateSupported, setDateSupported] = useState(false);
  const [partySize] = useState(Array.from({ length: 20 }));

  const nameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const dateRef = useRef();
  const timeRef = useRef();
  const peopleRef = useRef();
  const specialReqRef = useRef();
  const typeOfServiceRef = useRef();
  const tableRef = useRef();

  useEffect(() => {
    if (isDateSupported()) {
      setDateSupported(true);
    }
  }, []);

  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    const reservationData = {
      fullName: nameRef.current.value.trim().toLowerCase(),
      phone: phoneRef.current.value.trim(),
      email: emailRef.current.value.trim(),
      specialRequest: specialReqRef.current.value.trim() || "none",
      typeOfService: typeOfServiceRef.current.value,
      numberOfPeople: peopleRef.current.value,
      date: Timestamp.fromDate(new Date(dateRef.current.value)),
      time: timeRef.current.value,
      tableNumber: tableRef.current.value.trim(),
    };
    props.onSubmit(reservationData);
    // Clear form after submission
    nameRef.current.value = "";
    phoneRef.current.value = "";
    emailRef.current.value = "";
    dateRef.current.value = "";
    timeRef.current.value = "8:00 am";
    peopleRef.current.value = "1";
    specialReqRef.current.value = "";
    typeOfServiceRef.current.value = "foodAndDrinks";
    tableRef.current.value = "";
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <div className={styles.controls}>
        <label htmlFor='customerName'>Full Name</label>
        <input
          type='text'
          id='customerName'
          placeholder='Customer Name'
          autoComplete='off'
          aria-label='Customer Name'
          required
          ref={nameRef}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='customerPhone'>Phone Number</label>
        <input
          type='tel'
          id='customerPhone'
          placeholder='Phone Number'
          autoComplete='off'
          aria-label='Customer Phone'
          required
          ref={phoneRef}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='customerEmail'>Email</label>
        <input
          type='email'
          id='customerEmail'
          placeholder='Email'
          autoComplete='off'
          aria-label='Customer Email'
          required
          ref={emailRef}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='reservationDate'>Date</label>
        <label htmlFor='date'>
          {!dateSupported && (
            <span className={styles["description-date"]}>Please use the YYYY-DD-MM format.</span>
          )}
        </label>
        <input
          type='date'
          id='reservationDate'
          pattern='[0-9]{4}(/|-)[0-9]{1,2}(/|-)[0-9]{1,2}'
          title='Please type in the date in this format'
          placeholder={!dateSupported ? "YYYY-DD-MM" : "Date"}
          autoComplete='off'
          min={new Date().toISOString().split("T")[0]}
          aria-label='Reservation Date'
          required
          ref={dateRef}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='reservationTime'>Time</label>
        <select id='reservationTime' ref={timeRef}>
          {reservationTimes.map((time, i) => {
            return <option key={i}>{time.label}</option>;
          })}
        </select>
      </div>
      <div className={styles.controls}>
        <label htmlFor='numberOfPeople'>Number of People</label>
        <select className={styles.select} id='numberOfPeople' ref={peopleRef}>
          {partySize.map((v, i) => {
            return (
              <option key={i} value={i + 1} label={`${i + 1} ${i + 1 === 1 ? "person" : "people"}`}>
                {i + 1} {i + 1 === 1 ? "person" : "people"}
              </option>
            );
          })}
        </select>
      </div>
      <div className={styles.controls}>
        <label htmlFor='specialRequest'>Special Request</label>
        <input type='text' placeholder='Special request (optional)' id='specialRequest' ref={specialReqRef} />
      </div>
      <div className={styles.controls}>
        <label htmlFor='typeOfService'>Type of Service</label>
        <select className={styles.select} id='typeOfService' required ref={typeOfServiceRef}>
          <option value='foodAndDrinks'>Food and Drinks</option>
          <option value='drinksOnly'>Drinks Only</option>
        </select>
      </div>
      <div className={styles.controls}>
        <label htmlFor='tableNumber'>Table Number</label>
        <input
          type='text'
          placeholder='Table Number'
          id='tableNumber'
          autoComplete='off'
          required
          ref={tableRef}
        />
      </div>
      <div className={styles.action}>
        <button type='submit' className={styles["button-24"]}>
          Add Reservation
        </button>
      </div>
    </form>
  );
};

export default AddReservationForm;
