import { useEffect, useState } from "react";

import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import { v4 as uuid } from "uuid";
import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Slide from "./SwiperSlide";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { getFirestore, collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { getStorage, deleteObject, ref } from "firebase/storage";

const SwiperRecords = () => {
  const [recentRecords, setRecentRecords] = useState([]);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    const getRecentlyAddedRecords = async () => {
      const db = getFirestore();
      const recentColl = collection(db, "recentlyAddedRecords");
      setIsPending(true);
      const snapshot = await getDocs(recentColl);
      const recentRecordsArray = [];

      snapshot.forEach(async (record) => {
        let now = new Date();
        let expires = record.data().expires;
        if (now.getTime() < expires.toMillis()) {
          recentRecordsArray.push(record.data());
        } else {
          await deleteDoc(doc(db, "recentlyAddedRecords", record.id));
          const storage = getStorage();
          const recordCoverImgRef = ref(storage, record.data().imageRef);

          // Delete the file
          deleteObject(recordCoverImgRef)
            .then(() => {
              // File deleted successfully
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
              console.log(error);
            });
        }
      });

      setRecentRecords(recentRecordsArray);
      setIsPending(false);
    };
    getRecentlyAddedRecords();
  }, []);
  return (
    <Swiper
      // install Swiper modules
      modules={[A11y, Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => "onSwiper"}
      onSlideChange={() => "slide change"}>
      {isPending ? (
        <MultiLingualContent contentId='events' prop='note3' />
      ) : recentRecords.length > 0 ? (
        recentRecords.map((record) => {
          return (
            <SwiperSlide key={uuid()}>
              <Slide title={record.title} artist={record.artist} imageRef={record.imageRef} />
            </SwiperSlide>
          );
        })
      ) : (
        <p>
          <MultiLingualContent contentId='booksAndRecords' prop='note4' />
        </p>
      )}
    </Swiper>
  );
};

export default SwiperRecords;
