import React, { Fragment, useRef } from "react";
import ReactDOM from "react-dom";
import styles from "./AddTableNumberModal.module.css";

const Backdrop = (props) => {
  const cssClasses = [styles.backdrop, props.show ? styles.backdropOpen : styles.backdropClosed];
  return <div className={cssClasses.join(" ")} />;
};

const Modal = (props) => {
  const tableNumberRef = useRef();
  const cssClasses = [styles.modal, props.show ? styles.modalOpen : styles.modalClosed];

  const onSubmitTableNumberHandler = (ev) => {
    ev.preventDefault();
    const tableNumber = tableNumberRef.current.value.trim();
    props.onAddTable(tableNumber);
    tableNumberRef.current.value = "";
  };

  const onCancelAddTableHandler = () => {
    // set input to an empty string
    tableNumberRef.current.value = "";
    // removes modal
    props.onCancel(false);
  };
  return (
    <div className={cssClasses.join(" ")}>
      <div className={styles["modal-header"]}>
        <h2>{props.headerText}</h2>
        <div className={styles["close-modal"]} onClick={props.onCancel}>
          <svg width='20' height='21' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <line x1='1.35355' y1='0.646447' x2='25.3536' y2='24.6464' stroke='black'></line>
            <line x1='0.646447' y1='24.6464' x2='24.6464' y2='0.646448' stroke='black'></line>
          </svg>
        </div>
      </div>
      <form onSubmit={onSubmitTableNumberHandler}>
        <div className={styles["modal-body"]}>
          <input ref={tableNumberRef} type='text' placeholder='Table number' required />
        </div>
        <div className={styles["modal-actions"]}>
          <button type='button' className={styles["btn-secondary"]} onClick={onCancelAddTableHandler}>
            Cancel
          </button>
          <button type='submit' className={styles["btn-primary"]}>
            {props.headerText === "Add Table Number" ? "Add Table" : "Change Table"}
          </button>
        </div>
      </form>
    </div>
  );
};

const AddTableNumberModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop show={props.showModal} />, document.getElementById("backdrop-root"))}
      {ReactDOM.createPortal(
        <Modal
          headerText={props.headerText}
          show={props.showModal}
          onAddTable={props.onAddTable}
          onCancel={props.onCancel}
        />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default AddTableNumberModal;
