import { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import RequestIcon from "../../assets/icons/RequestIcon";
import BookMarkIcon from "../../assets/icons/BookMarkIcon";
import TickIcon from "../../assets/icons/TickIcon";
import ExclamationIcon from "../../assets/icons/ExclamationIcon";
import GearIcon from "../../assets/icons/GearIcon";
import ListIcon from "../../assets/icons/ListIcon";

import styles from "./BooksTabs.module.css";

// FIREBASE
import { getFirestore, onSnapshot, collection } from "firebase/firestore";

const ReservationTabs = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [indicatorIndex, setindicatorIndex] = useState(0);

  const [overdueBooksCounter, setOverdueBooksCounter] = useState(0);

  const onButtonClickHandler = (index) => {
    setindicatorIndex(index);

    if (index === 3) {
      setOverdueBooksCounter(0);
    }
  };

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 1150) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    const getRealTimeCounters = async () => {
      const db = getFirestore();

      onSnapshot(collection(db, "overdueBooks"), (querySnapshot) => {
        const overdueBooks = [];
        querySnapshot.forEach((doc) => {
          overdueBooks.push(doc.data());
        });

        let counter = overdueBooks.length;
        setOverdueBooksCounter(counter);
      });
    };
    getRealTimeCounters();
  }, []);

  return (
    <div className={styles.tabs}>
      <h1 className={styles.title}>Library</h1>
      <header className={styles["tab-header"]}>
        <div className={styles["tab-btn-container"]}>
          <NavLink
            to='holdRequests'
            className={`${styles["tab-btn"]} ${indicatorIndex === 0 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(0)}>
            {!isMobile && <RequestIcon active={indicatorIndex === 0} />}
            <span>Hold {!isMobile && <br />} Requests</span>
          </NavLink>
          <NavLink
            to='bookHolds'
            className={`${styles["tab-btn"]} ${indicatorIndex === 1 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(1)}>
            {!isMobile && <BookMarkIcon active={indicatorIndex === 1} />}
            <span>Book{!isMobile && <br />} Holds</span>
          </NavLink>
          <NavLink
            to='borrowedBooks'
            className={`${styles["tab-btn"]} ${indicatorIndex === 2 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(2)}>
            {!isMobile && <TickIcon active={indicatorIndex === 2} />}
            <span>Borrowed{!isMobile && <br />} Books</span>
          </NavLink>
          <NavLink
            to='overdueBooks'
            className={`${styles["tab-btn"]} ${indicatorIndex === 3 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(3)}>
            {!isMobile && <ExclamationIcon active={indicatorIndex === 3} />}
            <span>Overdue {!isMobile && <br />} Books</span>
            {overdueBooksCounter > 0 && !isMobile ? (
              <span className={styles.indicator}>{overdueBooksCounter}</span>
            ) : overdueBooksCounter > 0 && isMobile ? (
              <span className={styles.indicatorMobile}>{overdueBooksCounter}</span>
            ) : (
              ""
            )}
          </NavLink>
          <NavLink
            to='libraryMembers'
            className={`${styles["tab-btn"]} ${indicatorIndex === 4 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(4)}>
            {!isMobile && <ListIcon active={indicatorIndex === 4} />}
            <span>Library{!isMobile && <br />} Members</span>
          </NavLink>
          <NavLink
            to='libraryCrud'
            className={`${styles["tab-btn"]} ${indicatorIndex === 5 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(5)}>
            {!isMobile && <GearIcon active={indicatorIndex === 5} />}
            <span>Library{!isMobile && <br />} CRUD</span>
          </NavLink>
        </div>
        {!isMobile && (
          <div
            style={{ left: `calc(calc(100% / 6)*${indicatorIndex})` }}
            className={styles["tab-indicator"]}></div>
        )}
      </header>
    </div>
  );
};

export default ReservationTabs;
