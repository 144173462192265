import FriedDesktop from "./FriedDesktop";
import FriedMobile from "./FriedMobile";
import styles from "./FriedALB.module.css";

const FriedALB = (props) => {
  return (
    <div className={styles.container}>
      <FriedDesktop onShowModalHandler={props.showModal} />
      <FriedMobile onShowModalHandler={props.showModal} />
    </div>
  );
};

export default FriedALB;
