import { Outlet } from "react-router-dom";

import TopNavBar from "./components/TopNavBar/TopNavBar";
import SideNavBar from "./components/SideNavBar/SideNavBar";

import Footer from "./components/Footer/Footer";

import styles from "./App.module.css";

const LayoutMain = () => {
  return (
    <div className={styles["page-container"]}>
      <TopNavBar />
      <SideNavBar />
      <main className={styles["main-container"]}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default LayoutMain;
