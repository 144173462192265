import React, { useState, useEffect } from "react";
import logo from "../../assets/images/soma_logo.png";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import { romanize } from "../../utils/romanize";
import CreditsModal from "../../Modals/CreditsModal";
import styles from "./Footer.module.css";
const Footer = () => {
  const [showCreditsModal, setShowCreditsModal] = useState(false);

  let copyrightYear = romanize(new Date().getFullYear());

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowCreditsModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  const creditsModalHandler = () => {
    setShowCreditsModal(true);
  };

  const onCancelCreditsModal = () => {
    setShowCreditsModal(false);
  };
  return (
    <footer className={styles.footer}>
      <CreditsModal showModal={showCreditsModal} onCancel={onCancelCreditsModal} />
      <h1>
        SOMA
        <img src={logo} className={styles.logo} alt='Soma Logo representing a bird' />
        <br />
        <span className={styles.test}>BOOK STATION</span>
      </h1>
      <div className={styles["footer-credits"]}>
        <small>
          <MultiLingualContent contentId='footer' prop='body' />
          <br />© {copyrightYear}{" "}
          {/* <a href='#/' title='Privacy Policy'>
            <MultiLingualContent contentId='footer' prop='link1' />{" "}
          </a>
          ·{" "} */}
          <button href='' title='Website Credits' onClick={creditsModalHandler}>
            <MultiLingualContent contentId='footer' prop='link2' />{" "}
          </button>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
