import { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../hooks/useScrollToTop";

import { LanguageContext } from "../../contexts/Language/LanguageContext";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";

import isDateSupported from "../../utils/isDateSupported";

import getTranslatedMonth from "../../utils/getTranslatedMonth";
import getTranslatedWeekday from "../../utils/getTranslatedWeekday";

import ChevronDown from "../../assets/icons/ChevronDown";
import NumberOfPeople from "../../assets/icons/NumberOfPeople";
import ReservationDate from "../../assets/icons/ReservationDate";
import Clock from "../../assets/icons/Clock";
import { reservationTimes } from "./reservationTimes";
import styles from "./ReservationRequestPage.module.css";

// FIREBASE

import { addDoc, getFirestore, Timestamp, collection } from "firebase/firestore";

const ReservationRequestPage = () => {
  // For older versions of Safari that don't support date and time inputs
  const [dateSupported, setDateSupported] = useState(false);
  const [invalidDay, setInvalidDay] = useState(false);

  const { language } = useContext(LanguageContext);

  let singular;
  let plural;
  let placeholderName;
  let placeHolderLastName;
  let placeHolderPhone;
  let placeHolderEmail;
  let placeHolderSpecialReq;

  if (language === "albanian") {
    singular = "vetë";
    plural = "veta";
    placeholderName = "Emni";
    placeHolderLastName = "Mbiemni";
    placeHolderPhone = "Nr. i telefonit";
    placeHolderEmail = "Email-i";
    placeHolderSpecialReq = "Shto kërkesë të veçantë (opcionale)";
  } else {
    singular = "person";
    plural = "people";
    placeholderName = "First name";
    placeHolderLastName = "Last name";
    placeHolderPhone = "Phone number";
    placeHolderEmail = "Email";
    placeHolderSpecialReq = "Add a special request (optional)";
  }

  const navigate = useNavigate();
  useScrollToTop();
  const [partySize] = useState(Array.from({ length: 20 }));
  const [numberOfPeople, setNumberOfPeople] = useState("1");
  const [reservationDisplayTime, setReservationDisplayTime] = useState("8:00 am");

  const [reservationDate, setReservationDate] = useState(null);
  const [typeOfService, setTypeOfService] = useState("");

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const specialRequestRef = useRef();

  let weekday = new Date(reservationDate).toLocaleString("default", { weekday: "long" });
  let month = new Date(reservationDate).toLocaleString("default", { month: "long" });
  let day = new Date(reservationDate).toLocaleString("default", { day: "numeric" });

  let displayDate;

  if (language === "albanian" && reservationDate) {
    displayDate = getTranslatedWeekday(weekday) + ", " + day + " " + getTranslatedMonth(month);
  }
  if (language === "english" && reservationDate) {
    displayDate = weekday + ", " + month + " " + day;
  }

  if (!reservationDate) {
    displayDate = "-";
  }

  useEffect(() => {
    if (isDateSupported()) {
      setDateSupported(true);
    }
  }, []);

  const onGoBackHandler = () => {
    navigate(-1);
  };

  const onChangePartySizeHandler = (ev) => {
    setNumberOfPeople(parseInt(ev.target.value));
  };
  const onTimeChangeHandler = (ev) => {
    setReservationDisplayTime(ev.target.value);
  };

  const typeOfServiceHandler = (ev) => {
    setTypeOfService(ev.target.value);
  };

  const onDateChangeHandler = (ev) => {
    const day = new Date(ev.target.value).getDay();

    if (day === 0) {
      setInvalidDay(true);
    } else {
      // Here sometimes you can get NaN. Should I take care of that case?
      setInvalidDay("");
    }
    setReservationDate(ev.target.value);
  };
  const onSubmitHandler = async (ev) => {
    ev.preventDefault();

    const reservationRequest = {
      fullName: firstNameRef.current.value.toLowerCase() + " " + lastNameRef.current.value.toLowerCase(),
      phone: phoneNumberRef.current.value.trim(),
      email: emailRef.current.value,
      specialRequest: specialRequestRef.current.value.trim() || "none",
      typeOfService: typeOfService,
      numberOfPeople: numberOfPeople,
      date: Timestamp.fromDate(new Date(reservationDate)),
      time: reservationDisplayTime,
    };
    // Check if use has selected invalid day. If yes, don't allow form submission
    if (invalidDay) {
      return;
    }

    const db = getFirestore();

    await addDoc(collection(db, "reservationRequests"), reservationRequest);

    navigate("/reservations/success", { replace: true });
  };

  return (
    <div className={styles["page-wrapper"]}>
      <div className={styles.container}>
        <nav className={styles.nav}>
          &lt;
          <span onClick={onGoBackHandler}>
            <MultiLingualContent contentId='reservations' prop='button2' />
          </span>
        </nav>

        <h1 className={styles.title}>
          <MultiLingualContent contentId='reservations' prop='formTitle' />
        </h1>
        <div className={styles["inner-container"]}>
          <form className={styles.form} onSubmit={onSubmitHandler}>
            <div className={styles.pickers}>
              <div className={styles.control}>
                <div className={styles["input-icon"]}>
                  <NumberOfPeople />
                </div>
                <div className={styles.label}>{`${numberOfPeople} ${
                  numberOfPeople === "1" ? singular : plural
                }`}</div>
                <ChevronDown />
                <select className={styles.select} onChange={onChangePartySizeHandler} value={numberOfPeople}>
                  {partySize.map((v, i) => {
                    return (
                      <option key={i} value={i + 1} label={`${i + 1} ${i + 1 === 1 ? singular : plural}`}>
                        {i + 1} {i + 1 === 1 ? singular : plural}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={styles.control}>
                <input
                  type='date'
                  className={styles["date-input"]}
                  onBlur={onDateChangeHandler}
                  placeholder={!dateSupported ? "YYYY-DD-MM" : undefined}
                  min={new Date().toISOString().split("T")[0]}
                  required></input>
                <div className={styles["input-icon"]}>
                  <ReservationDate />
                </div>
                {!dateSupported ? "" : <ChevronDown />}
              </div>
              <div className={styles.control}>
                <div className={styles["input-icon"]}>
                  <Clock />
                </div>
                <div className={styles.label}>{reservationDisplayTime}</div>
                <ChevronDown />
                <select
                  className={styles.select}
                  value={reservationDisplayTime}
                  onChange={onTimeChangeHandler}>
                  {reservationTimes.map((time, i) => {
                    return (
                      <option key={i} value={time.label} label={time.label}>
                        {time.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className={styles.wrapper}>
              <div className={styles["user-inputs-wrapper"]}>
                <div className={styles["input-control-wrapper"]}>
                  <input ref={firstNameRef} type='text' placeholder={placeholderName} required />
                </div>
                <div className={styles["input-control-wrapper"]}>
                  <input ref={lastNameRef} type='text' placeholder={placeHolderLastName} required />
                </div>
                <div className={styles["input-control-wrapper"]}>
                  <input ref={phoneNumberRef} type='tel' placeholder={placeHolderPhone} required />
                </div>
                <div className={styles["input-control-wrapper"]}>
                  <input ref={emailRef} type='email' placeholder={placeHolderEmail} required />
                </div>
                <div className={styles["input-control-wrapper"]}>
                  <input ref={specialRequestRef} type='text' placeholder={placeHolderSpecialReq} />
                </div>
                <fieldset onChange={typeOfServiceHandler} className={styles.fieldset}>
                  <legend>
                    <MultiLingualContent contentId='reservations' prop='legend' />
                  </legend>
                  <div className={styles.radio}>
                    <input
                      type='radio'
                      id='foodAndDrinks'
                      name='typeOfService'
                      value='foodAndDrinks'
                      required
                    />
                    <label htmlFor='foodAndDrinks'>
                      <MultiLingualContent contentId='reservations' prop='radioLabel1' />
                    </label>
                  </div>
                  <div className={styles.radio}>
                    <input type='radio' id='drinksOnly' name='typeOfService' value='drinksOnly' required />
                    <label htmlFor='drinksOnly'>
                      <MultiLingualContent contentId='reservations' prop='radioLabel2' />
                    </label>
                  </div>
                </fieldset>
              </div>
              <div className={styles["info-wrapper"]}>
                <section className={styles["reservation-details"]}>
                  <h3 className={styles["reservation-details-title"]}>Soma Book Station</h3>
                  <div>
                    <ul className={styles["reservation-details-list"]}>
                      <li className={styles["reservation-details-item"]}>
                        <ReservationDate />
                        <span>
                          {invalidDay ? (
                            <span className={styles.invalidDayAlert}>
                              {language === "albanian"
                                ? "Jemi mshelë të Dieleve!"
                                : "We are closed on Sundays!"}
                            </span>
                          ) : (
                            displayDate
                          )}
                        </span>
                      </li>
                      <li className={styles["reservation-details-item"]}>
                        <NumberOfPeople />
                        <span>
                          {numberOfPeople} {numberOfPeople === "1" ? singular : plural}
                        </span>
                      </li>
                      <li className={styles["reservation-details-item"]}>
                        <Clock />
                        <span>{reservationDisplayTime}</span>
                      </li>
                    </ul>
                  </div>
                </section>
                <section className={styles["restaurant-notes"]}>
                  <h4 className={styles["notes-title"]}>
                    <MultiLingualContent contentId='reservations' prop='formSubtitle' />
                  </h4>
                  <p>
                    <MultiLingualContent contentId='reservations' prop='formNote1' />
                  </p>
                  <p>
                    <MultiLingualContent contentId='reservations' prop='formNote2' />
                  </p>
                </section>
              </div>
            </div>
            <button type='submit' className={styles["button-23"]}>
              <MultiLingualContent contentId='reservations' prop='button3' />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReservationRequestPage;
