import BeersMobile from "./BeersMobile";
import BeersDesktop from "./BeersDesktop";
import styles from "./BeersALB.module.css";

const BeersALB = (props) => {
  return (
    <div className={styles.container}>
      <BeersMobile />
      <BeersDesktop />
    </div>
  );
};

export default BeersALB;
