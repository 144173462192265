import styles from "./ReservationDate.module.css";

const ReservationDate = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' className={styles.svg}>
      <path d='M96 32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32zM448 464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192H448V464z' />
    </svg>
  );
};

export default ReservationDate;
