import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import FlashMessage from "../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../Modals/DeleteModal";
import NextButton from "../../../../components/UI/NextButton";
import EventRow from "./EventRow";
import styles from "./UpcomingEvents.module.css";

// FIREBASE
import {
  getDocs,
  getFirestore,
  collection,
  deleteDoc,
  doc,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

const UpcomingEvents = () => {
  const [alert, setAlert] = useState("");
  const [events, setEvents] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [eventId, setSetEventId] = useState(null);
  // For Pagination
  const [last, setLast] = useState();

  useEffect(() => {
    // Connect to firestore
    const getEvents = async () => {
      setIsPending(true);
      const db = getFirestore();

      const q = query(collection(db, "events"), orderBy("date", "asc"), limit(6));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setIsPending(false);
        return;
      }

      // FOR PAGINATION
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLast(lastVisible);

      const eventsData = [];
      querySnapshot.forEach(async (d) => {
        eventsData.push({ ...d.data(), docId: d.id });
      });
      setEvents(eventsData);
      setIsPending(false);
    };
    getEvents();
  }, []);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onConfirmHandler = (eventId) => {
    setShowDeleteModal(true);
    setSetEventId(eventId);
  };

  const onCancelHandler = () => {
    setShowDeleteModal(false);
  };

  const onDeleteEventHandler = async () => {
    // Delete doc from upcomingEvents collection
    const db = getFirestore();
    await deleteDoc(doc(db, "events", eventId));

    // delete image from upcomingEvents folder in storage

    const event = events.find((event) => event.docId === eventId);
    const storage = getStorage();

    if (event.imageRef) {
      const imageRef = ref(storage, event.imageRef);
      // Delete the file
      deleteObject(imageRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    }
    setEvents((prevState) => {
      return prevState.filter((events) => events.docId !== eventId);
    });
    flashMessageHandler({ message: "Event Deleted ", success: true });
    setShowDeleteModal(false);
    setSetEventId(null);
  };

  const showMoreItemsHandler = async () => {
    const db = getFirestore();

    if (last === "") {
      return;
    }
    const next = query(collection(db, "events"), orderBy("date", "asc"), startAfter(last), limit(6));
    const querySnapshot = await getDocs(next);

    if (querySnapshot.empty) {
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLast(lastVisible);

    querySnapshot.forEach((d) => {
      setEvents((prevState) => {
        return [...prevState, { ...d.data(), docId: d.id }];
      });
    });
  };

  return (
    <div className={styles["upcoming-events-container"]}>
      <DeleteModal
        message={"Are you sure you want to delete this event?"}
        showModal={showDeleteModal}
        onConfirm={onDeleteEventHandler}
        onCancel={onCancelHandler}
      />
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      {isPending ? (
        "Loading..."
      ) : events.length > 0 ? (
        <table className={styles.events}>
          <thead>
            <tr>
              <th>Event Name</th>
              <th>Date/Time</th>
              <th>Type</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {events.map((data) => {
              return (
                <EventRow
                  key={uuid()}
                  id={data.id}
                  docId={data.docId}
                  name={data.name}
                  date={data.date}
                  type={data.type}
                  onDeleteEvent={onConfirmHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>Currently there are no events</p>
      )}
      {events.length > 0 && <NextButton onClick={showMoreItemsHandler} />}
    </div>
  );
};

export default UpcomingEvents;
