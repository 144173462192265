let genres = [
  {
    disabled: false,
    genre: "Americana",
  },
  {
    disabled: false,
    genre: "Blues",
  },
  {
    disabled: false,
    genre: "Classical",
  },
  {
    disabled: false,
    genre: "Country",
  },
  {
    disabled: false,
    genre: "Dance",
  },
  {
    disabled: false,
    genre: "Deep House",
  },
  {
    disabled: false,
    genre: "Dubstep",
  },
  {
    disabled: false,
    genre: "EDM",
  },
  {
    disabled: false,
    genre: "Electro House",
  },
  {
    disabled: false,
    genre: "Electronic",
  },
  {
    disabled: false,
    genre: "Electronica",
  },
  {
    disabled: false,
    genre: "Folk",
  },
  {
    disabled: false,
    genre: "Future House",
  },
  {
    disabled: false,
    genre: "Gospel",
  },
  {
    disabled: false,
    genre: "Hip-Hop",
  },
  {
    disabled: false,
    genre: "House",
  },
  {
    disabled: false,
    genre: "Instrumental",
  },
  {
    disabled: false,
    genre: "Jazz",
  },
  {
    disabled: false,
    genre: "Latin",
  },
  {
    disabled: false,
    genre: "Metal",
  },
  {
    disabled: false,
    genre: "Minimal",
  },
  {
    disabled: false,
    genre: "Pop",
  },
  {
    disabled: false,
    genre: "Progressive House",
  },
  {
    disabled: false,
    genre: "R&B",
  },
  {
    disabled: false,
    genre: "Rap",
  },
  {
    disabled: false,
    genre: "Reggae",
  },
  {
    disabled: false,
    genre: "Rock",
  },
  {
    disabled: false,
    genre: "Spanish",
  },
  {
    disabled: false,
    genre: "Tech House",
  },
  {
    disabled: false,
    genre: "Techno",
  },
  {
    disabled: false,
    genre: "Trap",
  },
];

export default genres;
