import React, { Fragment, useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../../contexts/Language/LanguageContext";
import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import SwiperRecords from "./SwiperRecords";
import SearchBar from "../../../components/UI/SearchBar";
import RecordItemModal from "../../../Modals/RecordItemModal.js";
import ItemCategoriesNav from "../../../components/UI/ItemCategoriesNavRecords";
import FilterToolbar from "../Books/FilterToolbar";
import RecordList from "./RecordList";

import { vinylCategoriesData, vinylCategoriesDataALB } from "./vinylCategoriesData";

import Loader from "../../../components/UI/Loader";
import styles from "./Records.module.css";

// FIREBASE
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  orderBy,
} from "firebase/firestore";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

const Records = () => {
  const [browseByCategory, setBrowseByCategory] = useState(false);
  const [searchResultRecords, setSearchResultRecords] = useState([]);
  // PAGINATION STATE
  const [last, setLast] = useState();
  const [first, setFirst] = useState();
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disablePrevButton, setDisablePrevButton] = useState(false);

  const [searchObj, setSearchObj] = useState(null);
  const [categorySearchStr, setCategorySearchStr] = useState("");

  const [noResult, setNoResult] = useState(false);
  const [recordsSortOrder, setRecordsSortOrder] = useState("titleAsc");
  const [showNewArrivals, setShowNewArrivals] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [recordDetailsModal, setRecordDetailsModal] = useState(false);
  const [recordDetails, setRecordDetails] = useState({});

  const { language } = useContext(LanguageContext);
  useEffect(() => {
    if (sessionStorage.getItem("showNewArrivalsRecords") !== null) {
      setShowNewArrivals(JSON.parse(sessionStorage.showNewArrivalsRecords));
    }
  }, []);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setRecordDetailsModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  // SORT ORDER
  useEffect(() => {
    const sortRecords = (type) => {
      const types = {
        titleAsc: "title",
        titleDesc: "title",
        authorAsc: "artist",
        authorDesc: "artist",
      };

      const sortProperty = types[type];

      function sortAsc(a, b) {
        if (a[sortProperty].toLowerCase() > b[sortProperty].toLowerCase()) {
          return 1;
        } else if (b[sortProperty] > a[sortProperty]) {
          return -1;
        } else {
          return 0;
        }
      }

      if (type === "titleAsc") {
        setSearchResultRecords((prevState) => {
          return [...prevState].sort(sortAsc);
        });
      }

      if (type === "titleDesc") {
        setSearchResultRecords((prevState) => {
          return [...prevState].sort(sortAsc).reverse();
        });
      }
      if (type === "authorAsc") {
        setSearchResultRecords((prevState) => {
          return [...prevState].sort(sortAsc);
        });
      }

      if (type === "authorDesc") {
        setSearchResultRecords((prevState) => {
          return [...prevState].sort(sortAsc).reverse();
        });
      }
    };

    sortRecords(recordsSortOrder);
  }, [recordsSortOrder]);

  const browseByCategoryHandler = () => {
    setBrowseByCategory(!browseByCategory);
  };

  const newArrivalsHandler = () => {
    setShowNewArrivals(!showNewArrivals);
    sessionStorage.setItem("showNewArrivalsRecords", JSON.stringify(!showNewArrivals));
  };

  function loadRecordData(querySnapshot) {
    const storage = getStorage();
    querySnapshot.forEach(async (doc) => {
      if (doc.data().imageRef) {
        const pathReference = ref(storage, doc.data().imageRef);
        try {
          let url = await getDownloadURL(pathReference);
          setSearchResultRecords((prevState) => {
            return [
              ...prevState,
              {
                ...doc.data(),
                docId: doc.id,
                url,
              },
            ];
          });
        } catch (error) {
          setSearchResultRecords((prevState) => {
            return [
              ...prevState,
              {
                ...doc.data(),
                docId: doc.id,
                url: "not available",
              },
            ];
          });
        }
      } else {
        setSearchResultRecords((prevState) => {
          return [
            ...prevState,
            {
              ...doc.data(),
              docId: doc.id,
              url: "not available",
            },
          ];
        });
      }
    });
  }
  useEffect(() => {
    // INITIAL SEARCH
    const searchRecordsHandler = async () => {
      window.sessionStorage.removeItem("searchCategory");

      // REMOVE HTML ELEMENTS FROM PREVIOUS SEARCH
      setNoResult(false);
      setSearchResultRecords([]);

      if (searchObj === null) {
        return;
      }
      if (searchObj) {
        setCategorySearchStr("");
        setBrowseByCategory(false);

        let { searchCriteria, searchValue } = searchObj;

        if (searchValue === "") {
          setNoResult(true);
          return;
        }

        const db = getFirestore();

        const q = query(
          collection(db, "records"),
          where(searchCriteria, ">=", searchValue.toLowerCase()),
          where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff"),
          orderBy(searchCriteria),
          limit(4)
        );
        setIsLoading(true);
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setIsLoading(false);
          return setNoResult(true);
        }
        // FOR PAGINATION
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const firstVisible = querySnapshot.docs[0];
        setLast(lastVisible);
        setFirst(firstVisible);
        loadRecordData(querySnapshot);
        setIsLoading(false);
      }
    };
    searchRecordsHandler();
  }, [searchObj]);

  useEffect(() => {
    const searchByCategoryHandler = async () => {
      setSearchResultRecords([]);
      setSearchObj(null);
      setNoResult(false);

      const db = getFirestore();

      if (!categorySearchStr) {
        return;
      }

      const q = query(
        collection(db, "records"),
        where("genre", "array-contains", categorySearchStr),
        orderBy("title"),
        limit(4)
      );
      setIsLoading(true);
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setIsLoading(false);
        return setNoResult(true);
      }

      // FOR PAGINATION
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const firstVisible = querySnapshot.docs[0];
      setLast(lastVisible);
      setFirst(firstVisible);
      setIsLoading(false);
      loadRecordData(querySnapshot);
    };
    searchByCategoryHandler();
  }, [categorySearchStr]);

  async function nextPage() {
    const db = getFirestore();
    setDisablePrevButton(false);
    let next;

    if (searchObj) {
      const { searchValue, searchCriteria } = searchObj;
      next = query(
        collection(db, "records"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff"),
        orderBy(searchCriteria),
        startAfter(last),
        limit(4)
      );
    } else {
      next = query(
        collection(db, "records"),
        where("genre", "array-contains", categorySearchStr),
        orderBy("title"),
        startAfter(last),
        limit(4)
      );
    }

    setIsLoading(true);

    const querySnapshot = await getDocs(next);
    console.log(querySnapshot.empty, "hello");
    if (querySnapshot.empty) {
      setIsLoading(false);
      setDisableNextButton(true);
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const firstVisible = querySnapshot.docs[0];

    setLast(lastVisible);
    setFirst(firstVisible);
    setSearchResultRecords([]);
    loadRecordData(querySnapshot);
    setIsLoading(false);
  }

  async function prevPage() {
    const db = getFirestore();
    setDisableNextButton(false);
    let prev;
    if (searchObj) {
      const { searchValue, searchCriteria } = searchObj;
      prev = query(
        collection(db, "records"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff"),
        orderBy(searchCriteria),
        endBefore(first),
        limitToLast(4)
      );
    } else {
      prev = query(
        collection(db, "records"),
        where("genre", "array-contains", categorySearchStr),
        orderBy("title"),
        endBefore(first),
        limitToLast(6)
      );
    }
    setIsLoading(true);
    const querySnapshot = await getDocs(prev);
    if (querySnapshot.empty) {
      setIsLoading(false);
      setDisablePrevButton(true);
      return;
    }

    setIsLoading(false);

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const firstVisible = querySnapshot.docs[0];
    setLast(lastVisible);
    setFirst(firstVisible);
    setSearchResultRecords([]);
    loadRecordData(querySnapshot);
    setIsLoading(false);
  }

  const onUpdateCategoryString = (str) => {
    setDisablePrevButton(false);
    setDisableNextButton(false);
    setCategorySearchStr(str);
  };

  const onSubmitSearchQuery = (obj) => {
    setDisablePrevButton(false);
    setDisableNextButton(false);
    setSearchObj(obj);
  };

  const onShowRecordDetailsModal = (docId) => {
    let record = searchResultRecords.find((record) => record.docId === docId);
    setRecordDetails(record);
    setRecordDetailsModal(true);
  };

  const onCancelRecordDetailsModal = () => {
    setRecordDetailsModal(false);
  };

  return (
    <Fragment>
      <div className={styles.container}>
        {recordDetailsModal && (
          <RecordItemModal onCancel={onCancelRecordDetailsModal} recordDetails={recordDetails} />
        )}
        <SearchBar onSubmit={onSubmitSearchQuery} type={"records"} />
        {!browseByCategory && (
          <button type='button' className={styles["browse-btn"]} onClick={browseByCategoryHandler}>
            <MultiLingualContent contentId='booksAndRecords' prop='button2' />
          </button>
        )}
        {browseByCategory && (
          <ItemCategoriesNav
            onSubmit={onUpdateCategoryString}
            categories={language === "albanian" ? vinylCategoriesDataALB : vinylCategoriesData}
            onCancel={browseByCategoryHandler}
          />
        )}
        {!showNewArrivals && (
          <button type='button' className={styles["browse-btn"]} onClick={newArrivalsHandler}>
            <MultiLingualContent contentId='booksAndRecords' prop='button3' />
          </button>
        )}
        {showNewArrivals && (
          <div className={styles["new-records-container"]}>
            <div className={styles["records-carousel-container"]}>
              <svg
                className={styles.svg}
                viewBox='0 0 26 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={newArrivalsHandler}>
                <line x1='1.35355' y1='0.646447' x2='25.3536' y2='24.6464' stroke='black'></line>
                <line x1='0.646447' y1='24.6464' x2='24.6464' y2='0.646448' stroke='black'></line>
              </svg>
              <SwiperRecords />
            </div>
          </div>
        )}

        {searchObj || categorySearchStr ? (
          <FilterToolbar
            numberOfBooks={searchResultRecords.length}
            sortType={recordsSortOrder}
            onSortOrderChange={setRecordsSortOrder}
          />
        ) : (
          ""
        )}
        <div className={searchObj || categorySearchStr ? styles["record-list-container"] : ""}>
          {noResult ? (
            <p className={styles["no-result"]}>
              <MultiLingualContent contentId='booksAndRecords' prop='note1' />
            </p>
          ) : isLoading ? (
            <Loader />
          ) : (
            <RecordList
              sortType={recordsSortOrder}
              onSortOrderChange={setRecordsSortOrder}
              searchResult={searchResultRecords}
              showMoreItems={nextPage}
              prevPage={prevPage}
              buttonState={{ nextButton: disableNextButton, prevButton: disablePrevButton }}
              onShowRecordDetailsModal={onShowRecordDetailsModal}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Records;
