import styles from "./EditFlaggedGuestForm.module.css";

const EditFlaggedGuestForm = (props) => {
  const onChangeNameHandler = (ev) => {
    props.editGuest((prevState) => {
      return {
        ...prevState,
        fullName: ev.target.value,
      };
    });
  };
  const onChangePhoneHandler = (ev) => {
    props.editGuest((prevState) => {
      return {
        ...prevState,
        phone: ev.target.value,
      };
    });
  };

  const onChangeEmailHandler = (ev) => {
    props.editGuest((prevState) => {
      return {
        ...prevState,
        email: ev.target.value.trim(),
      };
    });
  };

  return (
    <form onSubmit={props.onSubmit}>
      <div className={styles.controls}>
        <label htmlFor='customerName'>Full Name</label>
        <input
          type='text'
          id='customerName'
          placeholder='Customer Name'
          autoComplete='off'
          aria-label='Customer Name'
          required
          value={props.guest.fullName}
          onChange={onChangeNameHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='customerPhone'>Phone Number</label>
        <input
          type='tel'
          id='customerPhone'
          placeholder='Phone Number'
          autoComplete='off'
          aria-label='Customer Phone'
          required
          value={props.guest.phone}
          onChange={onChangePhoneHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='customerEmail'>Email</label>
        <input
          type='email'
          id='customerEmail'
          placeholder='Email'
          autoComplete='off'
          aria-label='Customer Email'
          required
          value={props.guest.email}
          onChange={onChangeEmailHandler}
        />
      </div>

      <div className={styles["modal-actions"]}>
        <button type='button' className={styles["btn-secondary"]} onClick={props.onCancel}>
          Cancel Edit
        </button>
        <button type='submit' className={styles["btn-primary"]}>
          Edit Flagged Guest
        </button>
      </div>
    </form>
  );
};

export default EditFlaggedGuestForm;
