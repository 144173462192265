import { v4 as uuidv4 } from "uuid";
import CheckboxList from "../../../AdminBooksPage/LibraryCRUD/UpdateBook/CheckboxList";
import styles from "./UpdateRecordForm.module.css";

const UpdateRecordForm = (props) => {
  const onChangeTitleHandler = (ev) => {
    props.editRecord((prevState) => {
      return {
        ...prevState,
        title: ev.target.value.toLowerCase().trim(),
      };
    });
  };
  const onChangeArtistHandler = (ev) => {
    props.editRecord((prevState) => {
      return {
        ...prevState,
        artist: ev.target.value.toLowerCase().trim(),
      };
    });
  };

  const onChangeGenreHandler = (ev, value) => {
    props.setChecklistOptions((prevState) => {
      return prevState.map((option) => {
        return option.value === value ? { ...option, checked: !option.checked } : option;
      });
    });
  };
  const onChangeDescriptionHandler = (ev) => {
    props.editRecord((prevState) => {
      return {
        ...prevState,
        overview: ev.target.value.trim(),
      };
    });
  };
  const onChangeTracklistHandler = (ev) => {
    props.editRecord((prevState) => {
      return {
        ...prevState,
        tracklist: ev.target.value.split("\n"),
      };
    });
  };
  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    props.onSubmit();
  };

  const onChangeImageHandler = (event) => {
    const resize_width = 450; //without px

    //get the image selected
    let item = event.target.files[0];

    let uniqueImgName = uuidv4() + ".jpeg";

    if (item) {
      props.editRecord((prevState) => {
        return {
          ...prevState,
          imageRef: `recordcovers/${uniqueImgName}`,
        };
      });

      //create a FileReader
      const reader = new FileReader();

      //image turned to base64-encoded Data URI.
      reader.readAsDataURL(item);
      reader.name = item.name; //get the image's name
      reader.size = item.size; //get the image's size

      reader.onload = function (event) {
        let img = new Image(); //create an image
        img.src = event.target.result; //result is base64-encoded Data URI

        img.size = event.target.size; //set size (optional)
        img.onload = function (el) {
          var elem = document.createElement("canvas"); //create a canvas

          //scale the image to 600 (width) and keep aspect ratio
          var scaleFactor = resize_width / el.target.width;
          elem.width = resize_width;
          elem.height = el.target.height * scaleFactor;

          //draw in canvas
          var ctx = elem.getContext("2d");
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          //get the base64-encoded Data URI from the resize image
          var srcEncoded = ctx.canvas.toDataURL("image/jpeg", 0.5);
          //assign it to thumb src
          props.setImgUrl(srcEncoded);
          props.setImgName(uniqueImgName);
          /*Now you can send "srcEncoded" to the server and
          convert it to a png o jpg. Also can send
          "el.target.name" that is the file's name.*/
        };
      };
    }
  };

  return (
    <form onSubmit={onSubmitHandler} className={styles.form}>
      <div className={styles.controls}>
        <label htmlFor='recordTitle'>Record Title</label>
        <input
          type='text'
          id='recordTitle'
          placeholder='Record Title'
          autoComplete='off'
          aria-label='Record Title'
          required
          value={props.record.title}
          onChange={onChangeTitleHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='author'>Record Artist</label>
        <input
          type='text'
          id='artist'
          placeholder='Record Artist'
          autoComplete='off'
          aria-label='Record Artist'
          required
          value={props.record.artist}
          onChange={onChangeArtistHandler}
        />
      </div>
      <CheckboxList
        options={props.checklistOptions}
        categories={props.record.genre}
        setChecklistOptions={props.setChecklistOptions}
        onChange={onChangeGenreHandler}
      />
      <div className={styles.controls}>
        <label htmlFor='recordDescription'>Record Description</label>
        <textarea
          id='recordDescription'
          placeholder='Short Description'
          aria-label='Short Description'
          value={props.record.overview}
          onChange={onChangeDescriptionHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='recordTracklist'>Tracklist (please use newline for each track )</label>
        <textarea
          id='recordTracklist'
          placeholder='Record Tracklist'
          aria-label='Record Tracklist'
          value={props.record.tracklist ? props.record.tracklist.join("\n") : ""}
          onChange={onChangeTracklistHandler}
        />
      </div>
      <div className={styles["file-control"]}>
        <label htmlFor='recordCover'>Record Cover</label>
        <input
          type='file'
          id='recordCover'
          aria-label='Upload Image'
          accept='image/*'
          onChange={onChangeImageHandler}
        />
      </div>
      <div className={styles.action}>
        <button type='submit' className={styles["button-24"]}>
          Update Record
        </button>
      </div>
      <p>{props.isRequired ? "Please select at least one of the record genres above" : ""}</p>
    </form>
  );
};

export default UpdateRecordForm;
