import { Fragment, useState, useEffect, useContext, useRef } from "react";
import { MultiLingualContent } from "../../../../contexts/Language/MultiLingualContent";
import { LanguageContext } from "../../../../contexts/Language/LanguageContext";
import AuthContext from "../../../../contexts/AuthContext";
import FlashMessage from "../../../../components/UI/FlashMessage";
import styles from "./Account.module.css";
// FIREBASE
import {
  getAuth,
  sendEmailVerification,
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider,
} from "firebase/auth";
import { doc, getFirestore, getDoc } from "firebase/firestore";

const Account = () => {
  const [alert, setAlert] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [accountCreatedWith, setAccountCreatedWith] = useState(null);
  const [showCurrentPasswordInput, setShowCurrentPasswordInput] = useState(false);
  const [showNewPasswordInput, setShowNewPasswordInput] = useState(false);
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();

  const authCtx = useContext(AuthContext);

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const cancelPasswordUpdate = (ev) => {
      if (ev.code === "Escape") {
        setShowCurrentPasswordInput(false);
        setShowNewPasswordInput(false);
        setAccountCreatedWith("password");
      }
    };
    window.addEventListener("keyup", cancelPasswordUpdate);
    return () => window.removeEventListener("keyup", cancelPasswordUpdate);
  }, []);

  useEffect(() => {
    const getCurrentUser = async () => {
      if (authCtx.authUser) {
        setIsPending(true);
        const db = getFirestore();
        const { uid, emailVerified } = authCtx.authUser;
        const userRef = doc(db, "users", uid);
        const userSnap = await getDoc(userRef);
        const userInfo = userSnap.data();

        setUser({ ...userInfo, emailVerified });
        setAccountCreatedWith(authCtx.authUser.providerData[0].providerId);
        setIsPending(false);
      }
    };
    getCurrentUser();
  }, [authCtx.authUser]);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 4000);
  };
  const onEmailVerificationHandler = () => {
    const auth = getAuth();
    sendEmailVerification(auth.currentUser).then(() => {
      flashMessageHandler({
        message: language === "albanian" ? "Email-i për verifikim u dërgua" : "Email verification sent!",
        success: true,
      });
    });
  };

  const reauthenticateWithCredentialHandler = async (ev) => {
    ev.preventDefault();
    setError("");
    // Here is a suggested flow for prompting a user to update their password with Firebase:
    // Prompt the user to enter their current password.
    // Verify that the current password is correct by re-authenticating the user with reauthenticateWithCredential.
    // If the current password is correct, prompt the user to enter a new password.
    // Update the user's password using updatePassword method of the user object.
    // Show a success message to the user.
    let currentPassword = currentPasswordRef.current.value;

    if (!currentPassword) {
      setError(
        language === "albanian"
          ? "Ju lutemi jepni fjalëkalimin tuaj aktual."
          : "Please provide your current password."
      );
      return;
    }

    setError("");
    const auth = getAuth();
    try {
      const credential = EmailAuthProvider.credential(authCtx.authUser.email, currentPassword);
      const result = await reauthenticateWithCredential(auth.currentUser, credential);
      console.log(result);
      setShowNewPasswordInput(true);
      setShowCurrentPasswordInput(false);
    } catch (error) {
      if (error.message === "Firebase: Error (auth/wrong-password).") {
        setError(language === "albanian" ? "Fjalëkalim i gabuar" : "Wrong Password");
      }
    }
  };

  const updatePasswordHandler = async () => {
    setError("");
    let newPassword = newPasswordRef.current.value;
    if (!newPassword) {
      setError(
        language === "albanian"
          ? "Ju lutemi jepni fjalëkalimin tuaj të ri."
          : "Please provide your new password."
      );
      return;
    }
    try {
      await updatePassword(authCtx.authUser, newPassword);
      setShowNewPasswordInput(false);
      flashMessageHandler({
        message:
          language === "albanian" ? "Fjalëkalimi u përditësua me sukses." : "Password updated successfully.",
        success: true,
      });
      setAccountCreatedWith("password");
    } catch (error) {
      if (error.message === "Firebase: Password should be at least 6 characters (auth/weak-password).") {
        setError(
          language === "albanian"
            ? "Fjalëkalimi duhet të jetë së paku 6 karaktere"
            : "Password should be at least 6 characters"
        );
      }
      console.log(error.message);
    }
  };

  const onShowCurrentPasswordInput = () => {
    setAccountCreatedWith(null);
    setShowCurrentPasswordInput(true);
  };

  return (
    <Fragment>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <ul className={styles.account}>
        <li>
          <MultiLingualContent contentId='myLibrary' prop='label1' /> {user.fullName}
        </li>
        <li>
          <MultiLingualContent contentId='myLibrary' prop='label2' /> {user.email}
        </li>
        <li>
          <MultiLingualContent contentId='myLibrary' prop='label3' />{" "}
          {!user.phone ? <MultiLingualContent contentId='myLibrary' prop='value3' /> : user.phone}
        </li>
        <li className={styles.flex}>
          <MultiLingualContent contentId='myLibrary' prop='label5' />{" "}
          {!user.emailVerified ? (
            <MultiLingualContent contentId='myLibrary' prop='value2' />
          ) : (
            <MultiLingualContent contentId='myLibrary' prop='value1' />
          )}
          {isPending
            ? "..."
            : !user.emailVerified && (
                <button className={styles["button-80"]} onClick={onEmailVerificationHandler}>
                  {language === "albanian" ? "Verifiko emailin" : "Verify Email"}
                </button>
              )}
        </li>
        <li>
          <MultiLingualContent contentId='myLibrary' prop='label4' />{" "}
          {!user.member ? (
            <MultiLingualContent contentId='myLibrary' prop='value2' />
          ) : (
            <MultiLingualContent contentId='myLibrary' prop='value1' />
          )}
        </li>
        <li>
          <MultiLingualContent contentId='myLibrary' prop='label6' />{" "}
          {!user.depositAmount ? (
            <MultiLingualContent contentId='myLibrary' prop='value3' />
          ) : (
            user.depositAmount
          )}
        </li>
      </ul>

      {accountCreatedWith === "password" && (
        <button className={styles["button-80"]} onClick={onShowCurrentPasswordInput}>
          {language === "albanian" ? "Përditëso fjalëkalimin" : "Update Password"}
        </button>
      )}
      <Fragment>
        {showCurrentPasswordInput && (
          <>
            <div className={styles.controls}>
              <input
                ref={currentPasswordRef}
                type='password'
                aria-label='Current Password'
                placeholder={language === "albanian" ? "Fjalëkalimi aktual" : "Current Password"}
                autoFocus
              />
            </div>
            <button
              type='button'
              className={styles["button-80"]}
              onClick={reauthenticateWithCredentialHandler}>
              {language === "albanian" ? "PARAQIT" : "SUBMIT"}
            </button>
            {<span className={styles.error}>{error}</span>}
          </>
        )}
        {showNewPasswordInput && (
          <>
            <div className={styles.controls}>
              <input
                ref={newPasswordRef}
                type='password'
                aria-label='New Password'
                placeholder={language === "albanian" ? "Fjalëkalim i ri" : "New Password"}
                autoFocus
              />
            </div>
            <button type='button' className={styles["button-80"]} onClick={updatePasswordHandler}>
              {language === "albanian" ? "PËRDITËSO" : "UPDATE"}
            </button>
            {<span className={styles.error}>{error}</span>}
          </>
        )}
      </Fragment>
    </Fragment>
  );
};

export default Account;
