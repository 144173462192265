import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import FlashMessage from "../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../Modals/DeleteModal";
import AddTableNumberModal from "../../../../Modals/AddTableNumberModal";

import ReservationRow from "./ReservationRow";
import styles from "./ReservationRequests.module.css";

// FIREBASE
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  deleteDoc,
  updateDoc,
  addDoc,
  getDoc,
  Timestamp,
  getDocs,
} from "firebase/firestore";

const ReservationRequests = () => {
  const [alert, setAlert] = useState("");
  const [reservationsRequests, setReservationRequests] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showAddTableModal, setShowAddTableModal] = useState(false);
  const [modalHeaderText, setModalHeaderText] = useState("");

  const [reservationId, setReservationId] = useState(null);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
        setShowAddTableModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  useEffect(() => {
    const getReservationRequests = async () => {
      const db = getFirestore();
      const reservationReqRef = collection(db, "reservationRequests");

      const flaggedGuestsSnapshot = await getDocs(collection(db, "flaggedGuests"));

      let flaggedGuestsArr = [];

      flaggedGuestsSnapshot.forEach((doc) => {
        flaggedGuestsArr.push(doc.data());
      });

      onSnapshot(reservationReqRef, (querySnapshot) => {
        const resRequests = [];
        querySnapshot.forEach(async (doc) => {
          let resRequest = doc.data();
          let isFlagged = flaggedGuestsArr.find((guest) => {
            return (
              guest.fullName === resRequest.fullName ||
              guest.email === resRequest.email ||
              guest.phone === resRequest.phone
            );
          });

          if (isFlagged) {
            resRequests.push({ ...doc.data(), id: doc.id, flagged: true });
          } else {
            resRequests.push({ ...doc.data(), id: doc.id, flagged: false });
          }
        });
        setReservationRequests(resRequests);
      });
    };
    getReservationRequests();
  }, []);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  // *** DELETE RESERVATION ***
  // Open delete reservation confirmation modal
  const onConfirmHandler = (reservationId) => {
    setShowDeleteModal(true);
    setReservationId(reservationId);
  };
  // Cancel deletion of reservation by removing modal
  const onCancelDeleteReservationHandler = () => {
    setShowDeleteModal(false);
  };
  // Delete reservation and remove modal
  const onDeleteReservationHandler = async () => {
    const db = getFirestore();
    // Add doc to recenltyDeleted collection as a fail safe
    const docSnap = await getDoc(doc(db, "reservationRequests", reservationId));
    let now = new Date();
    let nextDay = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    now.setDate();

    let deletedRes = {
      ...docSnap.data(),
      deletedFrom: "Reservation Requests",
      expires: Timestamp.fromDate(nextDay),
    };
    await addDoc(collection(db, "recentlyDeletedReservations"), deletedRes);
    await deleteDoc(doc(db, "reservationRequests", reservationId));
    setReservationRequests((prevState) => {
      return prevState.filter((reservation) => reservation.id !== reservationId);
    });

    flashMessageHandler({ message: "Reservation Deleted ", success: true });
    setShowDeleteModal(false);
    setReservationId(null);
  };

  // *** ADD TABLE ***
  // Add table number by showing Add table modal
  const addTableNumberHandler = (reservationRequestId, actionType) => {
    if (actionType === "add") {
      setReservationId(reservationRequestId);
      setModalHeaderText("Add Table Number");
      setShowAddTableModal(true);
    }

    if (actionType === "edit") {
      setReservationId(reservationRequestId);
      setModalHeaderText("Change Table Number");
      setShowAddTableModal(true);
    }
    setReservationId(reservationRequestId);
    setShowAddTableModal(true);
  };

  // Add table number to customer request and remove modal
  const onAddTableHandler = async (tableNumber) => {
    const db = getFirestore();

    await updateDoc(doc(db, "reservationRequests", reservationId), {
      tableNumber: tableNumber,
    });
    setReservationRequests((prevState) => {
      return prevState.map((reservation) =>
        reservation.id === reservationId ? { ...reservation, tableNumber: tableNumber } : reservation
      );
    });

    setShowAddTableModal(false);
  };

  // *** CONFIRM RESERVATION ***
  const confirmReservationHandler = async (reservationId) => {
    // Check if table number has been added
    const db = getFirestore();
    const docSnap = await getDoc(doc(db, "reservationRequests", reservationId));
    let tableNo = docSnap.data().tableNumber;
    //  Check if admin has added table number. If not, alert them and prevent reservation confirmation
    if (!tableNo) {
      flashMessageHandler({ message: "Please add table number ", success: false });
      return;
    }
    // If there is a table number add reservation to confirmedReservations and delete it from reservationRequests
    await addDoc(collection(db, "confirmedReservations"), docSnap.data());
    await deleteDoc(doc(db, "reservationRequests", reservationId));
    // Update UI
    setReservationRequests((prevState) => {
      return prevState.filter((reservation) => reservation.id !== reservationId);
    });

    // Notify user
    flashMessageHandler({ message: "Reservation Confirmed ", success: true });
    setShowDeleteModal(false);
    setReservationId(null);
  };

  return (
    <div className={styles.container}>
      <DeleteModal
        showModal={showDeleteModal}
        onConfirm={onDeleteReservationHandler}
        onCancel={onCancelDeleteReservationHandler}
        message={"Are you sure you want to delete this reservation request?"}
      />
      <AddTableNumberModal
        showModal={showAddTableModal}
        onAddTable={onAddTableHandler}
        onCancel={() => setShowAddTableModal(false)}
        headerText={modalHeaderText}
      />
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      {reservationsRequests.length > 0 ? (
        <table className={styles.reservations}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Requested Date/time</th>
              <th>Number of People</th>
              <th>Special Requests</th>
              <th>Type of Service</th>
              <th>Table Number</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {reservationsRequests.map((data) => {
              return (
                <ReservationRow
                  key={uuidv4()}
                  id={data.id}
                  name={data.fullName}
                  phone={data.phone}
                  email={data.email}
                  date={data.date}
                  time={data.time}
                  numberOfPeople={data.numberOfPeople}
                  specialReq={data.specialRequest}
                  type={data.typeOfService}
                  tableNumber={data.tableNumber}
                  onDeleteReservation={onConfirmHandler}
                  onAddTableNumber={addTableNumberHandler}
                  onConfirmReservation={confirmReservationHandler}
                  flagged={data.flagged}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>Currently there are no reservation requests.</p>
      )}
    </div>
  );
};

export default ReservationRequests;
