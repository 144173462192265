import styles from "./AppetizersDesktop.module.css";

const AppetizersDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='appetizers'>
        Ullinjë të Ngrohtë - ullinjë nga Berati, djath të bardhë, patate në furrë + bukë petë .. 4.50
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='appetizers'>
        Brusketa - spanak, trosha djathi, dardhë dhe krem uthulle (balsamiko) .................. 3.50
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='appetizers'>
        Djath në Furrë - djath i bardhë vendor, mjaltë pishe + bukë petë ....................... 4.50
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='appetizers'>
        Krem Djathi - Krem djathi vendor me shegë, arra, vaj ullini dhe bukë petë .............. 3.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='appetizers'>
        Hummus Dip - Pire nga qiqrat, hudër, limon, fara të zeza, çarragat dhe bukë petë ....... 4.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='appetizers'>
        Speca të Mbushur - Speca të vegjël turrshi të mbushur me krem djath dhe trumëz ......... 3.50
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='appetizers'>
        Saganaki me Karkaleca - Karkaleca në salcë të domateve, djath i bardhë dhe Ouzo ........ 9.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='appetizers'>
        Patate të Vogla në Furrë - Patate të vogla me tarator gjizë të thartë .................. 3.50
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='8' category='appetizers'>
        Tzatziki - Kos i kulluar, tranguj, hudër, krip, uthull e bardhë, vaj ulliri ............ 3.50
      </li>
    </ul>
  );
};

export default AppetizersDesktop;
