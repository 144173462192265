import { Routes, Route } from "react-router-dom";

import RecentlyAddedRecordForm from "./RecentlyAddedRecordForm";
import RecentlyAddedRecordsList from "./RecentlyAddedRecordsList";
import RecentlyAddedRecordsTab from "../../../../../components/UI/RecentlyAddedRecordsTab";

import styles from "./RecentlyAddedRecords.module.css";

const RecentlyAddedRecords = () => {
  return (
    <div className={styles.container}>
      <RecentlyAddedRecordsTab />
      <div className={styles["tab-body"]}>
        <Routes>
          <Route path='createRecentlyAddedRecord' element={<RecentlyAddedRecordForm />}></Route>
          <Route path='recentRecords' element={<RecentlyAddedRecordsList />}></Route>
        </Routes>
      </div>
    </div>
  );
};

export default RecentlyAddedRecords;
