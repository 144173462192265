import styles from "./RecordPlayer.module.css";

const RecordPlayer = () => {
  return (
    <svg width='45px' version='1.1' id='圖層_1' x='0' y='0' viewBox='0 0 100 100' className={styles.svg}>
      <path
        d='M82.454 84.268H17.546a8.722 8.722 0 0 1-8.722-8.722V24.454a8.722 8.722 0 0 1 8.722-8.722h64.908a8.722 8.722 0 0 1 8.722 8.722v51.092a8.722 8.722 0 0 1-8.722 8.722z'
        fill='#e0e0e0'
        stroke='#333'
        strokeWidth='3.5'
        strokeMiterlimit='10'
      />
      <circle
        cx='59.639'
        cy='50'
        r='21.709'
        fill='#666'
        stroke='#333'
        strokeWidth='3.5'
        strokeMiterlimit='10'
      />
      <circle cx='59.639' cy='50' r='7.5' fill='#fff' stroke='#333' strokeWidth='3.5' strokeMiterlimit='10' />
      <circle
        cx='25.272'
        cy='68.047'
        r='7.619'
        fill='#f5e6c8'
        stroke='#333'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeMiterlimit='10'
      />
      <path
        fill='none'
        stroke='#333'
        strokeWidth='6.5'
        strokeLinecap='round'
        strokeMiterlimit='10'
        d='M66.131 36.392l7.443 7.337'
      />
      <path
        fill='none'
        stroke='#333'
        strokeWidth='3.5'
        strokeMiterlimit='10'
        d='M30.208 28.869H58.5l7.631 7.523'
      />
      <circle
        cx='30.401'
        cy='28.575'
        r='5.901'
        fill='#f5e6c8'
        stroke='#333'
        strokeWidth='3.5'
        strokeMiterlimit='10'
      />
    </svg>
  );
};

export default RecordPlayer;
