import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import LibraryMembersRow from "./LibraryMembersRow";
import LibraryMembersToolbar from "./LibraryMembersToolbar";
import NextButton from "../../../../components/UI/NextButton";

import FlashMessage from "../../../../components/UI/FlashMessage";

import CreateNewMemberModal from "../../../../Modals/CreateNewMemberModal";
import BorrowBookModal from "../../../../Modals/BorrowBookModal";
import DeleteModal from "../../../../Modals/DeleteModal";

import styles from "./LibraryMembers.module.css";
// FIREBASE
import {
  getDocs,
  getFirestore,
  collection,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
  limit,
  startAfter,
  getDoc,
  addDoc,
  setDoc,
} from "firebase/firestore";

import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { secondaryApp } from "../../../../Firebase/firebaseConfig";

const LibraryMembers = () => {
  const [alert, setAlert] = useState("");
  const [libraryMembers, setLibraryMembers] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [memberId, setMemberId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showBorrowBookModal, setShowBorrowBookModal] = useState(false);
  const [memberDetails, setMemberDetails] = useState({ name: "", email: "" });

  const [showCreateNewMemberModal, setShowCreateNewMemberModal] = useState(false);

  const [searchObj, setSearchObj] = useState(null);
  const [last, setLast] = useState();

  useEffect(() => {
    const searchLibraryMembersHandler = async (obj) => {
      if (obj === null) {
        return;
      }
      let { searchCriteria, searchValue } = obj;
      if (searchValue === "") {
        return;
      }
      setLibraryMembers([]);
      const db = getFirestore();
      const q = query(
        collection(db, "libraryMembers"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff")
      );
      setIsPending(true);

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setIsPending(false);
          return;
        }

        querySnapshot.forEach((doc) => {
          setLibraryMembers((prevState) => {
            return [...prevState, { ...doc.data(), docId: doc.id }];
          });
        });
      } catch (error) {
        console.log(error);
      }

      setIsPending(false);
    };
    searchLibraryMembersHandler(searchObj);
  }, [searchObj]);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
        setShowBorrowBookModal(false);
        setShowCreateNewMemberModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  useEffect(() => {
    const getLibraryMembers = async () => {
      const db = getFirestore();

      const q = query(collection(db, "libraryMembers"), limit(5));
      let querySnapshot = await getDocs(q);

      // FOR PAGINATION
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLast(lastVisible);

      let libraryMembersData = [];
      querySnapshot.forEach((book) => {
        libraryMembersData.push(book.data());
      });
      setLibraryMembers(libraryMembersData);

      setIsPending(false);
    };
    getLibraryMembers();
  }, []);

  // NEXT SET OF DATA - When we press More Button
  const showMoreItemsHandler = async () => {
    const db = getFirestore();

    const next = query(collection(db, "libraryMembers"), startAfter(last), limit(5));
    const querySnapshot = await getDocs(next);

    if (querySnapshot.empty) {
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLast(lastVisible);

    querySnapshot.forEach((doc) => {
      setLibraryMembers((prevState) => {
        return [...prevState, { ...doc.data(), docId: doc.id }];
      });
    });
  };

  const onShowConfirmDeleteMemberModalHandler = (id) => {
    setShowDeleteModal(true);
    setMemberId(id);
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onConfirmDeleteMemberHandler = async () => {
    setLibraryMembers((prevState) => {
      return [...prevState].filter((item) => item.docId !== memberId);
    });
    flashMessageHandler({ message: "Member Deleted ", success: true });
    const db = getFirestore();

    try {
      // Remove member from libraryMembers collection
      let memberRef = doc(db, "libraryMembers", memberId);
      await deleteDoc(memberRef);
      // Update user in users member property to false and deposit amount to null in users collection
      let userRef = doc(db, "users", memberId);
      await updateDoc(userRef, { member: false, depositAmount: null });

      // Remove any books from onHold array in users doc if there is any
      const userSnap = await getDoc(userRef);
      let onHoldBook = userSnap.data().onHold[0];
      if (onHoldBook) {
        // Get book document id and remove book from bookHolds and from holdRequest if there is any, associated with this user
        let bookDocId = onHoldBook.docId;
        await deleteDoc(doc(db, "bookHolds", bookDocId));
        await deleteDoc(doc(db, "holdRequests", bookDocId));

        // Make book available
        await updateDoc(doc(db, "books", bookDocId), { available: true });

        // update onHold array in users doc. Make it empty
        await updateDoc(userRef, { onHold: [] });
      }
    } catch (error) {
      console.log(error);
    }

    setShowDeleteModal(false);
    setMemberId(null);
    setSearchObj(null);
  };

  const onCancelDeleteMemberHandler = () => {
    setShowDeleteModal(false);
  };

  const onShowBorrowBookModal = (userId) => {
    setShowBorrowBookModal(true);
    setMemberId(userId);

    // FIND MEMBER
    // An object is returned
    const libraryMember = libraryMembers.find((member) => member.docId === userId);
    setMemberDetails({ ...libraryMember, docId: userId });
  };

  const onCancelBorrowBookModal = () => {
    setShowBorrowBookModal(false);
  };

  const onBorrowBookHandler = async (borrowedBook) => {
    // Add book to borrowedBooks collection
    const db = getFirestore();
    await addDoc(collection(db, "borrowedBooks"), borrowedBook);

    // Add book to user borrowed array
    const userId = borrowedBook.user.docId;

    delete borrowedBook.user;
    await updateDoc(doc(db, "users", userId), {
      borrowed: [borrowedBook],
    });

    // Update book document in books collection
    const bookId = borrowedBook.docId;
    const docRef = doc(db, "books", bookId);
    const docSnap = await getDoc(docRef);
    let copies = docSnap.data().copies;
    await updateDoc(docRef, { copies: Number(copies) - 1, available: Number(copies) === 1 ? false : true });

    flashMessageHandler({ message: "Book borrowed", success: true });

    setShowBorrowBookModal(false);
    setMemberId(null);
    setSearchObj(null);
  };

  const onShowCreateNewMemberModal = () => {
    setShowCreateNewMemberModal(true);
  };
  const onCancelCreateNewMemberModal = () => {
    setShowCreateNewMemberModal(false);
  };

  const onCreateNewMemberHandler = (user) => {
    const { email, pass, fullName, phone, depositAmount } = user;

    const auth = getAuth(secondaryApp);

    createUserWithEmailAndPassword(auth, email, pass).then(async (userCredential) => {
      const uid = userCredential.user.uid;
      updateProfile(userCredential.user, { emailVerified: true });
      const db = getFirestore();
      try {
        await setDoc(doc(db, "users", uid), {
          fullName: fullName,
          email: email,
          phone: phone,
          member: true,
          depositAmount: depositAmount,
          onHold: [],
          borrowed: [],
          wishlist: [],
          borrowingHistory: [],
          roles: { isAdmin: false },
        });

        await setDoc(doc(db, "libraryMembers", uid), {
          fullName: fullName,
          email: email,
          phone: phone,
          depositAmount: depositAmount,
          docId: uid,
        });
      } catch (error) {
        flashMessageHandler({ message: error.message, success: true });
      }

      flashMessageHandler({ message: "New Member Created", success: true });

      setLibraryMembers((prevState) => {
        return [
          ...prevState,
          {
            fullName: fullName,
            email: email,
            phone: phone,
            depositAmount: depositAmount,
            docId: uid,
          },
        ];
      });
    });
  };

  return (
    <div className={styles.container}>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <DeleteModal
        showModal={showDeleteModal}
        onConfirm={onConfirmDeleteMemberHandler}
        onCancel={onCancelDeleteMemberHandler}
        message={"Are you sure you want to delete this library member?"}
      />
      <BorrowBookModal
        memberDetails={memberDetails}
        showModal={showBorrowBookModal}
        onCancel={onCancelBorrowBookModal}
        onSubmit={onBorrowBookHandler}
      />

      <CreateNewMemberModal
        showModal={showCreateNewMemberModal}
        onCancel={onCancelCreateNewMemberModal}
        onSubmit={onCreateNewMemberHandler}
      />
      <LibraryMembersToolbar
        onChangeSearchCriteria={setSearchObj}
        showCreateNewMemberModal={onShowCreateNewMemberModal}
      />
      {isPending ? (
        "Loading..."
      ) : libraryMembers.length > 0 ? (
        <table className={styles["library-members"]}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Deposit Amount</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {libraryMembers.map((data) => {
              return (
                <LibraryMembersRow
                  key={uuid()}
                  docId={data.docId}
                  name={data.fullName}
                  phone={data.phone}
                  email={data.email}
                  depositAmount={data.depositAmount}
                  showConfirmModal={onShowConfirmDeleteMemberModalHandler}
                  onShowBorrowBookModal={onShowBorrowBookModal}
                />
              );
            })}
          </tbody>
        </table>
      ) : !searchObj ? (
        <p>Your library has no members.</p>
      ) : (
        <p>No results</p>
      )}
      {searchObj || libraryMembers.length > 0 ? <NextButton onClick={showMoreItemsHandler} /> : ""}
    </div>
  );
};

export default LibraryMembers;
