import { useState, useRef, useContext } from "react";
import { LanguageContext } from "../../contexts/Language/LanguageContext";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import MagnifyingGlass from "../../assets/icons/MagnifyingGlass";
import styles from "./SearchBar.module.css";

const SearchBar = (props) => {
  const [searchCriteria, setSearchCriteria] = useState("title");
  const searchRef = useRef();
  const { language } = useContext(LanguageContext);

  let placeholder =
    language === "albanian"
      ? `Kërko sipas ${searchCriteria === "title" ? "titullit" : props.type ? "artistit" : "autorit"}`
      : `Search by ${searchCriteria === "title" ? "title" : props.type ? "artist" : "author"}`;

  const onSumbitHandler = (ev) => {
    ev.preventDefault();
    const searchValue = searchRef.current.value.trim();
    searchValue.trim();
    props.onSubmit({
      searchValue,
      searchCriteria,
    });
  };
  return (
    <form onSubmit={onSumbitHandler}>
      <div className={styles.control}>
        <select
          name='searchFilter'
          id='searchFilter'
          className={styles.select}
          defaultValue={searchCriteria}
          onChange={(ev) => setSearchCriteria(ev.target.value)}>
          <option value='title'>
            <MultiLingualContent contentId='booksAndRecords' prop='searchBarOption1' />
          </option>
          <option value={props.type ? "artist" : "author"}>
            {props.type ? (
              <MultiLingualContent contentId='booksAndRecords' prop='searchBarOption3' />
            ) : (
              <MultiLingualContent contentId='booksAndRecords' prop='searchBarOption2' />
            )}
          </option>
        </select>
        <div className={styles["input-container"]}>
          <input ref={searchRef} type='text' placeholder={placeholder} />
          <MagnifyingGlass />
        </div>
        <button type='submit' className={styles["button-52"]}>
          {language === "albanian" ? "KËRKO" : "SEARCH"}
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
