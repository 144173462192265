import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";

import styles from "./404.module.css";

const PageNotFound = () => {
  return (
    <div className={styles.notfound}>
      <div className={styles["notfound-404"]}>
        <h3>
          <MultiLingualContent contentId='pageNotFound' prop='title1' />
        </h3>
        <h1>
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </h1>
      </div>
      <h2>
        <MultiLingualContent contentId='pageNotFound' prop='title2' />
      </h2>
    </div>
  );
};

export default PageNotFound;
