import { Fragment, useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import FoodNav from "./FoodNav";
import { LanguageContext } from "../../../contexts/Language/LanguageContext";
import Appetizers from "./Appetizers/Appetizers";
import AppetizersALB from "./AppetizersALB/AppetizersALB";
import Salads from "./Salads/Salads";
import SaladsALB from "./SaladsALB/SaladsALB";
import Mains from "./Mains/Mains";
import MainsALB from "./MainsALB/MainsALB";
import PastaAndRissoto from "./PastaAndRisotto/PastaAndRissoto";
import PastaAndRissotoALB from "./PastaAndRisottoALB/PastaAndRissotoALB";
import BurgerAndWraps from "./BurgersAndWraps/BurgerAndWraps";
import BurgerAndWrapsALB from "./BurgersAndWrapsALB/BurgerAndWrapsALB";
import Fried from "./Fried/Fried";
import FriedALB from "./FriedALB/FriedALB";
import Desserts from "./Desserts/Desserts";
import DessertsALB from "./DessertsALB/DessertsALB";

import MenuItemModal from "../../../Modals/MenuItemModal";
import { AppetizerImages } from "./Images/AppetizerImages";
import { SaladsImages } from "./Images/SaladsImages";
import { FromTheSeaImages } from "./Images/FromTheSeaImages";
import { PastaAndRiceImages } from "./Images/PastaAndRiceImages";
import { BurgersImages } from "./Images/BurgersImages";
import { ToShareImages } from "./Images/ToShareImages";
import { DessertsImages } from "./Images/DessertsImages";

import styles from "./Food.module.css";

const Food = () => {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState({ url: "", alt: "" });

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowModal(false);
        setImage({ url: "", alt: "" });
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  const onShowModalHandler = (ev) => {
    const dataIndex = ev.target.getAttribute("data-index");
    const category = ev.target.getAttribute("category");

    if (category === "appetizers") {
      const image = AppetizerImages[dataIndex];
      if (!image) return;
      setImage(image);
      setShowModal(true);
    }
    if (category === "salads") {
      const image = SaladsImages[dataIndex];
      if (!image) return;
      setImage(image);
      setShowModal(true);
    }
    if (category === "fromTheSea") {
      const image = FromTheSeaImages[dataIndex];
      if (!image) return;
      setImage(image);
      setShowModal(true);
    }
    if (category === "pastaAndRice") {
      const image = PastaAndRiceImages[dataIndex];
      if (!image) return;
      setImage(image);
      setShowModal(true);
    }

    if (category === "burgers") {
      const image = BurgersImages[dataIndex];
      if (!image) return;
      setImage(image);
      setShowModal(true);
    }
    if (category === "toShare") {
      const image = ToShareImages[dataIndex];
      if (!image) return;
      setImage(image);
      setShowModal(true);
    }
    if (category === "desserts") {
      const image = DessertsImages[dataIndex];
      if (!image) return;
      setImage(image);
      setShowModal(true);
    }
  };

  const onCancelModalHandler = () => {
    setShowModal(false);
    setImage({ url: "", alt: "" });
  };

  return (
    <Fragment>
      <FoodNav />
      <MenuItemModal showModal={showModal} onCancel={onCancelModalHandler} image={image} />
      <div className={styles.container}>
        <Routes>
          <Route
            path='/appetizers'
            element={
              language === "albanian" ? (
                <AppetizersALB showModal={onShowModalHandler} />
              ) : (
                <Appetizers showModal={onShowModalHandler} />
              )
            }
          />
          <Route
            path='/salads'
            element={
              language === "albanian" ? (
                <SaladsALB showModal={onShowModalHandler} />
              ) : (
                <Salads showModal={onShowModalHandler} />
              )
            }
          />
          <Route
            path='/mains'
            element={
              language === "albanian" ? (
                <MainsALB showModal={onShowModalHandler} />
              ) : (
                <Mains showModal={onShowModalHandler} />
              )
            }
          />
          <Route
            path='/pasta&rissoto'
            element={
              language === "albanian" ? (
                <PastaAndRissotoALB showModal={onShowModalHandler} />
              ) : (
                <PastaAndRissoto showModal={onShowModalHandler} />
              )
            }
          />
          <Route
            path='/burgers&wraps'
            element={
              language === "albanian" ? (
                <BurgerAndWrapsALB showModal={onShowModalHandler} />
              ) : (
                <BurgerAndWraps showModal={onShowModalHandler} />
              )
            }
          />
          <Route
            path='/fried'
            element={
              language === "albanian" ? (
                <FriedALB showModal={onShowModalHandler} />
              ) : (
                <Fried showModal={onShowModalHandler} />
              )
            }
          />
          <Route
            path='/desserts'
            element={
              language === "albanian" ? (
                <DessertsALB showModal={onShowModalHandler} />
              ) : (
                <Desserts showModal={onShowModalHandler} />
              )
            }
          />
        </Routes>
      </div>
    </Fragment>
  );
};

export default Food;
