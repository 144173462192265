import Trash from "../../../../assets/icons/Trash";
import Edit from "../../../../assets/icons/Edit";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import styles from "./ConfirmedReservationsRow.module.css";

const ConfirmedReservationsRow = (props) => {
  let options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  let resDate = props.date.toDate().toLocaleString("default", options);
  return (
    <tr className={styles.tr}>
      <td data-label='NAME'>{capitalizeFirstLetter(props.name)}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='DATE/TIME'>{resDate + " at " + props.time}</td>
      <td data-label='NUMBER OF PEOPLE'>{props.numberOfPeople}</td>
      <td data-label='SPECIAL REQUESTS'>{props.specialReq}</td>
      <td data-label='TYPE OF SERVICE'>{props.type}</td>
      <td data-label='TABLE NUMBER'>{props.tableNumber}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onEditReservation(props.id)}>
          <Edit />
        </button>
        <button className={styles.btn} type='button' onClick={() => props.onDeleteReservation(props.id)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default ConfirmedReservationsRow;
