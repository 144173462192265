import { v4 as uuidv4 } from "uuid";
import CheckboxList from "./CheckboxList";

import styles from "./UpdateBookForm.module.css";

const UpdateBookForm = (props) => {
  const onChangeIdHandler = (ev) => {
    props.editBook((prevState) => {
      return {
        ...prevState,
        id: ev.target.value.toLowerCase().split(","),
      };
    });
  };

  const onChangeCopiesHandler = (ev) => {
    props.editBook((prevState) => {
      return {
        ...prevState,
        copies: ev.target.value,
      };
    });
  };
  const onChangeTitleHandler = (ev) => {
    props.editBook((prevState) => {
      console.log(ev.target.value.toLowerCase().trim());
      return {
        ...prevState,
        title: ev.target.value.toLowerCase().trim(),
      };
    });
  };
  const onChangeAuthorHandler = (ev) => {
    props.editBook((prevState) => {
      return {
        ...prevState,
        author: ev.target.value.toLowerCase().trim(),
      };
    });
  };
  const onChangeCategoryHandler = (ev, value) => {
    props.setChecklistOptions((prevState) => {
      return prevState.map((option) => {
        return option.value === value ? { ...option, checked: !option.checked } : option;
      });
    });
  };
  const onChangeSynopsisHandler = (ev) => {
    props.editBook((prevState) => {
      return {
        ...prevState,
        synopsis: ev.target.value.trim(),
      };
    });
  };
  const onChangeAvailability = (ev) => {
    props.editBook((prevState) => {
      return {
        ...prevState,
        available: ev.target.value === "true" ? true : false,
      };
    });
  };

  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    props.onSubmit();
  };

  const onChangeImageHandler = (event) => {
    const resize_width = 450; //without px

    //get the image selected
    let item = event.target.files[0];

    let uniqueImgName = uuidv4() + ".jpeg";

    if (item) {
      props.editBook((prevState) => {
        return {
          ...prevState,
          imageRef: `bookcovers/${uniqueImgName}`,
        };
      });

      //create a FileReader
      const reader = new FileReader();

      //image turned to base64-encoded Data URI.
      reader.readAsDataURL(item);
      reader.name = item.name; //get the image's name
      reader.size = item.size; //get the image's size

      reader.onload = function (event) {
        let img = new Image(); //create an image
        img.src = event.target.result; //result is base64-encoded Data URI

        img.size = event.target.size; //set size (optional)
        img.onload = function (el) {
          var elem = document.createElement("canvas"); //create a canvas

          //scale the image to 600 (width) and keep aspect ratio
          var scaleFactor = resize_width / el.target.width;
          elem.width = resize_width;
          elem.height = el.target.height * scaleFactor;

          //draw in canvas
          var ctx = elem.getContext("2d");
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          //get the base64-encoded Data URI from the resize image
          var srcEncoded = ctx.canvas.toDataURL("image/jpeg", 0.5);
          //assign it to thumb src
          props.setImgUrl(srcEncoded);

          props.setImgName(uniqueImgName);
          /*Now you can send "srcEncoded" to the server and
          convert it to a png o jpg. Also can send
          "el.target.name" that is the file's name.*/
        };
      };
    }
  };

  return (
    <form onSubmit={onSubmitHandler} className={styles.form}>
      <div className={styles.controls}>
        <label htmlFor='bookID'>Book ID</label>
        <input
          type='text'
          id='bookID'
          placeholder='Book ID'
          autoComplete='off'
          aria-label='Book ID'
          required
          value={props.book.id ? props.book.id.join(",") : ""}
          onChange={onChangeIdHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='copies'>Copies</label>
        <input
          type='number'
          id='copies'
          aria-label='Number of copies'
          required
          defaultValue={props.book.copies}
          min={1}
          onChange={onChangeCopiesHandler}
          disabled={true}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='bookTitle'>Book Title</label>
        <input
          type='text'
          id='bookTitle'
          placeholder='Book Title'
          autoComplete='off'
          aria-label='Book Title'
          required
          value={props.book.title}
          onChange={onChangeTitleHandler}
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='author'>Author</label>
        <input
          type='text'
          id='author'
          placeholder='Book Author/s'
          autoComplete='off'
          aria-label='Book Author'
          required
          value={props.book.author}
          onChange={onChangeAuthorHandler}
        />
      </div>
      <CheckboxList
        options={props.checklistOptions}
        categories={props.book.category}
        setChecklistOptions={props.setChecklistOptions}
        onChange={onChangeCategoryHandler}
      />
      <div className={styles.controls}>
        <label htmlFor='bookSynopsis'>Short Synopsis</label>
        <textarea
          id='bookSynopsis'
          placeholder='Short Synopsis'
          aria-label='Short Synopsis'
          value={props.book.synopsis}
          onChange={onChangeSynopsisHandler}
        />
      </div>
      <fieldset>
        <legend>Book Available? (WARNING! Triple check this before changing)</legend>
        <div>
          <input
            type='radio'
            id='true'
            name='book'
            value='true'
            checked={props.book.available ? true : false}
            onChange={onChangeAvailability}
          />
          <label htmlFor='true'>yes</label>
        </div>
        <div>
          <input
            type='radio'
            id='false'
            name='book'
            value='false'
            checked={props.book.available === false ? true : false}
            onChange={onChangeAvailability}
          />
          <label htmlFor='false'>no</label>
        </div>
      </fieldset>
      <div className={styles["file-control"]}>
        <label htmlFor='bookCover'>Book Cover</label>
        <input
          type='file'
          id='bookCover'
          aria-label='Upload Image'
          accept='image/*'
          onChange={onChangeImageHandler}
        />
      </div>
      <div className={styles.action}>
        <button type='submit' className={styles["button-24"]}>
          Update Book
        </button>
      </div>
      <p>{props.isRequired ? "Please select at least one of the book catgeories above" : ""}</p>
    </form>
  );
};

export default UpdateBookForm;
