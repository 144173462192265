import SoftDrinksMobile from "./SoftDrinksMobile";
import SoftDrinksDesktop from "./SoftDrinksDesktop";
import styles from "./SoftDrinks.module.css";

const SoftDrinks = (props) => {
  return (
    <div className={styles.container}>
      <SoftDrinksMobile />
      <SoftDrinksDesktop />
    </div>
  );
};

export default SoftDrinks;
