import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import RequestIcon from "../../assets/icons/RequestIcon";
import TickIcon from "../../assets/icons/TickIcon";
import CreateEvent from "../../assets/icons/CreateEvent";
import TrashReservationIcon from "../../assets/icons/TrashReservationIcon";
import ListIcon from "../../assets/icons/ListIcon";
import BannedIcon from "../../assets/icons/BannedIcon";

import styles from "./ReservationTabs.module.css";

const ReservationTabs = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [indicatorIndex, setindicatorIndex] = useState(0);

  const onButtonClickHandler = (index) => {
    setindicatorIndex(index);
  };

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 1150) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <div className={styles.tabs}>
      <h1 className={styles.title}>Reservations</h1>
      <header className={styles["tab-header"]}>
        <div className={styles["tab-btn-container"]}>
          <NavLink
            to='requests'
            className={`${styles["tab-btn"]} ${indicatorIndex === 0 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(0)}>
            {!isMobile && <RequestIcon active={indicatorIndex === 0} />}
            <span>Reservation Requests</span>
          </NavLink>
          <NavLink
            to='confirmed'
            className={`${styles["tab-btn"]} ${indicatorIndex === 1 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(1)}>
            {!isMobile && <TickIcon active={indicatorIndex === 1} />}
            <span>Confirmed reservations</span>
          </NavLink>
          <NavLink
            to='addReservation'
            className={`${styles["tab-btn"]} ${indicatorIndex === 2 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(2)}>
            {!isMobile && <CreateEvent active={indicatorIndex === 2} />}
            <span>Add reservation</span>
          </NavLink>
          <NavLink
            to='deleted'
            className={`${styles["tab-btn"]} ${indicatorIndex === 3 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(3)}>
            {!isMobile && <TrashReservationIcon active={indicatorIndex === 3} />}
            <span>Recently Deleted</span>
          </NavLink>
          <NavLink
            to='pastReservations'
            className={`${styles["tab-btn"]} ${indicatorIndex === 4 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(4)}>
            {!isMobile && <ListIcon active={indicatorIndex === 4} />}
            <span>Past Reservations</span>
          </NavLink>
          <NavLink
            to='flaggedGuests'
            className={`${styles["tab-btn"]} ${indicatorIndex === 5 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(5)}>
            {!isMobile && <BannedIcon active={indicatorIndex === 5} />}
            <span>Flagged Guests</span>
          </NavLink>
        </div>
        {!isMobile && (
          <div
            style={{ left: `calc(calc(100% / 6)*${indicatorIndex})` }}
            className={styles["tab-indicator"]}></div>
        )}
      </header>
    </div>
  );
};

export default ReservationTabs;
