import { Fragment } from "react";
import { v4 as uuid } from "uuid";
import BookItem from "./BookItem";
import PaginationButtons from "../../../components/UI/PaginationButtons";
import styles from "./BookList.module.css";

const BookList = (props) => {
  return (
    <Fragment>
      <ul className={styles["book-list"]}>
        {props.searchResult.map((book) => {
          return (
            <BookItem
              key={uuid()}
              id={book.id}
              title={book.title}
              author={book.author}
              available={book.available}
              url={book.url}
              imageRef={book.imageRef}
              synopsis={book.synopsis}
              category={book.category}
              docId={book.docId}
              copies={book.copies}
              onShowModal={props.onShowBookDetailsModal}
            />
          );
        })}
      </ul>
      {props.searchResult.length > 0 && (
        <PaginationButtons
          buttonState={props.buttonState}
          prevPage={props.prevPage}
          showMoreItems={props.showMoreItems}
        />
      )}
    </Fragment>
  );
};

export default BookList;
