import { Routes, Route, Navigate } from "react-router-dom";

import BooksTabs from "../../../components/UI/BooksTabs";

import HoldRequests from "./HoldRequests/HoldRequests";
import BookHolds from "./BookHolds/BookHolds";
import BorrowedBooks from "./BorrowedBooks/BorrowedBooks";
import OverDueBooks from "./OverDueBooks/OverDueBooks";
import LibraryMembers from "./LibraryMembers/LibraryMembers";
import LibraryCrud from "./LibraryCRUD/LibraryCrud";

import Card from "../../../components/UI/Card";
import styles from "./AdminBooks.module.css";

const AdminBooks = () => {
  return (
    <Card className={styles.container}>
      <BooksTabs />
      <div className={styles["tab-body"]}>
        <Routes>
          <Route path='/holdRequests' element={<HoldRequests />}></Route>
          <Route path='/bookHolds' element={<BookHolds />}></Route>
          <Route path='/borrowedBooks' element={<BorrowedBooks />}></Route>
          <Route path='/overdueBooks' element={<OverDueBooks />}></Route>
          <Route path='/libraryMembers' element={<LibraryMembers />}></Route>
          <Route
            path='/libraryCrud'
            element={<Navigate replace to='/admin/books/libraryCrud/createBook' />}></Route>
          <Route path='/libraryCrud/*' element={<LibraryCrud />}></Route>
        </Routes>
      </div>
    </Card>
  );
};

export default AdminBooks;
