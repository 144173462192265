import styles from "./FriedMobile.module.css";

const FriedMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='toShare'>
        Pjatë e Ftohtë - Djath Rugove, sienice, përshutë viçi, derri, ullinjë, fruta, shkopinjë, buke
        <span>12.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='toShare'>
        Përzgjedhje Suxhuku - Suxhuk viçi dhe derri, kajmak, Tzatziki, dhe bukë petë
        <span>7.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='toShare'>
        Patate - Patate me majonez të djegëst dhe parmezan <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='toShare'>
        Karkaleca në Friturë - karkaleca dhe majonez i djegëst <span>8.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='toShare'>
        Kungulleshka të Fërguara - me djath të bardhë, shërbehet me Tzatziki <span>3.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='toShare'>
        Pachanga - Mish viçi, misër, kaçkavall në petë të fërguara, shërbehet me majonez të djegëst
        <span>5.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='toShare'>
        Copëza Pule Krokante - me patate, shërbehet me salcë BBQ dhe majonez të djegëst <span>6.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='toShare'>
        Kaçkavall në Friturë - kaçkavall i mbështjellun në copëza buke, shërbehet me salce domatesh
        <span>6.00</span>
      </li>
    </ul>
  );
};

export default FriedMobile;
