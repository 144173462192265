import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import FlashMessage from "../../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../../Modals/DeleteModal";

import RecentlyAddedRecordsRow from "./RecentlyAddedRecordsRow";
import styles from "./RecentlyAddedRecordsList.module.css";

// FIREBASE
import { getDocs, getFirestore, collection, deleteDoc, doc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

const RecentlyAddedRecordsList = () => {
  const [alert, setAlert] = useState("");
  const [recentlyAddedRecords, setRecentlyAddedRecords] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [recordId, setRecordId] = useState(null);

  useEffect(() => {
    // Connect to firestore
    const getRecentlyAddedRecords = async () => {
      setIsPending(true);
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, "recentlyAddedRecords"));

      const eventsData = [];
      querySnapshot.forEach((doc) => {
        eventsData.push({ ...doc.data(), docId: doc.id });
      });
      setRecentlyAddedRecords(eventsData);
      setIsPending(false);
    };
    getRecentlyAddedRecords();
  }, []);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onConfirmHandler = (recordId) => {
    setShowDeleteModal(true);
    setRecordId(recordId);
  };

  const onCancelHandler = () => {
    setShowDeleteModal(false);
  };

  const onDeleteRecentlyAddedRecordHandler = async () => {
    // Delete doc from "recentlyAddedRecords" collection
    const db = getFirestore();
    await deleteDoc(doc(db, "recentlyAddedRecords", recordId));

    // delete image from recentlyAddedRecords folder in storage

    const record = recentlyAddedRecords.find((record) => record.docId === recordId);
    const storage = getStorage();
    const imageRef = ref(storage, record.imageRef);

    if (record.imageRef) {
      // Delete the file
      deleteObject(imageRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    }

    setRecentlyAddedRecords((prevState) => {
      return prevState.filter((record) => record.docId !== recordId);
    });
    flashMessageHandler({ message: "Record Deleted ", success: true });
    setShowDeleteModal(false);
    setRecordId(null);
  };

  return (
    <div className={styles["recent-records-container"]}>
      <DeleteModal
        message={"Are you sure you want to delete this record?"}
        showModal={showDeleteModal}
        onConfirm={onDeleteRecentlyAddedRecordHandler}
        onCancel={onCancelHandler}
      />
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      {isPending ? (
        "Loading..."
      ) : recentlyAddedRecords.length > 0 ? (
        <table className={styles.records}>
          <thead>
            <tr>
              <th>Title</th>
              <th>Artist</th>
              <th>Date Added</th>
              <th>Expires</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {recentlyAddedRecords.map((data) => {
              return (
                <RecentlyAddedRecordsRow
                  key={uuid()}
                  id={data.id}
                  docId={data.docId}
                  title={data.title}
                  artist={data.artist}
                  dateAdded={data.dateAdded}
                  expires={data.expires}
                  onDeleteRecord={onConfirmHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>There are no recently added records</p>
      )}
    </div>
  );
};

export default RecentlyAddedRecordsList;
