import styles from "./ReservationMessage.module.css";

const ReservationMessage = () => {
  return (
    <svg
      className={styles.svg}
      version='1.1'
      x='0px'
      y='0px'
      viewBox='0 0 100 100'
      enableBackground='new 0 0 100 100'>
      <g>
        <path
          fill='#E15B64'
          d='M88.24,13.99H11.76c-2.31,0-4.18,1.87-4.18,4.18v15.21h84.83V18.17C92.42,15.86,90.55,13.99,88.24,13.99z'
        />
        <circle fill='#C33737' cx='19.8' cy='23.95' r='3.5' />
        <line
          fill='#333333'
          stroke='#CCCCCC'
          strokeWidth='4'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='19.8'
          y1='10.03'
          x2='19.8'
          y2='23.95'
        />
        <circle fill='#C33737' cx='34.91' cy='23.95' r='3.5' />
        <line
          fill='#333333'
          stroke='#CCCCCC'
          strokeWidth='4'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='34.91'
          y1='10.03'
          x2='34.91'
          y2='23.95'
        />
        <circle fill='#C33737' cx='50.01' cy='23.95' r='3.5' />
        <line
          fill='#333333'
          stroke='#CCCCCC'
          strokeWidth='4'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='50.01'
          y1='10.03'
          x2='50.01'
          y2='23.95'
        />
        <circle fill='#C33737' cx='65.11' cy='23.95' r='3.5' />
        <line
          fill='#333333'
          stroke='#CCCCCC'
          strokeWidth='4'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='65.11'
          y1='10.03'
          x2='65.11'
          y2='23.95'
        />
        <circle fill='#C33737' cx='80.22' cy='23.95' r='3.5' />
        <line
          fill='#333333'
          stroke='#CCCCCC'
          strokeWidth='4'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='80.22'
          y1='10.03'
          x2='80.22'
          y2='23.95'
        />
      </g>
      <g>
        <path
          fill='#E0E0E0'
          d='M7.58,33.38v51.84c0,2.31,1.87,4.18,4.18,4.18h64.95h1.4l14.3-14.3v-1.4V33.38H7.58z'
        />
        <path fill='#B3B3B3' d='M92.42,73.7H81.27c-2.52,0-4.55,2.04-4.55,4.55V89.4h1.4l14.3-14.3V73.7z' />
      </g>
      <g>
        <line
          fill='none'
          stroke='#333333'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='35.32'
          y1='68.87'
          x2='35.32'
          y2='78.62'
        />
        <g>
          <path
            fill='#ABBD81'
            d='M36.81,71.77h-2.98c-0.97,0-1.66-0.93-1.39-1.85l0.93-3.18h3.9l0.93,3.18 C38.47,70.84,37.78,71.77,36.81,71.77z'
          />
          <path
            fill='#ABBD81'
            d='M45.64,55.19c0,6.92-4.62,13.37-10.32,13.37S25,62.11,25,55.19s4.62-11.48,10.32-11.48 S45.64,48.27,45.64,55.19z'
          />
        </g>
      </g>
      <g>
        <line
          fill='none'
          stroke='#333333'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='64.21'
          y1='64.62'
          x2='64.21'
          y2='74.92'
        />
        <path
          fill='#F8B26A'
          d='M65.77,67.65h-3.12c-1.01,0-1.74-0.97-1.45-1.94l0.97-3.33h4.07l0.97,3.33 C67.5,66.68,66.77,67.65,65.77,67.65z'
        />
        <path
          fill='#F8B26A'
          d='M75,50.23c0,7.24-4.83,13.99-10.79,13.99s-10.79-6.75-10.79-13.99s4.83-12,10.79-12S75,42.99,75,50.23z'
        />
      </g>
      <g>
        <line
          fill='none'
          stroke='#333333'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='50.39'
          y1='74.22'
          x2='50.39'
          y2='83.78'
        />
        <path
          fill='#F47E60'
          d='M52.04,77.43h-3.31c-1.07,0-1.84-1.03-1.54-2.06l1.03-3.53h4.32l1.03,3.53 C53.89,76.4,53.12,77.43,52.04,77.43z'
        />
        <path
          fill='#F47E60'
          d='M61.85,58.82c0,7.68-5.13,14.84-11.45,14.84S38.94,66.5,38.94,58.82s5.13-12.74,11.45-12.74 S61.85,51.14,61.85,58.82z'
        />
      </g>
    </svg>
  );
};

export default ReservationMessage;
