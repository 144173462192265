import { useState, useEffect, useContext } from "react";
import { checklistALB, checklistENG } from "./checklistsArray";
import { LanguageContext } from "../../contexts/Language/LanguageContext";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";

import YearSelector from "./YearSelector";
import CheckboxList from "./CheckboxList";
import MonthsSelector from "./MonthsSelector";
import styles from "./EventsNavigationToolbar.module.css";

const EventsNavigationToolbar = ({ activeButton, setEventsQuery }) => {
  const [initialRender, setInitialRender] = useState(true);
  const [year, setYear] = useState("upcoming");
  const [selectedMonth, setSelectedMonth] = useState("all");
  const [isVisible, setIsVisible] = useState(false);
  const [checkBoxVisible, setCheckBoxVisible] = useState(false);
  const { language } = useContext(LanguageContext);
  const [checklistOptions, setChecklistOptions] = useState([]);

  useEffect(() => {
    if (language === "albanian") {
      setChecklistOptions(checklistALB);
    } else {
      setChecklistOptions(checklistENG);
    }
  }, [language]);

  useEffect(() => {
    if (initialRender === true) {
      return;
    }

    let queryObj = {
      checklistOptions,
      selectedMonth,
      year,
    };
    setEventsQuery(queryObj);
  }, [checklistOptions, selectedMonth, year, setEventsQuery, initialRender, language]);

  const resetToolbarHandler = () => {
    if (activeButton === "past") {
      setYear("past");
      setSelectedMonth("all");
      setCheckBoxVisible(false);
      setIsVisible(false);
      setChecklistOptions((prevState) =>
        [...prevState].map((option) => {
          return { ...option, checked: false };
        })
      );
    } else {
      setCheckBoxVisible(false);
      setSelectedMonth("all");
      setChecklistOptions((prevState) =>
        [...prevState].map((option) => {
          return { ...option, checked: false };
        })
      );
    }
  };

  useEffect(() => {
    if (activeButton === "upcoming") {
      setYear("upcoming");
      setSelectedMonth("all");
      setCheckBoxVisible(false);
      setIsVisible(true);
      setChecklistOptions((prevState) =>
        [...prevState].map((option) => {
          return { ...option, checked: false };
        })
      );
    } else {
      setYear("past");
      setSelectedMonth("all");
      setIsVisible(false);
      setCheckBoxVisible(false);
      setChecklistOptions((prevState) =>
        [...prevState].map((option) => {
          return { ...option, checked: false };
        })
      );
    }
  }, [activeButton]);

  const onChangeHandler = (ev, query) => {
    setChecklistOptions((prevState) => {
      return [...prevState].map((option) =>
        option.value === query ? { ...option, checked: !option.checked } : option
      );
    });
    setInitialRender(false);
  };
  const setMonthHandler = (month) => {
    setInitialRender(false);
    setSelectedMonth(month);
  };
  const setYearHandler = (str) => {
    setInitialRender(false);
    setYear(str);
    setIsVisible(true);
  };

  return (
    <>
      <div className={styles["toolbar-container"]}>
        <div className={styles["upper-toolbar"]}>
          <YearSelector
            year={year}
            setYear={setYearHandler}
            activeButton={activeButton}
            resetToolbar={resetToolbarHandler}
            setIsVisible={setIsVisible}
          />

          <div className={styles["buttons-container"]}>
            <button type='button' className={styles.btn} onClick={resetToolbarHandler}>
              <MultiLingualContent contentId='events' prop='button3' />
            </button>
            <button
              type='button'
              className={styles.btn}
              onClick={() => setCheckBoxVisible((prevState) => !prevState)}>
              <MultiLingualContent contentId='events' prop='button4' />
            </button>
          </div>
        </div>
        <div
          style={{
            transition: "all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
            height: checkBoxVisible ? "100px" : "0px",
            overflow: "scroll",
          }}>
          <CheckboxList options={checklistOptions} onChange={onChangeHandler} />
        </div>
      </div>
      <MonthsSelector
        isVisible={isVisible}
        year={year}
        monthState={{ selectedMonth, setMonthHandler }}
        activeButton={activeButton}
      />
    </>
  );
};

export default EventsNavigationToolbar;
