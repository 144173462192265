import Flickity from "react-flickity-component";
import "flickity/dist/flickity.css";
import "./carousel.css";
import books_and_piano from "../../assets/images/carousel/books_and_piano.jpg";
import drink from "../../assets/images/carousel/drink.jpg";
import olive_oil from "../../assets/images/carousel/olive_oil.jpg";
import piano_has_been_drinking from "../../assets/images/carousel/piano_has_been_drinking.jpg";
import mulled_wine from "../../assets/images/carousel/mulled_wine.jpg";
import salmon_dish from "../../assets/images//carousel/salmon_dish.jpg";

const flickityOptions = {
  initialIndex: 0,
  wrapAround: true,
  pageDots: true,
  arrowShape: "M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z",
};

const Carousel = () => {
  return (
    <>
      <Flickity
        className={"carousel"} // default ''
        elementType={"div"} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        <div className='gallery-cell'>
          <img src={books_and_piano} alt='Books and a piano' />
        </div>

        <div className='gallery-cell'>
          <img
            src={piano_has_been_drinking}
            alt='Glass with a cocktail and a slice of orange on top of the piano keys'
          />
        </div>
        <div className='gallery-cell'>
          <img src={salmon_dish} alt='Restaurant table with a dish of salmon and glass of white wine' />
        </div>
        <div className='gallery-cell'>
          <img src={olive_oil} alt='Bottles of homemade produce' />
        </div>
        <div className='gallery-cell'>
          <img src={mulled_wine} alt='A waiter pouring mulled wine into a glass' />
        </div>
        <div className='gallery-cell'>
          <img src={drink} alt='A hand holding a cocktail' />
        </div>
      </Flickity>
    </>
  );
};

export default Carousel;
