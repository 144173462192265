import { Outlet } from "react-router-dom";

import styles from "./App.module.css";
import AdminDesktopNav from "./components/AdminNavBars/AdminDesktopNav";
import AdminMobileNav from "./components/AdminNavBars/AdminMobileNav";

const LayoutAdmin = () => {
  return (
    <div className={styles.admin}>
      <AdminMobileNav />
      <AdminDesktopNav />
      <div className={styles.main}>
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutAdmin;
