import styles from "./Records.module.css";

const Records = () => {
  return (
    <svg className={styles.svg} version='1.1' id='圖層_1' x='0' y='0' viewBox='0 0 100 100'>
      <g>
        <path
          d='M48.336 13.431c-18.57 0-33.624 15.054-33.624 33.624s15.054 33.624 33.624 33.624S81.96 65.625 81.96 47.055c-.001-18.57-15.055-33.624-33.624-33.624zm0 45.24c-6.415 0-11.616-5.201-11.616-11.616 0-6.416 5.201-11.616 11.616-11.616s11.616 5.201 11.616 11.616-5.201 11.616-11.616 11.616z'
          fill='#666'
          stroke='#333'
          strokeWidth='3.5'
          strokeMiterlimit='10'
        />
        <path
          fill='none'
          stroke='#333'
          strokeWidth='8'
          strokeLinecap='round'
          strokeMiterlimit='10'
          d='M62.641 31.043l8.058 7.944'
        />
        <path
          fill='none'
          stroke='#333'
          strokeWidth='3.5'
          strokeLinecap='round'
          strokeMiterlimit='10'
          d='M9.126 22.845h43.82l7.338 5.874'
        />
      </g>
      <g>
        <circle
          cx='76.136'
          cy='71.831'
          r='14.738'
          fill='#fff'
          stroke='#333'
          strokeWidth='4'
          strokeMiterlimit='10'
        />
        <path
          d='M76.51 64.031l2.149 4.354a.418.418 0 0 0 .314.228l4.805.698c.342.05.479.47.231.712l-3.477 3.389a.419.419 0 0 0-.12.369l.821 4.786a.417.417 0 0 1-.605.44l-4.298-2.26a.416.416 0 0 0-.388 0l-4.298 2.26a.417.417 0 0 1-.605-.44l.821-4.786a.419.419 0 0 0-.12-.369l-3.477-3.389a.417.417 0 0 1 .231-.712l4.805-.698a.418.418 0 0 0 .314-.228l2.149-4.354a.417.417 0 0 1 .748 0z'
          fill='#f8b26a'
        />
      </g>
    </svg>
  );
};

export default Records;
