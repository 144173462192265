import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import BookHoldRow from "./BookHoldRow";
import ExpiredHoldsRow from "./ExpiredHoldsRow";
import NextButton from "../../../../components/UI/NextButton";

import FlashMessage from "../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../Modals/DeleteModal";
import AddLibraryMemberModal from "../../../../Modals/AddLibraryMemberModal";

import styles from "./BookHolds.module.css";

// FIREBASE
import {
  getDocs,
  getFirestore,
  collection,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  Timestamp,
  getDoc,
  query,
  limit,
  startAfter,
  where,
} from "firebase/firestore";

const BookHolds = () => {
  const [alert, setAlert] = useState("");
  const [bookHolds, setBookHolds] = useState([]);
  const [expiredHolds, setExpiredHolds] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [holdId, setHoldId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [last, setLast] = useState();

  const [member, setMember] = useState({
    id: null,
    fullName: "",
    phone: "",
    email: "",
    depositAmount: "",
  });

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
        setShowAddMemberModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  const [isDocWritten, setIsDocWritten] = useState(false);

  useEffect(() => {
    // Connect to firestore
    const getBookHolds = async () => {
      const db = getFirestore();
      const q = query(collection(db, "bookHolds"), limit(5));
      const querySnapshot = await getDocs(q);

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLast(lastVisible);

      const bookHoldsData = [];

      querySnapshot.forEach(async (document) => {
        const now = new Date().getTime(); // now in miliseconds
        const holdExp = document.data().holdExpires.toMillis();
        const isCanceled = document.data().holdCanceled;
        // If doc was canceled Im not going to update book copies and book avaialbility. That's is done in onHold component when user cancels hold
        // Only when book has expired I will update book availability and copies and user onHold array. But in both cases I will add the book to expiredHolds and delete it from bookHolds

        if (now > holdExp && !isCanceled) {
          const bookId = document.data().docId;
          const bookSnap = await getDoc(doc(db, "books", bookId));
          let copies = bookSnap.data().copies;
          await updateDoc(doc(db, "books", bookId), { available: true, copies: Number(copies) + 1 });

          // Remove book from users onHold array
          const userId = document.data().user.docId;
          await updateDoc(doc(db, "users", userId), { onHold: [] });
          let expiredHoldId = uuidv4();
          await setDoc(doc(db, "expiredHolds", expiredHoldId), {
            ...document.data(),
            expiredHoldId,
          });

          // I'm changing state when document is written to database to render expired hold component

          setIsDocWritten(true);

          await deleteDoc(doc(db, "bookHolds", document.id));
          // if user cancels book, but book hasn't expired only place doc in expiredHolds and delete it from bookHold
        } else if (isCanceled) {
          let expiredHoldId = uuidv4();
          await setDoc(doc(db, "expiredHolds", expiredHoldId), {
            ...document.data(),
            expiredHoldId,
          });

          await deleteDoc(doc(db, "bookHolds", document.id));
          // I'm changing state when document is written to database to render expired hold component

          setIsDocWritten(true);
        } else {
          bookHoldsData.push(document.data());
        }
      });

      setBookHolds(bookHoldsData);

      setIsPending(false);
    };
    getBookHolds();
  }, []);

  useEffect(() => {
    // Connect to firestores
    const getExpiredHolds = async () => {
      const db = getFirestore();
      const q = query(collection(db, "expiredHolds"));
      const querySnapshot = await getDocs(q);

      const expiredHolds = [];

      querySnapshot.forEach((doc) => {
        expiredHolds.push(doc.data());
      });

      setExpiredHolds(expiredHolds);
    };

    getExpiredHolds();
  }, [isDocWritten]);

  // NEXT SET OF DATA - When we press More Button
  const showMoreItemsHandler = async () => {
    const db = getFirestore();

    const next = query(collection(db, "bookHolds"), startAfter(last), limit(5));
    const querySnapshot = await getDocs(next);

    if (querySnapshot.empty) {
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLast(lastVisible);

    querySnapshot.forEach((doc) => {
      setBookHolds((prevState) => {
        return [...prevState, { ...doc.data(), docId: doc.id }];
      });
    });
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onShowDeleteModalHandler = (holdId) => {
    setShowDeleteModal(true);
    setHoldId(holdId);
  };

  const onDeleteBookHoldHandler = async () => {
    const db = getFirestore();
    // Update available copies of the book in books collection, only if hold was not canceled by the user

    const bookHoldsCollectionRef = collection(db, "bookHolds");
    const q = query(bookHoldsCollectionRef, where("docId", "==", holdId));
    const querySnapshot = await getDocs(q);

    //  Remove doc from bookHolds

    querySnapshot.forEach(async (document) => {
      //  only if book was not canceled update book in books collection
      //  Also delete book from bookHolds collection
      const bookRef = doc(db, "bookHolds", document.id);
      const bookSnap = await getDoc(bookRef);
      const isCanceled = bookSnap.data().holdCanceled;
      if (!isCanceled) {
        const docRef = doc(db, "books", holdId);
        const docSnap = await getDoc(docRef);
        let copies = docSnap.data().copies;
        await updateDoc(docRef, { copies: Number(copies) + 1, available: true });
      }

      await deleteDoc(bookRef);
    });

    // Remove it also from users account. In order to do this, we need users docId, which we we placed in user object when we placed hold, either from library or from wishlist.

    const book = bookHolds.filter((book) => book.docId === holdId)[0];
    const userId = book.user.docId;

    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { onHold: [] });

    // Update UI
    setBookHolds((prevState) => {
      return prevState.filter((hold) => hold.docId !== holdId);
    });

    flashMessageHandler({ message: "Book Hold Deleted ", success: true });
    setShowDeleteModal(false);
    setHoldId(null);
  };

  const onCancelDeleteBookHoldHandler = () => {
    setShowDeleteModal(false);
  };

  const confirmBookPickupHandler = async (id) => {
    // Remove book from bookHolds collection
    const db = getFirestore();

    const bookHoldsRef = collection(db, "bookHolds");

    // Create a query against the collection.
    const q = query(bookHoldsRef, where("docId", "==", id));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (document) => {
      const holdRequestRef = doc(db, "bookHolds", document.id);
      await deleteDoc(holdRequestRef);
    });

    // Place book in borrowedBooks collection
    let book = bookHolds.filter((book) => book.docId === id)[0];

    // Due date a month from now
    let now = new Date();
    now.setDate(now.getDate() + 31);

    book = {
      ...book,
      dateBorrowed: Timestamp.now(),
      dueDate: Timestamp.fromDate(now),
    };

    delete book.holdPlaced;
    delete book.holdExpires;

    await addDoc(collection(db, "borrowedBooks"), book);

    // Get book from onHold array and place it in borrowed array in user account. Remove it from onHold array. Also add dateBorrowed and dueDate property before placing it in borrowed books. And remove holdPlaced and holdExpires props. Later when member returns books add dateReturned property (which will be used in history in users account)

    const userId = book.user.docId;

    let userRef = doc(db, "users", userId);
    let userSnap = await getDoc(userRef);
    let onHoldBook = userSnap.data().onHold[0];

    // Timestamp.now() is not a javascrip date object. It has to be converted
    // Timestamp.fromDate() is a javascript object
    onHoldBook = {
      ...onHoldBook,
      dateBorrowed: Timestamp.now(),
      dueDate: Timestamp.fromDate(now),
    };

    delete onHoldBook.holdPlaced;
    delete onHoldBook.holdExpires;

    await updateDoc(userRef, {
      onHold: [],
      borrowed: [onHoldBook],
    });

    // Update UI
    setBookHolds((prevState) => {
      return prevState.filter((hold) => hold.docId !== id);
    });
    flashMessageHandler({ message: "Book Pickup Confirmed ", success: true });
    // setShowDeleteModal(false);
    setHoldId(null);
  };

  // Add New Member

  const onCancelAddMemberHandler = () => {
    setShowAddMemberModal(false);
  };

  const onAddNewMemberHandler = async (ev) => {
    ev.preventDefault();

    // Add member to libraryMembers collection. Doc id in libraryMembers collection needs to be users id
    const db = getFirestore();
    //  get user docId
    const book = bookHolds.filter((book) => book.docId === holdId)[0];
    const userId = book.user.docId;
    try {
      await setDoc(doc(db, "libraryMembers", userId), { ...member, docId: userId });

      // Update users collection: deposit amount and member properties
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, { depositAmount: member.depositAmount, member: true, phone: member.phone });

      // Update book in bookHolds collection, its user property, member:true and phone
      // Here now we need a query
      const bookHoldsCollection = collection(db, "bookHolds");
      const q = query(bookHoldsCollection, where("docId", "==", holdId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (document) => {
        const bookRef = doc(db, "bookHolds", document.id);
        await updateDoc(bookRef, { "user.member": true, "user.phone": member.phone });
      });
    } catch (error) {
      flashMessageHandler({ message: error, success: false });
    }

    // UPDATE UI

    setBookHolds((prevState) => {
      return [...prevState].map((book) => {
        if (book.docId === holdId) {
          return {
            ...book,
            user: {
              ...book.user,
              member: true,
              fullName: member.fullName,
              phone: member.phone,
              email: member.email,
            },
          };
        } else {
          return book;
        }
      });
    });
    setShowAddMemberModal(false);
    flashMessageHandler({ message: "Member Added", success: true });
  };

  const addMemberModalHandler = (id) => {
    setHoldId(id);
    setShowAddMemberModal(true);
    const bookHold = bookHolds.filter((bookHold) => bookHold.docId === id)[0];

    const member = {
      fullName: bookHold.user.fullName.toLowerCase(),
      phone: bookHold.user.phone === null ? "" : bookHold.user.phone,
      email: bookHold.user.email,
      depositAmount: "20",
    };
    setMember(member);
  };

  const deleteExpiredHoldHandler = async (id) => {
    const db = getFirestore();

    await deleteDoc(doc(db, "expiredHolds", id));

    setExpiredHolds((prevState) => {
      return [...prevState].filter((item) => item.expiredHoldId !== id);
    });
    flashMessageHandler({ message: "Return Book to shelf", success: true });
  };

  return (
    <div className={styles.container}>
      <DeleteModal
        showModal={showDeleteModal}
        onConfirm={onDeleteBookHoldHandler}
        onCancel={onCancelDeleteBookHoldHandler}
        message={"Are you sure you want to delete this book hold?"}
      />
      <AddLibraryMemberModal
        member={member}
        showModal={showAddMemberModal}
        onCancel={onCancelAddMemberHandler}
        onSubmit={onAddNewMemberHandler}
        setMember={setMember}
      />
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      {isPending ? (
        "Loading..."
      ) : bookHolds.length > 0 ? (
        <table className={styles["book-holds"]}>
          <thead>
            <tr>
              <th>Book Title</th>
              <th>Book ID</th>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Member</th>
              <th>On Hold Until</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {bookHolds.map((data) => {
              return (
                <BookHoldRow
                  key={uuidv4()}
                  title={data.title}
                  id={data.id}
                  docId={data.docId}
                  fullName={data.user.fullName}
                  phone={data.user.phone}
                  email={data.user.email}
                  member={data.user.member}
                  holdUntil={data.holdExpires}
                  showDeleteModal={onShowDeleteModalHandler}
                  onConfirmPickUp={confirmBookPickupHandler}
                  showAddMemberModal={addMemberModalHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>Currently there are no books on hold.</p>
      )}
      {bookHolds.length > 0 && <NextButton onClick={showMoreItemsHandler} />}

      {expiredHolds.length > 0 && (
        <div className={styles.expiredHolds}>
          <h2>Expired or canceled</h2>
          <table className={styles["book-holds"]}>
            <thead>
              <tr>
                <th>Book Title</th>
                <th>Book ID</th>
                <th>Full Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Member</th>
                <th>On Hold Until</th>
                <th>Operations</th>
              </tr>
            </thead>
            <tbody>
              {expiredHolds.map((data) => {
                return (
                  <ExpiredHoldsRow
                    key={uuidv4()}
                    title={data.title}
                    id={data.id}
                    docId={data.docId}
                    expiredHoldId={data.expiredHoldId}
                    fullName={data.user.fullName}
                    phone={data.user.phone}
                    email={data.user.email}
                    member={data.user.member}
                    holdUntil={data.holdExpires}
                    onDeleteExpiredHold={deleteExpiredHoldHandler}
                    isHoldCanceled={data.holdCanceled}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BookHolds;
