import styles from "./ChevronDown.module.css";
const ChevronDown = () => {
  return (
    <svg className={styles.svg} width='8' height='6' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path
        d='M4 3.414L6.475.94a.5.5 0 0 1 .707 0l.707.707a.5.5 0 0 1 0 .708L5.061 5.182l-.707.707a.5.5 0 0 1-.708 0L.111 2.354a.5.5 0 0 1 0-.708L.818.94a.5.5 0 0 1 .707 0L4 3.414z'
        fill='#333'
        fillRule='evenodd'></path>
    </svg>
  );
};

export default ChevronDown;
