import styles from "./Library.module.css";

const Library = () => {
  return (
    <svg className={styles.svg} version='1.1' id='Layer_1' x='0' y='0' viewBox='0 0 100 100'>
      <path
        d='M80.5 43.5v-7h-61v7h.5a4 4 0 0 1 4 4V65a4 4 0 0 1-4 4h-1v7h61v-7h0a4 4 0 0 1-4-4V47.5a4 4 0 0 1 4-4h.5zm-26.5 4V65a4 4 0 0 1-4 4h0a4 4 0 0 1-4-4V47.5a4 4 0 0 1 4-4h0a4 4 0 0 1 4 4zm-19.25-4h.5A3.75 3.75 0 0 1 39 47.25V65a4 4 0 0 1-4 4h0a4 4 0 0 1-4-4V47.25a3.75 3.75 0 0 1 3.75-3.75zM65 69h0a4 4 0 0 1-4-4V47.5a4 4 0 0 1 4-4h0a4 4 0 0 1 4 4V65a4 4 0 0 1-4 4z'
        strokeWidth='3.5'
        strokeMiterlimit='10'
        fill='#e0e0e0'
        stroke='#333'
      />
      <path
        d='M88 82H12v-4.625A3.375 3.375 0 0 1 15.375 74h69.25A3.375 3.375 0 0 1 88 77.375V82z'
        strokeWidth='3.5'
        strokeMiterlimit='10'
        fill='#e0e0e0'
        stroke='#333'
      />
      <path
        d='M91 90H9v-4.494A3.506 3.506 0 0 1 12.506 82h74.989A3.506 3.506 0 0 1 91 85.506V90z'
        strokeWidth='3.5'
        strokeMiterlimit='10'
        fill='#e0e0e0'
        stroke='#333'
      />
      <path
        d='M85 30H15v5.406A2.594 2.594 0 0 0 17.594 38h64.813A2.593 2.593 0 0 0 85 35.406V30z'
        strokeWidth='3.5'
        strokeMiterlimit='10'
        fill='#e0e0e0'
        stroke='#333'
      />
      <path
        d='M87.559 30.5H12.441c-1.913 0-2.646-2.494-1.038-3.529l37.559-16.666a1.916 1.916 0 0 1 2.075 0l37.559 16.666c1.609 1.035.876 3.529-1.037 3.529z'
        strokeWidth='3.5'
        strokeMiterlimit='10'
        fill='#e0e0e0'
        stroke='#333'
      />
      <circle cx='50' cy='21' r='4.5' fill='#333' />
    </svg>
  );
};

export default Library;
