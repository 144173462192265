import styles from "./MainsMobile.module.css";

const MainsMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <div className={styles["main-section"]}>
        <li className={styles.item} onClick={props.onShowModalHandler} category='fromTheSea' data-index='0'>
          Peshk Koc - Koc, rigon, limon, hudhër, qepë e kuqe, vaj ulliri ekstra të virgjër, domate
          <span>11.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} category='fromTheSea' data-index='1'>
          Peshk’E’Patate - Peshk Merluc, patate, krem uthulle, salcë tartar dhe pire të bizeleve
          <span>7.50</span>
        </li>
      </div>
      <div className={styles["main-section"]}>
        <h3>PULË</h3>
        <li className={styles.item} onClick={props.onShowModalHandler} category='fromTheSea' data-index='2'>
          Pule ne furre - Kofshë pule e marinuar në mjaltë me pire të patateve të ëmbla dhe spanak{" "}
          <span>6.00</span>
        </li>
      </div>
    </ul>
  );
};

export default MainsMobile;
