import styles from "./BurgerAndWrapsDesktop.module.css";

const BurgerAndWrapsDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <div className={styles.section}>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='burgers'>
          Burger Djathi - Mish viçi, djath Amerikan, ajsberg, tranguj turrshi, patate dhe bukë briosh ... 5.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='burgers'>
          Burger Chutney - Mish viçi, chutney, ajsberg, rukolla, djath Amerikan, patate dhe bukë briosh . 5.50
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='burgers'>
          Burger Tartufi - Mish viçi, majonez tartufi, rukolla, djath Amerikan patate dhe bukë briosh ... 6.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='burgers'>
          Burger Vegjetarian - Këpurdha, panxhar, fasule, misër, tërshërë, spanak, qepë e kuqe dhe patate 4.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='burgers'>
          Burger Pule - Pulë, sallatë ajsberg, domate, tranguj turrshi, majonez me hudër dhe salcë BBQ .. 5.50
        </li>
      </div>
      <div className={styles.section}>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='burgers'>
          Hotdog - Virshlle pule, mustardë, qepë krokante dhe qipsa të shtëpisë anash ................... 3.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='burgers'>
          NYC Chopped Sandwich - Mish viçi i bluar me speca të gjelbërt, qepë dhe djath Amerikan ........ 6.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='burgers'>
          Mish Viçi në Petë - me salcë BBQ, rukolla, djath i bardhë, flegër molle dhe djath Amerikan .... 5.40
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='8' category='burgers'>
          Kungulleshka në Petë - humus, domate, ajsberg, spanak dhe qepë , salcë kosi dhe vaj majdanozi . 4.00
        </li>
      </div>
    </ul>
  );
};

export default BurgerAndWrapsDesktop;
