import styles from "./Calendar.module.css";

const Calendar = () => {
  return (
    <svg
      className={styles.svg}
      version='1.1'
      id='圖層_1'
      x='0px'
      y='0px'
      viewBox='0 0 100 100'
      enableBackground='new 0 0 100 100'>
      <g>
        <path
          fill='#E15B64'
          d='M88.24,13.99H11.76c-2.31,0-4.18,1.87-4.18,4.18v15.21h84.83V18.17C92.42,15.86,90.55,13.99,88.24,13.99z'
        />
        <circle fill='#C33737' cx='19.8' cy='23.95' r='3.5' />
        <line
          fill='#333333'
          stroke='#CCCCCC'
          strokeWidth='4'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='19.8'
          y1='10.03'
          x2='19.8'
          y2='23.95'
        />
        <circle fill='#C33737' cx='34.91' cy='23.95' r='3.5' />
        <line
          fill='#333333'
          stroke='#CCCCCC'
          strokeWidth='4'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='34.91'
          y1='10.03'
          x2='34.91'
          y2='23.95'
        />
        <circle fill='#C33737' cx='50.01' cy='23.95' r='3.5' />
        <line
          fill='#333333'
          stroke='#CCCCCC'
          strokeWidth='4'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='50.01'
          y1='10.03'
          x2='50.01'
          y2='23.95'
        />
        <circle fill='#C33737' cx='65.11' cy='23.95' r='3.5' />
        <line
          fill='#333333'
          stroke='#CCCCCC'
          strokeWidth='4'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='65.11'
          y1='10.03'
          x2='65.11'
          y2='23.95'
        />
        <circle fill='#C33737' cx='80.22' cy='23.95' r='3.5' />
        <line
          fill='#333333'
          stroke='#CCCCCC'
          strokeWidth='4'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='80.22'
          y1='10.03'
          x2='80.22'
          y2='23.95'
        />
      </g>
      <g>
        <path
          fill='#E0E0E0'
          d='M7.58,33.38v51.84c0,2.31,1.87,4.18,4.18,4.18h64.95h1.4l14.3-14.3v-1.4V33.38H7.58z'
        />
        <path fill='#B3B3B3' d='M92.42,73.7H81.27c-2.52,0-4.55,2.04-4.55,4.55V89.4h1.4l14.3-14.3V73.7z' />
      </g>
      <rect x='53.23' y='40.25' fill='#A0C8D7' width='12' height='12' />
      <rect x='71.7' y='40.25' fill='#A0C8D7' width='12' height='12' />
      <rect x='16.3' y='55.75' fill='#A0C8D7' width='12' height='12' />
      <rect x='34.77' y='55.75' fill='#A0C8D7' width='12' height='12' />
      <rect x='53.23' y='55.75' fill='#A0C8D7' width='12' height='12' />
      <rect x='71.7' y='55.75' fill='#A0C8D7' width='12' height='12' />
      <rect x='16.3' y='71.25' fill='#A0C8D7' width='12' height='12' />
      <rect x='34.77' y='71.25' fill='#FFFFFF' width='12' height='12' />
      <rect x='53.23' y='71.25' fill='#FFFFFF' width='12' height='12' />
      <polyline
        fill='none'
        stroke='#39B54A'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        points=' 16.44,45.82 20.35,49.72 28.35,41.72 '
      />
      <polyline
        fill='none'
        stroke='#39B54A'
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        points=' 34.44,45.82 38.35,49.72 46.35,41.72 '
      />
    </svg>
  );
};

export default Calendar;
