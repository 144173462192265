import { NavLink, Routes, Route } from "react-router-dom";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import Borrowed from "./MyLibraryPages/Borrowed/Borrowed";
import OnHold from "./MyLibraryPages/OnHold/OnHold";
import History from "./MyLibraryPages/History/History";
import Wishlist from "./MyLibraryPages/Wishlist/Wishlist";
import Account from "./MyLibraryPages/Account/Account";

import styles from "./MyLibrary.module.css";

const MyLibrary = () => {
  return (
    <div className={styles.container}>
      <nav className={styles.nav}>
        <ul>
          <NavLink to='/my_library/borrowed' className={(navData) => (navData.isActive ? styles.active : "")}>
            <span>
              <MultiLingualContent contentId='myLibrary' prop='navLink1' />
            </span>
          </NavLink>
          <NavLink to='/my_library/onhold' className={(navData) => (navData.isActive ? styles.active : "")}>
            <span>
              <MultiLingualContent contentId='myLibrary' prop='navLink2' />
            </span>
          </NavLink>
          <NavLink to='/my_library/history' className={(navData) => (navData.isActive ? styles.active : "")}>
            <span>
              <MultiLingualContent contentId='myLibrary' prop='navLink3' />
            </span>
          </NavLink>
          <NavLink to='/my_library/wishlist' className={(navData) => (navData.isActive ? styles.active : "")}>
            <span>
              <MultiLingualContent contentId='myLibrary' prop='navLink4' />
            </span>
          </NavLink>
          <NavLink to='/my_library/account' className={(navData) => (navData.isActive ? styles.active : "")}>
            <span>
              <MultiLingualContent contentId='myLibrary' prop='navLink5' />
            </span>
          </NavLink>
        </ul>
      </nav>
      <main className={styles.main}>
        <Routes>
          <Route path='/borrowed' element={<Borrowed />} />
          <Route path='/onhold' element={<OnHold />} />
          <Route path='/history' element={<History />} />
          <Route path='/wishlist' element={<Wishlist />} />
          <Route path='/account' element={<Account />} />
        </Routes>
      </main>
    </div>
  );
};

export default MyLibrary;
