import styles from "./BeersMobile.module.css";

const BeersMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <div className={styles.section}>
        <h4 className={styles.title}>Our Homemade Brew</h4>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='0'>
          Sabaja Pils
          <span>2.50(0.3l) - 3.50(0.5l)</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='1'>
          Sabaja IPA <span>3.00(0.3l) - 4.00(0.5l)</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='2'>
          Sabaja Porter <span>3.00(0.3l) - 4.00(0.5l)</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='3'>
          Sabaja Weiss Beer<span>3.00(0.3l) - 4.00(0.5l)</span>
        </li>
      </div>
      <div className={styles.section}>
        <h4 className={styles.title}>Other Beers</h4>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='0'>
          Heineken
          <span>3.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='1'>
          Tuborg <span>3.50</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='2'>
          Paulaner Weissbier <span>4.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='3'>
          Corona<span>4.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='3'>
          Leffe Blonde <span>5.00</span>
        </li>
      </div>
    </ul>
  );
};

export default BeersMobile;
