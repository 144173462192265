import styles from "./BeersDesktop.module.css";

const BeersDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <div className={styles.section}>
        <h3 className={styles.title}>Our Homemade Brew</h3>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='0'>
          Sabaja Pils ......................................................... 2.50(0.3l)... 3.50(0.5l)
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='1'>
          Sabaja IPA .......................................................... 3.00(0.3l)... 4.00(0.5l)
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='5'>
          Sabaja Porter ....................................................... 3.00(0.3l)... 4.00(0.5l)
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='6'>
          Sabaja Weiss Beer ................................................... 3.00(0.3l)... 4.00(0.5l)
        </li>
      </div>
      <div className={styles.section}>
        <h3 className={styles.title}>Other Beers</h3>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='0'>
          Heineken ................................................................................ 3.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='1'>
          Tuborg .................................................................................. 3.50
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='5'>
          Paulaner Weissbier ...................................................................... 4.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='6'>
          Corona .................................................................................. 4.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='6'>
          Leffe Blonde ............................................................................ 5.00
        </li>
      </div>
    </ul>
  );
};

export default BeersDesktop;
