import React, { Fragment } from "react";

import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import styles from "./Library.module.css";
import image1 from "../../../assets/images/collage_books_and_records/crumb1.jpg";
import image2 from "../../../assets/images/collage_books_and_records/book2.jpg";
import image3 from "../../../assets/images/collage_books_and_records/book3.jpg";
import image4 from "../../../assets/images/collage_books_and_records/vinyls3.jpg";
import image5 from "../../../assets/images/collage_books_and_records/vinylcollection1.jpg";
import image6 from "../../../assets/images/collage_books_and_records/vinylcollection2.jpg";

const Library = () => {
  return (
    <Fragment>
      <h1>
        <MultiLingualContent contentId='booksAndRecords' prop='title1' />
      </h1>
      <p>
        <MultiLingualContent contentId='booksAndRecords' prop='booksAndRecordsP1' />
      </p>
      <div className={styles["collage-container"]}>
        <div className={styles["collage-image"]}>
          <img src={image1} alt='' className='skeleton' />
        </div>
        <div className={styles["collage-image"]}>
          <img src={image2} alt='' className='skeleton' />
        </div>
        <div className={styles["collage-image"]}>
          <img src={image3} alt='' className='skeleton' />
        </div>
      </div>
      <p>
        <MultiLingualContent contentId='booksAndRecords' prop='booksAndRecordsP2' />
      </p>

      <p>
        <MultiLingualContent contentId='booksAndRecords' prop='booksAndRecordsP3' />
      </p>

      <p>
        <MultiLingualContent contentId='booksAndRecords' prop='booksAndRecordsP4' />
      </p>
      <p>
        <MultiLingualContent contentId='booksAndRecords' prop='booksAndRecordsP5' />
      </p>
      <div className={styles["collage-container"]}>
        <div className={styles["collage-image"]}>
          <img src={image4} alt='' className='skeleton' />
        </div>
        <div className={styles["collage-image"]}>
          <img src={image5} alt='' className='skeleton' />
        </div>
        <div className={styles["collage-image"]}>
          <img src={image6} alt='' className='skeleton' />
        </div>
      </div>
      <h1>
        <MultiLingualContent contentId='booksAndRecords' prop='title2' />
      </h1>
      <p>
        <MultiLingualContent contentId='booksAndRecords' prop='booksAndRecordsP6' />
      </p>
      <p>
        <MultiLingualContent contentId='booksAndRecords' prop='booksAndRecordsP7' />
      </p>
      <p>
        <MultiLingualContent contentId='booksAndRecords' prop='booksAndRecordsP8' />
      </p>
    </Fragment>
  );
};

export default Library;
