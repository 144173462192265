import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import FlashMessage from "../../../../components/UI/FlashMessage";

import DeletedReservationsRow from "./DeletedReservationsRow";
import styles from "./DeletedReservations.module.css";

// FIREBASE
import { getFirestore, collection, doc, deleteDoc, getDocs, query, addDoc, getDoc } from "firebase/firestore";

const DeletedReservations = () => {
  const [alert, setAlert] = useState("");
  const [deletedReservations, setDeletedReservations] = useState([]);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    const getDeletedRes = async () => {
      setIsPending(true);
      setDeletedReservations([]);
      const db = getFirestore();
      const q = query(collection(db, "recentlyDeletedReservations"));

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setIsPending(false);
        return;
      }

      querySnapshot.forEach(async (document) => {
        const now = Date.now();
        let expires = document.data().expires.toMillis();
        if (now > expires) {
          await deleteDoc(doc(db, "recentlyDeletedReservations", document.id));
        } else {
          setDeletedReservations((prevState) => {
            return [...prevState, { ...document.data(), id: document.id }];
          });
        }
      });
      setIsPending(false);
    };
    getDeletedRes();
  }, []);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onUndoDeletedReservationHandler = async (docId) => {
    const db = getFirestore();
    // const deletedRes = deletedReservations.find((res) => res.id === docId);
    const docSnap = await getDoc(doc(db, "recentlyDeletedReservations", docId));
    let deletedRes = docSnap.data();
    if (deletedRes.deletedFrom === "Confirmed Reservations") {
      delete deletedRes.expires;
      delete deletedRes.deletedFrom;
      delete deletedRes.id;

      await addDoc(collection(db, "confirmedReservations"), deletedRes);
      await deleteDoc(doc(db, "recentlyDeletedReservations", docId));
    }

    if (deletedRes.deletedFrom === "Reservation Requests") {
      delete deletedRes.expires;
      delete deletedRes.deletedFrom;
      delete deletedRes.id;
      await addDoc(collection(db, "reservationRequests"), deletedRes);
      await deleteDoc(doc(db, "recentlyDeletedReservations", docId));
    }

    setDeletedReservations((prevState) => {
      return [...prevState].filter((res) => res.id !== docId);
    });

    flashMessageHandler({ message: "Item Recovered ", success: true });
  };

  return (
    <div className={styles["deleted-reservations-container"]}>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <p className={styles.note}>
        Time Remaining shows time before deletion. After that time, items will be permanently deleted.
      </p>
      {isPending ? (
        "Loading..."
      ) : deletedReservations.length > 0 ? (
        <table className={styles.reservations}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Date/time</th>
              <th>Number of People</th>
              <th>Type of Service</th>
              <th>Table Number</th>
              <th>Time Remaining</th>
              <th>Deleted From</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {deletedReservations.map((data) => {
              return (
                <DeletedReservationsRow
                  key={uuidv4()}
                  id={data.id}
                  name={data.fullName}
                  phone={data.phone}
                  email={data.email}
                  date={data.date}
                  expires={data.expires}
                  time={data.time}
                  people={data.numberOfPeople}
                  type={data.typeOfService}
                  deletedFrom={data.deletedFrom}
                  tableNumber={data.tableNumber}
                  onUndoDeletedReservation={onUndoDeletedReservationHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>There are no deleted reservations</p>
      )}
    </div>
  );
};

export default DeletedReservations;
