import styles from "./SaladsMobile.module.css";

const SaladsMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='salads'>
        Gjethe të Ndryshme - ajsberg, rukola, lakën, vinegrette, sirup panje dhe shkopa buke <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='salads'>
        Sallatë me Rrepë t’Kuqe - Rrepë e kuqe me arra në mjaltë, djath i bardhë dhe rukolla <span>4.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='salads'>
        Tuna Sallatë - File peshku Tuna me spanak, speca të kuq dhe patate <span>12.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='salads'>
        Cezar Sallatë - ajsberg në salcë cezar, tomatina, parmezan, buke të thekun dhe pulë
        <span>5.50</span>
      </li>
    </ul>
  );
};

export default SaladsMobile;
