import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import OverDueBooksRow from "./OverDueBooksRow";
import OverDueBooksToolbar from "./OverDueBooksToolbar";
import NextButton from "../../../../components/UI/NextButton";

import FlashMessage from "../../../../components/UI/FlashMessage";

import DeleteModal from "../../../../Modals/DeleteModal";

import styles from "./OverDueBooks.module.css";

// FIREBASE
import {
  getDocs,
  getFirestore,
  collection,
  doc,
  updateDoc,
  deleteDoc,
  Timestamp,
  getDoc,
  query,
  where,
  limit,
  startAfter,
} from "firebase/firestore";

const OverDueBooks = () => {
  const [alert, setAlert] = useState("");
  const [overDueBooks, setOverDueBooks] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [itemId, setItemId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [searchObj, setSearchObj] = useState(null);
  const [last, setLast] = useState();

  useEffect(() => {
    const searchOverdueBookHandler = async (obj) => {
      if (obj === null) {
        return;
      }
      let { searchCriteria, searchValue } = obj;

      if (searchValue === "") {
        return;
      }
      setOverDueBooks([]);
      const db = getFirestore();
      const q = query(
        collection(db, "overdueBooks"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff")
      );
      setIsPending(true);

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setIsPending(false);
          return;
        }

        querySnapshot.forEach((doc) => {
          setOverDueBooks((prevState) => {
            return [...prevState, { ...doc.data(), docId: doc.id }];
          });
        });
      } catch (error) {
        console.log(error);
      }

      setIsPending(false);
    };
    searchOverdueBookHandler(searchObj);
  }, [searchObj]);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  useEffect(() => {
    const getOverdueBooks = async () => {
      const db = getFirestore();

      const q = query(collection(db, "overdueBooks"), limit(5));
      let querySnapshot = await getDocs(q);

      // FOR PAGINATION
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLast(lastVisible);

      let overduBooksData = [];
      querySnapshot.forEach((book) => {
        overduBooksData.push(book.data());
      });
      setOverDueBooks(overduBooksData);

      setIsPending(false);
    };
    getOverdueBooks();
  }, []);

  // NEXT SET OF DATA - When we press More Button
  const showMoreItemsHandler = async () => {
    const db = getFirestore();

    const next = query(collection(db, "overdueBooks"), startAfter(last), limit(5));
    const querySnapshot = await getDocs(next);

    if (querySnapshot.empty) {
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLast(lastVisible);

    querySnapshot.forEach((doc) => {
      setOverDueBooks((prevState) => {
        return [...prevState, { ...doc.data(), docId: doc.id }];
      });
    });
  };

  const onShowConfirmReturnModalHandler = (id) => {
    setShowDeleteModal(true);
    setItemId(id);
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onConfirmBookReturnHandler = async () => {
    try {
      // Update available copies of the book in books collection.
      const db = getFirestore();

      const docRef = doc(db, "books", itemId);
      const docSnap = await getDoc(docRef);
      let copies = docSnap.data().copies;
      await updateDoc(docRef, { copies: Number(copies + 1), available: true });

      //  Remove doc from overdueBooks
      const borrowedBooksCollectionRef = collection(db, "overdueBooks");
      const q = query(borrowedBooksCollectionRef, where("docId", "==", itemId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (document) => {
        const bookRef = doc(db, "overdueBooks", document.id);
        await deleteDoc(bookRef);
      });

      // Remove it also from users account and place it in borrowingHistory. In order to do this, we need users docId, which we we placed in user object when we placed the hold. Also delete dueDate timestamp and add dateReturned timestamp
      const book = overDueBooks.filter((book) => book.docId === itemId)[0];
      const userId = book.user.docId;
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      let borrowingHistoryArr = userSnap.data().borrowingHistory;

      delete book.dueDate;
      book.dateReturned = Timestamp.now();

      borrowingHistoryArr.push(book);

      await updateDoc(userRef, { borrowed: [], borrowingHistory: borrowingHistoryArr });
    } catch (error) {
      console.log(error);
    }
    // Update UI
    setOverDueBooks((prevState) => {
      return [...prevState].filter((item) => item.docId !== itemId);
    });
    flashMessageHandler({ message: "Return Book to Shelf ", success: true });
    setShowDeleteModal(false);
    setItemId(null);
  };
  const onCancelReturnBookHandler = () => {
    setShowDeleteModal(false);
  };

  return (
    <div className={styles.container}>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <DeleteModal
        showModal={showDeleteModal}
        onConfirm={onConfirmBookReturnHandler}
        onCancel={onCancelReturnBookHandler}
        message={"Has the book been returned?"}
      />
      <OverDueBooksToolbar onChangeSearchCriteria={setSearchObj} />
      {isPending ? (
        "Loading..."
      ) : overDueBooks.length > 0 ? (
        <table className={styles["overdue-books"]}>
          <thead>
            <tr>
              <th>Book Title</th>
              <th>Book Author</th>
              <th>Book ID</th>
              <th>Due Date</th>
              <th>Borrower</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {overDueBooks.map((data) => {
              return (
                <OverDueBooksRow
                  key={uuid()}
                  id={data.id}
                  docId={data.docId}
                  title={data.title}
                  author={data.author}
                  dueDate={data.dueDate}
                  name={data.user.fullName}
                  phone={data.user.phone}
                  email={data.user.email}
                  showConfirmModal={onShowConfirmReturnModalHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : !searchObj ? (
        <p>There are no overdue books.</p>
      ) : (
        <p>No results</p>
      )}
      {searchObj || overDueBooks.length === 0 ? "" : <NextButton onClick={showMoreItemsHandler} />}
    </div>
  );
};

export default OverDueBooks;
