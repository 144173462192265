import { Fragment, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import FlashMessage from "../../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../../Modals/DeleteModal";
import DeleteBookToolbar from "./DeleteBookToolbar";
import SearchResultRow from "./SearchResultRow";
import styles from "./DeleteBook.module.css";

// FIREBASE
import {
  getDocs,
  getFirestore,
  collection,
  doc,
  query,
  where,
  deleteDoc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { deleteObject, ref, getStorage } from "firebase/storage";

const DeleteBook = () => {
  const [alert, setAlert] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [searchObj, setSearchObj] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [bookId, setBookId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const findBookToDelete = async (obj) => {
      setSearchData([]);
      if (obj === null) {
        return;
      }

      let { searchCriteria, searchValue } = obj;

      if (searchValue === "") {
        return;
      }

      const db = getFirestore();
      let q;

      if (searchCriteria === "id") {
        q = query(
          collection(db, "books"),
          where(searchCriteria, "array-contains", searchValue.toLowerCase())
        );
      } else {
        q = query(
          collection(db, "books"),
          where(searchCriteria, ">=", searchValue.toLowerCase()),
          where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff")
        );
      }
      setIsPending(true);

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setIsPending(false);
          return;
        }

        querySnapshot.forEach((doc) => {
          setSearchData((prevState) => {
            return [...prevState, { ...doc.data(), docId: doc.id }];
          });
        });
      } catch (error) {
        console.log(error);
      }

      setIsPending(false);
    };
    findBookToDelete(searchObj);
  }, [searchObj]);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const deleteBookHandler = (id) => {
    setBookId(id);
    setShowDeleteModal(true);
  };

  const onConfirmDeleteBookHandler = async () => {
    const db = getFirestore();
    const storage = getStorage();

    let bookRef = doc(db, "books", bookId);
    let bookSnap = await getDoc(bookRef);

    let imgRef = bookSnap.data().imageRef;

    if (imgRef) {
      // Delete bookcover image associate with the book doc in books collection
      // Create a reference to the file to delete
      const storedImgRef = ref(storage, imgRef);

      // Delete the file
      deleteObject(storedImgRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error);
        });
    }
    // Decrement counters
    const docRef = doc(db, "bookCategoryCounters", "counters");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let categoryArr = bookSnap.data().category;
      let countersObj = docSnap.data();

      categoryArr.forEach((category) => {
        countersObj[category] -= 1;
      });
      await setDoc(docRef, countersObj);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

    await deleteDoc(bookRef);
    setSearchData((prevState) => {
      return [...prevState].filter((book) => book.docId !== bookId);
    });
    setShowDeleteModal(false);
    flashMessageHandler({ message: "Book Deleted from Library", success: true });
  };

  const onCancelDeleteBookHandler = () => {
    setShowDeleteModal(false);
    setBookId("");
  };
  return (
    <Fragment>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <DeleteModal
        showModal={showDeleteModal}
        onConfirm={onConfirmDeleteBookHandler}
        onCancel={onCancelDeleteBookHandler}
        message={"Are you sure you want to delete this book?"}
      />
      <DeleteBookToolbar onChangeSearchCriteria={setSearchObj} />
      {isPending ? (
        "Loading..."
      ) : searchData.length > 0 ? (
        <table className={styles.books}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Author</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {searchData.map((data) => {
              return (
                <SearchResultRow
                  key={uuid()}
                  id={data.id}
                  docId={data.docId}
                  title={data.title}
                  author={data.author}
                  onDeleteBook={deleteBookHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : !searchObj ? (
        <p>Search for a book...</p>
      ) : (
        <p>No results</p>
      )}
    </Fragment>
  );
};

export default DeleteBook;
