import styles from "./CocktailsMobile.module.css";

const CocktailsMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0'>
        Mimosa - Prosecco, Orange Juice
        <span>5.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1'>
        Vodka Sour - Vodka, Passion Fruit, Lime, Orange Juice<span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2'>
        Basil Smash - Gin, Lime, Sugar, Basil <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3'>
        Amaretto Sour - Amareto, Lime, Sugar <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Whiskey Sour - Scotch Whiskey, Lime, Sugar <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='5'>
        Espresso Martini - Vodka, Kahlua, Espresso <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='6'>
        Vodka Martini - Vodka, Dry Martini<span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Dry Martini - Gin, Dry Martini <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Margarita - Tequila, Lime, Triple Sec - Gin, Dry Martini <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Manhattan - Whiskey, Martini Rosso, Angostura Bitters <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Cosmopolitan - Vodka, Lime, Triple Sec, Cranberry Juice <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Tom Collins - Gin, Lime, Sugar, Sparkling Water <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Bitter Almond - Amaretto, Lime, Sugar, Old Fashioned Bitters <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Mojito - Rum, Lime, Sugar, Mint <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Negroni - Gin, Campari, Martini Rosso <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        White Russian - Vodka, Kahlua, Heavy Cream <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Aperol Spritz - Aperol, Prosecco, Sparkling Water <span>6.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Long Island Ice Tea - Vodka, Gin, Tequila, Rum, Triple Sec, Lime, Cola<span>7.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7'>
        Old Fashioned - Bourbon, Sugar, Old Fashioned Bitters<span>7.00</span>
      </li>
    </ul>
  );
};

export default CocktailsMobile;
