import img1 from "../../../../assets/images/foodAndDrinks/menuPhotos/fromTheSea/1.jpg";
import img2 from "../../../../assets/images/foodAndDrinks/menuPhotos/fromTheSea/2.jpg";
import img3 from "../../../../assets/images/foodAndDrinks/menuPhotos/fromTheSea/3.jpg";

export const FromTheSeaImages = [
  {
    url: img1,
    alt: "A whole grilled fish on a plate with a side of potato salad and a charred lemon half",
  },
  {
    url: img2,
    alt: "Deep fried fish, golden and crunchy, with a drizzle of brown balsamic sauce and a side of fried potator wedges and two ramikne filled with white and green sauces",
  },
  {
    url: img3,
    alt: "Dark and shiny glazed chciken on top of orange red potato mash and small amount of sauteed green spinach leaves",
  },
];
