import styles from "./MainsDesktop.module.css";

const MainsDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <div className={styles["main-section"]}>
        <li className={styles.item} onClick={props.onShowModalHandler} category='fromTheSea' data-index='0'>
          Koce Fish w. oregano,lemon,garlic,red onion in extra virgin olive oil,tomatini...........11.00
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} category='fromTheSea' data-index='1'>
          Fish n' Chips w. homemade potato fries, mushy peas, tartar sauce and balsamic cream ..... 7.50
        </li>
      </div>
      <div className={styles["main-section"]}>
        <h3>CHICKEN</h3>
        <li className={styles.item} onClick={props.onShowModalHandler} category='fromTheSea' data-index='2'>
          Chicken marinated in honey, sweet mashed potatoes and spinach............................ 6.00
        </li>
      </div>
    </ul>
  );
};

export default MainsDesktop;
