import React from "react";
import { NavLink } from "react-router-dom";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import styles from "./SideNavBar.module.css";

const SideNavBar = () => {
  return (
    <nav className={styles.navbar}>
      <NavLink to='/about' className={(navData) => (navData.isActive ? styles.active : "")}>
        <MultiLingualContent contentId='navigation' prop='navLink1' />
      </NavLink>
      <NavLink to='/events' className={(navData) => (navData.isActive ? styles.active : "")}>
        <MultiLingualContent contentId='navigation' prop='navLink2' />
      </NavLink>
      {/* <NavLink to='/reservations' className={(navData) => (navData.isActive ? styles.active : "")}>
        <MultiLingualContent contentId='navigation' prop='navLink3' />
      </NavLink> */}
      <NavLink to='/menusAndDrinks' className={(navData) => (navData.isActive ? styles.active : "")}>
        <MultiLingualContent contentId='navigation' prop='navLink4' />
      </NavLink>
      <NavLink to='/booksAndRecords' className={(navData) => (navData.isActive ? styles.active : "")}>
        <MultiLingualContent contentId='navigation' prop='navLink5' />
      </NavLink>
      <NavLink to='/careers' className={(navData) => (navData.isActive ? styles.active : "")}>
        <MultiLingualContent contentId='navigation' prop='navLink6' />
      </NavLink>
      <NavLink to='/contact' className={(navData) => (navData.isActive ? styles.active : "")}>
        <MultiLingualContent contentId='navigation' prop='navLink7' />
      </NavLink>
      <NavLink to='/gallery' className={(navData) => (navData.isActive ? styles.active : "")}>
        <MultiLingualContent contentId='navigation' prop='navLink8' />
      </NavLink>
    </nav>
  );
};

export default SideNavBar;
