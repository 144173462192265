import Trash from "../../../../assets/icons/Trash";

import styles from "./BorrowedBooksRow.module.css";

const BorrowedBooksRow = (props) => {
  let options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  const dueDate = props.dueDate.toDate().toLocaleString("default", options);
  return (
    <tr className={styles.tr}>
      <td data-label='BOOK TITLE'>{props.title}</td>
      <td data-label='BOOK AUTHOR'>{props.author}</td>
      <td data-label='BOOK ID'>{props.id}</td>
      <td data-label='DUE DATE'>{dueDate}</td>
      <td data-label='BORROWER'>{props.name}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.showConfirmModal(props.docId)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default BorrowedBooksRow;
