import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import FlashMessage from "../../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../../Modals/DeleteModal";

import RecentlyAddedBooksRow from "./RecentlyAddedBooksRow";
import styles from "./RecentlyAddedBooksList.module.css";

// FIREBASE
import { getDocs, getFirestore, collection, deleteDoc, doc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

const RecentlyAddedBooksList = () => {
  const [alert, setAlert] = useState("");
  const [recentlyAddedBooks, setRecentlyAddedBooks] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [bookId, setBookId] = useState(null);

  useEffect(() => {
    // Connect to firestore
    const getRecentlyAddedBooks = async () => {
      setIsPending(true);
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, "recentlyAddedBooks"));

      const recentBooksArray = [];
      querySnapshot.forEach(async (book) => {
        let now = new Date();
        let expires = book.data().expires;
        if (now.getTime() < expires.toMillis()) {
          recentBooksArray.push({ ...book.data(), docId: book.id });
        } else {
          await deleteDoc(doc(db, "recentlyAddedBooks", book.id));
          const storage = getStorage();
          const bookCoverImgRef = ref(storage, book.data().imageRef);

          // Delete the file
          deleteObject(bookCoverImgRef)
            .then(() => {
              // File deleted successfully
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
              console.log(error);
            });
        }
      });
      setRecentlyAddedBooks(recentBooksArray);
      setIsPending(false);
    };
    getRecentlyAddedBooks();
  }, []);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onConfirmHandler = (bookId) => {
    setShowDeleteModal(true);
    setBookId(bookId);
  };

  const onCancelHandler = () => {
    setShowDeleteModal(false);
  };

  const onDeleteRecentlyAddedBookHandler = async () => {
    // Delete doc from upcomingEvents collection
    const db = getFirestore();
    await deleteDoc(doc(db, "recentlyAddedBooks", bookId));

    // delete image from recentlyAddedBooks folder in storage

    const book = recentlyAddedBooks.find((book) => book.docId === bookId);
    const storage = getStorage();
    const imageRef = ref(storage, book.imageRef);
    if (book.imageRef) {
      // Delete the file
      deleteObject(imageRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    }

    setRecentlyAddedBooks((prevState) => {
      return prevState.filter((book) => book.docId !== bookId);
    });
    flashMessageHandler({ message: "Book Deleted ", success: true });
    setShowDeleteModal(false);
    setBookId(null);
  };

  return (
    <div className={styles["upcoming-events-container"]}>
      <DeleteModal
        message={"Are you sure you want to delete this book?"}
        showModal={showDeleteModal}
        onConfirm={onDeleteRecentlyAddedBookHandler}
        onCancel={onCancelHandler}
      />
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      {isPending ? (
        "Loading..."
      ) : recentlyAddedBooks.length > 0 ? (
        <table className={styles.books}>
          <thead>
            <tr>
              <th>Title</th>
              <th>Author</th>
              <th>Date Added</th>
              <th>Expires</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {recentlyAddedBooks.map((data) => {
              return (
                <RecentlyAddedBooksRow
                  key={uuid()}
                  id={data.id}
                  docId={data.docId}
                  title={data.title}
                  author={data.author}
                  dateAdded={data.dateAdded}
                  expires={data.expires}
                  onDeleteEvent={onConfirmHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>There are no recently added books</p>
      )}
    </div>
  );
};

export default RecentlyAddedBooksList;
