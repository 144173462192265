import React from "react";
import { NavLink } from "react-router-dom";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import Library from "../../assets/icons/Library";
import Books from "../../assets/icons/Books";
import RecordPlayer from "../../assets/icons/RecordPlayer";

import styles from "./BooksAndRecordsNav.module.css";

const BooksAndRecordsNav = () => {
  return (
    <div className={styles.container}>
      <nav className={styles.nav}>
        <NavLink
          to='/booksAndRecords/library'
          className={(navData) => (navData.isActive ? styles.active : "")}>
          <button className={styles["nav-btn"]}>
            <Library />
            <span>
              <MultiLingualContent contentId='booksAndRecords' prop='navLink1' />
            </span>
          </button>
        </NavLink>
        <NavLink to='/booksAndRecords/books' className={(navData) => (navData.isActive ? styles.active : "")}>
          <button className={styles["nav-btn"]}>
            <Books />
            <span>
              {" "}
              <MultiLingualContent contentId='booksAndRecords' prop='navLink2' />
            </span>
          </button>
        </NavLink>
        <NavLink
          to='/booksAndRecords/records'
          className={(navData) => (navData.isActive ? styles.active : "")}>
          <button className={styles["nav-btn"]}>
            <RecordPlayer />
            <span>
              <MultiLingualContent contentId='booksAndRecords' prop='navLink3' />
            </span>
          </button>
        </NavLink>
      </nav>
    </div>
  );
};

export default BooksAndRecordsNav;
