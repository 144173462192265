import styles from "./Download.module.css";

const Download = () => {
  return (
    <svg className={styles.svg} version='1.1' id='圖層_1' x='0' y='0' viewBox='0 0 100 100'>
      <path
        d='M76.5 90h-53C16 90 10 84 10 76.5v-53C10 16 16 10 23.5 10h53C84 10 90 16 90 23.5v53C90 84 84 90 76.5 90z'
        fill='#333'
      />
      <g>
        <path
          d='M65.1 70.6H34.9c-1.1 0-2-.9-2-2v-9.2c0-1.1.9-2 2-2s2 .9 2 2v7.2h26.2v-7.2c0-1.1.9-2 2-2s2 .9 2 2v9.2c.1 1.1-.8 2-2 2z'
          fill='#b3b3b3'
        />
      </g>
      <path
        id='XMLID_20_'
        d='M66.7 47L51.9 61.9c-1 1-2.7 1-3.7 0L33.3 47c-1-1-1-2.7 0-3.7s2.7-1 3.7 0l10.4 10.4V31c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6v22.7L63 43.3c1-1 2.7-1 3.7 0 1.1 1 1.1 2.7 0 3.7z'
        fill='#fff'
      />
    </svg>
  );
};

export default Download;
