import React, { Fragment, useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import styles from "./ItemCategoriesNavBooks.module.css";
import { doc, getFirestore, getDoc } from "firebase/firestore";
const ItemCategoriesNavBooks = (props) => {
  let str = JSON.parse(window.sessionStorage.getItem("searchCategoryBooks"));

  const [active, setActive] = useState(str ? str.toUpperCase() : null);
  const [counterObj, setCouterObj] = useState(null);

  const [sortedCategories, setSortedCategories] = useState([]);

  const searchByCategoryHandler = (cat) => {
    setActive(cat.category);
    props.onSubmit(cat.queryString);
    window.sessionStorage.setItem("searchCategoryBooks", JSON.stringify(cat.category));
  };

  useEffect(() => {
    async function getCounters() {
      let db = getFirestore();
      const docRef = doc(db, "bookCategoryCounters", "counters");
      const docSnap = await getDoc(docRef);

      let counterObj = docSnap.data();
      setCouterObj(counterObj);
    }
    getCounters();
  }, []);

  useEffect(() => {
    if (counterObj) {
      let sortedArr = props.categories
        .map((cat) => {
          return { ...cat, numberOfItems: counterObj[cat.queryString] };
        })
        .sort((a, b) => {
          return b.numberOfItems - a.numberOfItems;
        });
      setSortedCategories(sortedArr);
    }
  }, [counterObj, props.categories]);

  return (
    <Fragment>
      <div className={styles["nav-container"]}>
        <nav className={styles.navigation}>
          <div className={styles["close-btn"]} onClick={props.onCancel}>
            <span className={styles.one}></span>
            <span className={styles.two}></span>
          </div>
          <div className={styles["categories-grid"]}>
            {sortedCategories.map((cat) => {
              return (
                <div key={uuidv4()}>
                  <button
                    className={`${styles["nav-btn"]} ${active === cat.category && styles.active}`}
                    onClick={() => {
                      searchByCategoryHandler(cat);
                    }}>
                    <span>
                      {cat.buttonLabel} {cat.numberOfItems}
                    </span>
                  </button>
                </div>
              );
            })}
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default ItemCategoriesNavBooks;
