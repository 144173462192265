import { Fragment } from "react";
import ReactDOM from "react-dom";

import UpdateRecordForm from "../pages/AdminPages/AdminRecordsPage/RecordsCRUD/UpdateRecord/UpdateRecordForm";

import styles from "./UpdateRecordModal.module.css";

const Backdrop = (props) => {
  const cssClasses = [styles.backdrop, props.show ? styles.backdropOpen : styles.backdropClosed];
  return <div className={cssClasses.join(" ")} />;
};

const Modal = (props) => {
  const cssClasses = [styles.modal, props.show ? styles.modalOpen : styles.modalClosed];
  return (
    <div className={cssClasses.join(" ")}>
      <div className={styles["modal-header"]}>
        <h2>Update Record</h2>
        <div className={styles["close-modal"]} onClick={props.onCancel}>
          <svg width='20' height='21' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <line x1='1.35355' y1='0.646447' x2='25.3536' y2='24.6464' stroke='black'></line>
            <line x1='0.646447' y1='24.6464' x2='24.6464' y2='0.646448' stroke='black'></line>
          </svg>
        </div>
      </div>
      <div className={styles["modal-body"]}>
        <UpdateRecordForm
          onCancel={props.onCancel}
          record={props.record}
          editRecord={props.editRecord}
          tracklist={props.tracklist}
          onSubmit={props.onSubmit}
          setImgUrl={props.setImgUrl}
          setImgName={props.setImgName}
          setChecklistOptions={props.setChecklistOptions}
          checklistOptions={props.checklistOptions}
          isRequired={props.isRequired}
        />
      </div>
    </div>
  );
};

const UpdateRecordModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop show={props.show} />, document.getElementById("backdrop-root"))}
      {ReactDOM.createPortal(
        <Modal
          show={props.show}
          onConfirm={props.onConfirm}
          onCancel={props.onCancel}
          onSubmit={props.onSubmit}
          record={props.record}
          editRecord={props.editRecord}
          tracklist={props.tracklist}
          setImgUrl={props.setImgUrl}
          setImgName={props.setImgName}
          setChecklistOptions={props.setChecklistOptions}
          checklistOptions={props.checklistOptions}
          isRequired={props.isRequired}
        />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default UpdateRecordModal;
