import styles from "./DessertsMobile.module.css";

const DessertsMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <h3 className={styles["category-title"]}>DESSERTS</h3>
      <div className={styles.section}>
        {" "}
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='desserts'>
          Cheesecake <span>3.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='desserts'>
          Brownie <span>3.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='desserts'>
          Chocolate Fondue <span>3.50</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='desserts'>
          Apple Crumble <span>3.50</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='desserts'>
          Krem Katalana <span>2.50</span>
        </li>
      </div>
      <h3 className={styles["category-title"]}>MILKSHAKES</h3>
      <div className={styles.section}>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='desserts'>
          Oreo Milkshake <span>3.50</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='desserts'>
          Salty Caramel Pretzel <span>3.50</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='desserts'>
          Vanilla Milkshake <span>3.50</span>
        </li>
      </div>
    </ul>
  );
};

export default DessertsMobile;
