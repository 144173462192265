import styles from "./SoftDrinksMobile.module.css";

const SoftDrinksMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0'>
        Fresh Apple
        <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1'>
        Fresh Carrot<span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2'>
        Fresh Orange <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3'>
        Fresh Lemonade <span>3.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Fresh Mix <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='5'>
        Homemade Ice Tea <span>2.00</span>
      </li>
    </ul>
  );
};

export default SoftDrinksMobile;
