import { useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import Globe from "../../assets/icons/Globe";
import styles from "./LanguageButton.module.css";

const LanguageButton = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);

  return (
    <>
      <button className={styles["button-16"]} onClick={toggleLanguage}>
        <Globe />
        {language === "albanian" ? "AL" : "EN"}
      </button>
    </>
  );
};

export default LanguageButton;
