import React from "react";
import { NavLink } from "react-router-dom";

import { MultiLingualContent } from "../../contexts/Language//MultiLingualContent";
import Kitchen from "../../assets/icons/Kitchen";
import Bowl from "../../assets/icons/Bowl";
import Bottle from "../../assets/icons/Bottle";

import styles from "./MenusAndDrinksNav.module.css";

const MenusAndDrinksNav = () => {
  return (
    <div className={styles.container}>
      <nav className={styles.nav}>
        <NavLink
          to='/menusAndDrinks/kitchen'
          className={(navData) => (navData.isActive ? styles.active : "")}>
          <button className={styles["nav-btn"]}>
            <Kitchen />
            <span>
              <MultiLingualContent contentId='foodAndDrinks' prop='navLink1' />
            </span>
          </button>
        </NavLink>
        <NavLink to='/menusAndDrinks/food' className={(navData) => (navData.isActive ? styles.active : "")}>
          <button className={styles["nav-btn"]}>
            <Bowl />
            <span>
              <MultiLingualContent contentId='foodAndDrinks' prop='navLink2' />
            </span>
          </button>
        </NavLink>
        <NavLink to='/menusAndDrinks/drinks' className={(navData) => (navData.isActive ? styles.active : "")}>
          <button className={styles["nav-btn"]}>
            <Bottle />
            <span>
              <MultiLingualContent contentId='foodAndDrinks' prop='navLink3' />
            </span>
          </button>
        </NavLink>
      </nav>
    </div>
  );
};

export default MenusAndDrinksNav;
