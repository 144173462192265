import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import BorrowBookSearchBar from "./BorrowBookSearchBar";
import SearchResult from "./SearchResult";
import BorrowedBook from "./BorrowedBook";
import MemberDetails from "./MemberDetails";
import styles from "./BorrowBookForm.module.css";

// FIREBASE
import { getDocs, getFirestore, collection, doc, getDoc, query, where, Timestamp } from "firebase/firestore";

const BorrowBookForm = (props) => {
  const [bookToBorrow, setBookToBorrow] = useState(null);
  const [searchResult, setSearchResult] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [searchObj, setSearchObj] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [alert, setAlert] = useState("");

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setSearchResult([]);
        setBookToBorrow(null);
        setNoResult(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  useEffect(() => {
    const findBookHandler = async (obj) => {
      setBookToBorrow(null);
      setSearchResult([]);
      setAlert("");
      if (obj === null) {
        return;
      }
      let { searchCriteria, searchValue } = obj;

      if (searchValue === "") {
        return;
      }

      const db = getFirestore();
      let q;

      if (searchCriteria === "id") {
        q = query(
          collection(db, "books"),
          where(searchCriteria, "array-contains", searchValue.toLowerCase())
        );
      } else {
        q = query(
          collection(db, "books"),
          where(searchCriteria, ">=", searchValue.toLowerCase()),
          where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff")
        );
      }

      setIsPending(true);

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setIsPending(false);
          setNoResult(true);
          return;
        }

        querySnapshot.forEach((doc) => {
          setSearchResult((prevState) => {
            return [...prevState, { ...doc.data(), docId: doc.id }];
          });
        });
      } catch (error) {
        console.log(error);
      }

      setIsPending(false);
    };
    findBookHandler(searchObj);
  }, [searchObj]);

  // Check if user has already borrowed a book. If yes, disable borrow button and inform admin

  const { docId } = props.memberDetails;

  useEffect(() => {
    setDisabled(false);
    if (docId) {
      const getUserDoc = async () => {
        const db = getFirestore();
        const userSnap = await getDoc(doc(db, "users", docId));
        const borrowed = userSnap.data().borrowed;
        if (borrowed.length > 0) {
          setDisabled(true);
        }
      };
      getUserDoc();
    }
  }, [docId]);

  const onCancelModalHandler = () => {
    setSearchResult([]);
    setBookToBorrow(null);
    setNoResult(false);
    props.onCancel();
  };

  // Here we should check: does book have more than one copy? If no do nothing. If yes: We need to check whether any of the copies has been borrowed and which one.(what id)
  const onConfirmBookHandler = async (docId) => {
    const book = searchResult.filter((book) => book.docId === docId)[0];
    delete book.copies;
    book.id = searchObj.searchValue.toLowerCase().trim(); // this is not so good
    console.log(book);
    setBookToBorrow(book);
    setSearchResult([]);
  };

  const onRemoveBookHandler = () => {
    setBookToBorrow(null);
  };

  const onSubmitBookHandler = (ev) => {
    ev.preventDefault();

    if (bookToBorrow === null) {
      setAlert("Please search and select a book to borrow");
      return;
    }
    // create dateBorrowed and dueDate
    let now = new Date();
    now.setDate(now.getDate() + 31);

    const borrower = props.memberDetails;
    // delete unneeded fields
    delete borrower.depositAmount;
    delete bookToBorrow.available;

    const borrowedBook = {
      ...bookToBorrow,
      dateBorrowed: Timestamp.now(),
      dueDate: Timestamp.fromDate(now),
      user: { ...borrower },
    };
    props.onSubmit(borrowedBook);
    setBookToBorrow(null);
    // Disable borrow button for the current user, since they have borrowed one book
    setDisabled(true);
    setAlert("");
  };

  return (
    <form onSubmit={onSubmitBookHandler}>
      <MemberDetails member={props.memberDetails} />
      {isPending ? (
        "Loading..."
      ) : bookToBorrow ? (
        <BorrowedBook book={bookToBorrow} onRemove={onRemoveBookHandler} />
      ) : (
        ""
      )}
      <BorrowBookSearchBar setSearchObj={setSearchObj} />
      {isPending ? (
        "Searching..."
      ) : searchResult.length ? (
        searchResult.map((book) => {
          return (
            <SearchResult
              key={uuidv4()}
              id={book.id}
              docId={book.docId}
              title={book.title}
              author={book.author}
              available={book.available}
              onConfirm={onConfirmBookHandler}
            />
          );
        })
      ) : noResult ? (
        <p className={styles.noResult}>Could not find the book</p>
      ) : (
        ""
      )}
      {disabled && (
        <small className={styles.note}>
          NOTE: Member has already borrowed a book. Not allowed to borrow another one until book is returned.
        </small>
      )}
      <div className={styles["modal-actions"]}>
        <button type='button' className={styles["btn-secondary"]} onClick={onCancelModalHandler}>
          Cancel
        </button>
        <button type='submit' className={styles["btn-primary"]} disabled={disabled}>
          Borrow
        </button>
      </div>
      <small>{alert}</small>
    </form>
  );
};

export default BorrowBookForm;
