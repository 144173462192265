import React, { useState, useEffect } from "react";
import styles from "./DjGenresCheckBoxGroup.module.css";
import genres from "./djGenresData";

const DjGenresCheckboxGroup = (props) => {
  const [checkedState, setCheckedState] = useState(new Array(genres.length).fill(false));
  const [selectedDjGenres, setSelectedDjGenres] = useState([]);
  const [checkBoxCounter, setcheckBoxCounter] = useState(0);

  useEffect(() => {
    props.selectedDjGenres(selectedDjGenres);
  }, [selectedDjGenres]);

  const checkboxOnChange = (position, e) => {
    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));

    const isChecked = e.target.checked;
    setcheckBoxCounter((prevState) => {
      return isChecked === true ? prevState + 1 : prevState - 1;
    });

    setCheckedState(updatedCheckedState);

    const genre = e.target.defaultValue;

    setSelectedDjGenres((prevState) => {
      return [...prevState, genre];
    });
  };

  return (
    <div className={styles["checkbox-group"]}>
      <fieldset>
        <legend>
          DJ Music Genres
          <small>(Select maximum 3 genres)</small>
        </legend>
        <div className={styles["genres-grid"]}>
          {genres.map((obj, index) => {
            return (
              <label htmlFor={`custom-checkbox-${index}`} key={index}>
                <span>{obj.genre.toUpperCase()}</span>
                <input
                  type='checkbox'
                  id={`custom-checkbox-${index}`}
                  name={obj.genre.toUpperCase()}
                  value={obj.genre.toUpperCase()}
                  checked={checkedState[index]}
                  onChange={(e) => checkboxOnChange(index, e)}
                  disabled={checkBoxCounter === 3 && checkedState[index] !== true ? true : false}></input>
              </label>
            );
          })}
        </div>
      </fieldset>
    </div>
  );
};

export default DjGenresCheckboxGroup;
