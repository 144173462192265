import React, { useState, useEffect, useRef } from "react";
import { v4 as uuid } from "uuid";
import FlashMessage from "../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../Modals/DeleteModal";
import DateFilterModal from "../../../../Modals/DateFilterModal";
import JobApplicationsToolBar from "./JobApplicationsToolBar";
import JobApplicationsRow from "./JobApplicationsRow";
import styles from "./JobApplications.module.css";

// FIREBASE
import {
  getDocs,
  getFirestore,
  collection,
  deleteDoc,
  doc,
  query,
  where,
  Timestamp,
  addDoc,
} from "firebase/firestore";
import PrintableJobApplications from "./PrintableJobApplications";

const JobApplications = () => {
  const [jobApplications, setJobApplications] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [applicationId, setApplicationId] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [alert, setAlert] = useState("");

  const [sortType, setSortType] = useState("");
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState("");
  const [dateInputRef, setDateInputRef] = useState();

  // A ref which is forwarded to the toolbar where print button is located
  const jobApplicationsRef = useRef();

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDateFilter(false);
        setShowDeleteModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  // *** SORTING NON DATE FILTERED DATA - CODE STARTS HERE ***
  useEffect(() => {
    const sortReservations = (type) => {
      const types = {
        "name-ascending": "fullName",
        "name-descending": "fullName",
      };

      const sortProperty = types[type];

      function byName(a, b) {
        if (a[sortProperty].toLowerCase() > b[sortProperty].toLowerCase()) {
          return 1;
        } else if (b[sortProperty] > a[sortProperty]) {
          return -1;
        } else {
          return 0;
        }
      }

      if (type === "name-ascending") {
        setJobApplications((prevState) => {
          return [...prevState].sort(byName);
        });
      }

      if (type === "name-descending") {
        setJobApplications((prevState) => {
          return [...prevState].sort(byName).reverse();
        });
      }
    };

    sortReservations(sortType);
  }, [sortType]);

  useEffect(() => {
    const getJobApplications = async () => {
      const db = getFirestore();
      setIsPending(true);
      setJobApplications([]);

      if (dateFilter) {
        let date = Timestamp.fromDate(new Date(dateFilter));

        const q = query(collection(db, "jobApplications"), where("interviewDate", "==", date));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          setJobApplications((prevState) => {
            return [...prevState, { ...doc.data(), id: doc.id }];
          });
        });
      } else {
        const q = query(collection(db, "jobApplications"));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (document) => {
          // Check to see interview date. If current time is bigger than interview date at midnight, delete it from job applications
          let now = Date.now();
          let interviewDate = document.data().interviewDate.toDate().toISOString().split("T")[0];
          const jobApplicatonExpires = new Date(interviewDate + ",23:58:00").getTime();

          if (now > jobApplicatonExpires) {
            await deleteDoc(doc(db, "jobApplications", document.id));
          } else {
            setJobApplications((prevState) => {
              return [...prevState, { ...document.data(), id: document.id }];
            });
          }
        });
      }

      setSortType("name-ascending");
      setIsPending(false);
    };

    getJobApplications();
  }, [dateFilter]);

  const onShowDateModalHandler = () => {
    setShowDateFilter(true);
  };

  const clearDateFilterHandler = () => {
    setDateFilter("");
    // clearing the date input by setting the value of ref to an empty string. This worked to my surprise, and Im not sure if this is good practice.
    setDateInputRef((prevState) => (!prevState ? prevState : (prevState.current.value = "")));
  };

  const onSubmitFilterByDateHandler = (ev, ref) => {
    ev.preventDefault();
    setDateFilter(ref.current.value);
    // Im holding onto date input reaf, to the input element so I can clear it when user clears date filter
    setDateInputRef(ref);
    setShowDateFilter(false);
  };

  const onCancelFilterByDateHandler = () => {
    setShowDateFilter(false);
  };

  // *** DELETE Job Application ***
  // Open delete application confirmation modal
  const onConfirmDeleteHandler = (reservationId) => {
    setShowDeleteModal(true);
    setApplicationId(reservationId);
  };
  const onCancelDeleteHandler = (reservationId) => {
    setShowDeleteModal(false);
  };

  // Delete job application and remove modal
  const onDeleteJobApplicationHandler = async () => {
    const db = getFirestore();

    await deleteDoc(doc(db, "jobApplications", applicationId));

    setJobApplications((prevState) => {
      return prevState.filter((application) => application.id !== applicationId);
    });

    flashMessageHandler({ message: "Application Deleted", success: true });
    setShowDeleteModal(false);
    setApplicationId(null);
  };

  const onSaveContactHandler = async (id) => {
    const db = getFirestore();
    let application = jobApplications.find((application) => application.id === id);

    const { fullName, email, phone, role, typeOfRole } = application;

    let contact = {
      fullName,
      email,
      phone,
      role,
      typeOfRole,
    };

    await addDoc(collection(db, "applicantContacts"), contact);
    flashMessageHandler({ message: "Contact Saved", success: true });
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };
  return (
    <div className={styles["job-applications-container"]}>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <DeleteModal
        message={"Are you sure you want to delete this job application?"}
        showModal={showDeleteModal}
        onConfirm={onDeleteJobApplicationHandler}
        onCancel={onCancelDeleteHandler}
      />
      <DateFilterModal
        show={showDateFilter}
        onSubmit={onSubmitFilterByDateHandler}
        onCancel={onCancelFilterByDateHandler}
      />
      <JobApplicationsToolBar
        onChangeSort={setSortType}
        onShowDateModal={onShowDateModalHandler}
        clearDateFilter={clearDateFilterHandler}
        ref={jobApplicationsRef}
        sortType={sortType}
      />
      {isPending ? (
        "Loading..."
      ) : jobApplications.length > 0 ? (
        <table className={styles["job-applications"]}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Type of Role</th>
              <th>Applying for</th>
              <th>Interview Date</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {jobApplications.map((data) => {
              return (
                <JobApplicationsRow
                  key={uuid()}
                  id={data.id}
                  name={data.fullName}
                  phone={data.phone}
                  email={data.email}
                  date={data.interviewDate}
                  role={data.role}
                  typeOfRole={data.typeOfRole}
                  onSaveContact={onSaveContactHandler}
                  onDeleteJobApplication={onConfirmDeleteHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>There are no job applications</p>
      )}
      <div style={{ display: "none" }}>
        <PrintableJobApplications jobApplications={jobApplications} ref={jobApplicationsRef} />
      </div>
    </div>
  );
};

export default JobApplications;
