import { useState } from "react";
import AddReservationForm from "./AddReservationForm";
import FlashMessage from "../../../../components/UI/FlashMessage";
import styles from "./AddReservation.module.css";
import { addDoc, collection, getFirestore } from "firebase/firestore";

const AddReservation = () => {
  const [alert, setAlert] = useState("");
  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };
  const onSubmitHandler = async (reservation) => {
    // add to confirmedReservations collection in firestore
    const db = getFirestore();
    await addDoc(collection(db, "confirmedReservations"), reservation);
    flashMessageHandler({ message: "Reservation Added ", success: true });
  };
  return (
    <div className={styles.container}>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <div className={styles.body}>
        <AddReservationForm onSubmit={onSubmitHandler} />
      </div>
    </div>
  );
};

export default AddReservation;
