import Trash from "../../../../assets/icons/Trash";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";

import styles from "./ApplicantContactsRow.module.css";

const ApplicantContactsRow = (props) => {
  return (
    <tr className={styles.tr}>
      <td data-label='NAME'>{capitalizeFirstLetter(props.name)}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='TYPE OF ROLE'>{props.typeOfRole}</td>
      <td data-label='APPLIED FOR'>{props.role}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onDeleteContact(props.id)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default ApplicantContactsRow;
