import styles from "./PaginationButtons.module.css";

import PrevButton from "./PrevButton";
import NextButton from "./NextButton";

const PaginationButtons = (props) => {
  return (
    <div className={styles["pagination-buttons-container"]}>
      <PrevButton buttonState={props.buttonState.prevButton} onClick={props.prevPage} />
      <NextButton buttonState={props.buttonState.nextButton} onClick={props.showMoreItems} />
    </div>
  );
};

export default PaginationButtons;
