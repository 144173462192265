import img1 from "../../../../assets/images/foodAndDrinks/menuPhotos/pastaAndRice/1.jpg";
import img2 from "../../../../assets/images/foodAndDrinks/menuPhotos/pastaAndRice/2.jpg";
import img3 from "../../../../assets/images/foodAndDrinks/menuPhotos/pastaAndRice/3.jpg";
import img4 from "../../../../assets/images/foodAndDrinks/menuPhotos/pastaAndRice/4.jpg";
import img5 from "../../../../assets/images/foodAndDrinks/menuPhotos/pastaAndRice/5.jpg";
import img6 from "../../../../assets/images/foodAndDrinks/menuPhotos/pastaAndRice/6.jpg";
import img7 from "../../../../assets/images/foodAndDrinks/menuPhotos/pastaAndRice/7.jpg";
import img8 from "../../../../assets/images/foodAndDrinks/menuPhotos/pastaAndRice/8.jpg";

export const PastaAndRiceImages = [
  {
    url: img1,
    alt: "Creamy rice covered with pan fried mushrooms and shavings of parmesan on a white plate",
  },
  {
    url: img2,
    alt: "Rice with red sauce pieces of tomatoes, grated parmesan and sprinkled with parsley leaves",
  },
  {
    url: img3,
    alt: "Golden pieces of juicy chicken covered with sesame seed and sprinkled with spring onion on top of rice",
  },
  {
    url: img4,
    alt: "Square ravioli pasta with grated parmesan on top and melted butter",
  },
  {
    url: img5,
    alt: "Yellowish shpagetti on a fork in the foreground with a slice of parmesan seen from above. In the background out of focus plate of spaghetti",
  },
  {
    url: img6,
    alt: "Intricately arranged tower of sphagetti in the middle of a plate with a shrimp on top. A second larger shrimp placed on the side recling on the tower of sphagetti with shavings of parmesan and tomatoes surrounding the tower",
  },
  {
    url: img7,
    alt: "Spiral pasta with red sauce, pieces of chicken, grated parmesan and couple of green leaves on top.",
  },
  {
    url: img8,
    alt: "Oven baked pasta on a baking dish, with a fork digging in the golden crust, revealing creamy filling",
  },
];
