import { Fragment, useState, useEffect, useContext } from "react";
import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";

import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import HeartCircle from "../../../assets/icons/HeartCircle";
import LogInModal from "../../../Modals/LogInModal";
import AuthContext from "../../../contexts/AuthContext";
import { LanguageContext } from "../../../contexts/Language/LanguageContext";
import noImagePlaceHolder from "../../../assets/images/No-Image-Placeholder.png";

import FlashMessage from "../../../components/UI/FlashMessage";

import styles from "./BookDetails.module.css";

// FIREBASE
import {
  doc,
  getFirestore,
  setDoc,
  updateDoc,
  getDoc,
  Timestamp,
  addDoc,
  collection,
} from "firebase/firestore";

const BookDetails = (props) => {
  const { synopsis, available, author, title, url, docId, id, imageRef, category, copies } =
    props.bookDetails;

  const [bookBorrowedJustNow, setBookBorrowedJustNow] = useState(false);
  const [alert, setAlert] = useState("");

  const [showLogInModal, setShowLogInModal] = useState(false);
  const [isHoldDisabled, setIsHoldDisabled] = useState(false);
  const [userHasABorrowedBook, setUserHasABorrowedBook] = useState(false);
  const [isWishlistDisabled, setIsWishListDisabled] = useState(true);

  const authCtx = useContext(AuthContext);

  const { language } = useContext(LanguageContext);

  let uid;
  if (authCtx.authUser) {
    uid = authCtx.authUser.uid;
  }

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowLogInModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  const onCancelLoginModal = () => {
    setShowLogInModal(false);
  };

  const { authUser } = authCtx;

  // Disable PLACE HOLD Button if user has a book on hold or has a borrowed a book and/or bookStatus is false (book is not available)
  useEffect(() => {
    const checkIfUserCanPlaceHold = async () => {
      // User can NOT place hold if:
      // 1. book is not available or
      // 2. user has book on hold or
      // 3. user has a borrowed book

      // User can place hold if:
      // 1. book is available and user has no book on hold or borrowed
      // 2. User is not authenticated (special case: user can click hold button which prompts them to sign in)
      if (authUser) {
        const db = getFirestore();
        const userRef = doc(db, "users", uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.data().borrowed.length > 0) {
          setUserHasABorrowedBook(true);
        }

        if (userSnap.data().onHold.length > 0 || !available || userSnap.data().borrowed.length > 0) {
          setIsHoldDisabled(true);
        } else {
          setIsHoldDisabled(false);
        }
      }
      if (!authUser) {
        setIsHoldDisabled(false);
      }
    };

    checkIfUserCanPlaceHold();
  }, [uid, authUser, available]);

  // Disable wishlist button if this particular book is already on users wishlist
  useEffect(() => {
    const checkIfUserHasBookOnWishlist = async () => {
      if (authUser) {
        const db = getFirestore();
        const userRef = doc(db, "users", uid);
        const userSnap = await getDoc(userRef);
        let wishlist = userSnap.data().wishlist;

        let bookOnWishlist = wishlist.find((b) => b.docId === docId);
        if (bookOnWishlist) {
          setIsWishListDisabled(true);
        } else {
          setIsWishListDisabled(false);
        }
      }
      if (!authUser) {
        setIsWishListDisabled(false);
      }
    };
    checkIfUserHasBookOnWishlist();
  }, [uid, docId, authUser]);

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onHoldBookHandler = async () => {
    if (authCtx.authUser === null) {
      return setShowLogInModal(true);
    }

    const db = getFirestore();

    //  CHECK IF BOOK IS AVAILABLE IN CASE IT BECAME UNAVAILABLE IN THE MEANTIME SINCE WE'RE NOT USING REAL TIME UPDATES
    const bookRef = doc(db, "books", docId);
    const bookSnap = await getDoc(bookRef);

    let isAvailable = bookSnap.data().available;

    if (!isAvailable) {
      // DISABLE BUTTON
      setIsHoldDisabled(true);
      props.setSearchResult((prevState) => {
        return [...prevState].map((book) => (book.docId === docId ? { ...book, available: false } : book));
      });
      setBookBorrowedJustNow(true);
      props.setBookDetails((prevState) => {
        return { ...prevState, available: false };
      });
      return;
    }

    // Define when hold was placed and when it will expire
    let now = new Date();
    let day = now.getDay();
    // HoldExpires expires the next day, unless its saturday then we push it for an extra day
    let nextDay = new Date(
      day === 6 ? now.getTime() + 48 * 60 * 60 * 1000 : now.getTime() + 24 * 60 * 60 * 1000
    );

    const b = {
      title: title,
      author: author,
      synopsis: synopsis,
      id: id[0],
      imageRef: imageRef,
      category: category,
      docId: docId,
      holdPlaced: Timestamp.now(),
      holdExpires: Timestamp.fromDate(nextDay),
    };
    const userRef = doc(db, "users", uid);
    // Update holdRequests collection implicitly. Documents in this collection also have the user object that requested the hold
    const userSnap = await getDoc(userRef);
    const { fullName, email, phone, member } = userSnap.data();
    let docRef = await addDoc(collection(db, "holdRequests"), {
      ...b,
      user: { fullName, email, phone, member, docId: userSnap.id },
    });

    // Add book on hold in users document, by also adding docId that refers to the id of document in holdRequests collection
    await setDoc(
      userRef,
      {
        onHold: [{ ...b, holdRequestId: docRef.id }],
      },
      { merge: true }
    );
    // update number of copies available on the book document in books collection
    await updateDoc(bookRef, { copies: Number(copies) - 1 });

    // Update books collection, set available: false if it is the last copy of the book.
    if (Number(copies) === 1) {
      await updateDoc(bookRef, { available: false });
      // Update UI
      setIsHoldDisabled(true);
    }
    // Disable button since user can't place more than one hold
    setIsHoldDisabled(true);

    // update search results by setting available to false
    props.setSearchResult((prevState) => {
      return [...prevState].map((book) => (book.docId === docId ? { ...book, available: false } : book));
    });

    props.setBookDetails((prevState) => {
      return { ...prevState, available: false };
    });
    flashMessageHandler({
      message: language === "albanian" ? "Libri asht i rezervuem " : "Book is on hold.",
      success: true,
    });
  };

  const onAddToWishListHandler = async () => {
    if (authCtx.authUser === null) {
      return setShowLogInModal(true);
    }

    // Update user account wishlist array with book
    const b = {
      title: title,
      author: author,
      synopsis: synopsis,
      id: id[0],
      imageRef: imageRef,
      category: category,
      docId: docId,
    };

    const db = getFirestore();
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);

    const wishlistArr = userSnap.data().wishlist;

    let bookIndex = wishlistArr.findIndex((obj) => obj.id === id);

    if (bookIndex === -1) {
      wishlistArr.push({ ...b });

      await updateDoc(userRef, { wishlist: wishlistArr });
      setIsWishListDisabled(true);
      flashMessageHandler({
        message:
          language === "albanian"
            ? "Libri iu shtu listës tande të dëshirave"
            : "Book added to your wishlist.",
        success: true,
      });
    }
  };

  return (
    <Fragment>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <LogInModal show={showLogInModal} onCancel={onCancelLoginModal} />
      <div className={styles["book-details"]}>
        <header className={styles["book-details-header"]}>
          <div className={styles["bookcover-container"]}>
            <img src={url === "not available" ? noImagePlaceHolder : url} className='skeleton' alt='' />
          </div>
          <div className={styles["book-info-container"]}>
            <h3 className={styles["book-title"]}>{capitalizeFirstLetter(title)}</h3>
            <span className={styles["book-author"]}>{capitalizeFirstLetter(author)}</span>
            {/* {parseInt(book.copies) > 0 && (
              <span className={styles.copies}>
                <MultiLingualContent contentId='booksAndRecords' prop='bookLabel3' /> {book.copies}
              </span>
            )} */}
            <p className={styles.red}>
              {bookBorrowedJustNow && <MultiLingualContent contentId='booksAndRecords' prop='note2' />}
            </p>
            <div>
              <button
                type='button'
                className={`${styles["button-80"]} ${styles.secondary}`}
                disabled={isWishlistDisabled}
                onClick={onAddToWishListHandler}>
                <HeartCircle />
                <span>
                  <MultiLingualContent contentId='booksAndRecords' prop='button6' />
                </span>
              </button>
              {available ? (
                <span className={`${styles["book-status"]} ${styles["green"]}`}>
                  <MultiLingualContent contentId='booksAndRecords' prop='bookLabel1' />
                </span>
              ) : (
                <span className={`${styles["book-status"]} ${styles["red"]}`}>
                  <MultiLingualContent contentId='booksAndRecords' prop='bookLabel2' />
                </span>
              )}
              {/* disabled is set to true in cases when user already has a book on hold and/or book is not avaialble */}
              <button
                type='button'
                className={styles["button-80"]}
                disabled={isHoldDisabled}
                onClick={onHoldBookHandler}>
                <MultiLingualContent contentId='booksAndRecords' prop='button7' />
              </button>
            </div>
            {userHasABorrowedBook && (
              <small>
                <MultiLingualContent contentId='booksAndRecords' prop='note3' />
              </small>
            )}
          </div>
        </header>
        <div className={styles.synopsis}>
          <h3>
            <MultiLingualContent contentId='booksAndRecords' prop='title3' />
          </h3>
          {<p>{synopsis}</p>}
        </div>
      </div>
    </Fragment>
  );
};

export default BookDetails;
