import Sun from "../../assets/icons/Sun";
import Moon from "../../assets/icons/Moon";
import styles from "./DayNightToggle.module.css";

const DayNightToggle = (props) => {
  return (
    <div className={styles.wrapper}>
      <Sun />
      <label className={styles.switch}>
        <input type='checkbox' onClick={props.onClick} /> <div></div>
      </label>
      <Moon />
    </div>
  );
};

export default DayNightToggle;
