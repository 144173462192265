import React, { useEffect, useState } from "react";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import useScrollToTop from "../../hooks/useScrollToTop";
import img1 from "../../assets/images/soma_entrance.jpg";
import styles from "./About.module.css";

const About = () => {
  const [skeleton, setSkeleton] = useState(true);
  useScrollToTop();

  useEffect(() => {
    if (window.innerWidth < 540) {
      setSkeleton(false);
    } else {
      setSkeleton(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 540) {
        setSkeleton(false);
      } else {
        setSkeleton(true);
      }
    });
  }, []);
  return (
    <div className={styles.container}>
      <header>
        <div className={`${styles.background} ${skeleton ? "skeleton" : ""}`}>
          <div className={`${styles["header-image"]} skeleton`}>
            <img src={img1} alt='Soma entrance' className='skeleton' />
          </div>
        </div>
      </header>
      <div className={styles.body}>
        <h1>
          <MultiLingualContent contentId='about' prop='title' />
        </h1>
        <p>
          <MultiLingualContent contentId='about' prop='bodyP1' />
        </p>

        <p>
          <MultiLingualContent contentId='about' prop='bodyP2' />
        </p>

        <p>
          <MultiLingualContent contentId='about' prop='bodyP3' />
        </p>

        <p>
          <MultiLingualContent contentId='about' prop='bodyP4' />
        </p>
      </div>
    </div>
  );
};

export default About;
