import { Fragment } from "react";
import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";

import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import noImagePlaceHolder from "../../../assets/images/No-Image-Placeholder.png";
import styles from "./RecordItem.module.css";
// FIREBASE

const RecordItem = (props) => {
  let { title, artist, docId, url } = props;

  return (
    <Fragment>
      <li className={styles["record-item"]}>
        <div className={styles["recordcover-container"]}>
          <img src={url === "not available" ? noImagePlaceHolder : url} className='skeleton' alt='' />
        </div>
        <div className={styles["record-info-container"]}>
          <div>
            <h3 className={styles["record-title"]}>{capitalizeFirstLetter(title)}</h3>
            <span className={styles["record-author"]}>{capitalizeFirstLetter(artist)}</span>
          </div>
          <button type='button' className={styles["button-80"]} onClick={() => props.onShowModal(docId)}>
            <MultiLingualContent contentId='booksAndRecords' prop='button4' />
          </button>
        </div>
      </li>
    </Fragment>
  );
};

export default RecordItem;
