import { Fragment } from "react";
import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import noImagePlaceholder from "../../../assets/images/No-Image-Placeholder.png";
import styles from "./BookItem.module.css";

const BookItem = (props) => {
  let { title, author, available, docId, url } = props;

  author = author.length > 20 ? author.slice(0, 15) + "..." : author;
  title = title.length > 20 ? title.slice(0, 25) + "..." : title;

  return (
    <Fragment>
      <li className={styles["book-item"]}>
        <div className={styles["bookcover-container"]}>
          <img src={url === "not available" ? noImagePlaceholder : url} className='skeleton' alt='' />
        </div>
        <div className={styles["book-info-container"]}>
          <h3 className={styles["book-title"]}>{capitalizeFirstLetter(title)}</h3>
          <span className={styles["book-author"]}>{capitalizeFirstLetter(author)}</span>
          <div className={styles.actions}>
            {available ? (
              <span className={`${styles["book-status"]} ${styles["green"]}`}>
                <MultiLingualContent contentId='booksAndRecords' prop='bookLabel1' />
              </span>
            ) : (
              <span className={`${styles["book-status"]} ${styles["red"]}`}>
                <MultiLingualContent contentId='booksAndRecords' prop='bookLabel2' />
              </span>
            )}
            <button type='button' className={styles["button-80"]} onClick={() => props.onShowModal(docId)}>
              <MultiLingualContent contentId='booksAndRecords' prop='button4' />
            </button>
          </div>
        </div>
      </li>
    </Fragment>
  );
};

export default BookItem;
