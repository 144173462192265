import styles from "./PrintableReservationsRow.module.css";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
const PrintableReservationsRow = (props) => {
  let options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let resDate = props.date.toDate().toLocaleString("default", options);
  return (
    <tr className={styles.tr}>
      <td data-label='NAME'>{capitalizeFirstLetter(props.name)}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='DATE/TIME'>{resDate + " at " + props.time}</td>
      <td data-label='NUMBER OF PEOPLE'>{props.numberOfPeople}</td>
      <td data-label='SPECIAL REQUESTS'>{props.specialReq}</td>
      <td data-label='TYPE OF SERVICE'>{props.type}</td>
      <td data-label='TABLE NUMBER'>{props.tableNumber}</td>
    </tr>
  );
};

export default PrintableReservationsRow;
