import Edit from "../../../../assets/icons/Edit";
import Trash from "../../../../assets/icons/Trash";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import styles from "./FlaggedGuestsRow.module.css";

const FlaggedGuestsRow = (props) => {
  return (
    <tr className={styles.tr}>
      <td data-label='NAME'>{capitalizeFirstLetter(props.name)}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onEditFlagCustomer(props.id)}>
          <Edit />
        </button>
        <button className={styles.btn} type='button' onClick={() => props.onDeleteCustomer(props.id)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default FlaggedGuestsRow;
