import React, { Fragment } from "react";
import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import { NavLink } from "react-router-dom";
import styles from "./DrinksNav.module.css";

const DrinksNav = () => {
  return (
    <Fragment>
      <div className={styles["nav-container"]}>
        <nav className={styles.navigation}>
          <NavLink
            to='/menusAndDrinks/drinks/softdrinks'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <button className={styles["nav-btn"]}>
              <span>
                <MultiLingualContent contentId='foodAndDrinks' prop='navLink11' />
              </span>
            </button>
          </NavLink>
          <NavLink
            to='/menusAndDrinks/drinks/beers'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <button className={styles["nav-btn"]}>
              <span>
                <MultiLingualContent contentId='foodAndDrinks' prop='navLink12' />
              </span>
            </button>
          </NavLink>
          <NavLink
            to='/menusAndDrinks/drinks/cocktails'
            className={(navData) => (navData.isActive ? styles.active : "")}>
            <button className={styles["nav-btn"]}>
              <span>
                <MultiLingualContent contentId='foodAndDrinks' prop='navLink13' />
              </span>
            </button>
          </NavLink>
        </nav>
      </div>
    </Fragment>
  );
};

export default DrinksNav;
