import Card from "../../../components/UI/Card";
import { Routes, Route } from "react-router-dom";

import JobApplications from "./JobApplications/JobApplications";
import ApplicantContacts from "./ApplicantContacts/ApplicantContacts";

import JobApplicantsTabs from "../../../components/UI/JobApplicantsTabs";

import styles from "./AdminJobApplicants.module.css";

const AdminJobApplicants = () => {
  return (
    <Card className={styles.container}>
      <JobApplicantsTabs />
      <div className={styles["tab-body"]}>
        <Routes>
          <Route path='/jobApplicants' element={<JobApplications />}></Route>
          <Route path='/applicantContacts' element={<ApplicantContacts />}></Route>
        </Routes>
      </div>
    </Card>
  );
};

export default AdminJobApplicants;
