import styles from "./BurgerAndWrapsMobile.module.css";

const BurgerAndWrapsMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <div className={styles.section}>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='burgers'>
          Cheese Burger w. cheese, grilled onions, gherkins on homemade brioche + potato wedges
          <span>5.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='burgers'>
          Chutney Burger w. pepper chutney, lettuce, onion on homemade brioche + potato wedges
          <span>5.50</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='burgers'>
          Truffle Burger w. truffle mayo, rocket, cheese on homemade brioche + local fries <span>6.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='burgers'>
          Vegetarian Burger w. beetroot patty, spinach, onion and mayo on homemade brioche + wedges
          <span>4.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='burgers'>
          Chicken Burger w. lettuce, tomato, gherkins on brioche + wedges and garlic sauce
          <span>5.50</span>
        </li>
      </div>
      <div className={styles.section}>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='burgers'>
          Chicken Hotdog w. mustard and crunchy onions
          <span>3.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='burgers'>
          NYC Chopped Sandwich w. beef mince, green peppers, white onion and American Cheese
          <span>6.00</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='burgers'>
          Baleadas w. homemade BBQ sauce, rocket, white cheese, apple + sour curds and herb oil
          <span>5.40</span>
        </li>
        <li className={styles.item} onClick={props.onShowModalHandler} data-index='8' category='burgers'>
          Vegetarian Wrap w. fried zucch., cheese, hummus, tomato, leaves + sour curds w. herb oil
          <span>4.00</span>
        </li>
      </div>
    </ul>
  );
};

export default BurgerAndWrapsMobile;
