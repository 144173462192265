import BurgerAndWrapsMobile from "./BurgerAndWrapsMobile";
import BurgerAndWrapsDesktop from "./BurgerAndWrapsDesktop";
import styles from "./BurgerAndWrapsALB.module.css";

const BurgerAndWrapsALB = (props) => {
  return (
    <div className={styles.container}>
      <BurgerAndWrapsMobile onShowModalHandler={props.showModal} />
      <BurgerAndWrapsDesktop onShowModalHandler={props.showModal} />
    </div>
  );
};

export default BurgerAndWrapsALB;
