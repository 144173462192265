import { Routes, Route, Navigate } from "react-router-dom";
import About from "./pages/About/About";
import Reservations from "./pages/Reservations/Reservations";
import ReservationRequestPage from "./pages/Reservations/ReservationRequestPage";
import ReservationMessagePage from "./pages/Reservations/ReservationMessagePage";
import MenusAndDrinks from "./pages/Menus&Drinks/MenusAndDrinks";
import Events from "./pages/Events/Events";
import BooksAndRecords from "./pages/Books&Records/BooksAndRecords";
import Careers from "./pages/Careers/Careers";
import JobApplicationForm from "./pages/Careers/JobApplicationForm";
import ApplicationMessagePage from "./pages/Careers/ApplicationMessagePage";
import Contact from "./pages/Contact/Contact";
import Gallery from "./pages/Gallery/Gallery";
import Landing from "./pages/Landing/Landing";

import MyLibrary from "./pages/MyLibrary/MyLibrary";

import AdminEvents from "./pages/AdminPages/AdminEventsPage/AdminEvents";
import AdminReservations from "./pages/AdminPages/AdminReservationsPage/AdminReservations";
import AdminBooks from "./pages/AdminPages/AdminBooksPage/AdminBooks";
import AdminRecords from "./pages/AdminPages/AdminRecordsPage/AdminRecords";
import AdminJobApplicants from "./pages/AdminPages/AdminJobApplicantsPage/AdminJobApplicants";

import LayoutMain from "./LayoutMain";
import LayoutAdmin from "./LayoutAdmin";

import ProtectedRoute from "./RouteProtectors/ProtectedRoute";
import AdminRoute from "./RouteProtectors/AdminRoute";
import PageNotFound from "./pages/404/404";

function App() {
  return (
    <>
      <Routes>
        <Route element={<LayoutMain />}>
          <Route path='/booksAndRecords/*' element={<BooksAndRecords />} />
          <Route path='/menusAndDrinks/*' element={<MenusAndDrinks />} />
          <Route
            path='/menusAndDrinks/food'
            element={<Navigate replace to='/menusAndDrinks/food/appetizers' />}
          />
          <Route
            path='/menusAndDrinks/drinks'
            element={<Navigate replace to='/menusAndDrinks/drinks/softdrinks' />}
          />
          <Route path='/' element={<Navigate replace to='/welcome' />} />
          <Route path='/welcome' element={<Landing />} />
          <Route path='/about' element={<About />} />
          {/* <Route path='/reservations' element={<Reservations />} />
          <Route path='/reservations/request-form' element={<ReservationRequestPage />} /> */}
          {/* Protected route. Is there a way to protect the route below? */}
          {/* <Route path='/reservations/success' element={<ReservationMessagePage />} /> */}
          <Route path='/events' element={<Events />} />
          <Route path='/menusAndDrinks' element={<Navigate replace to='/menusAndDrinks/kitchen' />} />
          <Route path='/booksAndRecords' element={<Navigate replace to='/booksAndRecords/library' />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/careers/application-form' element={<JobApplicationForm />} />
          <Route path='/careers/success' element={<ApplicationMessagePage />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route
            path='/my_library/*'
            element={
              <ProtectedRoute>
                <MyLibrary />
              </ProtectedRoute>
            }
          />
          <Route path='/my_library' element={<Navigate replace to='/my_library/borrowed' />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>
        <Route
          element={
            <AdminRoute>
              <LayoutAdmin />
            </AdminRoute>
          }>
          <Route path='/admin' element={<Navigate replace to='/admin/events' />} />
          <Route path='/admin/events/*' element={<AdminEvents />} />
          <Route path='/admin/events' element={<Navigate replace to='/admin/events/createEvent' />} />
          <Route path='/admin/tableReservations/*' element={<AdminReservations />} />
          <Route
            path='/admin/tableReservations'
            element={<Navigate replace to='/admin/tableReservations/requests' />}
          />
          <Route path='/admin/books/*' element={<AdminBooks />} />
          <Route path='/admin/books/' element={<Navigate replace to='/admin/books/holdRequests' />} />
          <Route path='/admin/records/*' element={<AdminRecords />} />
          <Route path='/admin/records/' element={<Navigate replace to='/admin/records/createRecord' />} />
          <Route path='/admin/jobApplications/*' element={<AdminJobApplicants />} />
          <Route
            path='/admin/jobApplications/'
            element={<Navigate replace to='/admin/jobApplications/jobApplicants' />}
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
