import styles from "./SoftDrinksMobile.module.css";

const SoftDrinksMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0'>
        Mollë e shtrydhur
        <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1'>
        Karrotë e shtrydhur<span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2'>
        Portokall i shtrydhur <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3'>
        Limonadë <span>3.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4'>
        Mix i shtrydhur <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='5'>
        Ice Tea Shtëpie <span>2.00</span>
      </li>
    </ul>
  );
};

export default SoftDrinksMobile;
