import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import HoldRequestRow from "./HoldRequestRow";

import FlashMessage from "../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../Modals/DeleteModal";

import styles from "./HoldRequests.module.css";

// FIREBASE
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  deleteDoc,
  updateDoc,
  query,
  where,
  getDocs,
  addDoc,
  getDoc,
} from "firebase/firestore";

const HoldRequests = () => {
  const [alert, setAlert] = useState("");
  const [holdRequests, setHoldRequests] = useState([]);
  const [holdId, setHoldId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  useEffect(() => {
    const getBookHoldRequests = () => {
      const db = getFirestore();
      const holdReqRef = collection(db, "holdRequests");

      onSnapshot(holdReqRef, (querySnapshot) => {
        const holdRequests = [];
        querySnapshot.forEach(async (d) => {
          // if book hold expires
          const now = new Date().getTime();
          if (now > d.data().holdExpires.toMillis()) {
            // remove it from users hold array
            const userId = d.data().user.docId;
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef, { onHold: [] });
            const bookHoldRequestRef = doc(db, "holdRequests", d.id);
            // make book available

            const docSnap = await getDoc(bookHoldRequestRef);
            let bookDocId = docSnap.data().docId;

            const bookRef = doc(db, "books", bookDocId);
            const bookSnap = await getDoc(bookRef);

            let copies = bookSnap.data().copies;
            await updateDoc(bookRef, { available: true, copies: Number(copies) + 1 });
            // remove it from holdRequests

            await deleteDoc(bookHoldRequestRef);
          } else {
            holdRequests.push(d.data());
          }
        });
        setHoldRequests(holdRequests);
      });
    };
    getBookHoldRequests();
  }, []);

  const onShowDeleteModalHandler = (id) => {
    setShowDeleteModal(true);
    setHoldId(id);
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onDeleteHoldRequestHandler = async () => {
    const db = getFirestore();
    // Update available copies of the book in books collection.

    const docRef = doc(db, "books", holdId);
    const docSnap = await getDoc(docRef);
    let copies = docSnap.data().copies;
    await updateDoc(docRef, { copies: Number(copies) + 1, available: true });

    //  Remove doc from holdRequests

    const holdReqCollectionRef = collection(db, "holdRequests");
    const q = query(holdReqCollectionRef, where("docId", "==", holdId));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (document) => {
      const bookRef = doc(db, "holdRequests", document.id);
      await deleteDoc(bookRef);
    });

    // Remove it also from users account. In order to do this, we need users docId, which we we placed in user object when we placed hold, either from library or from wishlist.

    const book = holdRequests.filter((book) => book.docId === holdId)[0];
    const userId = book.user.docId;

    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { onHold: [] });

    // Update UI
    setHoldRequests((prevState) => {
      return prevState.filter((hold) => hold.docId !== holdId);
    });
    flashMessageHandler({ message: "Hold Deleted ", success: true });
    setShowDeleteModal(false);
    setHoldId(null);
  };
  const onCancelDeleteHoldRequestHandler = () => {
    setShowDeleteModal(false);
  };

  const confirmHoldHandler = async (id) => {
    const db = getFirestore();

    // Delete doc from holdRequests collection
    const holdRequestsRef = collection(db, "holdRequests");

    // Create a query against the collection.

    const q = query(holdRequestsRef, where("docId", "==", id));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (document) => {
      const holdRequestRef = doc(db, "holdRequests", document.id);
      await deleteDoc(holdRequestRef);
    });

    //  Add book to bookHolds collection.

    const book = holdRequests.filter((book) => book.docId === id)[0];
    let docId = await addDoc(collection(db, "bookHolds"), book);

    // Update users book in onHold array with the id of the document once it's placed in bookHolds (in case user cancels after the book was placed in bookHolds)
    let userDocId = book.user.docId;

    let userRef = doc(db, "users", userDocId);
    let userSnap = await getDoc(userRef);
    let onHoldBook = userSnap.data().onHold[0];

    let updateOnHoldBook = {
      ...onHoldBook,
      bookHoldId: docId.id,
    };

    await updateDoc(userRef, { onHold: [updateOnHoldBook] });

    // Update UI
    setHoldRequests((prevState) => {
      return prevState.filter((hold) => hold.docId !== id);
    });
    flashMessageHandler({ message: "Book Hold Confirmed ", success: true });

    setHoldId(null);
  };

  return (
    <div className={styles.container}>
      <DeleteModal
        showModal={showDeleteModal}
        onConfirm={onDeleteHoldRequestHandler}
        onCancel={onCancelDeleteHoldRequestHandler}
        message={"Are you sure you want to delete this book hold request?"}
      />
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      {holdRequests.length > 0 ? (
        <table className={styles["hold-requests"]}>
          <thead>
            <tr>
              <th>Book Title</th>
              <th>Book ID</th>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Member</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {holdRequests.map((data) => {
              return (
                <HoldRequestRow
                  key={uuidv4()}
                  id={data.id}
                  docId={data.docId}
                  fullName={data.user.fullName}
                  phone={data.user.phone}
                  email={data.user.email}
                  title={data.title}
                  member={data.user.member}
                  showDeleteModal={onShowDeleteModalHandler}
                  onConfirmHold={confirmHoldHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>Currently there are no hold requests.</p>
      )}
    </div>
  );
};

export default HoldRequests;
