import React, { Fragment, useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import styles from "./ItemCategoriesNavRecords.module.css";

import { doc, getFirestore, getDoc } from "firebase/firestore";

const ItemCategoriesNav = (props) => {
  let str = JSON.parse(window.sessionStorage.getItem("searchCategory"));
  const [active, setActive] = useState(str ? str.toUpperCase() : null);
  const [counterObj, setCouterObj] = useState(null);

  const [sortedCategories, setSortedCategories] = useState([]);

  const searchByCategoryHandler = (cat) => {
    setActive(cat.genre);
    props.onSubmit(cat.queryString);
    window.sessionStorage.setItem("searchCategory", JSON.stringify(cat.genre));
  };

  useEffect(() => {
    async function getCounters() {
      let db = getFirestore();
      const docRef = doc(db, "recordCategoryCounters", "counters");
      const docSnap = await getDoc(docRef);

      let counterObj = docSnap.data();
      setCouterObj(counterObj);
    }
    getCounters();
  }, []);

  useEffect(() => {
    if (counterObj) {
      let sortedArr = props.categories
        .map((cat) => {
          return { ...cat, numberOfItems: counterObj[cat.queryString] };
        })
        .sort((a, b) => {
          return b.numberOfItems - a.numberOfItems;
        });
      setSortedCategories(sortedArr);
    }
  }, [counterObj, props.categories]);

  return (
    <Fragment>
      <div className={styles["nav-container"]}>
        <nav className={styles.navigation}>
          <div className={styles["close-btn"]} onClick={props.onCancel}>
            <span className={styles.one}></span>
            <span className={styles.two}></span>
          </div>
          {sortedCategories.map((cat) => {
            return (
              <div key={uuidv4()}>
                <button
                  className={`${styles["nav-btn"]} ${active === cat.genre && styles.active}`}
                  onClick={() => {
                    searchByCategoryHandler(cat);
                  }}>
                  <span>
                    {cat.buttonLabel} {counterObj ? counterObj[cat.queryString] : ""}
                  </span>
                </button>
              </div>
            );
          })}
        </nav>
      </div>
    </Fragment>
  );
};

export default ItemCategoriesNav;
