import Card from "../../../components/UI/Card";
import { Routes, Route } from "react-router-dom";

import ReservationRequests from "./ReservationRequests/ReservationRequests";
import ConfirmedReservations from "./ConfirmedReservations/ConfirmedReservations";
import AddReservation from "./AddReservation/AddReservation";
import DeletedReservations from "./DeletedReservations/DeletedReservations";
import PastReservations from "./PastReservations/PastReservations";
import FlaggedGuests from "./FlaggedGuests/FlaggedGuests";
import ReservationTabs from "../../../components/UI/ReservationTabs";

import styles from "./AdminReservations.module.css";

const AdminReservations = () => {
  return (
    <Card className={styles.container}>
      <ReservationTabs />
      <Routes>
        <Route path='/requests' element={<ReservationRequests />}></Route>
        <Route path='/confirmed' element={<ConfirmedReservations />}></Route>
        <Route path='/addReservation' element={<AddReservation />}></Route>
        <Route path='/deleted' element={<DeletedReservations />}></Route>
        <Route path='/pastReservations' element={<PastReservations />}></Route>
        <Route path='/flaggedGuests' element={<FlaggedGuests />}></Route>
      </Routes>
      {/* <div className={styles["tab-body"]}>{paneIndex === 0 && <ReservationRequests />}</div>
      <div className={styles["tab-body"]}>{paneIndex === 1 && <ConfirmedReservations />}</div>
      <div className={styles["tab-body"]}>{paneIndex === 2 && <AddReservation />}</div>
      <div className={styles["tab-body"]}>{paneIndex === 3 && <DeletedReservations />}</div>
      <div className={styles["tab-body"]}>{paneIndex === 4 && <PastReservations />}</div>
      <div className={styles["tab-body"]}>{paneIndex === 5 && <FlaggedGuests />}</div> */}
    </Card>
  );
};

export default AdminReservations;
