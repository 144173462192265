import { useState, useRef, useContext, useEffect } from "react";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import { LanguageContext } from "../../contexts/Language/LanguageContext";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../hooks/useScrollToTop";
import ChevronDown from "../../assets/icons/ChevronDown";
import NumberOfPeople from "../../assets/icons/NumberOfPeople";
import roles from "./Positions";
import rolesALB from "./PositionsALB";
import FlashMessage from "../../components/UI/FlashMessage";
import styles from "./JobApplicationForm.module.css";

import { addDoc, getFirestore, Timestamp, collection } from "firebase/firestore";

const JobApplicationForm = () => {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  useScrollToTop();
  const [selectedRole, setselectedRole] = useState("");
  const [typeOfRole, setTypeOfRole] = useState("");
  const [alert, setAlert] = useState("");
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const otherRef = useRef();

  useEffect(() => {
    if (language === "albanian") {
      setselectedRole(rolesALB[0]);
    } else {
      setselectedRole(roles[0]);
    }
  }, [language]);

  const onGoBackHandler = () => {
    navigate(-1);
  };

  const onChangeRoleHandler = (ev) => {
    setselectedRole(ev.target.value);
  };

  const typeOfRoleHandler = (ev) => {
    setTypeOfRole(ev.target.value);
  };

  const onSubmitHandler = async (ev) => {
    ev.preventDefault();

    const db = getFirestore();

    const now = new Date();
    const day = now.getDay();

    let nextAllocatedDate;

    if (day === 1 || (day > 1 && day < 6)) {
      now.setDate(now.getDate() + (6 - day));

      nextAllocatedDate = now;
    }

    if (day === 6 || day === 0) {
      now.setDate(now.getDate() + (day === 6 ? 2 : 1));
      nextAllocatedDate = now;
    }
    let interviewDateISOString = nextAllocatedDate.toISOString().split("T")[0];

    const jobApplication = {
      fullName:
        firstNameRef.current.value.toLowerCase().trim() +
        " " +
        lastNameRef.current.value.toLowerCase().trim(),
      phone: phoneNumberRef.current.value.trim(),
      email: emailRef.current.value.trim(),
      typeOfRole: typeOfRole,
      role:
        selectedRole === "Other" || selectedRole === "Tjera"
          ? "OTHER: " + otherRef.current.value
          : selectedRole.toLowerCase(),
      interviewDate: Timestamp.fromDate(new Date(interviewDateISOString)),
    };

    await addDoc(collection(db, "jobApplications"), jobApplication);

    flashMessageHandler({ message: "Job Application Received", success: true });

    navigate("/careers/success", { replace: true });
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  return (
    <div className={styles["page-wrapper"]}>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <div className={styles.container}>
        <nav className={styles.nav}>
          &lt;
          <span onClick={onGoBackHandler}>
            <MultiLingualContent contentId='careers' prop='button2' />
          </span>
        </nav>
        <h1 className={styles.title}>
          <MultiLingualContent contentId='careers' prop='title2' />
        </h1>
        <div className={styles["inner-container"]}>
          <form className={styles.form} onSubmit={onSubmitHandler}>
            <fieldset onChange={typeOfRoleHandler} className={styles.fieldset}>
              <legend>
                <MultiLingualContent contentId='careers' prop='legend' />
              </legend>
              <div className={styles.radio}>
                <input
                  type='radio'
                  id='practician'
                  name='typeOfRole'
                  value={language === "albanian" ? "praktikant" : "practician"}
                  required
                />
                <label htmlFor='practician'>
                  <MultiLingualContent contentId='careers' prop='label1' />
                </label>
              </div>
              <div className={styles.radio}>
                <input
                  type='radio'
                  id='experienced'
                  name='typeOfRole'
                  value={language === "albanian" ? "me përvojë" : "experienced"}
                  required
                />
                <label htmlFor='experienced'>
                  <MultiLingualContent contentId='careers' prop='label2' />
                </label>
              </div>
            </fieldset>
            <div className={styles.picker}>
              <div className={styles.control}>
                <div className={styles["input-icon"]}>
                  <NumberOfPeople />
                </div>
                <div className={styles.label}>{`${selectedRole} `}</div>
                <ChevronDown />
                <select
                  className={styles.select}
                  onChange={onChangeRoleHandler}
                  value={selectedRole}
                  required>
                  {(language === "albanian" ? rolesALB : roles).map((role, i) => {
                    return (
                      <option key={i} value={role} label={`${role}`}>
                        {role}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className={styles["user-inputs-wrapper"]}>
              {selectedRole === "Other" || selectedRole === "Tjera" ? (
                <div className={styles["input-control-wrapper"]}>
                  <input
                    ref={otherRef}
                    type='text'
                    placeholder={
                      language === "albanian"
                        ? "Të lutem përshkruej postin"
                        : "Please describe other position"
                    }
                    required
                  />
                </div>
              ) : (
                ""
              )}
              <div className={styles["input-control-wrapper"]}>
                <input
                  ref={firstNameRef}
                  type='text'
                  placeholder={language === "albanian" ? "Emni" : "First name"}
                  required
                />
              </div>
              <div className={styles["input-control-wrapper"]}>
                <input
                  ref={lastNameRef}
                  type='text'
                  placeholder={language === "albanian" ? "Mbiemni" : "Last name"}
                  required
                />
              </div>
              <div className={styles["input-control-wrapper"]}>
                <input
                  ref={phoneNumberRef}
                  type='tel'
                  placeholder={language === "albanian" ? "Nr. i telefonit" : "Phone number"}
                  required
                />
              </div>
              <div className={styles["input-control-wrapper"]}>
                <input
                  ref={emailRef}
                  type='email'
                  placeholder={language === "albanian" ? "Email-i" : "Email"}
                  required
                />
              </div>
            </div>
            <button type='submit' className={styles["button-23"]}>
              <MultiLingualContent contentId='careers' prop='button3' />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JobApplicationForm;
