import Trash from "../../../../../assets/icons/Trash";

import styles from "./SearchResultRow.module.css";

const SearchResultRow = (props) => {
  return (
    <tr className={styles.tr}>
      <td data-label='ID'>{props.id.join(",")}</td>
      <td data-label='TITLE'>{props.title}</td>
      <td data-label='AUTHOR'>{props.author}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onDeleteBook(props.docId)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default SearchResultRow;
