import { useState } from "react";
import Card from "../../../components/UI/Card";
import { Routes, Route } from "react-router-dom";

import CreateEvent from "./CreateNewEvent/CreateEvent";
import UpcomingEvents from "./UpcominEvents/UpcomingEvents";

import EventTabs from "../../../components/UI/EventTabs";
import FlashMessage from "../../../components/UI/FlashMessage";

import styles from "./AdminEvents.module.css";

// FIREBASE

import { addDoc, getFirestore, collection } from "firebase/firestore";
import { getStorage, ref, uploadString } from "firebase/storage";

const AdminEvents = () => {
  const [alert, setAlert] = useState("");

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onSubmitHandler = async (eventData, obj) => {
    // Create an Events collection in firestore and add document
    const db = getFirestore();

    await addDoc(collection(db, "events"), eventData);

    // Create eventImages folder in Storage and add image

    if (eventData.imageRef) {
      const storage = getStorage();
      let storageRef = ref(storage, `eventImages/${obj.imageName}`);
      uploadString(storageRef, obj.imageUrl, "data_url").then((snapshot) => {});
    }

    flashMessageHandler({ message: "Event Created ", success: true });
  };

  return (
    <Card className={styles.container}>
      <EventTabs />
      <div className={styles["tab-body"]}>
        <Routes>
          <Route path='/createEvent' element={<CreateEvent onSubmit={onSubmitHandler} />}></Route>
          <Route path='/events' element={<UpcomingEvents />}></Route>
        </Routes>
      </div>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
    </Card>
  );
};

export default AdminEvents;
