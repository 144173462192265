import React from "react";
import styles from "./BurgerButton.module.css";

const BurgerButton = (props) => {
  return (
    <button className={styles["open-btn"]} onClick={props.onClick}>
      <span className={styles.one}></span>
      <span className={styles.two}></span>
      <span className={styles.three}></span>
    </button>
  );
};

export default BurgerButton;
