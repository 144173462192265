import { useRef, useState } from "react";
import SearchGlassIcon from "../../../../assets/icons/SearchGlassIcon";

import styles from "./LibraryMembersToolbar.module.css";

const LibraryMembersToolbar = (props) => {
  const [searchCriteria, setSearchCriteria] = useState("fullName");
  const searchValueRef = useRef();
  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    let obj = {
      searchCriteria,
      searchValue: searchValueRef.current.value.trim(),
    };
    props.onChangeSearchCriteria(obj);
    searchValueRef.current.value = "";
  };
  return (
    <div>
      <hr className={styles.hr} />
      <div className={styles["filters-toolbar"]}>
        <form className={styles.form} onSubmit={onSubmitHandler}>
          <div className={styles["control-wrapper"]}>
            <div className={styles.control}>
              <label className={styles.label}>Search by:</label>
              <div className={styles["select-group-wrapper"]}>
                <select
                  name='searchFilter'
                  className={styles.select}
                  defaultValue={searchCriteria}
                  onChange={(ev) => setSearchCriteria(ev.target.value)}>
                  <option value='fullName'>Name</option>
                  <option value='email'>Email</option>
                </select>
                <input ref={searchValueRef} type='text' placeholder='Search...'></input>
                <button className={styles["search-btn"]}>
                  <SearchGlassIcon />
                </button>
              </div>
            </div>
            <div className={styles["member-control"]}>
              <button className={styles.primary} onClick={props.showCreateNewMemberModal}>
                CREATE NEW MEMBER
              </button>
            </div>
          </div>
        </form>
      </div>
      <hr className={styles.hr} />
    </div>
  );
};

export default LibraryMembersToolbar;
