import UndoIcon from "../../../../assets/icons/UndoIcon";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import styles from "./DeletedReservationsRow.module.css";

const DeletedReservationsRow = (props) => {
  let options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  let resDate = props.date.toDate().toLocaleString("default", options);

  let expiresInMilliseconds = props.expires.toMillis();

  let now = Date.now(); // now in milliseconds
  let totalHours = Math.floor((expiresInMilliseconds - now) / 1000 / 60 / 60);

  return (
    <tr className={styles.tr}>
      <td data-label='NAME'>{capitalizeFirstLetter(props.name)}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='TIME REMAINING'>{resDate + " at " + props.time}</td>
      <td data-label='NUMBER OF PEOPLE'>{props.people}</td>
      <td data-label='TYPE OF SERVICE'>{props.type}</td>
      <td data-label='TABLE NUMBER'>{props.tableNumber}</td>
      <td data-label='TIME REMAINING'>{totalHours} hours</td>
      <td data-label='DELETED FROM'>{props.deletedFrom}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onUndoDeletedReservation(props.id)}>
          <UndoIcon />
        </button>
      </td>
    </tr>
  );
};

export default DeletedReservationsRow;
