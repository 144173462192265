import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { NavLink } from "react-router-dom";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";

import BookBookMarkIcon from "../../assets/icons//BookBookMarkIcon";
import AdminIcon from "../../assets/icons/AdminIcon";
import styles from "./DropDownMenu.module.css";

// FIREBASE

import { getAuth, signOut } from "firebase/auth";

const DropDownMenu = () => {
  const authCtx = useContext(AuthContext);

  const signOutHandler = () => {
    const auth = getAuth();
    signOut(auth);
    window.sessionStorage.removeItem("previousSearch");
  };
  return (
    <div className={styles.dropdown}>
      <ul>
        <li className={styles["dropdown-item"]}>
          <NavLink to='/my_library'>
            <BookBookMarkIcon />
            <MultiLingualContent contentId='dropDownMenu' prop='link1' />
          </NavLink>
        </li>
        {authCtx.isAdmin && (
          <li className={styles["dropdown-item"]}>
            <NavLink to='/admin'>
              <AdminIcon />
              <MultiLingualContent contentId='dropDownMenu' prop='link2' />
            </NavLink>
          </li>
        )}
        <li className={styles["dropdown-item"]} onClick={signOutHandler}>
          <NavLink to='/about'>
            <MultiLingualContent contentId='dropDownMenu' prop='link3' />
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default DropDownMenu;
