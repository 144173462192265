import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import ButtonPrimary from "../../components/UI/ButtonPrimary";
import useScrollToTop from "../../hooks/useScrollToTop";
import img1 from "../../assets/images/staff.jpg";
import img2 from "../../assets/images/careers1.jpg";
import styles from "./Careers.module.css";

const Careers = () => {
  const [skeleton, setSkeleton] = useState(true);
  useScrollToTop();

  useEffect(() => {
    if (window.innerWidth < 540) {
      setSkeleton(false);
    } else {
      setSkeleton(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 540) {
        setSkeleton(false);
      } else {
        setSkeleton(true);
      }
    });
  }, []);
  return (
    <div className={styles.container}>
      <header>
        <div className={`${styles.background} ${skeleton ? "skeleton" : ""}`}>
          <div className={`${styles["header-image"]} skeleton`}>
            <img src={img2} className='skeleton' alt='Kitchen staff preparing a dessert' />
          </div>
        </div>
      </header>
      <div className={styles.body}>
        <h1>
          <MultiLingualContent contentId='careers' prop='title1' />
        </h1>
        <p>
          <MultiLingualContent contentId='careers' prop='careersP1' />
        </p>
        <p>
          <MultiLingualContent contentId='careers' prop='careersP2' />
        </p>
        <p>
          <MultiLingualContent contentId='careers' prop='careersP3' />
        </p>

        <p>
          <MultiLingualContent contentId='careers' prop='careersP4' />
        </p>

        <p>
          <MultiLingualContent contentId='careers' prop='careersP5' />{" "}
        </p>

        <p>
          <MultiLingualContent contentId='careers' prop='careersP6' />
        </p>

        <p>
          <MultiLingualContent contentId='careers' prop='careersP7' />
        </p>
        <p>
          <MultiLingualContent contentId='careers' prop='careersP8' />
        </p>
        <p>
          <MultiLingualContent contentId='careers' prop='careersP91' />{" "}
          <strong className={styles.strong}>
            <MultiLingualContent contentId='careers' prop='careersP9Strong' />
          </strong>{" "}
          <MultiLingualContent contentId='careers' prop='careersP92' />
        </p>
        <p>
          <MultiLingualContent contentId='careers' prop='careersP10' />
        </p>

        <p>
          <MultiLingualContent contentId='careers' prop='careersP11' />
        </p>

        <NavLink to='/careers/application-form' className={styles.navLink}>
          <ButtonPrimary className={styles.btn}>
            <MultiLingualContent contentId='careers' prop='button1' />
          </ButtonPrimary>
        </NavLink>

        {/* <p>(Note: Interviews will be conducted on Mondays and Saturdays from 9:00 – 10:00 in the morning).</p> */}
        <div className={styles.image}>
          <img src={img1} alt='A cheerful Soma staff' className='skeleton' />
        </div>
      </div>
    </div>
  );
};

export default Careers;
