import styles from "./FriedMobile.module.css";

const FriedMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='toShare'>
        Cold Plate w. white cheese, cured beef and pork, olives, fruits + variety of breads <span>12.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='toShare'>
        Selection of Local Sausages - homemade pork and Gjakovë sausage w. tzatziki and cream{" "}
        <span>7.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='toShare'>
        Potato Wedges w. spicy mayo and parmesan <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='toShare'>
        Fried Shrimps w. spicy mayo <span>8.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='toShare'>
        Fried Zuchinni stuffed w. white cheese and served with sour curd tarator <span>3.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='toShare'>
        Pachanga w. minced beef, melted cheese and corn <span>5.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='toShare'>
        Chicken Fingers w. potato wedges, BBQ sauce and spicy mayo <span>6.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='toShare'>
        Cheese Sticks w. tomato sauce <span>6.00</span>
      </li>
    </ul>
  );
};

export default FriedMobile;
