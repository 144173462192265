import React, { useState } from "react";
import { translations } from "./translations";

export const LanguageContext = React.createContext(translations.english);

export const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("albanian");

  const toggleLanguage = () => {
    return setLanguage((language) => (language === "english" ? "albanian" : "english"));
  };
  return <LanguageContext.Provider value={{ language, toggleLanguage }}>{children}</LanguageContext.Provider>;
};
