import {
  where,
  query,
  orderBy,
  limit,
  getFirestore,
  collection,
  startAfter,
  endBefore,
  limitToLast,
} from "firebase/firestore";

const getPaginationQueryType = (checkListValues, selectedMonth, year, querynumber, paginate, first, last) => {
  const db = getFirestore();
  const eventsRef = collection(db, "events");
  let currentDate = new Date();
  let q;
  if (querynumber === 1) {
    q = query(
      eventsRef,
      where("date", ">=", new Date(currentDate.getFullYear(), selectedMonth - 1, 1)),
      where("date", "<", new Date(currentDate.getFullYear(), selectedMonth, 1)),
      where("date", ">=", currentDate),
      where("type", "in", checkListValues),
      orderBy("date", "asc"),
      paginate === "next" ? startAfter(last) : endBefore(first),
      paginate === "next" ? limit(6) : limitToLast(6)
    );
  }
  if (querynumber === 2) {
    q = query(
      eventsRef,
      where("date", ">=", new Date(currentDate.getFullYear(), selectedMonth - 1, 1)),
      where("date", "<", new Date(currentDate.getFullYear(), selectedMonth, 1)),
      where("date", ">=", currentDate),
      orderBy("date", "asc"),
      paginate === "next" ? startAfter(last) : endBefore(first),
      paginate === "next" ? limit(6) : limitToLast(6)
    );
  }

  if (querynumber === 3) {
    q = query(
      eventsRef,
      where("date", ">=", currentDate),
      where("type", "in", checkListValues),
      orderBy("date", "asc"),
      paginate === "next" ? startAfter(last) : endBefore(first),
      paginate === "next" ? limit(6) : limitToLast(6)
    );
  }
  if (querynumber === 4) {
    q = query(
      eventsRef,
      where("date", ">=", currentDate),
      orderBy("date", "asc"),
      paginate === "next" ? startAfter(last) : endBefore(first),
      paginate === "next" ? limit(6) : limitToLast(6)
    );
  }

  if (querynumber === 5) {
    q = query(
      eventsRef,
      where("date", ">=", new Date(year, selectedMonth - 1, 1)),
      where("date", "<", new Date(year, selectedMonth, 1)),
      where("date", "<=", currentDate),
      where("type", "in", checkListValues),
      orderBy("date", "asc"),
      paginate === "next" ? startAfter(last) : endBefore(first),
      paginate === "next" ? limit(6) : limitToLast(6)
    );
  }

  if (querynumber === 6) {
    q = query(
      eventsRef,
      where("date", ">=", new Date(year, selectedMonth - 1, 1)),
      where("date", "<", new Date(year, selectedMonth, 1)),
      where("date", "<=", currentDate),
      orderBy("date", "asc"),
      paginate === "next" ? startAfter(last) : endBefore(first),
      paginate === "next" ? limit(6) : limitToLast(6)
    );
  }

  if (querynumber === 7) {
    q = query(
      eventsRef,
      where("date", ">=", new Date(year, 0, 1)),
      where("date", "<", new Date(parseInt(year) + 1, 0, 1)),
      where("date", "<=", currentDate),
      where("type", "in", checkListValues),
      orderBy("date", "asc"),
      paginate === "next" ? startAfter(last) : endBefore(first),
      paginate === "next" ? limit(6) : limitToLast(6)
    );
  }
  if (querynumber === 8) {
    q = query(
      eventsRef,
      where("date", ">=", new Date(year, 0, 1)),
      where("date", "<", new Date(parseInt(year) + 1, 0, 1)),
      where("date", "<=", currentDate),
      orderBy("date", "asc"),
      paginate === "next" ? startAfter(last) : endBefore(first),
      paginate === "next" ? limit(6) : limitToLast(6)
    );
  }

  return q;
};

export default getPaginationQueryType;
