export let checklistENG = [
  { label: "DJ", value: "dj", checked: false },
  { label: "CONCERT", value: "concert", checked: false },
  { label: "EXHIBITION", value: "exhibition", checked: false },
  { label: "SCREENING", value: "screening", checked: false },
  { label: "WORKSHOP", value: "workshop", checked: false },
  { label: "BOOK SIGNING", value: "book signing", checked: false },
  { label: "FOOD TASTING", value: "food tasting", checked: false },
  { label: "WINE TASTING", value: "wine tasting", checked: false },
  { label: "OTHER", value: "other", checked: false },
];

export let checklistALB = [
  { label: "DJ", value: "dj", checked: false },
  { label: "KONCERT", value: "concert", checked: false },
  { label: "EKSPOZITË", value: "exhibition", checked: false },
  { label: "EKRANIZIM", value: "screening", checked: false },
  { label: "PUNËTORI", value: "workshop", checked: false },
  { label: "NËNSHKRIM LIBRI", value: "book signing", checked: false },
  { label: "DEGUSTIM USHQIMI", value: "food tasting", checked: false },
  { label: "DEGUSTIM VENE", value: "wine tasting", checked: false },
  { label: "TJERA", value: "other", checked: false },
];
