import { Fragment } from "react";
import ReactDOM from "react-dom";
import BorrowBookForm from "../pages/AdminPages/AdminBooksPage/LibraryMembers/BorrowBookForm";
import styles from "./BorrowBookModal.module.css";

const Backdrop = (props) => {
  const cssClasses = [styles.backdrop, props.show ? styles.backdropOpen : styles.backdropClosed];
  return <div className={cssClasses.join(" ")} />;
};

const Modal = (props) => {
  const cssClasses = [styles.modal, props.show ? styles.modalOpen : styles.modalClosed];

  return (
    <div className={cssClasses.join(" ")}>
      <div className={styles["modal-body"]}>
        <BorrowBookForm
          onCancel={props.onCancel}
          memberDetails={props.memberDetails}
          onSubmit={props.onSubmit}
        />
      </div>
    </div>
  );
};
const BorrowBookModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop show={props.showModal} />, document.getElementById("backdrop-root"))}
      {ReactDOM.createPortal(
        <Modal
          show={props.showModal}
          onCancel={props.onCancel}
          onSubmit={props.onSubmit}
          memberDetails={props.memberDetails}
        />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default BorrowBookModal;
