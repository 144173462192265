import ConfirmReservationIcon from "../../../../assets/icons/ConfirmReservationIcon";
import styles from "./SearchResult.module.css";

const SearchResult = (props) => {
  return (
    <div className={styles["searchResult"]}>
      <div className={styles.item}>
        <div className={styles.book}>
          <div className={styles["book-data"]}>
            <span>Title: </span>
            <span>{props.title}</span>
          </div>
          <div className={styles["book-data"]}>
            <span>Author: </span>
            <span>{props.author}</span>
          </div>
        </div>
        <div className={styles.operation}>
          {props.available ? (
            <button className={styles.confirmBtn} type='button' onClick={() => props.onConfirm(props.docId)}>
              <ConfirmReservationIcon />
            </button>
          ) : (
            "Book not available"
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
