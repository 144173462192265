import Trash from "../../../../assets/icons/Trash";
import SaveContact from "../../../../assets/icons/SaveContact";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import styles from "./JobApplicationsRow.module.css";

const JobApplicationsRow = (props) => {
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  let interviewDate = props.date.toDate().toLocaleString("default", options);

  return (
    <tr className={styles.tr}>
      <td data-label='NAME'>{capitalizeFirstLetter(props.name)}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='TYPE OF ROLE'>{props.typeOfRole}</td>
      <td data-label='APPLYING FOR'>{props.role}</td>
      <td data-label='DATE'>{interviewDate} between 9am-10am</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onSaveContact(props.id)}>
          <SaveContact />
        </button>
        <button className={styles.btn} type='button' onClick={() => props.onDeleteJobApplication(props.id)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default JobApplicationsRow;
