import styles from "./Bottle.module.css";

const Bottle = () => {
  return (
    <svg className={styles.svg} version='1.1' id='Layer_1' x='0' y='0' viewBox='0 0 100 100'>
      <path
        d='M64.3 51.4c-1.1-2.8-3.4-5-6.3-5.9-1.9-1.1-2.6-3.1-2.9-5.4-.1-.7-.1-1.5-.1-2.2V7.5H45v30.3c0 .8 0 1.5-.1 2.3-.3 2.2-1 4.3-2.9 5.4-2.9.9-5.2 3.1-6.3 5.9-.4 1-.7 2.2-.7 3.6v35.3c0 2.6 2.1 4.7 4.7 4.7h20.6c2.6 0 4.7-2.1 4.7-4.7V55c0-1.4-.3-2.6-.7-3.6z'
        fill='#acbd81'
      />
      <path d='M58.6 92.5H41.4c-2.2 0-3.9-1.8-3.9-3.9V65h25v23.6c0 2.1-1.8 3.9-3.9 3.9z' fill='#849b87' />
      <path
        d='M54.7 5h-9.5c-.7 0-1.3.6-1.3 1.3v11.5c0 .7.6 1.3 1.3 1.3h9.5c.7 0 1.3-.6 1.3-1.3V6.3c.1-.7-.5-1.3-1.3-1.3z'
        fill='#67503b'
      />
      <path d='M65 84.2H45.5c-1.6 0-3-1.3-3-3V61.3c0-1.6 1.3-3 3-3H65v25.9z' fill='#e0e0e0' />
    </svg>
  );
};

export default Bottle;
