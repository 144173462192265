import { Fragment, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import CheckboxList from "../../../AdminBooksPage/LibraryCRUD/CreateBook/CheckboxList";
import { checkboxOptions } from "./checkboxOptions";
import FlashMessage from "../../../../../components/UI/FlashMessage";
import styles from "./CreateRecordForm.module.css";

// FIREBASE
import { getFirestore, addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { getStorage, uploadString, ref } from "firebase/storage";

const CreateRecordForm = (props) => {
  const recordTitleRef = useRef();
  const recordArtistRef = useRef();
  const descriptionRef = useRef();
  const tracklistRef = useRef();
  const uploadedImageRef = useRef();

  const [alert, setAlert] = useState("");

  const [imgUrl, setImgUrl] = useState("");
  const [imgName, setImgName] = useState("");

  const [checklistOptions, setChecklistOptions] = useState(checkboxOptions);
  const [isRequired, setIsRequired] = useState(false);

  const onSubmitHandler = async (ev) => {
    ev.preventDefault();
    // The record object is stored in firestore collection of records
    const recordTracklist = tracklistRef.current.value.trim().split("\n");

    const genreArr = checklistOptions.filter((option) => option.checked).map((option) => option.value);

    if (genreArr.length) {
      setIsRequired(false);
    } else {
      setIsRequired(true);
      return;
    }

    const record = {
      title: recordTitleRef.current.value.toLowerCase().trim(),
      artist: recordArtistRef.current.value.toLowerCase().trim(),
      overview: descriptionRef.current.value.trim(),
      imageRef: imgName ? `recordcovers/${imgName}` : "",
      tracklist: recordTracklist,
      genre: genreArr,
    };
    if (imgUrl) {
      const storage = getStorage();
      let storageRef = ref(storage, `recordcovers/${imgName}`);
      uploadString(storageRef, imgUrl, "data_url").then((snapshot) => {
        console.log("Uploaded a data_url string!");
      });
    }

    const db = getFirestore();
    await addDoc(collection(db, "records"), record);

    // Increment category counter/s

    const docRef = doc(db, "recordCategoryCounters", "counters");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let countersObj = docSnap.data();

      genreArr.forEach((category) => {
        countersObj[category] += 1;
      });
      await setDoc(docRef, countersObj);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

    // Clear form after submission
    recordTitleRef.current.value = "";
    recordArtistRef.current.value = "";
    descriptionRef.current.value = "";
    tracklistRef.current.value = "";
    uploadedImageRef.current.value = "";

    setChecklistOptions((prevState) => {
      return prevState.map((option) => {
        return { ...option, checked: false };
      });
    });

    flashMessageHandler({ message: "Record added to library database", success: true });
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onChangeImageHandler = (event) => {
    const resize_width = 600; //without px

    //get the image selected
    let item = event.target.files[0];
    if (item) {
      //create a FileReader
      const reader = new FileReader();

      //image turned to base64-encoded Data URI.
      reader.readAsDataURL(item);
      reader.name = item.name; //get the image's name
      reader.size = item.size; //get the image's size
      // Create unique image name. We're not using item.name
      setImgName(uuidv4() + ".jpeg");
      reader.onload = function (event) {
        let img = new Image(); //create an image
        img.src = event.target.result; //result is base64-encoded Data URI

        img.size = event.target.size; //set size (optional)
        img.onload = function (el) {
          var elem = document.createElement("canvas"); //create a canvas

          //scale the image to 600 (width) and keep aspect ratio
          var scaleFactor = resize_width / el.target.width;
          elem.width = resize_width;
          elem.height = el.target.height * scaleFactor;

          //draw in canvas
          var ctx = elem.getContext("2d");
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          //get the base64-encoded Data URI from the resize image
          var srcEncoded = ctx.canvas.toDataURL("image/jpeg", 0.5);
          //assign it to thumb src
          setImgUrl(srcEncoded);
          /*Now you can send "srcEncoded" to the server and
      convert it to a png o jpg. Also can send
      "el.target.name" that is the file's name.*/
        };
      };
    }
  };

  return (
    <Fragment>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <form onSubmit={onSubmitHandler} className={styles.form}>
        {/* <div className={styles.controls}>
          <label htmlFor='recordID'>Record ID</label>
          <input
            type='text'
            id='recordID'
            placeholder='Record ID'
            autoComplete='off'
            aria-label='Record ID'
            required
            ref={recordIdRef}
          />
        </div> */}
        <div className={styles.controls}>
          <label htmlFor='recordTitle'>Record Title</label>
          <input
            type='text'
            id='recordTitle'
            placeholder='Record Title'
            autoComplete='off'
            aria-label='Record Title'
            required
            ref={recordTitleRef}
          />
        </div>
        <div className={styles.controls}>
          <label htmlFor='artist'>Artist</label>
          <input
            type='text'
            id='artist'
            placeholder='Artist'
            autoComplete='off'
            aria-label='Artist'
            required
            ref={recordArtistRef}
          />
        </div>
        {/* <div className={styles.controls}>
          <label htmlFor='genre'>Genre</label>
          <input
            type='text'
            id='genre'
            placeholder='Genre'
            autoComplete='off'
            aria-label='Genre'
            required
            ref={recordGenreRef}
          />
        </div> */}
        <CheckboxList options={checklistOptions} onChange={setChecklistOptions} />
        <div className={styles.controls}>
          <label htmlFor='recordDescription'>Description</label>
          <textarea
            id='recordDescription'
            placeholder='Description'
            aria-label='Description'
            ref={descriptionRef}
          />
        </div>
        <div className={styles.controls}>
          <label htmlFor='recordTracklist'>Tracklist (please use newline for each track)</label>
          <textarea id='recordTracklist' placeholder='Tracklist' aria-label='Tracklist' ref={tracklistRef} />
        </div>
        <div className={styles["file-control"]}>
          <label htmlFor='recordCover'>Record Cover</label>
          <input
            type='file'
            id='recordCover'
            aria-label='Upload Image'
            accept='image/*'
            ref={uploadedImageRef}
            onChange={onChangeImageHandler}
          />
        </div>
        <div className={styles.action}>
          <button type='submit' className={styles["button-24"]}>
            Add Record
          </button>
        </div>
      </form>
      <label>{isRequired ? "Please select at least one of the catgeories below" : ""}</label>
    </Fragment>
  );
};

export default CreateRecordForm;
