import Edit from "../../../../../assets/icons/Edit";

import styles from "./SearchResultRow.module.css";

const SearchResultRow = (props) => {
  return (
    <tr className={styles.tr}>
      <td data-label='ID'>{props.id}</td>
      <td data-label='TITLE'>{props.title}</td>
      <td data-label='ARTIST'>{props.artist}</td>
      <td data-label='RECORDCOVER'>{props.imageRef ? "available" : "not available"}</td>
      <td data-label='DESCRIPTION'>{props.description ? "available" : "not available"}</td>
      <td data-label='TRACKLIST'>{props.tracklist.length ? "available" : "not available"}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onEditRecord(props.docId)}>
          <Edit />
        </button>
      </td>
    </tr>
  );
};

export default SearchResultRow;
