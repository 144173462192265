import React from "react";
import PrintableReservationsRow from "./PrintableReservationsRow";
import { v4 as uuidv4 } from "uuid";
import styles from "./PrintableReservationsComponent.module.css";

const PrintableReservationsComponent = React.forwardRef((props, ref) => {
  return (
    <div className={styles["confirmed-reservations-container"]} ref={ref}>
      {props.confirmedReservationsData.length > 0 ? (
        <table className={styles.reservations}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Confirmed Date/time</th>
              <th>Number of People</th>
              <th>Special Requests</th>
              <th>Type of Service</th>
              <th>Table Number</th>
            </tr>
          </thead>
          <tbody>
            {props.confirmedReservationsData.map((data) => {
              return (
                <PrintableReservationsRow
                  key={uuidv4()}
                  id={data.id}
                  name={data.fullName}
                  phone={data.phone}
                  email={data.email}
                  date={data.date}
                  time={data.time}
                  numberOfPeople={data.numberOfPeople}
                  specialReq={data.specialRequest}
                  type={data.typeOfService}
                  tableNumber={data.tableNumber}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>Currently there are no confirmed reservations.</p>
      )}
    </div>
  );
});

export default PrintableReservationsComponent;
