import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import DateFilterModal from "../../../../Modals/DateFilterModal";
import FlashMessage from "../../../../components/UI/FlashMessage";
import PastResToolbar from "./PastResToolbar";
import PastReservationsRow from "./PastReservationsRow";

import NextButton from "../../../../components/UI/NextButton";
import styles from "./PastReservations.module.css";

// FIREBASE
import {
  getFirestore,
  collection,
  where,
  getDocs,
  query,
  limit,
  Timestamp,
  startAfter,
  addDoc,
} from "firebase/firestore";

const PastReservations = () => {
  const [searchCriteria, setSearchCriteria] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [isPending, setIsPending] = useState(true);
  const [dateInputRef, setDateInputRef] = useState(); // this may need to be reworked
  const [searchObj, setSearchObj] = useState(null);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [alert, setAlert] = useState("");
  const [pastReservations, setPastReservations] = useState([]);

  const [last, setLast] = useState();

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDateFilter(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  // GET DATA FROM FIREBASE

  useEffect(() => {
    const getPastRes = async () => {
      setIsPending(true);
      setPastReservations([]);

      const db = getFirestore();
      const q = query(collection(db, "pastReservations"), limit(5));

      const querySnapshot = await getDocs(q);

      // FOR PAGINATION
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLast(lastVisible);

      if (dateFilter) {
        let date = Timestamp.fromDate(new Date(dateFilter));

        const q = query(collection(db, "pastReservations"), where("date", "==", date));

        const querySnap = await getDocs(q);

        querySnap.forEach((doc) => {
          setPastReservations((prevState) => {
            return [...prevState, { ...doc.data(), id: doc.id }];
          });
        });
      } else {
        querySnapshot.forEach((doc) => {
          setPastReservations((prevState) => {
            return [...prevState, { ...doc.data(), id: doc.id }];
          });
        });
      }
      setIsPending(false);
    };
    getPastRes();
  }, [dateFilter]);

  useEffect(() => {
    const searchPastReservations = async (obj) => {
      if (obj === null) {
        return;
      }

      let { searchCriteria, searchValue } = obj;

      if (searchValue === "") {
        return;
      }

      setPastReservations([]);
      setLast("");

      const db = getFirestore();
      const q = query(
        collection(db, "pastReservations"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff")
      );
      setIsPending(true);

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setIsPending(false);
          return;
        }

        querySnapshot.forEach((doc) => {
          setPastReservations((prevState) => {
            return [...prevState, { ...doc.data(), docId: doc.id }];
          });
        });
      } catch (error) {
        console.log(error);
      }

      setIsPending(false);
    };
    searchPastReservations(searchObj);
  }, [searchObj]);

  // NEXT SET OF DATA - When we press More Button
  const showMoreItemsHandler = async () => {
    const db = getFirestore();

    if (last === "") {
      return;
    }
    const next = query(collection(db, "pastReservations"), startAfter(last), limit(5));
    const querySnapshot = await getDocs(next);

    if (querySnapshot.empty) {
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLast(lastVisible);

    querySnapshot.forEach((doc) => {
      setPastReservations((prevState) => {
        return [...prevState, { ...doc.data(), id: doc.id }];
      });
    });
  };

  const onFlagCustomerHandler = async (reservationId) => {
    const reservation = pastReservations.find((res) => res.id === reservationId);

    const { fullName, email, phone } = reservation;
    const guest = {
      fullName,
      email,
      phone,
    };
    const db = getFirestore();
    await addDoc(collection(db, "flaggedGuests"), guest);

    flashMessageHandler({ message: "Customer Flagged", success: true });
  };

  // *** FILTER BY DATE ***
  const onShowDateModalHandler = (ev) => {
    setShowDateFilter(true);
  };

  const onCancelFilterByDateHandler = () => {
    setShowDateFilter(false);
  };

  // This sets dateFilter, and fires up useEffect, where we query the database
  const onSubmitFilterByDateHandler = (ev, ref) => {
    ev.preventDefault();

    setDateFilter(ref.current.value);

    setDateInputRef(ref);
    setShowDateFilter(false);
  };

  const clearDateFilterHandler = () => {
    setDateFilter("");

    setDateInputRef((prevState) => (!prevState ? prevState : (prevState.current.value = "")));
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  return (
    <div>
      <DateFilterModal
        show={showDateFilter}
        onSubmit={onSubmitFilterByDateHandler}
        onCancel={onCancelFilterByDateHandler}
        noLimitOnDateFilter={true}
        minLimit={false}
        maxLimit={true}
      />
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <PastResToolbar
        onShowDateModal={onShowDateModalHandler}
        clearDateFilter={clearDateFilterHandler}
        onChangeSearchCriteria={setSearchCriteria}
        searchCriteria={searchCriteria}
        onSearch={setSearchObj}
      />
      {isPending ? (
        "Loading..."
      ) : pastReservations.length > 0 ? (
        <table className={styles.reservations}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Date/time</th>
              <th>Number of People</th>
              <th>Special Requests</th>
              <th>Type of Service</th>
              <th>Table Number</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {pastReservations.map((data) => {
              return (
                <PastReservationsRow
                  key={uuidv4()}
                  id={data.id}
                  name={data.fullName}
                  phone={data.phone}
                  email={data.email}
                  date={data.date}
                  time={data.time}
                  people={data.numberOfPeople}
                  specialReq={data.specialRequest}
                  type={data.typeOfService}
                  tableNumber={data.tableNumber}
                  onFlagCustomer={onFlagCustomerHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : !dateFilter ? (
        <p>There are no past reservations</p>
      ) : (
        <p>There were no reservations on this date</p>
      )}
      {!dateFilter && pastReservations.length ? <NextButton onClick={showMoreItemsHandler} /> : ""}
    </div>
  );
};

export default PastReservations;
