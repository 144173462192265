import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import ApplicantContactsRow from "./ApplicantContactsRow";
import ApplicantContactsToolbar from "./ApplicantContactsToolbar";
import FlashMessage from "../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../Modals/DeleteModal";
import styles from "./ApplicantContacts.module.css";

// FIREBASE
import { getDocs, getFirestore, collection, deleteDoc, doc, query, where } from "firebase/firestore";

const ApplicantContacts = () => {
  const [applicantContacts, setApplicantContacts] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [searchObj, setSearchObj] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState("");
  const [alert, setAlert] = useState("");

  useEffect(() => {
    const getApplicantContacts = async () => {
      const db = getFirestore();
      setIsPending(true);
      setApplicantContacts([]);
      const q = query(collection(db, "applicantContacts"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (document) => {
        setApplicantContacts((prevState) => {
          return [...prevState, { ...document.data(), id: document.id }];
        });
      });

      setIsPending(false);
    };

    getApplicantContacts();
  }, []);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);
  // SEARCH FUNCTIONALITY
  useEffect(() => {
    const searchContacts = async (obj) => {
      if (obj === null) {
        return;
      }

      let { searchCriteria, searchValue } = obj;

      if (searchValue === "") {
        return;
      }
      setApplicantContacts([]);
      const db = getFirestore();
      const q = query(
        collection(db, "applicantContacts"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff")
      );
      setIsPending(true);

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setIsPending(false);
          return;
        }

        querySnapshot.forEach((doc) => {
          setApplicantContacts((prevState) => {
            return [...prevState, { ...doc.data(), docId: doc.id }];
          });
        });
      } catch (error) {
        console.log(error);
      }

      setIsPending(false);
    };
    searchContacts(searchObj);
  }, [searchObj]);

  const onConfirmDeleteHandler = (id) => {
    setShowDeleteModal(true);
    setContactId(id);
  };

  const onCancelDeleteHandler = () => {
    setShowDeleteModal(false);
    setContactId(null);
  };

  const onDeleteContactHandler = async () => {
    const db = getFirestore();

    await deleteDoc(doc(db, "applicantContacts", contactId));
    setApplicantContacts((prevState) => {
      return prevState.filter((contact) => contact.id !== contactId);
    });
    flashMessageHandler({ message: "Contact Deleted", success: true });
    setShowDeleteModal(false);
    setContactId(null);
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };
  return (
    <div className={styles["job-applications-container"]}>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <DeleteModal
        message={"Are you sure you want to delete this contact?"}
        showModal={showDeleteModal}
        onConfirm={onDeleteContactHandler}
        onCancel={onCancelDeleteHandler}
      />
      <ApplicantContactsToolbar
        onChangeSearchCriteria={setSearchCriteria}
        searchCriteria={searchCriteria}
        onSearch={setSearchObj}
      />
      {isPending ? (
        "Loading..."
      ) : applicantContacts.length > 0 ? (
        <table className={styles.contacts}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Type of Role</th>
              <th>Applied for</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {applicantContacts.map((data) => {
              return (
                <ApplicantContactsRow
                  key={uuid()}
                  id={data.id}
                  name={data.fullName}
                  phone={data.phone}
                  email={data.email}
                  role={data.role}
                  typeOfRole={data.typeOfRole}
                  onDeleteContact={onConfirmDeleteHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>No contacts found</p>
      )}
    </div>
  );
};

export default ApplicantContacts;
