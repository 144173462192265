import { Fragment, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import FlashMessage from "../../../../../components/UI/FlashMessage";
import UpdateRecordModal from "../../../../../Modals/UpdateRecordModal";
import UpdateRecordToolbar from "./UpdateRecordToolbar";
import SearchResultRow from "./SearchResultRow";
import { checkboxOptions } from "../CreateRecord/checkboxOptions";
import NextButton from "../../../../../components/UI/NextButton";
import styles from "./UpdateRecord.module.css";

// FIREBASE
import {
  getDocs,
  getFirestore,
  collection,
  doc,
  updateDoc,
  query,
  where,
  getDoc,
  limit,
  startAfter,
} from "firebase/firestore";
import { getStorage, uploadString, ref, deleteObject } from "firebase/storage";

const UpdateRecord = () => {
  const [alert, setAlert] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const [updateRecordModal, setUpdateRecordModal] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [searchObj, setSearchObj] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [recordToEdit, setRecordToEdit] = useState({
    id: "",
    title: "",
    artist: "",
    imageRef: "",
    overview: "",
    genre: [],
    tracklist: [],
    docId: "",
  });

  const [checklistOptions, setChecklistOptions] = useState(checkboxOptions);
  // image for storage base64
  const [imgUrl, setImgUrl] = useState("");
  const [imgName, setImgName] = useState("");

  const [last, setLast] = useState();

  useEffect(() => {
    const findRecordToUpdate = async (obj) => {
      if (obj === null) {
        return;
      }

      let { searchCriteria, searchValue } = obj;

      if (searchValue === "") {
        return;
      }

      setSearchData([]);
      const db = getFirestore();
      const q = query(
        collection(db, "records"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff"),
        limit(5)
      );
      setIsPending(true);

      try {
        const querySnapshot = await getDocs(q);

        // FOR PAGINATION
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLast(lastVisible);

        if (querySnapshot.empty) {
          setIsPending(false);
          return;
        }

        querySnapshot.forEach((doc) => {
          setSearchData((prevState) => {
            return [...prevState, { ...doc.data(), docId: doc.id }];
          });
        });
      } catch (error) {
        console.log(error);
      }

      setIsPending(false);
    };
    findRecordToUpdate(searchObj);
  }, [searchObj]);

  // NEXT SET OF DATA - When we press More Button
  const showMoreItemsHandler = async () => {
    const db = getFirestore();
    let { searchCriteria, searchValue } = searchObj;
    const next = query(
      collection(db, "records"),
      where(searchCriteria, ">=", searchValue.toLowerCase()),
      where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff"),
      startAfter(last),
      limit(5)
    );
    const querySnapshot = await getDocs(next);

    if (querySnapshot.empty) {
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLast(lastVisible);

    querySnapshot.forEach((doc) => {
      setSearchData((prevState) => {
        return [...prevState, { ...doc.data(), docId: doc.id }];
      });
    });
  };

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setUpdateRecordModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  const onSubmitUpdateRecordHandler = async () => {
    const db = getFirestore();
    const storage = getStorage();
    const recordRef = doc(db, "records", recordToEdit.docId);

    // before updating the image in storage, we want to remove the previously used one, if any.
    let recordSnap = await getDoc(recordRef);
    let imgRef = recordSnap.data().imageRef;

    // if user wants to update image (imgUrl is present) and there is a previous reference to an image (imgRef), we remove the previous image from storage, before uploading a new one
    if (imgRef && imgUrl) {
      // Create a reference to the file to delete
      const storedImgRef = ref(storage, imgRef);
      // Delete the file
      deleteObject(storedImgRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error);
        });
    }

    // let genre = recordToEdit.genre.map((genre) => genre.trim()).filter((genre) => genre !== "");

    let genre = checklistOptions.filter((option) => option.checked).map((option) => option.value);

    if (genre.length) {
      setIsRequired(false);
    } else {
      setIsRequired(true);
      return;
    }

    recordToEdit.genre = genre;

    let tracklist = recordToEdit.tracklist.map((track) => track.trim()).filter((track) => track !== "");
    recordToEdit.tracklist = tracklist;

    await updateDoc(recordRef, { ...recordToEdit });

    if (imgName) {
      let storageRef = ref(storage, `recordcovers/${imgName}`);
      uploadString(storageRef, imgUrl, "data_url").then((snapshot) => {
        console.log("Uploaded a data_url string!");
      });
    }

    setSearchData((prevState) => {
      return [...prevState].map((record) => {
        return record.docId === recordToEdit.docId ? { ...recordToEdit } : record;
      });
    });

    setUpdateRecordModal(false);
    flashMessageHandler({ message: "Record Updated", success: true });
  };
  const onCancelUpdateRecordHandler = () => {
    setUpdateRecordModal(false);
    setRecordToEdit({
      id: "",
      title: "",
      artist: "",
      imageRef: "",
      overview: "",
      tracklist: [],
      docId: "",
    });
  };

  const editRecordHandler = (id) => {
    const record = searchData.filter((record) => record.docId === id)[0];

    let genreArr = record.genre;

    setChecklistOptions((prevState) => {
      return prevState.map((option) =>
        genreArr.includes(option.value) ? { ...option, checked: true } : { ...option, checked: false }
      );
    });
    setRecordToEdit(record);
    setUpdateRecordModal(true);
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };
  return (
    <Fragment>
      <UpdateRecordModal
        show={updateRecordModal}
        onSubmit={onSubmitUpdateRecordHandler}
        onCancel={onCancelUpdateRecordHandler}
        record={recordToEdit}
        editRecord={setRecordToEdit}
        setImgUrl={setImgUrl}
        setImgName={setImgName}
        setChecklistOptions={setChecklistOptions}
        checklistOptions={checklistOptions}
        isRequired={isRequired}
      />
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <UpdateRecordToolbar onChangeSearchCriteria={setSearchObj} />
      {isPending ? (
        "Loading..."
      ) : searchData.length > 0 ? (
        <table className={styles.books}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Artist</th>
              <th>Recordcover</th>
              <th>Description</th>
              <th>Tracklist</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {searchData.map((data) => {
              return (
                <SearchResultRow
                  key={uuid()}
                  id={data.id}
                  docId={data.docId}
                  title={data.title}
                  artist={data.artist}
                  imageRef={data.imageRef}
                  description={data.overview}
                  tracklist={data.tracklist ? data.tracklist : []}
                  onEditRecord={editRecordHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : !searchObj ? (
        <p>Search for a record...</p>
      ) : (
        <p>No results</p>
      )}
      {searchData.length > 0 ? <NextButton onClick={showMoreItemsHandler} /> : ""}
    </Fragment>
  );
};

export default UpdateRecord;
