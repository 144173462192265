import Edit from "../../../../../assets/icons/Edit";

import styles from "./SearchResultRow.module.css";

const SearchResultRow = (props) => {
  return (
    <tr className={styles.tr}>
      {/* id property is an array. Im joining it to render it as a string */}
      <td data-label='ID'>{props.id.join(",")}</td>
      <td data-label='TITLE'>{props.title}</td>
      <td data-label='AUTHOR'>{props.author}</td>
      <td data-label='BOOKCOVER'>{props.imageRef ? "available" : "not available"}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onEditBook(props.docId)}>
          <Edit />
        </button>
      </td>
    </tr>
  );
};

export default SearchResultRow;
