import styles from "./Events.module.css";

const Events = () => {
  return (
    <svg className={styles.svg} version='1.1' id='圖層_1' x='0' y='0' viewBox='0 0 100 100'>
      <path
        d='M63.833 40.374c-8.612-8.612-19.241-11.944-23.742-7.443-.938.938-1.517 2.154-1.798 3.551l-22.16 43.776c-1.445 1.445-.866 4.366 1.292 6.524s5.079 2.737 6.524 1.292l43.776-22.16c1.397-.281 2.613-.859 3.551-1.798 4.501-4.501 1.169-15.131-7.443-23.742z'
        fill='#e15b64'
      />
      <path
        d='M64.024 40.183c-8.813-8.813-19.692-12.223-24.298-7.617-.96.96-1.553 2.204-1.84 3.634-1.091 5.429 2.481 13.688 9.457 20.664 6.976 6.976 15.234 10.548 20.664 9.457 1.43-.287 2.674-.88 3.634-1.84 4.606-4.606 1.196-15.485-7.617-24.298z'
        fill='#c33737'
      />
      <circle cx='67.115' cy='27.842' r='3.746' fill='#a0c8d7' />
      <circle cx='81.925' cy='19.141' r='5.417' fill='#abbd81' />
      <circle cx='13.695' cy='46.571' r='4.264' fill='#f8b26a' />
      <circle cx='87.342' cy='66.279' r='3.227' fill='#a0c8d7' />
      <g>
        <path
          d='M49.999 41.615a1.382 1.382 0 0 1-1.186-2.093c6.352-10.604 6.799-20.403 1.368-29.955a1.382 1.382 0 1 1 2.404-1.366c5.886 10.35 5.415 21.367-1.398 32.742-.26.433-.718.672-1.188.672z'
          fill='#a0c8d7'
        />
      </g>
      <g>
        <path
          d='M60.748 56.926a1.382 1.382 0 0 1-1.245-1.983c6.034-12.548 17.871-18.149 29.464-13.937a1.383 1.383 0 0 1-.946 2.6c-10.341-3.76-20.558 1.16-26.027 12.536a1.379 1.379 0 0 1-1.246.784z'
          fill='#abbd81'
        />
      </g>
      <g>
        <path
          d='M30.637 37.719a1.382 1.382 0 0 1-1.309-1.828c2.63-7.738 1.474-15.193-3.533-22.79a1.382 1.382 0 1 1 2.308-1.522c5.433 8.241 6.726 16.72 3.843 25.201a1.38 1.38 0 0 1-1.309.939z'
          fill='#abbd81'
        />
      </g>
      <g>
        <path
          d='M76.055 89.711a5.99 5.99 0 0 1-2.021-.342c-2.548-.906-4.394-3.464-5.641-7.82-.763-3.191-1.961-5.123-3.576-5.802-1.736-.729-4.213-.144-7.357 1.745-4.978 2.858-8.9 3.547-12.012 2.12-3.08-1.414-5.095-4.796-6.159-10.341a1.383 1.383 0 0 1 2.716-.52c.872 4.539 2.418 7.348 4.597 8.347 2.209 1.015 5.392.343 9.458-1.99 3.975-2.387 7.196-3.015 9.831-1.91 2.473 1.04 4.216 3.613 5.179 7.65.954 3.329 2.269 5.339 3.893 5.917 1.448.512 3.375-.059 5.731-1.7a1.382 1.382 0 1 1 1.581 2.269c-2.273 1.581-4.356 2.377-6.22 2.377z'
          fill='#f8b26a'
        />
      </g>
      <circle cx='55.503' cy='89.647' r='2.853' fill='#abbd81' />
      <path fill='#f8b26a' d='M38.014 15.107l1.152 7.146 6.915 2.305-2.305-7.377z' />
      <path fill='#f8b26a' d='M81.925 50.835l-1.959 4.708 1.959 3.13 5.417-3.919z' />
    </svg>
  );
};

export default Events;
