const getTranslatedWeekday = (weekday) => {
  let day;
  switch (weekday) {
    case "Monday":
      day = "E hanë";
      break;
    case "Tuesday":
      day = "E martë";
      break;
    case "Wednesday":
      day = "E mërkurë";
      break;
    case "Thursday":
      day = "E ejte";
      break;
    case "Friday":
      day = "E premte";
      break;
    case "Saturday":
      day = "E shtunë";
      break;
    case "Sunday":
      day = "E diel";
      break;
  }
  return day;
};

export default getTranslatedWeekday;
