export const vinylCategoriesData = [
  {
    genre: "ROCK",
    numberOfItems: 54,
    queryString: "rock",
    buttonLabel: "ROCK",
  },
  {
    genre: "POP",
    numberOfItems: 20,
    queryString: "pop",
    buttonLabel: "POP",
  },
  {
    genre: "FOLK",
    numberOfItems: 21,
    queryString: "folk",
    buttonLabel: "FOLK",
  },
  {
    genre: "WORLD",
    numberOfItems: 21,
    queryString: "world",
    buttonLabel: "WORLD",
  },
  {
    genre: "COUNTRY",
    numberOfItems: 21,
    queryString: "country",
    buttonLabel: "COUNTRY",
  },
  {
    genre: "ELECTRONIC",
    numberOfItems: 10,
    queryString: "electronic",
    buttonLabel: "ELECTRONIC",
  },
  {
    genre: "JAZZ",
    numberOfItems: 48,
    queryString: "jazz",
    buttonLabel: "JAZZ",
  },
  {
    genre: "FUNK/SOUL",
    numberOfItems: 5,
    queryString: "funk/soul",
    buttonLabel: "FUNK/SOUL",
  },
  {
    genre: "LATIN",
    numberOfItems: 2,
    queryString: "latin",
    buttonLabel: "LATIN",
  },
  {
    genre: "BLUES",
    numberOfItems: 4,
    queryString: "blues",
    buttonLabel: "BLUES",
  },
  {
    genre: "CLASSICAL",
    numberOfItems: 6,
    queryString: "classical",
    buttonLabel: "CLASSICAL",
  },
  {
    genre: "REGGAE",
    numberOfItems: 1,
    queryString: "reggae",
    buttonLabel: "REGGAE",
  },
  {
    genre: "ALTERNATIVE",
    numberOfItems: 1,
    queryString: "alternative",
    buttonLabel: "ALTERNATIVE",
  },
  {
    genre: "STAGE&SCREEN",
    numberOfItems: 2,
    queryString: "stage&screen",
    buttonLabel: "STAGE&SCREEN",
  },
  {
    genre: "DANCE",
    numberOfItems: 1,
    queryString: "dance",
    buttonLabel: "DANCE",
  },
];

export const vinylCategoriesDataALB = [
  {
    genre: "ROCK",
    numberOfItems: 54,
    queryString: "rock",
    buttonLabel: "ROCK",
  },
  {
    genre: "POP",
    numberOfItems: 20,
    queryString: "pop",
    buttonLabel: "POP",
  },
  {
    genre: "FOLK",
    numberOfItems: 21,
    queryString: "folk",
    buttonLabel: "FOLK",
  },
  {
    genre: "WORLD",
    numberOfItems: 21,
    queryString: "world",
    buttonLabel: "NGA BOTA",
  },
  {
    genre: "COUNTRY",
    numberOfItems: 21,
    queryString: "country",
    buttonLabel: "COUNTRY",
  },
  {
    genre: "ELECTRONIC",
    numberOfItems: 10,
    queryString: "electronic",
    buttonLabel: "ELEKTRONIKE",
  },
  {
    genre: "JAZZ",
    numberOfItems: 48,
    queryString: "jazz",
    buttonLabel: "JAZZ",
  },
  {
    genre: "FUNK/SOUL",
    numberOfItems: 5,
    queryString: "funk/soul",
    buttonLabel: "FUNK/SOUL",
  },
  {
    genre: "LATIN",
    numberOfItems: 2,
    queryString: "latin",
    buttonLabel: "LATINE",
  },
  {
    genre: "BLUES",
    numberOfItems: 4,
    queryString: "blues",
    buttonLabel: "BLUES",
  },
  {
    genre: "CLASSICAL",
    numberOfItems: 6,
    queryString: "classical",
    buttonLabel: "KLASIKE",
  },
  {
    genre: "REGGAE",
    numberOfItems: 1,
    queryString: "reggae",
    buttonLabel: "REGGAE",
  },
  {
    genre: "ALTERNATIVE",
    numberOfItems: 1,
    queryString: "alternative",
    buttonLabel: "ALTERNATIVË",
  },
  {
    genre: "STAGE&SCREEN",
    numberOfItems: 2,
    queryString: "stage&screen",
    buttonLabel: "TEATËR/EKRAN",
  },
  {
    genre: "DANCE",
    numberOfItems: 1,
    queryString: "dance",
    buttonLabel: "MUZIKË PËR VALLËZIM",
  },
];
