import React from "react";
import ReactDOM from "react-dom";
import styles from "./FlashMessage.module.css";

const FlashMessageOverlay = (props) => {
  const classes = `${styles["flash-message"]} ${
    props.error ? styles.success : props.error === false ? styles.fail : ""
  }`;
  return <div className={classes}>{props.children}</div>;
};

const FlashMessage = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <FlashMessageOverlay children={props.children} error={props.error} />,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};

export default FlashMessage;
