import PastaAndRisottoDesktop from "./PastaAndRisottoDesktop";
import PastaAndRisottoMobile from "./PastaAndRisottoMobile";
import styles from "./PastaAndRissotoALB.module.css";

const PastaAndRissotoALB = (props) => {
  return (
    <div className={styles.container}>
      <PastaAndRisottoDesktop onShowModalHandler={props.showModal} />
      <PastaAndRisottoMobile onShowModalHandler={props.showModal} />
    </div>
  );
};

export default PastaAndRissotoALB;
