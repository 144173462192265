import styles from "./CheckboxList.module.css";

const CheckboxList = ({ options, onChange, setChecklistOptions, categories }) => {
  const onChangeHandler = (ev, value) => {
    onChange(ev, value);
  };
  return (
    <div className={styles.checkboxList}>
      {options.map((option) => (
        <label key={option.label} className={styles.label}>
          <input
            className={styles.checkbox}
            type='checkbox'
            value={option.value}
            checked={option.checked}
            onChange={(ev) => onChangeHandler(ev, option.value)}
            disabled={true}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default CheckboxList;
