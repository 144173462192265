import { Fragment, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import DrinksNav from "./DrinksNav";
import { LanguageContext } from "../../../contexts/Language/LanguageContext";
import SoftDrinks from "./SoftDrinks/SoftDrinks";
import SoftDrinksALB from "./SoftDrinksALB/SoftDrinksALB";
import Beers from "./Beers/Beers";
import BeersALB from "./BeersALB/BeersALB";
import Cocktails from "./Cocktails/Cocktails";

import styles from "./Drinks.module.css";

const Drinks = () => {
  const { language } = useContext(LanguageContext);
  return (
    <Fragment>
      <DrinksNav />
      <div className={styles.container}>
        <Routes>
          <Route path='/softdrinks' element={language === "albanian" ? <SoftDrinksALB /> : <SoftDrinks />} />
          <Route path='/beers' element={language === "albanian" ? <BeersALB /> : <Beers />} />
          <Route path='/cocktails' element={<Cocktails />} />
        </Routes>
      </div>
    </Fragment>
  );
};

export default Drinks;
