import { useEffect, useState, useContext } from "react";
import { MultiLingualContent } from "../../../../contexts/Language/MultiLingualContent";
import { LanguageContext } from "../../../../contexts/Language/LanguageContext";
import AuthContext from "../../../../contexts/AuthContext";
import BorrowedBook from "./BorrowedBook";
// FIREBASE
import { getFirestore, doc, getDoc } from "firebase/firestore";
import styles from "./Borrowed.module.css";

const Borrowed = () => {
  const [book, setBook] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const authCtx = useContext(AuthContext);
  const { uid } = authCtx.authUser;

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const getBorrowedBook = async () => {
      const db = getFirestore();
      const userRef = doc(db, "users", uid);
      const userSnap = await getDoc(userRef);
      let borrowedBook = userSnap.data().borrowed[0];

      if (!borrowedBook) {
        setIsPending(false);
        return;
      }
      setBook(borrowedBook);
      setIsPending(false);
    };
    getBorrowedBook();
  }, [uid]);

  const onRenewHandler = (updatedBook) => {
    setBook(updatedBook);
  };

  return isPending ? (
    language === "albanian" ? (
      "Duke shkarkuar..."
    ) : (
      "Loading..."
    )
  ) : book ? (
    <BorrowedBook book={book} onRenew={onRenewHandler} />
  ) : (
    <p className={styles.note}>
      <MultiLingualContent contentId='myLibrary' prop='note1' />
    </p>
  );
};

export default Borrowed;
