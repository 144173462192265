import img1 from "../../../../assets/images/foodAndDrinks/menuPhotos/burgers/1.jpg";
import img2 from "../../../../assets/images/foodAndDrinks/menuPhotos/burgers/2.jpg";
import img3 from "../../../../assets/images/foodAndDrinks/menuPhotos/burgers/3.jpg";
import img4 from "../../../../assets/images/foodAndDrinks/menuPhotos/burgers/4.jpg";
import img5 from "../../../../assets/images/foodAndDrinks/menuPhotos/burgers/5.jpg";
import img6 from "../../../../assets/images/foodAndDrinks/menuPhotos/burgers/6.jpg";
import img7 from "../../../../assets/images/foodAndDrinks/menuPhotos/burgers/7.jpg";
import img8 from "../../../../assets/images/foodAndDrinks/menuPhotos/burgers/8.jpg";
import img9 from "../../../../assets/images/foodAndDrinks/menuPhotos/burgers/9.jpg";

export const BurgersImages = [
  {
    url: img1,
    alt: "A burger bun cut in half with two layers of beef patty, melted yellow cheese and slices of gherkins",
  },
  {
    url: img2,
    alt: "A burger bun cut in half with two layers of beef patty, red pepper and a slice of white onion, with a sauce on the bottom",
  },
  {
    url: img3,
    alt: "A burger bun cut in half with two layers of beef patty, melted yellow cheese and creamy white sauce on top, on a bed of lettuce",
  },
  {
    url: img4,
    alt: "A burger on a plate with wedges and a ramiken filled with creamy white sauce on the side, seen in between some leaves in the foreground.",
  },
  {
    url: img5,
    alt: "A burger in close up with a crunchy golden deep fried chicken, on top of lettuce and white sauce, covered with a slice of tomato, couple of slices of gherkins and the top of the burger bun",
  },
  {
    url: img6,
    alt: "Long hot dog bread roll with a sausage in between and yellow mustard sauce on top held by a hand. Below the hotdog is a plate with potato chips on the side. Same person is holding a dark glass of beer with their right hand",
  },
  {
    url: img7,
    alt: "Long bread roll filled with cooked ground beef with melted yellow cheese on top, on a plate",
  },
  {
    url: img8,
    alt: "Pita bread rolls cut in half and filled with pieces of beef, green leaves on aplate, with a side dollop of sour curds covered with green dressing",
  },
  {
    url: img9,
    alt: "Pita bread rolls cut in hald and filled with zuchini balls, green leaves and diced peppers, with a dollop of sour curds covered with green dressing on the side",
  },
];
