import styles from "./SaladsMobile.module.css";

const SaladsMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='salads'>
        Leaf Salad w. iceberg, red cabbage,rocket, vinaigrette + bread stick <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='salads'>
        Beetroot Salad w. honey walnuts, local white cheese and rocket <span>4.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='salads'>
        Steak Tuna w. spinach, rocket, potatoes, red pepper, sesame seeds + balsamic vinegar{" "}
        <span>12.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='salads'>
        Chicken Caesar w. iceberg, baby tomatoes, parmesan, crutons, grilled chicken + dressing{" "}
        <span>5.50</span>
      </li>
    </ul>
  );
};

export default SaladsMobile;
