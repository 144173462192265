import { useEffect, useState, useContext } from "react";
import { MultiLingualContent } from "../../../../contexts/Language/MultiLingualContent";
import { LanguageContext } from "../../../../contexts/Language/LanguageContext";

import getTranslatedMonth from "../../../../utils/getTranslatedMonth";
import getTranslatedWeekday from "../../../../utils/getTranslatedWeekday";
import noImagePlaceHolder from "../../../../assets/images/No-Image-Placeholder.png";
import styles from "./OnHoldBook.module.css";

// FIREBASE
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const OnHoldBook = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const { language } = useContext(LanguageContext);

  // We convert timestamp to javascript date object

  let holdPlacedWeekday = props.book.holdPlaced.toDate().toLocaleString("default", { weekday: "long" });
  let holdPlacedMonth = props.book.holdPlaced.toDate().toLocaleString("default", { month: "long" });
  let holdPlacedDay = props.book.holdPlaced.toDate().toLocaleString("default", { day: "numeric" });
  let holdPlacedYear = props.book.holdPlaced.toDate().toLocaleString("default", { year: "numeric" });

  let holdExpiresWeekday = props.book.holdExpires.toDate().toLocaleString("default", { weekday: "long" });
  let holdExpiresMonth = props.book.holdExpires.toDate().toLocaleString("default", { month: "long" });
  let holdExpiresDay = props.book.holdExpires.toDate().toLocaleString("default", { day: "numeric" });
  let holdExpiresYear = props.book.holdExpires.toDate().toLocaleString("default", { year: "numeric" });

  if (language === "albanian") {
    holdPlacedWeekday = getTranslatedWeekday(holdPlacedWeekday);
    holdPlacedMonth = getTranslatedMonth(holdPlacedMonth);
    holdExpiresWeekday = getTranslatedWeekday(holdExpiresWeekday);
    holdExpiresMonth = getTranslatedMonth(holdExpiresMonth);
  }

  // Get image reference from storage
  useEffect(() => {
    if (props.book.imageRef) {
      const storage = getStorage();
      const pathReference = ref(storage, props.book.imageRef);

      getDownloadURL(pathReference)
        .then((url) => {
          // `url` is the download URL for 'bookcovers/*'
          setImageUrl(url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
          setImageUrl(noImagePlaceHolder);
        });
    } else {
      setImageUrl(noImagePlaceHolder);
    }
  }, [props.book.imageRef]);

  return (
    <div className={styles["book-container"]}>
      <div className={styles["bookcover-container"]}>
        <img src={imageUrl} className='skeleton' alt='' />
      </div>
      <div className={styles["book-info-container"]}>
        <div>
          <h3 className={styles["book-title"]}>{props.book.title}</h3>
          <span className={styles["book-author"]}>{props.book.author}</span>
        </div>

        <div className={styles["hold-info"]}>
          <p>
            <span>
              <MultiLingualContent contentId='myLibrary' prop='label7' />
            </span>
            <span>
              {holdPlacedWeekday}, {holdPlacedDay} {holdPlacedMonth} {holdPlacedYear}
            </span>
          </p>
          <p>
            <span>
              <MultiLingualContent contentId='myLibrary' prop='label8' />
            </span>
            <span>
              {holdExpiresWeekday}, {holdExpiresDay} {holdExpiresMonth} {holdExpiresYear}
            </span>
          </p>
          <div className={styles.actions}>
            <button className={styles["button-80"]} onClick={props.onCancelHold}>
              <MultiLingualContent contentId='myLibrary' prop='button1' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnHoldBook;
