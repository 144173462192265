import MainsMobile from "./MainsMobile";
import MainsDesktop from "./MainsDesktop";
import styles from "./Mains.module.css";

const Mains = (props) => {
  return (
    <div className={styles.container}>
      <MainsMobile onShowModalHandler={props.showModal} />
      <MainsDesktop onShowModalHandler={props.showModal} />
    </div>
  );
};

export default Mains;
