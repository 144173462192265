export let monthsENG = [
  { label: "all", value: "all" },
  { label: "january", value: 1 },
  { label: "february", value: 2 },
  { label: "march", value: 3 },
  { label: "april", value: 4 },
  { label: "may", value: 5 },
  { label: "june", value: 6 },
  { label: "july", value: 7 },
  { label: "august", value: 8 },
  { label: "september", value: 9 },
  { label: "october", value: 10 },
  { label: "november", value: 11 },
  { label: "december", value: 12 },
];

export let monthsALB = [
  { label: "të gjitha", value: "all" },
  { label: "janar", value: 1 },
  { label: "shkurt", value: 2 },
  { label: "mars", value: 3 },
  { label: "prill", value: 4 },
  { label: "maj", value: 5 },
  { label: "qershor", value: 6 },
  { label: "korrik", value: 7 },
  { label: "gusht", value: 8 },
  { label: "shtator", value: 9 },
  { label: "tetor", value: 10 },
  { label: "nëntor", value: 11 },
  { label: "dhjetor", value: 12 },
];
