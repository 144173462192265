import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";

import { AuthContextProvider } from "./contexts/AuthContext";
import { LanguageContextProvider } from "./contexts/Language/LanguageContext";
import { LastSnapshotContextProvider } from "./contexts/LastSnapshotContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <LastSnapshotContextProvider>
      <LanguageContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </LanguageContextProvider>
    </LastSnapshotContextProvider>
  </BrowserRouter>
);
