import React, { Fragment, useState, useRef, useContext } from "react";
import { LanguageContext } from "../contexts/Language/LanguageContext";
import { MultiLingualContent } from "../contexts/Language/MultiLingualContent";
import ReactDOM from "react-dom";

import FacebookLogInButton from "../components/UI/FacebookLogInButton";
import GoogleLogInButton from "../components/UI/GoogleLogInButton";
import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";
import styles from "./LogInModal.module.css";

// FIREBASE
// Auth
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
} from "firebase/auth";
// Firestore
import { doc, setDoc, getFirestore } from "firebase/firestore";

const Backdrop = (props) => {
  const cssClasses = [styles.backdrop, props.show ? styles.backdropOpen : styles.backdropClosed];
  return <div className={cssClasses.join(" ")} />;
};

const SignInForm = (props) => {
  const [error, setError] = useState("");
  const emailRef = useRef();
  const passwordRef = useRef();

  const { language } = useContext(LanguageContext);

  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    const auth = getAuth();
    const email = emailRef.current.value.trim();
    const password = passwordRef.current.value.trim();

    props.setResetNote(false);

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        emailRef.current.value = "";
        passwordRef.current.value = "";
        setError("");
        props.onCancel();
      })
      .catch((error) => {
        const errorMessage = error.message;
        setError(errorMessage);
      });
  };
  return (
    <form onSubmit={onSubmitHandler}>
      <div className={styles.container}>
        {/* translate this bit */}
        <div className={styles.control}>
          <label>
            <MultiLingualContent contentId='authentication' prop='label1' />
          </label>
          <input
            ref={emailRef}
            type='email'
            placeholder={language === "albanian" ? "Adresa e email-it" : "Email address"}
            required
          />
        </div>
        <div className={styles.control}>
          <input
            ref={passwordRef}
            type='password'
            placeholder={language === "albanian" ? "Fjalëkalimi" : "Password"}
            required
          />
        </div>
        <button
          type='button'
          className={styles.btn}
          onClick={() => {
            props.onClick("forgotPassword");
          }}>
          <MultiLingualContent contentId='authentication' prop='button5' />
        </button>
        <button type='submit' className={styles["submit-btn"]}>
          <MultiLingualContent contentId='authentication' prop='button3' />
        </button>
        <p className={styles.note}>
          <span>
            <MultiLingualContent contentId='authentication' prop='note1' />
          </span>
          <button
            type='button'
            className={styles.btn}
            onClick={() => {
              props.onClick("signup");
            }}>
            <MultiLingualContent contentId='authentication' prop='link1' />
          </button>
        </p>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </form>
  );
};

const ForgotPasswordForm = (props) => {
  const [error, setError] = useState("");
  const emailRef = useRef();

  const { language } = useContext(LanguageContext);

  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    const auth = getAuth();
    const email = emailRef.current.value.trim();

    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        props.onClick("signin");
        props.setResetNote(true);
      })
      .catch((error) => {
        const errorMessage = error.message;
        setError(errorMessage);
      });
  };
  return (
    <form onSubmit={onSubmitHandler}>
      <div className={styles.container}>
        <div className={styles.control}>
          <label>
            <MultiLingualContent contentId='authentication' prop='label3' />
          </label>
          <p>
            <MultiLingualContent contentId='authentication' prop='label4' />
          </p>
          <input
            ref={emailRef}
            type='email'
            placeholder={language === "albanian" ? "Adresa e email-it" : "Email address"}
            required
          />
        </div>
        <button
          type='button'
          className={styles.btn}
          onClick={() => {
            props.onClick("signin");
          }}>
          <MultiLingualContent contentId='authentication' prop='button7' />
        </button>
        <button type='submit' className={styles["submit-btn"]}>
          <MultiLingualContent contentId='authentication' prop='button6' />
        </button>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </form>
  );
};

const SignUpForm = (props) => {
  const [error, setError] = useState("");
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  const { language } = useContext(LanguageContext);

  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    setError("");

    const firstName = capitalizeFirstLetter(firstNameRef.current.value.trim());
    const lastName = capitalizeFirstLetter(lastNameRef.current.value.trim());
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (password.length < 6) {
      return setError(
        language === "albanian"
          ? "Email-i duhet të jetë së paku me 6 karaktere"
          : "Email must be at least 6 characters long"
      );
    }

    const auth = getAuth();

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        // Adding user to users collection when they sign up
        const uid = userCredential.user.uid;
        const db = getFirestore();
        await setDoc(doc(db, "users", uid), {
          fullName: firstName + " " + lastName,
          email: email,
          phone: null,
          member: false,
          depositAmount: null,
          onHold: [],
          borrowed: [],
          wishlist: [],
          borrowingHistory: [],
          roles: { isAdmin: false },
        });

        // Reset Form
        firstNameRef.current.value = "";
        lastNameRef.current.value = "";
        emailRef.current.value = "";
        passwordRef.current.value = "";
        // Remove any previous errors from UI
        setError("");
        // Remove Modal
        props.onCancel();
      })
      .catch((error) => {
        const errorMessage = error.message;
        setError(errorMessage);
      });
  };
  return (
    <form onSubmit={onSubmitHandler}>
      <div className={styles.container}>
        <div className={styles.control}>
          <label>
            <MultiLingualContent contentId='authentication' prop='label2' />
          </label>
          <input
            ref={firstNameRef}
            type='text'
            placeholder={language === "albanian" ? "Emni" : "First Name"}
            required
          />
        </div>
        <div className={styles.control}>
          <input
            ref={lastNameRef}
            type='text'
            placeholder={language === "albanian" ? "Mbiemni" : "Last Name"}
            required
          />
        </div>
        <div className={styles.control}>
          <input
            ref={emailRef}
            type='email'
            placeholder={language === "albanian" ? "Adresa e email-it" : "Email address"}
            required
          />
        </div>
        <div className={styles.control}>
          <input
            ref={passwordRef}
            type='password'
            placeholder={language === "albanian" ? "Fjalëkalimi" : "Password"}
            required
          />
        </div>
        <button type='submit' className={styles["submit-btn"]}>
          <MultiLingualContent contentId='authentication' prop='button4' />
        </button>
        <p className={styles.note}>
          <span>
            <MultiLingualContent contentId='authentication' prop='note2' />
          </span>
          <button
            type='button'
            className={styles.btn}
            onClick={() => {
              props.onClick("signin");
            }}>
            <MultiLingualContent contentId='authentication' prop='link2' />
          </button>
        </p>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </form>
  );
};

const Modal = (props) => {
  const [typeOfForm, setTypeOfForm] = useState("signin");
  const [resetNote, setResetNote] = useState(false);
  const [error, setError] = useState("");
  const cssClasses = [styles.modal, props.show ? styles.modalOpen : styles.modalClosed];

  const formHandler = (typeOfFormStr) => {
    setTypeOfForm(typeOfFormStr);
    setResetNote(false);
  };

  const signInWithGoogleHandler = () => {
    // Remove any previous errors from the UI
    setError("");
    setResetNote(false);
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        const uid = user.uid;
        const isFirstLogin = getAdditionalUserInfo(result).isNewUser;

        // Adding user to users collection only when they login for the first time
        if (isFirstLogin) {
          const db = getFirestore();
          await setDoc(doc(db, "users", uid), {
            fullName: user.providerData[0].displayName,
            email: user.providerData[0].email,
            phone: null,
            member: false,
            depositAmount: null,
            onHold: [],
            borrowed: [],
            wishlist: [],
            borrowingHistory: [],
            roles: { isAdmin: false },
          });
        }
        props.onCancel();
      })
      .catch((error) => {
        const errorMessage = error.message;
        setError(errorMessage);
      });
  };

  const signInWithFacebookHandler = () => {
    // Remove any previous errors from the UI
    setError("");
    setResetNote(false);
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    // I don't like using async/await here. very nested. Is there a different way?
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        const user = result.user;
        const uid = user.uid;
        const isFirstLogin = getAdditionalUserInfo(result).isNewUser;

        // Adding user to users collection only when they login for the first time
        if (isFirstLogin) {
          const db = getFirestore();

          await setDoc(doc(db, "users", uid), {
            fullName: user.providerData[0].displayName,
            email: user.providerData[0].email,
            phone: null,
            member: false,
            depositAmount: null,
            onHold: [],
            borrowed: [],
            wishlist: [],
            borrowingHistory: [],
            roles: { isAdmin: false },
          });
        }

        props.onCancel();
      })
      .catch((error) => {
        const errorMessage = error.message;
        setError(errorMessage);
      });
  };

  return (
    <div className={cssClasses.join(" ")}>
      <div className={styles["close-modal"]} onClick={props.onCancel}>
        <svg width='20' height='21' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <line x1='1.35355' y1='0.646447' x2='25.3536' y2='24.6464' stroke='black'></line>
          <line x1='0.646447' y1='24.6464' x2='24.6464' y2='0.646448' stroke='black'></line>
        </svg>
      </div>
      <div className={styles.inner}>
        <h1>
          <MultiLingualContent contentId='authentication' prop='title1' />
        </h1>
        <div className={styles["btn-signin-container"]}>
          <GoogleLogInButton onClick={signInWithGoogleHandler} />
          <FacebookLogInButton onClick={signInWithFacebookHandler} setResetNote={setResetNote} />
        </div>
        {resetNote && (
          <p className={styles.resetPasswordNote}>
            <MultiLingualContent contentId='authentication' prop='note3' />
          </p>
        )}
        {<p className={styles["providers-error"]}>{error}</p>}
        {typeOfForm === "signin" && (
          <SignInForm
            onClick={(str) => {
              formHandler(str);
            }}
            onCancel={props.onCancel}
            setResetNote={setResetNote}
          />
        )}
        {typeOfForm === "signup" && (
          <SignUpForm
            onClick={(str) => {
              formHandler(str);
            }}
            onCancel={props.onCancel}
          />
        )}
        {typeOfForm === "forgotPassword" && (
          <ForgotPasswordForm
            onClick={(str) => {
              formHandler(str);
            }}
            setResetNote={setResetNote}
            onCancel={props.onCancel}
          />
        )}
      </div>
    </div>
  );
};

const LogInModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop show={props.show} />, document.getElementById("backdrop-root"))}
      {ReactDOM.createPortal(
        <Modal show={props.show} onCancel={props.onCancel} />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default LogInModal;
