import SoftDrinksMobile from "./SoftDrinksMobile";
import SoftDrinksDesktop from "./SoftDrinksDesktop";
import styles from "./SoftDrinksALB.module.css";

const SoftDrinksALB = (props) => {
  return (
    <div className={styles.container}>
      <SoftDrinksMobile />
      <SoftDrinksDesktop />
    </div>
  );
};

export default SoftDrinksALB;
