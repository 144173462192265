import { NavLink, Routes, Route, Navigate } from "react-router-dom";

import CreateRecordForm from "./RecordsCRUD/CreateRecord/CreateRecordForm";

import RecentlyAddedRecords from "./RecordsCRUD/RecentlyAdded/RecentlyAddedRecords";
import UpdateRecord from "./RecordsCRUD/UpdateRecord/UpdateRecord";
import DeleteRecord from "./RecordsCRUD/DeleteRecord/DeleteRecord";

import styles from "./AdminRecords.module.css";

const AdminRecords = () => {
  return (
    <div>
      <div className={styles.container}>
        <nav className={styles.nav}>
          <ul>
            <NavLink
              to='/admin/records/createRecord'
              className={(navData) => (navData.isActive ? styles.active : "")}>
              <span>Create Record</span>
            </NavLink>
            <NavLink
              to='/admin/records/recentlyAdded'
              className={(navData) => (navData.isActive ? styles.active : "")}>
              <span>Recently Added</span>
            </NavLink>
            <NavLink
              to='/admin/records/updateRecord'
              className={(navData) => (navData.isActive ? styles.active : "")}>
              <span>Update Record</span>
            </NavLink>
            <NavLink
              to='/admin/records/deleteRecord'
              className={(navData) => (navData.isActive ? styles.active : "")}>
              <span>Delete Record</span>
            </NavLink>
          </ul>
        </nav>
        <main className={styles.main}>
          <Routes>
            <Route path='/createRecord' element={<CreateRecordForm />} />
            <Route path='/recentlyAdded/*' element={<RecentlyAddedRecords />} />
            <Route path='/recentlyAdded' element={<Navigate replace to='createRecentlyAddedRecord' />} />
            <Route path='/updateRecord' element={<UpdateRecord />} />
            <Route path='/deleteRecord' element={<DeleteRecord />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default AdminRecords;
