import img1 from "../../../../assets/images/foodAndDrinks/menuPhotos/desserts/1.jpg";
import img2 from "../../../../assets/images/foodAndDrinks/menuPhotos/desserts/2.jpg";
import img3 from "../../../../assets/images/foodAndDrinks/menuPhotos/desserts/3.jpg";
import img4 from "../../../../assets/images/foodAndDrinks/menuPhotos/desserts/4.jpg";
import img5 from "../../../../assets/images/foodAndDrinks/menuPhotos/desserts/5.jpg";
import img6 from "../../../../assets/images/foodAndDrinks/menuPhotos/desserts/6.jpg";
import img7 from "../../../../assets/images/foodAndDrinks/menuPhotos/desserts/7.jpg";
import img8 from "../../../../assets/images/foodAndDrinks/menuPhotos/desserts/8.jpg";

export const DessertsImages = [
  {
    url: img1,
    alt: "A quarter of cheesecake on a plate with a side of red sauce, dusted with icing sugar",
  },
  {
    url: img2,
    alt: "A square piece of brownie on a plate with a dollop of ice cream on top",
  },
  {
    url: img3,
    alt: "Chocolate fondue on a plate with a dollop of ice cream covered with red sauce on the side. A cocktail glass with orange liquid in the foreground",
  },
  {
    url: img4,
    alt: "A square of crusty apple crumble on a plate with a dollop of white ice crea, on the top",
  },
  {
    url: img5,
    alt: "A ramiken filled with golden ceamy filling. A spoon digging into it. A green mint leaf on top of creamy dessert",
  },
  {
    url: img6,
    alt: "A long milk shake glass filled with brownish thick liquid and covered with whipped cream and oreo biscuits",
  },
  {
    url: img7,
    alt: "A long milkshkae glass filled with white liquid and covered with whipped cream and couple of pretzels",
  },
  {
    url: img8,
    alt: "A long milkshkae glass filled with white liquid and covered with whipped cream and multi colored sprinkles.",
  },
];
