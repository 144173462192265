import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import BorrowedBooksRow from "./BorrowedBooksRow";
import BorrowedBooksToolbar from "./BorrowedBooksToolbar";

import FlashMessage from "../../../../components/UI/FlashMessage";

import DeleteModal from "../../../../Modals/DeleteModal";

import styles from "./BorrowedBooks.module.css";
// FIREBASE
import {
  getDocs,
  getFirestore,
  collection,
  doc,
  updateDoc,
  deleteDoc,
  Timestamp,
  getDoc,
  query,
  where,
  addDoc,
} from "firebase/firestore";

const BorrowedBooks = () => {
  const [alert, setAlert] = useState("");
  const [borrowedBooks, setBorrowedBooks] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [searchObj, setSearchObj] = useState(null);

  useEffect(() => {
    const searchBorrowedBookHandler = async (obj) => {
      if (obj === null) {
        return;
      }

      let { searchCriteria, searchValue } = obj;

      if (searchValue === "") {
        return;
      }
      setBorrowedBooks([]);
      const db = getFirestore();
      const q = query(
        collection(db, "borrowedBooks"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff")
      );
      setIsPending(true);

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setIsPending(false);
          return;
        }

        querySnapshot.forEach((doc) => {
          setBorrowedBooks((prevState) => {
            return [...prevState, { ...doc.data(), docId: doc.id }];
          });
        });
      } catch (error) {
        console.log(error);
      }

      setIsPending(false);
    };
    searchBorrowedBookHandler(searchObj);
  }, [searchObj]);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  useEffect(() => {
    const getBorrowedBooks = async () => {
      const db = getFirestore();
      // Get the whole collection
      const q = query(collection(db, "borrowedBooks"));
      let querySnapshot = await getDocs(q);

      // Update UI dont't wait for async calls, by only displaying the ones that are not past due date
      querySnapshot.forEach(async (book) => {
        let dueDate = book.data().dueDate;
        const now = new Date().getTime(); // in miliseconds

        if (now < dueDate.toMillis()) {
          setBorrowedBooks((prevState) => {
            return [...prevState, { ...book.data() }];
          });
        } else {
          let bookRef = doc(db, "borrowedBooks", book.id);
          const bookSnap = await getDoc(bookRef);
          await addDoc(collection(db, "overdueBooks"), { ...bookSnap.data() });
          await deleteDoc(doc(db, "borrowedBooks", book.id));
        }
      });

      setIsPending(false);
    };
    getBorrowedBooks();
  }, []);

  const onShowConfirmReturnModalHandler = (id) => {
    setShowDeleteModal(true);
    setItemId(id);
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  const onConfirmBookReturnHandler = async () => {
    try {
      // Update available copies of the book in books collection.
      const db = getFirestore();
      // const book = borrowedBooks.filter((book) => book.id === itemId)[0];
      // const docId = book.docId;
      const docRef = doc(db, "books", itemId);
      const docSnap = await getDoc(docRef);
      let copies = docSnap.data().copies;
      await updateDoc(docRef, { copies: Number(copies) + 1, available: true });

      //  Remove doc from borrowedBooks
      const borrowedBooksCollectionRef = collection(db, "borrowedBooks");
      const q = query(borrowedBooksCollectionRef, where("docId", "==", itemId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (document) => {
        const bookRef = doc(db, "borrowedBooks", document.id);
        await deleteDoc(bookRef);
      });

      // Remove it also from users account. In order to do this, we need users docId, which we we placed in user object when we placed the hold
      const book = borrowedBooks.filter((book) => book.docId === itemId)[0];
      const userId = book.user.docId;
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      let borrowingHistoryArr = userSnap.data().borrowingHistory;

      delete book.dueDate;
      book.dateReturned = Timestamp.now();

      borrowingHistoryArr.push(book);

      await updateDoc(userRef, { borrowed: [], borrowingHistory: borrowingHistoryArr });
    } catch (error) {
      console.log(error);
    }
    // Update UI
    setBorrowedBooks((prevState) => {
      return [...prevState].filter((item) => item.docId !== itemId);
    });
    flashMessageHandler({ message: "Return Book to Shelf ", success: true });
    setShowDeleteModal(false);
    setItemId(null);
  };
  const onCancelReturnBookHandler = () => {
    setShowDeleteModal(false);
  };

  return (
    <div className={styles.container}>
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <DeleteModal
        showModal={showDeleteModal}
        onConfirm={onConfirmBookReturnHandler}
        onCancel={onCancelReturnBookHandler}
        message={"Has the book been returned?"}
      />

      <BorrowedBooksToolbar onChangeSearchCriteria={setSearchObj} />
      {isPending ? (
        "Loading..."
      ) : borrowedBooks.length > 0 ? (
        <table className={styles["borrowed-books"]}>
          <thead>
            <tr>
              <th>Book Title</th>
              <th>Book Author</th>
              <th>Book ID</th>
              <th>Due Date</th>
              <th>Borrower</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {borrowedBooks.map((data) => {
              return (
                <BorrowedBooksRow
                  key={uuid()}
                  id={data.id}
                  docId={data.docId}
                  title={data.title}
                  author={data.author}
                  dueDate={data.dueDate}
                  name={data.user.fullName}
                  phone={data.user.phone}
                  email={data.user.email}
                  showConfirmModal={onShowConfirmReturnModalHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : !searchObj ? (
        <p>
          No results on this list. Books that are past their due date are automatically placed in Overdue
          Books
        </p>
      ) : (
        <p>No results</p>
      )}
    </div>
  );
};

export default BorrowedBooks;
