import styles from "./Info.module.css";

const Info = () => {
  return (
    // <svg className={styles.svg} viewBox='0 0 100 100'>
    //   <path
    //     d='M47.962 10.051c-20.276 1.006-36.785 17.429-37.9 37.699-.482 8.762 1.87 16.943 6.206 23.736l-4.889 14.443c-.564 1.667 1.027 3.258 2.693 2.693l14.443-4.889c6.793 4.336 14.974 6.688 23.736 6.206 20.27-1.115 36.693-17.624 37.699-37.9 1.175-23.701-18.288-43.165-41.988-41.988z'
    //     fill='#a0c8d7'
    //   />
    //   <g>
    //     <path
    //       d='M54.435 71.5h-8.87A1.565 1.565 0 0 1 44 69.935v-20.87c0-.864.701-1.565 1.565-1.565h8.87c.864 0 1.565.701 1.565 1.565v20.87c0 .864-.701 1.565-1.565 1.565z'
    //       fill='#fff'
    //     />
    //     <path d='M50 28a7.25 7.25 0 0 0 0 14.5A7.25 7.25 0 0 0 50 28z' fill='#fff' />
    //   </g>
    // </svg>
    <svg className={styles.svg} viewBox='0 0 100 100'>
      <g>
        <g>
          <path
            d='M60.812 72.351H39.188a1.519 1.519 0 1 1 0-3.038h21.624a1.519 1.519 0 1 1 0 3.038z'
            fill='#e0e0e0'
          />
        </g>
        <g>
          <path
            d='M60.812 77.414H39.188a1.519 1.519 0 1 1 0-3.038h21.624a1.519 1.519 0 1 1 0 3.038z'
            fill='#e0e0e0'
          />
        </g>
        <g>
          <path
            d='M60.812 82.477H39.188a1.519 1.519 0 1 1 0-3.038h21.624a1.519 1.519 0 1 1 0 3.038z'
            fill='#e0e0e0'
          />
        </g>
        <path
          d='M56.251 90H43.749c-.563 0-1.06-.366-1.228-.903l-.854-2.738a1.287 1.287 0 0 1 1.228-1.67h14.211c.868 0 1.487.841 1.228 1.67l-.854 2.738a1.289 1.289 0 0 1-1.229.903z'
          fill='#e0e0e0'
        />
      </g>
      <g>
        <path
          d='M75.714 35.714c0-14.749-12.417-26.597-27.367-25.662-12.526.783-22.83 10.782-23.954 23.282-.864 9.604 3.56 18.235 10.688 23.321a5.872 5.872 0 0 1 2.453 4.783v.004a5.905 5.905 0 0 0 5.905 5.905H56.56a5.905 5.905 0 0 0 5.905-5.905c0-1.87.864-3.659 2.389-4.741 6.571-4.658 10.86-12.32 10.86-20.987z'
          fill='#f5e169'
        />
        <g>
          <path
            d='M52.879 23.546h-5.758c-.561 0-1.016.455-1.016 1.016v13.549c0 .561.455 1.016 1.016 1.016h5.758c.561 0 1.016-.455 1.016-1.016V24.562c0-.561-.455-1.016-1.016-1.016z'
            fill='#fff'
          />
          <path d='M50 51.787a4.707 4.707 0 0 1 0-9.414 4.706 4.706 0 1 1 0 9.414z' fill='#fff' />
        </g>
      </g>
    </svg>
  );
};

export default Info;
