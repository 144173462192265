import styles from "./Books.module.css";

const Books = () => {
  return (
    // <svg version='1.1' id='圖層_1' x='0' y='0' viewBox='0 0 100 100' className={styles.svg}>
    //   <g>
    //     <path
    //       d='M56.68 84.131L79.862 67.11c.766-.272 1.296-1.177 1.296-2.209V15.096c0-1.511-1.105-2.608-2.226-2.209L56.68 30.277v53.854z'
    //       fill='#666666'
    //     />
    //     <path
    //       d='M43.32 84.131L20.138 67.11c-.766-.272-1.296-1.177-1.296-2.209V15.096c0-1.511 1.105-2.608 2.226-2.209l22.252 17.39v53.854z'
    //       fill='#666666'
    //     />
    //   </g>
    //   <g>
    //     <path
    //       d='M56.68 84.131l29.703-13.015c.933-.269 1.579-1.162 1.579-2.181V19.769c0-1.491-1.347-2.574-2.712-2.181L56.68 30.277v53.854z'
    //       fill='#c9c9c9'
    //     />
    //     <path
    //       d='M43.32 84.131L13.616 71.117c-.933-.269-1.58-1.162-1.58-2.181V19.769c0-1.491 1.347-2.574 2.712-2.181L43.32 30.277v53.854z'
    //       fill='#c9c9c9'
    //     />
    //   </g>
    //   <g>
    //     <path
    //       d='M53.481 87.195h-6.963a3.199 3.199 0 0 1-3.199-3.199V31.084a3.199 3.199 0 0 1 3.199-3.199h6.963a3.199 3.199 0 0 1 3.199 3.199v52.913a3.198 3.198 0 0 1-3.199 3.198z'
    //       fill='#77a4bd'
    //     />
    //     <path
    //       d='M56.68 84.131l31.656-8.66A2.26 2.26 0 0 0 90 73.29V24.124a2.261 2.261 0 0 0-2.857-2.181L56.68 30.277v53.854z'
    //       fill='#666666'
    //     />
    //     <path
    //       d='M43.32 84.131l-31.656-8.66A2.26 2.26 0 0 1 10 73.29V24.124a2.261 2.261 0 0 1 2.857-2.181l30.462 8.334v53.854z'
    //       fill='#666666'
    //     />
    //   </g>
    //   <path
    //     d='M53.481 87.195h-6.963a3.199 3.199 0 0 1-3.199-3.199V31.084a3.199 3.199 0 0 1 3.199-3.199h6.963a3.199 3.199 0 0 1 3.199 3.199v52.913a3.198 3.198 0 0 1-3.199 3.198z'
    //     fill='#77a4bd'
    //   />
    //   <path
    //     d='M53.481 87.195h-6.963a3.199 3.199 0 0 1-3.199-3.199V31.084a3.199 3.199 0 0 1 3.199-3.199h6.963a3.199 3.199 0 0 1 3.199 3.199v52.913a3.198 3.198 0 0 1-3.199 3.198z'
    //     fill='#F5E6C8'
    //   />
    //   <path fill='#F5E6C8' d='M56.68 71.122L90 61.771V51.073l-33.32 9.351z' />
    //   <path fill='#F5E6C8' d='M43.32 71.122L10 61.771V51.073l33.32 9.351z' />
    //   <path fill='#FFFFFF' d='M43.32 63.5h13.361v9.833H43.32z' />
    // </svg>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      id='圖層_1'
      x='0'
      y='0'
      viewBox='0 0 100 100'
      className={styles.svg}>
      <path
        d='M80.325 47.701a.74.74 0 0 0-.442-.539L49.94 34.609a.741.741 0 0 0-.822.167l-28.29 28.972c-.249.255-.221.553-.197.669s.114.401.442.539l4.913 2.06 2.602 1.091 22.428 9.403a.743.743 0 0 0 .822-.167l20.531-21.026 1.971-2.018 5.789-5.929a.738.738 0 0 0 .196-.669z'
        fill='#666666'
      />
      <path
        d='M74.97 57.408L53.714 79.176a3.351 3.351 0 0 1-3.713.753l-23.384-9.804-2.602-1.091-1.842-.772-1.336-.56c-.408-.171-.834-.53-1.228-.132-.175.177-.26.422-.322.663-.217.847-.173 1.773-.139 2.641.036.926.169 1.848.387 2.749.131.541.29 1.075.475 1.6.178.505.367 1.03.652 1.485.167.266.399.568.698.679.068.025.135.051.202.079l.41.172 1.147.481 1.529.641 1.849.775 2.106.883 2.3.964 2.431 1.019 2.5 1.048 2.505 1.05 2.447 1.026 2.327.975 2.143.898a3648.545 3648.545 0 0 0 4.699 1.969l.78.327.281.118a.742.742 0 0 0 .822-.167l26.251-26.883V54.215l-1.147 1.174-1.972 2.019z'
        fill='#e0e0e0'
      />
      <path d='M82.005 50.203a3.364 3.364 0 0 0 .891-3.024 3.366 3.366 0 0 0-1.999-2.437L50.955 32.189a3.354 3.354 0 0 0-3.713.753l-.421.431-1.955 2.002-3.184 3.261-4.008 4.105-4.427 4.534-4.441 4.548-4.049 4.146-3.252 3.33c-.922.944-1.845 1.788-2.587 2.903-.511.767-.825 1.563-1.143 2.424a17.888 17.888 0 0 0-.621 2.043 16.491 16.491 0 0 0-.415 2.697c-.125 1.819.069 3.65.542 5.41.377 1.401.858 2.836 1.789 3.974.443.542.915.948 1.567 1.175.178.062.351.147.525.22l3.455 1.449 5.02 2.105 5.776 2.422 5.723 2.4 4.862 2.038 3.192 1.338.812.34a3.355 3.355 0 0 0 3.713-.753l28.291-28.973a3.369 3.369 0 0 0-1.107-5.46l-.186-.078v-5.442l1.292-1.328zm-3.917 5.667v6.895L51.837 89.648a.742.742 0 0 1-.822.167l-.281-.118-.78-.327-1.215-.509-1.587-.665-1.897-.795-2.143-.898-2.327-.975-2.447-1.026-2.505-1.05-2.5-1.048-2.431-1.019-2.3-.964-2.106-.883-1.849-.775-1.529-.641-1.147-.481-.41-.172a4.966 4.966 0 0 0-.202-.079c-.3-.111-.532-.413-.698-.679-.285-.455-.474-.981-.652-1.485a16.222 16.222 0 0 1-.475-1.6 14.018 14.018 0 0 1-.387-2.749c-.034-.868-.078-1.794.139-2.641.062-.241.147-.486.322-.663.393-.398.819-.04 1.228.132l1.336.56 1.842.772 2.602 1.091L50 79.932a3.355 3.355 0 0 0 3.713-.753L74.97 57.408l1.971-2.018 1.147-1.174v1.654zm2.04-7.5l-5.789 5.929-1.971 2.018-20.531 21.026a.743.743 0 0 1-.822.167l-22.428-9.403-2.602-1.091-4.913-2.06a.738.738 0 0 1-.4-.963l.042-.086a.729.729 0 0 1 .113-.159l28.291-28.972a.74.74 0 0 1 .822-.167l29.943 12.554a.74.74 0 0 1 .442.539.735.735 0 0 1-.197.668z' />
      <path
        d='M45.032 57.32l-1.821-4.671-7.283.927c-.648.083-1.036-.385-.649-.782l18.81-19.28 11.119 4.573-18.81 19.28c-.387.397-1.204.369-1.366-.047z'
        fill='#c33737'
      />
      <path
        d='M80.659 35.49a.74.74 0 0 0-.442-.539L50.274 22.397a.741.741 0 0 0-.822.167l-28.29 28.972c-.249.255-.221.553-.197.669s.114.401.442.539l4.913 2.06 2.602 1.091 22.428 9.403a.743.743 0 0 0 .822-.167l20.531-21.026 1.971-2.018 5.789-5.929a.74.74 0 0 0 .196-.668z'
        fill='#333'
      />
      <path
        d='M75.304 45.196L54.048 66.964a3.351 3.351 0 0 1-3.713.753l-23.384-9.804-2.602-1.091-1.842-.772-1.336-.56c-.408-.171-.834-.53-1.228-.132-.175.177-.26.422-.322.663-.217.847-.173 1.773-.139 2.641.036.926.169 1.848.387 2.749.131.541.29 1.075.475 1.6.178.505.367 1.03.652 1.485.167.266.399.568.698.679.068.025.135.051.202.079l.41.172 1.147.481 1.529.641 1.849.775 2.106.883 2.3.964 2.431 1.019 2.5 1.048 2.505 1.05 2.447 1.026 2.327.975 2.143.898a3648.545 3648.545 0 0 0 4.699 1.969l.78.327.281.118a.742.742 0 0 0 .822-.167L78.423 50.55V42l-1.147 1.174-1.972 2.022z'
        fill='#E0E0E0'
      />
      <path d='M82.339 37.992a3.364 3.364 0 0 0 .891-3.024 3.366 3.366 0 0 0-1.999-2.437L51.288 19.978a3.354 3.354 0 0 0-3.713.753l-.421.431-1.955 2.002-3.184 3.261-4.008 4.105-4.427 4.534-4.441 4.548-4.049 4.146-3.252 3.33c-.922.944-1.845 1.788-2.587 2.903-.511.767-.825 1.563-1.143 2.424a17.888 17.888 0 0 0-.621 2.043 16.491 16.491 0 0 0-.415 2.697c-.125 1.819.069 3.65.542 5.41.377 1.401.858 2.836 1.789 3.974.443.542.915.948 1.567 1.175.178.062.351.147.525.22l3.455 1.449 5.02 2.105 5.776 2.422 5.723 2.4 4.862 2.038 3.192 1.338.812.34a3.355 3.355 0 0 0 3.713-.753L82.339 50.3a3.369 3.369 0 0 0-1.107-5.46l-.186-.078V39.32l1.293-1.328zm-3.918 5.667v6.895l-26.25 26.883a.742.742 0 0 1-.822.167l-.281-.118-.78-.327-1.215-.509-1.587-.665-1.897-.795-2.143-.898-2.327-.975-2.447-1.026-2.505-1.05-2.5-1.048-2.431-1.019-2.3-.964-2.106-.883-1.849-.775-1.529-.641-1.147-.481-.41-.172a4.966 4.966 0 0 0-.202-.079c-.3-.111-.532-.413-.698-.679-.285-.455-.474-.981-.652-1.485a16.222 16.222 0 0 1-.475-1.6 14.018 14.018 0 0 1-.387-2.749c-.034-.868-.078-1.794.139-2.641.062-.241.147-.486.322-.663.393-.398.819-.04 1.228.132l1.336.56 1.842.772 2.602 1.091 23.384 9.804a3.355 3.355 0 0 0 3.713-.753L75.303 45.2l1.971-2.018 1.147-1.174v1.651zm2.041-7.501l-5.789 5.929-1.971 2.018-20.531 21.026a.743.743 0 0 1-.822.167l-22.428-9.403-2.602-1.091-4.913-2.06a.738.738 0 0 1-.4-.963l.042-.086a.729.729 0 0 1 .113-.159l28.291-28.972a.74.74 0 0 1 .822-.167l29.943 12.554a.74.74 0 0 1 .442.539.738.738 0 0 1-.197.668z' />
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M45.365 45.109l-1.821-4.671-7.283.927c-.648.083-1.036-.385-.649-.782l18.81-19.28 11.119 4.573-18.81 19.28c-.387.396-1.204.368-1.366-.047z'
        fill='#c33737'
      />
      <path
        d='M80.325 23.277a.74.74 0 0 0-.442-.539L49.94 10.184a.741.741 0 0 0-.822.167l-28.29 28.972a.737.737 0 0 0-.197.669.74.74 0 0 0 .442.539l4.913 2.06 2.602 1.091 22.428 9.403a.743.743 0 0 0 .822-.167l20.531-21.026 1.971-2.018 5.789-5.929a.736.736 0 0 0 .196-.668z'
        fill='#666666'
      />
      <path
        d='M74.97 32.983L53.714 54.751a3.351 3.351 0 0 1-3.713.753L26.617 45.7l-2.602-1.091-1.842-.772-1.336-.56c-.408-.171-.834-.53-1.228-.132-.175.177-.26.422-.322.663-.217.847-.173 1.773-.139 2.641.036.926.169 1.848.387 2.749.131.541.29 1.075.475 1.6.178.505.367 1.03.652 1.485.167.266.399.568.698.679.068.025.135.051.202.079l.41.172 1.147.481 1.529.641 1.849.775 2.106.883 2.3.964 2.431 1.019 2.5 1.048 2.505 1.05 2.447 1.026 2.327.975 2.143.898a3648.545 3648.545 0 0 0 4.699 1.969l.78.327.281.118a.742.742 0 0 0 .822-.167l26.251-26.883V29.787l-1.147 1.174-1.972 2.022z'
        fill='#e0e0e0'
      />
      <path d='M82.005 25.779a3.364 3.364 0 0 0 .891-3.024 3.366 3.366 0 0 0-1.999-2.437L50.955 7.765a3.354 3.354 0 0 0-3.713.753l-.421.431-1.955 2.002-3.184 3.261-4.008 4.105-4.427 4.534-4.441 4.548-4.049 4.146-3.252 3.33c-.922.944-1.845 1.788-2.587 2.903-.511.767-.825 1.563-1.143 2.424a17.888 17.888 0 0 0-.621 2.043 16.491 16.491 0 0 0-.415 2.697c-.125 1.819.069 3.65.542 5.41.377 1.401.858 2.836 1.789 3.974.443.542.915.948 1.567 1.175.178.062.351.147.525.22l3.455 1.449 5.02 2.105 5.776 2.422 5.723 2.4 4.862 2.038 3.192 1.338.812.34a3.355 3.355 0 0 0 3.713-.753l28.291-28.973a3.369 3.369 0 0 0-1.107-5.46l-.186-.078v-5.442l1.292-1.328zm-3.917 5.667v6.895L51.837 65.224a.742.742 0 0 1-.822.167l-.281-.118-.78-.327-1.215-.509-1.587-.665-1.897-.795-2.143-.898-2.327-.975-2.447-1.026-2.505-1.05-2.5-1.048-2.431-1.019-2.3-.964-2.106-.883-1.849-.775-1.529-.641-1.147-.481-.41-.172a4.966 4.966 0 0 0-.202-.079c-.3-.111-.532-.413-.698-.679-.285-.455-.474-.981-.652-1.485a16.222 16.222 0 0 1-.475-1.6 14.018 14.018 0 0 1-.387-2.749c-.034-.868-.078-1.794.139-2.641.062-.241.147-.486.322-.663.393-.398.819-.04 1.228.132l1.336.56 1.842.772 2.602 1.091L50 55.508a3.355 3.355 0 0 0 3.713-.753L74.97 32.983l1.971-2.018 1.147-1.174v1.655zm2.04-7.501l-5.789 5.929-1.971 2.018-20.531 21.026a.743.743 0 0 1-.822.167l-22.428-9.403-2.602-1.091-4.913-2.06a.738.738 0 0 1-.4-.963l.042-.086a.729.729 0 0 1 .113-.159l28.291-28.972a.74.74 0 0 1 .822-.167l29.943 12.554a.74.74 0 0 1 .442.539.735.735 0 0 1-.197.668z' />
    </svg>
  );
};

export default Books;
