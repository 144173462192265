import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";

import styles from "./PrevButton.module.css";
const PrevButton = (props) => {
  return (
    <button className={styles.button} onClick={props.onClick} disabled={props.buttonState}>
      <MultiLingualContent contentId='events' prop='button2' />
    </button>
  );
};

export default PrevButton;
