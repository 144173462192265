import { useState, useRef } from "react";
import styles from "./CreateNewMemberForm.module.css";

const CreateNewMemberForm = (props) => {
  const [error, setError] = useState("");
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const depositAmountRef = useRef();

  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    setError("");
    const firstName = firstNameRef.current.value.trim();
    const lastName = lastNameRef.current.value.trim();
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;
    const depositAmount = depositAmountRef.current.value;
    const password = passwordRef.current.value;

    if (password.length < 6) {
      return setError("Email must be at least 6 characters long");
    }

    // Object used to create user doc in users collection
    const user = {
      fullName: firstName.toLowerCase() + " " + lastName.toLowerCase(),
      pass: password,
      email: email.trim().toLowerCase(),
      phone: phone,
      member: true,
      depositAmount: depositAmount,
      onHold: [],
      borrowed: [],
      wishlist: [],
    };

    props.onSubmit(user);

    // Reset Form
    firstNameRef.current.value = "";
    lastNameRef.current.value = "";
    emailRef.current.value = "";
    passwordRef.current.value = "";
    phoneRef.current.value = "";
    passwordRef.current.value = "";
    depositAmountRef.current.value = "";

    // Remove any previous errors from UI
    setError("");
    // Remove Modal
    props.onCancel();
  };
  return (
    <form onSubmit={onSubmitHandler}>
      <div className={styles.controls}>
        <label>First Name</label>
        <input
          ref={firstNameRef}
          type='text'
          placeholder='First Name'
          aria-label='First Name'
          autoComplete='off'
          required
        />
      </div>
      <div className={styles.controls}>
        <label>Last Name</label>
        <input
          ref={lastNameRef}
          type='text'
          placeholder='Last Name'
          aria-label='Last Name'
          autoComplete='off'
          required
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='customerPhone'>Phone Number</label>
        <input
          ref={phoneRef}
          type='tel'
          id='customerPhone'
          placeholder='Phone Number'
          autoComplete='off'
          aria-label='Customer Phone'
          required
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='customerEmail'>Email</label>
        <input
          ref={emailRef}
          type='email'
          id='customerEmail'
          placeholder='Email'
          autoComplete='off'
          aria-label='Customer Email'
          required
        />
        <div className={styles.controls}>
          <label htmlFor='customerEmail'>Password</label>
          <input ref={passwordRef} type='password' placeholder='Password' required />
        </div>
      </div>

      <div className={styles.controls}>
        <label htmlFor='typeOfService'>Deposit Amount</label>
        <input type='number' ref={depositAmountRef} required min='1' defaultValue='20'></input>
      </div>
      <div className={styles["modal-actions"]}>
        <button type='button' className={styles["btn-secondary"]} onClick={props.onCancel}>
          Cancel
        </button>
        <button type='submit' className={styles["btn-primary"]}>
          Create Member
        </button>
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </form>
  );
};

export default CreateNewMemberForm;
