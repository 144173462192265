const rolesALB = [
  "Banakier-e",
  "Kamarier-e",
  "Bibliotekist-e",
  "Menaxher-e ngjarjesh",
  "Kuzhinë",
  "Pastrues-e",
  "Financat",
  "Magazina",
  "Tjera",
];

export default rolesALB;
