import { useState } from "react";
import { NavLink } from "react-router-dom";

import CreateEvent from "../../assets/icons/CreateEvent";
import EventList from "../../assets/icons/EventList";

import styles from "./RecentlyAddedBookTab.module.css";

const RecentlyAddedBooksTab = (props) => {
  const [indicatorIndex, setindicatorIndex] = useState(0);

  const onButtonClickHandler = (index) => {
    setindicatorIndex(index);
  };

  return (
    <div className={styles.tabs}>
      <header className={styles["tab-header"]}>
        <div className={styles["tab-btn-container"]}>
          <NavLink
            to='createRecentlyAddedBook'
            className={`${styles["tab-btn"]} ${indicatorIndex === 0 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(0)}>
            <CreateEvent active={indicatorIndex === 0} />
            <span>Create a Book</span>
          </NavLink>
          <NavLink
            to='recentBooks'
            className={`${styles["tab-btn"]} ${indicatorIndex === 0 ? styles.active : ""}`}
            onClick={() => onButtonClickHandler(1)}>
            <EventList active={indicatorIndex === 1} />
            <span>Recently Added Books</span>
          </NavLink>
        </div>
        <div
          style={{ left: `calc(calc(100% / 2)*${indicatorIndex})` }}
          className={styles["tab-indicator"]}></div>
      </header>
    </div>
  );
};

export default RecentlyAddedBooksTab;
