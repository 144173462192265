import React from "react";
import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import FacebookIcon from "../../assets/icons/FacebookIcon";
import styles from "./FacebookLogInButton.module.css";

const FacebookLogInButton = (props) => {
  return (
    <button type='button' className={styles["btn-signin"]} onClick={props.onClick}>
      <i>
        <FacebookIcon />
      </i>
      <span>
        <MultiLingualContent contentId='authentication' prop='button2' />
      </span>
    </button>
  );
};

export default FacebookLogInButton;
