import React from "react";
import Carousel from "./Carousel";
import styles from "./Landing.module.css";

const Landing = () => {
  return (
    <header className={styles.header}>
      <Carousel />
    </header>
  );
};

export default Landing;
