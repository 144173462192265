import Trash from "../../../../assets/icons/Trash";
import styles from "./BorrowedBook.module.css";

const BorrowedBook = (props) => {
  return (
    <div className={styles["borrowed-book"]}>
      <span className={styles.label}>Book</span>
      <div className={styles.item}>
        <div className={styles.book}>
          <div className={styles["book-data"]}>
            <span className={styles.prop}>Title: </span>
            <span>{props.book.title}</span>
          </div>
          <div className={styles["book-data"]}>
            <span className={styles.prop}>Author: </span>
            <span>{props.book.author}</span>
          </div>
        </div>
        <div className={styles.operation}>
          <button className={styles.confirmBtn} type='button' onClick={props.onRemove}>
            <Trash />
          </button>
        </div>
      </div>
    </div>
  );
};
export default BorrowedBook;
