import styles from "./AppetizersMobile.module.css";

const AppetizersMobile = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='appetizers'>
        Warm Olives w. local white cheese, virgin olive oil,side of local potatoes + flat bread{" "}
        <span>4.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='appetizers'>
        Bruschetta w. spinach, crumbled cheese, pear and balsamic cream <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='appetizers'>
        Grilled Cheese w. local white cheese, pine honey, thyme + flat bread <span>4.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='appetizers'>
        Whipped White Cheese w. pomegranate seeds, walnuts, raisins and virgin olive oil <span>3.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='4' category='appetizers'>
        Hummus Dip w. garlic, lemon and black seeds <span>4.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='5' category='appetizers'>
        Stuffed Peppers w. whipped cheese and thyme <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='6' category='appetizers'>
        Shrimp Saganaki w. herebed tomato sauce and ouzo <span>9.00</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='7' category='appetizers'>
        Smashed Potatoes w. sour curd tarator <span>3.50</span>
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='8' category='appetizers'>
        Tzatziki - sour curd, cucumbers, garlic, salt, white vinegar, olive oil ................. 3.50
      </li>
    </ul>
  );
};

export default AppetizersMobile;
