import React from "react";
import ReactToPrint from "react-to-print";
import PrinterIcon from "../../../../assets/icons/PrinterIcon";

import styles from "./JobApplicationsToolbar.module.css";

const JobApplicationsToolbar = React.forwardRef((props, ref) => {
  return (
    <div className={styles["filters-toolbar-wrapper"]}>
      <hr className={styles.hr} />
      <div className={styles["filters-toolbar"]}>
        <div className={styles["control-wrapper"]}>
          <div className={styles.control}>
            <label className={styles.label} htmlFor='SortBy'>
              Sort by
            </label>
            <div className={styles["select-group-wrapper"]}>
              <select
                name='sort_by'
                id='SortBy'
                className={styles.select}
                defaultValue={props.sortType}
                onChange={(ev) => props.onChangeSort(ev.target.value)}>
                <option value='name-ascending'>Name, A-Z</option>
                <option value='name-descending'>Name, Z-A</option>
              </select>
            </div>
          </div>
          <div className={styles["date-control"]}>
            <button className={styles.primary} onClick={props.onShowDateModal}>
              FILTER BY DATE
            </button>
            <button className={styles.secondary} onClick={props.clearDateFilter}>
              Clear Filter
            </button>
          </div>
        </div>
        <ReactToPrint
          trigger={() => (
            <button className={styles["print-btn"]}>
              <PrinterIcon />
            </button>
          )}
          content={() => ref.current}
        />
      </div>

      <hr className={styles.hr} />
    </div>
  );
});

export default JobApplicationsToolbar;
