import { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";

import logo from "../../assets/images/soma_logo.png";
import User from "../../assets/icons/User";
import SignedInUserIcon from "../../assets/icons/SignedInUserIcon.js";
import BurgerButton from "../UI/BurgerButton";

import LanguageButton from "../../contexts/Language/LanguageButton";

import DropDownMenu from "./DropDownMenu";
import LogInModal from "../../Modals/LogInModal";
import styles from "./TopNavBar.module.css";

import AuthContext from "../../contexts/AuthContext";

const TopNavBar = () => {
  const [showLogInModal, setShowLogInModal] = useState(false);
  const [transparent, setTransparent] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 25) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    });
  }, []);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowLogInModal(false);
        setShowNav(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  useEffect(() => {
    function closeDropDown(ev) {
      if (ev.target.tagName !== "BUTTON") {
        setIsOpen(false);
      }
    }
    document.body.addEventListener("click", closeDropDown);

    return () => document.body.removeEventListener("click", closeDropDown);
  }, []);

  const onOpenNavPanel = () => {
    document.body.style.overflow = "hidden";
    setShowNav(true);
  };

  const onCloseNavPanel = () => {
    document.body.style.overflow = "visible";
    setShowNav(false);
  };

  const onShowLoginModal = () => {
    setShowLogInModal(true);
  };

  const onCancelLoginModal = () => {
    setShowLogInModal(false);
  };

  return (
    <header className={`${styles.header} ${transparent ? styles["sticky-header"] : ""}`}>
      <div className={`${styles["nav-panel"]} ${showNav ? styles["nav-open"] : ""}`}>
        <LogInModal show={showLogInModal} onCancel={onCancelLoginModal} />
        {!showNav && <BurgerButton onClick={onOpenNavPanel} />}
        <div className={styles["close-btn"]} onClick={onCloseNavPanel}>
          <span className={styles.one}></span>
          <span className={styles.two}></span>
        </div>
        <NavLink className={styles["logo-link"]} to='/' onClick={onCloseNavPanel}>
          <img src={logo} className={styles.logo} alt='Soma Logo' />
        </NavLink>
        {!authCtx.authUser ? (
          <button className={styles["login-btn"]} onClick={onShowLoginModal}>
            <User />
          </button>
        ) : (
          <div className={styles.test}>
            <button className={styles["login-btn"]} onClick={() => setIsOpen((prevState) => !prevState)}>
              <SignedInUserIcon />
            </button>
            {isOpen && <DropDownMenu />}
          </div>
        )}
        <LanguageButton></LanguageButton>
        <div className={styles["menu-primary-navigation-container"]}>
          <nav className={styles.nav}>
            <ul>
              <li onClick={onCloseNavPanel}>
                <NavLink to='/about' className={(navData) => (navData.isActive ? styles.active : "")}>
                  <MultiLingualContent contentId='navigation' prop='navLink1' />
                </NavLink>
              </li>
              <li onClick={onCloseNavPanel}>
                <NavLink to='/events' className={(navData) => (navData.isActive ? styles.active : "")}>
                  <MultiLingualContent contentId='navigation' prop='navLink2' />
                </NavLink>
              </li>
              {/* <li onClick={onCloseNavPanel}>
                <NavLink to='/reservations' className={(navData) => (navData.isActive ? styles.active : "")}>
                  <MultiLingualContent contentId='navigation' prop='navLink3' />
                </NavLink>
              </li> */}
              <li onClick={onCloseNavPanel}>
                <NavLink
                  to='/menusAndDrinks'
                  className={(navData) => (navData.isActive ? styles.active : "")}>
                  <MultiLingualContent contentId='navigation' prop='navLink4' />
                </NavLink>
              </li>
              <li onClick={onCloseNavPanel}>
                <NavLink
                  to='/booksAndRecords'
                  className={(navData) => (navData.isActive ? styles.active : "")}>
                  <MultiLingualContent contentId='navigation' prop='navLink5' />
                </NavLink>
              </li>
              <li onClick={onCloseNavPanel}>
                <NavLink to='/careers' className={(navData) => (navData.isActive ? styles.active : "")}>
                  <MultiLingualContent contentId='navigation' prop='navLink6' />
                </NavLink>
              </li>
              <li onClick={onCloseNavPanel}>
                <NavLink to='/contact' className={(navData) => (navData.isActive ? styles.active : "")}>
                  <MultiLingualContent contentId='navigation' prop='navLink7' />
                </NavLink>
              </li>
              <li onClick={onCloseNavPanel}>
                <NavLink to='/gallery' className={(navData) => (navData.isActive ? styles.active : "")}>
                  <MultiLingualContent contentId='navigation' prop='navLink8' />
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default TopNavBar;
