import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import styles from "./NextButton.module.css";
const NextButton = (props) => {
  return (
    <button className={styles.button} onClick={props.onClick} disabled={props.buttonState}>
      <MultiLingualContent contentId='events' prop='button1' />
    </button>
  );
};

export default NextButton;
