import ConfirmReservationIcon from "../../../../assets/icons/ConfirmReservationIcon";
import Trash from "../../../../assets/icons/Trash";

import styles from "./HoldRequestRow.module.css";

const HoldRequestRow = (props) => {
  return (
    <tr className={styles.tr}>
      <td data-label='BOOK TITLE'>{props.title}</td>
      <td data-label='BOOK ID'>{props.id}</td>
      <td data-label='NAME'>{props.fullName}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='MEMBER'>{props.member ? "Yes" : "No"}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onConfirmHold(props.docId)}>
          <ConfirmReservationIcon />
        </button>
        <button className={styles.btn} type='button' onClick={() => props.showDeleteModal(props.docId)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default HoldRequestRow;
