import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import Calendar from "../../assets/icons/Calendar";
import BooksAdmin from "../../assets/icons/BooksAdmin";
import Records from "../../assets/icons/Records";
import JobApplicants from "../../assets/icons/JobApplicants";
import Events from "../../assets/icons//Events";

// FIREBASE
import { getFirestore, onSnapshot, collection } from "firebase/firestore";

import styles from "./AdminDesktopNav.module.css";

const AdminDesktopNav = (props) => {
  const [bookHoldCounter, setBookHoldCounter] = useState(0);
  const [reservationCounter, setReservationCounter] = useState(0);
  const [jobApplicationCounter, setJobApplicationCounter] = useState(0);

  const navigate = useNavigate();
  const onGoBackHandler = () => {
    navigate("/about");
  };

  // Get number of reservations requests, job applicants, book hold requests when admin first lands or refreshses this page
  const resetHoldRequestsCounter = () => {
    setBookHoldCounter(0);
  };

  const resetReservationCounter = () => {
    setReservationCounter(0);
  };

  const resetJobApplicationCounter = () => {
    setJobApplicationCounter(0);
  };
  useEffect(() => {
    const getRealTimeCounters = async () => {
      const db = getFirestore();

      onSnapshot(collection(db, "holdRequests"), (querySnapshot) => {
        const holdRequests = [];
        querySnapshot.forEach((doc) => {
          holdRequests.push(doc.data());
        });

        let numberOfRequests = holdRequests.length;
        setBookHoldCounter(numberOfRequests);
      });
      onSnapshot(collection(db, "reservationRequests"), (querySnapshot) => {
        const holdRequests = [];
        querySnapshot.forEach((doc) => {
          holdRequests.push(doc.data());
        });

        let numberOfRequests = holdRequests.length;
        setReservationCounter(numberOfRequests);
      });
      onSnapshot(collection(db, "jobApplications"), (querySnapshot) => {
        const jobApplications = [];
        querySnapshot.forEach((doc) => {
          jobApplications.push(doc.data());
        });

        let numberOfApplicants = jobApplications.length;
        setJobApplicationCounter(numberOfApplicants);
      });
    };
    getRealTimeCounters();
  }, []);

  return (
    <div>
      <nav className={styles.nav}>
        <ul className={styles["nav-list"]}>
          <li className={styles["top-nav"]}>
            <h2>Soma Dashboard</h2>
          </li>
          <li className={styles["nav-item"]}>
            <NavLink
              className={(navData) => (navData.isActive ? `${styles.active} ${styles.link}` : styles.link)}
              to='/admin/events'>
              <Events />
              <span className={styles.label}>Events</span>
            </NavLink>
          </li>
          <li className={styles["nav-item"]}>
            <NavLink
              onClick={resetReservationCounter}
              className={(navData) => (navData.isActive ? `${styles.active} ${styles.link}` : styles.link)}
              to='/admin/tableReservations'>
              <Calendar />
              {reservationCounter > 0 ? <span className={styles.indicator}>{reservationCounter}</span> : ""}
              <span className={styles.label}>Table Reservations</span>
            </NavLink>
          </li>
          <li className={styles["nav-item"]}>
            <NavLink
              onClick={resetHoldRequestsCounter}
              className={(navData) => (navData.isActive ? `${styles.active} ${styles.link}` : styles.link)}
              to='/admin/books'>
              <BooksAdmin />
              {bookHoldCounter > 0 ? <span className={styles.indicator}>{bookHoldCounter}</span> : ""}
              <span className={styles.label}>Books</span>
            </NavLink>
          </li>
          <li className={styles["nav-item"]}>
            <NavLink
              className={(navData) => (navData.isActive ? `${styles.active} ${styles.link}` : styles.link)}
              to='/admin/records'>
              <Records />
              <span className={styles.label}>Records</span>
            </NavLink>
          </li>
          <li className={styles["nav-item"]}>
            <NavLink
              onClick={resetJobApplicationCounter}
              className={(navData) => (navData.isActive ? `${styles.active} ${styles.link}` : styles.link)}
              to='/admin/jobApplications'>
              <JobApplicants />
              {jobApplicationCounter > 0 ? (
                <span className={styles.indicator}>{jobApplicationCounter}</span>
              ) : (
                ""
              )}
              <span className={styles.label}>Job Applications</span>
            </NavLink>
          </li>
        </ul>
        <div className={styles["back-btn-container"]}>
          <button className={styles["back-btn"]}>
            &lt;
            <span onClick={onGoBackHandler}>Back</span>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default AdminDesktopNav;
