import { Fragment } from "react";
import { v4 as uuid } from "uuid";
import RecordItem from "./RecordItem";
import PaginationButtons from "../../../components/UI/PaginationButtons";
import styles from "./RecordList.module.css";

const RecordList = (props) => {
  return (
    <Fragment>
      <ul className={styles["record-list"]}>
        {props.searchResult.map((record) => {
          return (
            <RecordItem
              key={uuid()}
              id={record.id}
              docId={record.docId}
              title={record.title}
              artist={record.artist}
              url={record.url}
              overview={record.overview}
              tracklist={record.tracklist}
              onShowModal={props.onShowRecordDetailsModal}
            />
          );
        })}
      </ul>
      {props.searchResult.length > 0 && (
        <PaginationButtons
          buttonState={props.buttonState}
          prevPage={props.prevPage}
          showMoreItems={props.showMoreItems}
        />
      )}
    </Fragment>
  );
};

export default RecordList;
