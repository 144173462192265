import { useState, useEffect, useRef } from "react";

import { v4 as uuidv4 } from "uuid";
import FlashMessage from "../../../../components/UI/FlashMessage";
import DeleteModal from "../../../../Modals/DeleteModal";
import EditReservationModal from "../../../../Modals/EditReservationModal";
import DateFilterModal from "../../../../Modals/DateFilterModal";

import { reservationTimes } from "../../../Reservations/reservationTimes";
import ConfirmedResToolBar from "./ConfirmedResToolBar";

import PrintableReservationsComponent from "./PrintableReservationsComponent";
import ConfirmedReservationsRow from "./ConfirmedReservationsRow";
import styles from "./ConfirmedReservations.module.css";

// FIREBASE
import {
  getFirestore,
  collection,
  doc,
  deleteDoc,
  updateDoc,
  where,
  getDocs,
  query,
  Timestamp,
  addDoc,
  getDoc,
  orderBy,
} from "firebase/firestore";

const ConfirmedReservations = () => {
  const [alert, setAlert] = useState("");

  const [confirmedReservations, setConfirmedReservations] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [isPending, setIsPending] = useState(true);
  const [dateInputRef, setDateInputRef] = useState(); // this may need to be reworked
  const [sortType, setSortType] = useState("date-ascending");
  const [dateFilter, setDateFilter] = useState("");

  const [reservationId, setReservationId] = useState("");
  const [reservationToEdit, setReservationToEdit] = useState({
    id: null,
    fullName: "",
    phone: "",
    email: "",
    date: "",
    time: "",
    numberOfPeople: "",
    specialRequest: "",
    typeOfService: "",
    tableNumber: "",
  });

  // A ref which is forwarded to the toolbar where print button is located
  const reservationsRef = useRef();

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setShowDeleteModal(false);
        setShowEditModal(false);
        setShowDateFilter(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  // GET DATA FROM FIREBASE SHOULD HAPPEN HERE

  useEffect(() => {
    const getConfirmedRes = async () => {
      const db = getFirestore();
      setConfirmedReservations([]);
      setIsPending(true);
      if (dateFilter) {
        let date = Timestamp.fromDate(new Date(dateFilter));

        const q = query(collection(db, "confirmedReservations"), where("date", "==", date));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          setConfirmedReservations((prevState) => {
            return [...prevState, { ...doc.data(), id: doc.id }];
          });
        });
      } else {
        const q = query(collection(db, "confirmedReservations"), orderBy("date", "asc"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (document) => {
          // Remove reservations that have passed. Old reservations go to past reseravtions
          const resDate = document.data().date;
          let resDateStr = resDate.toDate().toISOString().split("T")[0];

          let resTime = reservationTimes.find((resTime) => resTime.label === document.data().time).value;

          let dateInMiliseconds = new Date(resDateStr + "T" + resTime).getTime();
          let now = Date.now();

          // If reservation date has passed
          if (now > dateInMiliseconds) {
            // place reservation in pastReservations collection
            await addDoc(collection(db, "pastReservations"), document.data());
            // delete it from confirmedReservations collection
            await deleteDoc(doc(db, "confirmedReservations", document.id));
          } else {
            setConfirmedReservations((prevState) => {
              return [...prevState, { ...document.data(), id: document.id }];
            });
          }
        });
      }
      setIsPending(false);
    };

    getConfirmedRes();
  }, [dateFilter]);

  // *** SORTING NON DATE FILTERED DATA - CODE STARTS HERE ***

  useEffect(() => {
    const sortReservations = (type) => {
      const types = {
        "name-ascending": "fullName",
        "name-descending": "fullName",
        "date-ascending": "date",
        "date-descending": "date",
        "people-ascending": "numberOfPeople",
        "people-descending": "numberOfPeople",
      };

      const sortProperty = types[type];

      function byName(a, b) {
        if (a[sortProperty].toLowerCase() > b[sortProperty].toLowerCase()) {
          return 1;
        } else if (b[sortProperty] > a[sortProperty]) {
          return -1;
        } else {
          return 0;
        }
      }

      function byDate(a, b) {
        let d1 = Date.parse(a.date.toDate());
        let d2 = Date.parse(b.date.toDate());
        return d1 - d2;
      }

      if (type === "name-ascending") {
        setConfirmedReservations((prevState) => {
          return [...prevState].sort(byName);
        });
      }

      if (type === "name-descending") {
        setConfirmedReservations((prevState) => {
          return [...prevState].sort(byName).reverse();
        });
      }

      if (type === "date-ascending") {
        setConfirmedReservations((prevState) => {
          return [...prevState].sort(byDate);
        });
      }

      if (type === "date-descending") {
        setConfirmedReservations((prevState) => {
          return [...prevState].sort(byDate).reverse();
        });
      }

      if (type === "people-ascending") {
        setConfirmedReservations((prevState) => {
          return [...prevState].sort((a, b) => parseInt(a[sortProperty]) - parseInt(b[sortProperty]));
        });
      }

      if (type === "people-descending") {
        setConfirmedReservations((prevState) => {
          return [...prevState].sort((a, b) => parseInt(b[sortProperty]) - parseInt(a[sortProperty]));
        });
      }
    };

    sortReservations(sortType);
  }, [sortType]);

  // **** SORTING CODE ENDS *****

  // *** FILTER BY DATE ***

  const onShowDateModalHandler = () => {
    setShowDateFilter(true);
  };

  const onCancelFilterByDateHandler = () => {
    setShowDateFilter(false);
  };

  const onSubmitFilterByDateHandler = (ev, ref) => {
    ev.preventDefault();
    setDateFilter(ref.current.value);
    // Im holding onto date input reaf, to the input element so I can clear it when user clears date filter
    setDateInputRef(ref);
    setShowDateFilter(false);
  };

  const clearDateFilterHandler = () => {
    setDateFilter("");
    // clearing the date input by setting the value of ref to an empty string. This worked to my surprise, and Im not sure if this is good practice.
    setDateInputRef((prevState) => (!prevState ? prevState : (prevState.current.value = "")));
  };

  const flashMessageHandler = ({ message, success }) => {
    setAlert({ message, success });
    setTimeout(() => {
      setAlert((prevState) => {
        return { ...prevState, success: undefined };
      });
    }, 3000);
  };

  // *** DELETE RESERVATION ***
  // Open delete reservation confirmation modal
  const onConfirmHandler = (reservationId) => {
    setShowDeleteModal(true);
    setReservationId(reservationId);
  };
  // Cancel deletion of reservation by removing modal
  const onCancelDeleteReservationHandler = () => {
    setShowDeleteModal(false);
  };

  // Delete reservation and remove modal
  const onDeleteReservationHandler = async () => {
    const db = getFirestore();

    // Add doc to recenltyDeleted collection as a fail safe
    const docSnap = await getDoc(doc(db, "confirmedReservations", reservationId));
    let now = new Date();
    let nextDay = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    now.setDate();

    let deletedRes = {
      ...docSnap.data(),
      deletedFrom: "Confirmed Reservations",
      expires: Timestamp.fromDate(nextDay),
    };
    await addDoc(collection(db, "recentlyDeletedReservations"), deletedRes);
    await deleteDoc(doc(db, "confirmedReservations", reservationId));

    setConfirmedReservations((prevState) => {
      return prevState.filter((reservation) => reservation.id !== reservationId);
    });

    flashMessageHandler({ message: "Reservation Deleted", success: true });
    setShowDeleteModal(false);
    setReservationId(null);
  };

  // *** EDIT RESERVATION ***

  const editReservationHandler = (resId) => {
    setReservationId(resId);
    setShowEditModal(true);
    const reservation = confirmedReservations.filter((res) => res.id === resId)[0];
    setReservationToEdit(reservation);
  };

  const onCancelEditReservationHandler = () => {
    setShowEditModal(false);
  };

  const onSubmitEditHandler = async (ev) => {
    ev.preventDefault();

    let { fullName, phone, email, date, time, numberOfPeople, specialRequest, typeOfService, tableNumber } =
      reservationToEdit;

    let editedReservation = {
      fullName,
      phone,
      email,
      date,
      time,
      numberOfPeople,
      specialRequest,
      typeOfService,
      tableNumber,
    };

    const db = getFirestore();
    await updateDoc(doc(db, "confirmedReservations", reservationId), editedReservation);

    flashMessageHandler({ message: "Reservation Edited ", success: true });

    const indexOfReservation = confirmedReservations.findIndex((res) => res.id === reservationId);
    setConfirmedReservations((prevState) => {
      return [...prevState].map((res, i) => (i === indexOfReservation ? reservationToEdit : res));
    });
    setShowEditModal(false);
  };

  return (
    <div className={styles["confirmed-reservations-container"]}>
      <DeleteModal
        showModal={showDeleteModal}
        onConfirm={onDeleteReservationHandler}
        onCancel={onCancelDeleteReservationHandler}
        message={"Are you sure you want to delete this reservation?"}
      />
      <EditReservationModal
        reservation={reservationToEdit}
        showModal={showEditModal}
        onCancel={onCancelEditReservationHandler}
        editReservation={setReservationToEdit}
        onSubmit={onSubmitEditHandler}
      />
      <DateFilterModal
        show={showDateFilter}
        onSubmit={onSubmitFilterByDateHandler}
        onCancel={onCancelFilterByDateHandler}
      />
      <FlashMessage error={alert.success}>{alert.message}</FlashMessage>
      <ConfirmedResToolBar
        onChangeSort={setSortType}
        onShowDateModal={onShowDateModalHandler}
        clearDateFilter={clearDateFilterHandler}
        dateFilter={dateFilter} // used for disabling option to sort by date in select element when data is filtered by date
        ref={reservationsRef}
        sortType={sortType}
      />
      {isPending ? (
        "Loading..."
      ) : confirmedReservations.length > 0 ? (
        <table className={styles.reservations}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Confirmed Date/time</th>
              <th>Number of People</th>
              <th>Special Requests</th>
              <th>Type of Service</th>
              <th>Table Number</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {confirmedReservations.map((data) => {
              return (
                <ConfirmedReservationsRow
                  key={uuidv4()}
                  id={data.id}
                  name={data.fullName}
                  phone={data.phone}
                  email={data.email}
                  date={data.date}
                  time={data.time}
                  numberOfPeople={data.numberOfPeople}
                  specialReq={data.specialRequest}
                  type={data.typeOfService}
                  tableNumber={data.tableNumber}
                  onDeleteReservation={onConfirmHandler}
                  onEditReservation={editReservationHandler}
                />
              );
            })}
          </tbody>
        </table>
      ) : !dateFilter ? (
        <p>Currently there are no confirmed reservations.</p>
      ) : (
        <p>There are no confirmed reservations on this date.</p>
      )}
      <div style={{ display: "none" }}>
        <PrintableReservationsComponent
          confirmedReservationsData={confirmedReservations}
          ref={reservationsRef}
        />
      </div>
    </div>
  );
};

export default ConfirmedReservations;
