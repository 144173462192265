import { useEffect, useState } from "react";
import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import { v4 as uuid } from "uuid";
import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Slide from "./Slide";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { getFirestore, collection, getDocs } from "firebase/firestore";

const SwiperBooks = (props) => {
  const [recentBooks, setRecentBooks] = useState([]);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    const getRecentlyAddedBooks = async () => {
      const db = getFirestore();
      const recentColl = collection(db, "recentlyAddedBooks");
      setIsPending(true);
      const snapshot = await getDocs(recentColl);
      const recentBooksArray = [];
      if (snapshot) {
        snapshot.forEach(async (book) => {
          recentBooksArray.push({ ...book.data(), docId: book.id });
        });
      }

      setRecentBooks(recentBooksArray);
      setIsPending(false);
    };
    getRecentlyAddedBooks();
  }, []);
  return (
    <Swiper
      // install Swiper modules
      modules={[Pagination, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => "onSwiper"}
      onSlideChange={() => "slide change"}>
      {isPending ? (
        "Loading..."
      ) : recentBooks.length > 0 ? (
        recentBooks.map((book) => {
          return (
            <SwiperSlide key={uuid()}>
              <Slide title={book.title} author={book.author} imageRef={book.imageRef} />
            </SwiperSlide>
          );
        })
      ) : (
        <p>
          <MultiLingualContent contentId='booksAndRecords' prop='note4' />
        </p>
      )}
    </Swiper>
  );
};

export default SwiperBooks;
