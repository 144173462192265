import DessertsDesktop from "./DessertsDesktop";
import DessertsMobile from "./DessertsMobile";
import styles from "./DessertsALB.module.css";

const DessertsALB = (props) => {
  return (
    <div className={styles.container}>
      <DessertsDesktop onShowModalHandler={props.showModal} />
      <DessertsMobile onShowModalHandler={props.showModal} />
    </div>
  );
};

export default DessertsALB;
