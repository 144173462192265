import MainsMobile from "./MainsMobile";
import MainsDesktop from "./MainsDesktop";
import styles from "./MainsALB.module.css";

const MainsALB = (props) => {
  return (
    <div className={styles.container}>
      <MainsMobile onShowModalHandler={props.showModal} />
      <MainsDesktop onShowModalHandler={props.showModal} />
    </div>
  );
};

export default MainsALB;
