import { useState } from "react";

import CalendarNext from "../../assets/icons/CalendarNext";
import CalendarPrev from "../../assets/icons/CalendarPrev";
import EventsNavigationToolbar from "./EventsNavigationToolbar";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import styles from "./EventsNavigation.module.css";

const EventsNavigation = ({ setEventsQuery }) => {
  const [activeButton, setActiveButton] = useState("upcoming");

  const onButtonActiveHandler = (str) => {
    setActiveButton(str);
    setEventsQuery((prevState) => {
      return { ...prevState, year: str };
    });
  };
  return (
    <nav className={styles.eventsNav}>
      <div className={styles.navButtons}>
        <button
          className={`${styles.btn} ${activeButton === "upcoming" ? styles.active : ""}`}
          onClick={() => onButtonActiveHandler("upcoming")}>
          <CalendarNext activeClass={activeButton} />
          <MultiLingualContent contentId='events' prop='label1' />
        </button>
        <button
          className={`${styles.btn} ${activeButton === "past" ? styles.active : ""}`}
          onClick={() => onButtonActiveHandler("past")}>
          <CalendarPrev activeClass={activeButton} />
          <MultiLingualContent contentId='events' prop='label2' />
        </button>
      </div>
      <EventsNavigationToolbar setEventsQuery={setEventsQuery} activeButton={activeButton} />
    </nav>
  );
};

export default EventsNavigation;
