import Trash from "../../../../assets/icons/Trash";
import BorrowBookIcon from "../../../../assets/icons/BorrowBookIcon";
import styles from "./LibraryMembersRow.module.css";

const LibraryMembersRow = (props) => {
  return (
    <tr className={styles.tr}>
      <td data-label='FULL NAME'>{props.name}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='DEPOSIT AMOUNT'>{props.depositAmount}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onShowBorrowBookModal(props.docId)}>
          <BorrowBookIcon />
        </button>
        <button className={styles.btn} type='button' onClick={() => props.showConfirmModal(props.docId)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default LibraryMembersRow;
