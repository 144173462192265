import Flickity from "react-flickity-component";
import "flickity/dist/flickity.css";
import "./SomaAtNightCarousel.css";
import img1 from "../../assets/images/gallery/somaAtNight/entrance.jpg";
import img2 from "../../assets/images/gallery/somaAtNight/records_soma.jpg";
import img3 from "../../assets/images/gallery/somaAtNight/tapbeer.jpg";
import img4 from "../../assets/images/gallery/somaAtNight/bar1.jpg";
import img5 from "../../assets/images/gallery/somaAtNight/umbrella.jpg";
import img6 from "../../assets/images/gallery/somaAtNight/discoball.jpg";
import img7 from "../../assets/images/gallery/somaAtNight/framedpicture.jpg";
import img8 from "../../assets/images/gallery/somaAtNight/cocktail1.jpg";
const SomaAtNightCarousel = () => {
  const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    freeScroll: true,
    prevNextButton: false,
    pageDots: false,
    lazyLoad: true,
    contain: true,
  };
  return (
    <Flickity
      className='galleryCarousel' // default ''
      elementType={"div"} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      <div className='gallery-cell'>
        <img src={img1} alt='Books and a piano' />
      </div>
      <div className='gallery-cell'>
        <img src={img2} alt='Glass with a cocktail and a slice of orange on top of the piano keys' />
      </div>
      <div className='gallery-cell'>
        <img src={img3} alt='Restaurant table with a dish of salmon and glass of white wine' />
      </div>
      <div className='gallery-cell'>
        <img src={img4} alt='Bottles of homemade produce' />
      </div>
      <div className='gallery-cell'>
        <img src={img5} alt='A waiter pouring mulled wine into a glass' />
      </div>
      <div className='gallery-cell'>
        <img src={img6} alt='A hand holding a cocktail' />
      </div>
      <div className='gallery-cell'>
        <img src={img7} alt='A hand holding a cocktail' />
      </div>
      <div className='gallery-cell'>
        <img src={img8} alt='A hand holding a cocktail' />
      </div>
    </Flickity>
  );
};

export default SomaAtNightCarousel;
