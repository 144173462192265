import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import styles from "./FilterToolbar.module.css";

const FilterToolbar = (props) => {
  return (
    <div className={styles["filters-toolbar-wrapper"]}>
      <hr className={styles.hr} />
      <div className={styles["filters-toolbar"]}>
        <div className={styles["control-wrapper"]}>
          <div className={styles.control}>
            <label className={styles.label} htmlFor='SortBy'>
              <MultiLingualContent contentId='booksAndRecords' prop='filterLabel1' />
            </label>
            <div className={styles["select-group-wrapper"]}>
              <select
                name='sort_by'
                id='SortBy'
                defaultValue={props.sortType}
                className={styles.select}
                onChange={(ev) => props.onSortOrderChange(ev.target.value)}>
                <option value='titleAsc'>
                  <MultiLingualContent contentId='booksAndRecords' prop='filterOption1' />
                </option>
                <option value='titleDesc'>
                  <MultiLingualContent contentId='booksAndRecords' prop='filterOption2' />
                </option>
                <option value='authorAsc'>
                  <MultiLingualContent contentId='booksAndRecords' prop='filterOption3' />
                </option>
                <option value='authorDesc'>
                  <MultiLingualContent contentId='booksAndRecords' prop='filterOption4' />
                </option>
              </select>
              <svg
                aria-hidden='true'
                focusable='false'
                role='presentation'
                className={styles["icon-chevron-down"]}
                viewBox='0 0 498.98 284.49'>
                <defs></defs>
                <path
                  d='M80.93 271.76A35 35 0 0 1 140.68 247l189.74 189.75L520.16 247a35 35 0 1 1 49.5 49.5L355.17 511a35 35 0 0 1-49.5 0L91.18 296.5a34.89 34.89 0 0 1-10.25-24.74z'
                  transform='translate(-80.93 -236.76)'></path>
              </svg>
            </div>
          </div>
        </div>
        <div className=''>
          <span className={styles["item-count"]}>{props.numberOfBooks || ""}</span>
        </div>
      </div>
      <hr className={styles.hr} />
    </div>
  );
};

export default FilterToolbar;
