import { useContext } from "react";
import { LanguageContext } from "../../contexts/Language/LanguageContext";
import { monthsALB, monthsENG } from "./monthsArray";
import styles from "./MonthsSelector.module.css";

const MonthsSelector = ({ activeButton, monthState, isVisible, year }) => {
  const { selectedMonth, setMonthHandler } = monthState;

  const { language } = useContext(LanguageContext);

  let months;

  if (language === "albanian") {
    months = monthsALB;
  } else {
    months = monthsENG;
  }

  if (activeButton !== "upcoming" && parseInt(year) === new Date().getFullYear()) {
    let currentMonth = new Date().getMonth() + 1; // January is 0, February is 1, etc.
    months = months.filter((month) => month.value <= currentMonth);
    months.unshift(
      language === "albanian" ? { label: "të gjitha", value: "all" } : { label: "all", value: "all" }
    );
  } else {
    months = language === "albanian" ? monthsALB : monthsENG;
  }

  if (activeButton === "upcoming") {
    let currentMonth = new Date().getMonth() + 1; // January is 0, February is 1, etc.
    months = months.filter((month) => month.value >= currentMonth);
    months.unshift(
      language === "albanian" ? { label: "të gjitha", value: "all" } : { label: "all", value: "all" }
    );
  }
  const onMonthSelectHandler = (month) => {
    setMonthHandler(month);
  };

  return (
    <div
      className={styles["months-container"]}
      style={{
        transition: "all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
        height: isVisible === true ? "120px" : "0px",
        overflow: "scroll",
        padding: isVisible === true ? "1em" : "0px",
        borderBottom: isVisible === true ? "2px solid #262626" : "none",
      }}>
      {months.map((month) => {
        return (
          <button
            key={month.label}
            aria-label={month}
            className={`${styles.month} ${selectedMonth === month.value ? styles.active : ""}`}
            onClick={() => onMonthSelectHandler(month.value)}>
            {month.label}
          </button>
        );
      })}
    </div>
  );
};

export default MonthsSelector;
