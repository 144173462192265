import React, { useEffect, useState } from "react";
import useScrollToTop from "../../hooks/useScrollToTop";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";

import OpeningHoursContact from "../../components/UI/OpeningHoursContact";
import img1 from "../../assets/images/telephone_reservation.jpg";
import styles from "./Contact.module.css";

const Contact = () => {
  const [skeleton, setSkeleton] = useState(true);
  useScrollToTop();

  useEffect(() => {
    if (window.innerWidth < 540) {
      setSkeleton(false);
    } else {
      setSkeleton(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 540) {
        setSkeleton(false);
      } else {
        setSkeleton(true);
      }
    });
  }, []);
  return (
    <div className={styles.container}>
      <header>
        <div className={`${styles.background} ${skeleton ? "skeleton" : ""}`}>
          <div className={`${styles["header-image"]} skeleton`}>
            <img src={img1} alt='Colorful large vintage phones' className='skeleton' />
          </div>
        </div>
      </header>
      <div className={styles.body}>
        <section>
          <h1>
            <MultiLingualContent contentId='contact' prop='title1' />
          </h1>
          <p>
            <MultiLingualContent contentId='contact' prop='contactP1' />
          </p>
          <div className={styles.address}>
            <a
              href='https://www.google.com/maps/place/Soma+Book+Station/@42.6639122,21.1604062,17z/data=!3m1!4b1!4m5!3m4!1s0x13549ee04bc82423:0x893fc6b4db13b3e6!8m2!3d42.6639083!4d21.1625949'
              className={styles.btn}
              target='_blank'
              rel='noreferrer'>
              <MultiLingualContent contentId='contact' prop='link1' />
            </a>
          </div>
        </section>
        <section>
          <h1>
            <MultiLingualContent contentId='contact' prop='title1' />
          </h1>
          <p>
            <MultiLingualContent contentId='contact' prop='contactP2' />
          </p>
          <p>
            <a href='tel:+381 38 748818'>+381 38 748818</a>
          </p>
          <p>
            <MultiLingualContent contentId='contact' prop='contactP3' />
          </p>
          <a href='mailto:somabookstation@gmail.com'>somabookstation@gmail.com</a>
          <p>
            <MultiLingualContent contentId='contact' prop='contactP4' />
          </p>
          <div className={styles.social}>
            <a
              href='https://www.instagram.com/somabookstation/'
              className={styles.btn}
              target='_blank'
              rel='noreferrer'>
              INSTAGRAM
            </a>
            <a
              href='https://www.facebook.com/Soma-Book-Station-897098200308591'
              className={styles.btn}
              target='_blank'
              rel='noreferrer'>
              FACEBOOK
            </a>
          </div>
        </section>
        <section className={styles.section}>
          <OpeningHoursContact />
        </section>
      </div>
    </div>
  );
};

export default Contact;
