import styles from "./CheckboxList.module.css";

const CheckboxList = ({ options, onChange }) => {
  const onChangeHandler = (ev, value) => {
    onChange((prevState) => {
      return prevState.map((option) =>
        option.value === value ? { ...option, checked: !option.checked } : option
      );
    });
  };
  return (
    <div className={styles.checkboxList}>
      {options.map((option) => (
        <label key={option.label} className={styles.label}>
          <input
            className={styles.checkbox}
            type='checkbox'
            value={option.value}
            checked={option.checked}
            onChange={(ev) => onChangeHandler(ev, option.value)}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default CheckboxList;
