import { useRef } from "react";
import styles from "./AddFlaggedGuestForm.module.css";

const AddFlaggedGuestForm = (props) => {
  // Should we check whether a flagged guest exist, prior to updatåing the collection. And if yes, we could ask the user if they want to merge the data into one object. Which means we would have arrays of data when user has 2 different telephone numbers. Too complicated but doable
  const nameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    const guest = {
      fullName: nameRef.current.value.toLowerCase().trim(),
      phone: phoneRef.current.value.trim(),
      email: emailRef.current.value.trim().toLowerCase(),
    };

    props.onSubmit(guest);

    nameRef.current.value = "";
    phoneRef.current.value = "";
    emailRef.current.value = "";
  };
  return (
    <form onSubmit={onSubmitHandler}>
      <div className={styles.controls}>
        <label htmlFor='customerName'>Full Name</label>
        <input
          type='text'
          id='customerName'
          placeholder='Customer Name'
          autoComplete='off'
          aria-label='Customer Name'
          ref={nameRef}
          required
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='customerPhone'>Phone Number</label>
        <input
          type='tel'
          id='customerPhone'
          placeholder='Phone Number'
          autoComplete='off'
          aria-label='Customer Phone'
          ref={phoneRef}
          required
        />
      </div>
      <div className={styles.controls}>
        <label htmlFor='customerEmail'>Email</label>
        <input
          type='email'
          id='customerEmail'
          placeholder='Email'
          autoComplete='off'
          aria-label='Customer Email'
          ref={emailRef}
          required
        />
      </div>

      <div className={styles["modal-actions"]}>
        <button type='button' className={styles["btn-secondary"]} onClick={props.onCancel}>
          Cancel
        </button>
        <button type='submit' className={styles["btn-primary"]}>
          Add Guest
        </button>
      </div>
    </form>
  );
};

export default AddFlaggedGuestForm;
