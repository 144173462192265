import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import img1 from "../../assets/images/event.jpg";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";

import useScrollToTop from "../../hooks/useScrollToTop";

import EventsNavigation from "./EventsNavigation";
import EventsList from "./EventsList";

import getQueryType from "./getQueryType";
import getPaginationQueryType from "./getPaginationQueryType";

import Loader from "../../components/UI/Loader";

import styles from "./Events.module.css";

// FIREBASE

import { getDocs } from "firebase/firestore";

const Events = () => {
  const [skeleton, setSkeleton] = useState(true);
  const [eventsQuery, setEventsQuery] = useState({
    checklistOptions: [
      [
        { label: "DJ", value: "dj", checked: false },
        { label: "CONCERT", value: "concert", checked: false },
        { label: "EXHIBITION", value: "exhibition", checked: false },
        { label: "SCREENING", value: "screening", checked: false },
        { label: "WORKSHOP", value: "workshop", checked: false },
        { label: "BOOK SIGNING", value: "book signing", checked: false },
        { label: "FOOD TASTING", value: "food tasting", checked: false },
        { label: "WINE TASTING", value: "wine tasting", checked: false },
        { label: "OTHER", value: "other", checked: false },
      ],
    ],
    selectedMonth: "all",
    year: "upcoming",
  });

  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(false);

  // FOR PAGINATION
  const [last, setLast] = useState("");
  const [first, setFirst] = useState("");

  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disablePrevButton, setDisablePrevButton] = useState(false);

  const [queryTypeNumber, setQueryTypeNumber] = useState(null);

  useScrollToTop();

  useEffect(() => {
    async function fetchData() {
      setDisableNextButton(false);
      setDisablePrevButton(false);
      setIsPending(true);
      const { checklistOptions, selectedMonth, year } = eventsQuery;

      let checkListValues = checklistOptions.filter((option) => option.checked === true);

      if (checkListValues.length > 0) {
        checkListValues = checkListValues.map((option) => option.value.toUpperCase());
      }

      let obj = getQueryType(checkListValues, selectedMonth, year);
      let q = obj.query;
      setQueryTypeNumber(obj.querynumber);

      let data = [];

      if (year === "past") {
        setData([]);
        return;
      }

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setIsPending(false);
        setData([]);
        return;
      }
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const firstVisible = querySnapshot.docs[0];
      setLast(lastVisible);
      setFirst(firstVisible);

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      setData(data);

      setIsPending(false);
    }
    fetchData();
  }, [eventsQuery]);

  useEffect(() => {
    if (window.innerWidth < 540) {
      setSkeleton(false);
    } else {
      setSkeleton(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 540) {
        setSkeleton(false);
      } else {
        setSkeleton(true);
      }
    });
  }, []);

  const nextPage = async () => {
    setDisablePrevButton(false);
    setIsPending(true);
    const { checklistOptions, selectedMonth, year } = eventsQuery;

    let checkListValues = checklistOptions.filter((option) => option.checked === true);

    if (checkListValues.length > 0) {
      checkListValues = checkListValues.map((option) => option.value.toUpperCase());
    }
    let next = getPaginationQueryType(
      checkListValues,
      selectedMonth,
      year,
      queryTypeNumber,
      "next",
      first,
      last
    );

    const querySnapshot = await getDocs(next);

    if (querySnapshot.empty) {
      setDisableNextButton(true);
      setIsPending(false);
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const firstVisible = querySnapshot.docs[0];

    setLast(lastVisible);
    setFirst(firstVisible);
    let data = [];
    setData([]);
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    setData(data);

    setIsPending(false);
  };
  const prevPage = async () => {
    setDisableNextButton(false);
    setIsPending(true);
    const { checklistOptions, selectedMonth, year } = eventsQuery;

    let checkListValues = checklistOptions.filter((option) => option.checked === true);

    if (checkListValues.length > 0) {
      checkListValues = checkListValues.map((option) => option.value.toUpperCase());
    }

    let prev = getPaginationQueryType(
      checkListValues,
      selectedMonth,
      year,
      queryTypeNumber,
      "prev",
      first,
      last
    );

    const querySnapshot = await getDocs(prev);

    if (querySnapshot.empty) {
      setDisablePrevButton(true);
      setIsPending(false);
      return;
    }

    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const firstVisible = querySnapshot.docs[0];

    setLast(lastVisible);
    setFirst(firstVisible);
    let data = [];
    setData([]);
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    setData(data);

    setIsPending(false);
  };

  return (
    <div className={styles.container}>
      <header>
        <div className={`${styles.background} ${skeleton ? "skeleton" : ""}`}>
          <div className={`${styles["header-image"]} skeleton`}>
            <img src={img1} alt='A band playing indoors' className='skeleton' />
          </div>
        </div>
      </header>
      <div className={styles.body}>
        <div className={styles.headline}>
          <h1>
            <MultiLingualContent contentId='events' prop='title' />
          </h1>
          <p>
            <MultiLingualContent contentId='events' prop='bodyP1' />
          </p>
          <p>
            <MultiLingualContent contentId='events' prop='bodyP2' />
          </p>
          <p>
            <MultiLingualContent contentId='events' prop='bodyP3' />{" "}
            <Link to='/contact'>
              <MultiLingualContent contentId='events' prop='link1' />
            </Link>
          </p>
          <p>
            <MultiLingualContent contentId='events' prop='bodyP4' />
          </p>
        </div>
        <EventsNavigation setEventsQuery={setEventsQuery} />
        <div className={styles["events-container"]}>
          {isPending && eventsQuery.year !== "past" ? (
            <Loader />
          ) : (
            <EventsList
              events={data}
              year={eventsQuery.year}
              prevPage={prevPage}
              showMoreItems={nextPage}
              buttonState={{ nextButton: disableNextButton, prevButton: disablePrevButton }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Events;
