import React, { Fragment, useState } from "react";
import useScrollToTop from "../../hooks/useScrollToTop";

import SomaAtDayCarousel from "./SomaAtDayCarousel";
import SomaAtNightCarousel from "./SomaAtNightCarousel";

import SomaAtNight from "./SomaAtNight";
import SomaAtDay from "./SomaAtDay";
import DayNightToggle from "../../components/UI/DayNightToggle";

const Gallery = () => {
  const [toggleChecked, setToggleChecked] = useState(false);
  useScrollToTop();

  const switchHandler = (ev) => {
    setToggleChecked(ev.target.checked);
  };
  return (
    <Fragment>
      <DayNightToggle onClick={switchHandler} />
      {!toggleChecked && <SomaAtDay />}
      {toggleChecked && <SomaAtNight />}

      {!toggleChecked && <SomaAtDayCarousel />}
      {toggleChecked && <SomaAtNightCarousel />}
    </Fragment>
  );
};

export default Gallery;
