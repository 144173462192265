import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Calendar from "../../assets/icons/Calendar";
import BooksAdmin from "../../assets/icons/BooksAdmin";
import Records from "../../assets/icons/Records";
import JobApplicants from "../../assets/icons/JobApplicants";
import Events from "../../assets/icons//Events";

import styles from "./AdminMobileNav.module.css";

// FIREBASE
import { getFirestore, onSnapshot, collection } from "firebase/firestore";

const AdminMobileNav = () => {
  const [bookHoldCounter, setBookHoldCounter] = useState(0);
  const [reservationCounter, setReservationCounter] = useState(0);
  const [jobApplicationCounter, setJobApplicationCounter] = useState(0);
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate("./about");
  };

  // Get number of reservations requests, job applicants, book hold requests when admin first lands or refreshses this page
  const resetHoldRequestsCounter = () => {
    setBookHoldCounter(0);
  };
  const resetReservationCounter = () => {
    setReservationCounter(0);
  };
  const resetJobApplicationCounter = () => {
    setJobApplicationCounter(0);
  };

  useEffect(() => {
    const getRealTimeCounters = async () => {
      const db = getFirestore();

      onSnapshot(collection(db, "holdRequests"), (querySnapshot) => {
        const holdRequests = [];
        querySnapshot.forEach((doc) => {
          holdRequests.push(doc.data());
        });

        let numberOfRequests = holdRequests.length;
        setBookHoldCounter(numberOfRequests);
      });
      onSnapshot(collection(db, "reservationRequests"), (querySnapshot) => {
        const holdRequests = [];
        querySnapshot.forEach((doc) => {
          holdRequests.push(doc.data());
        });

        let numberOfRequests = holdRequests.length;
        setReservationCounter(numberOfRequests);
      });
      onSnapshot(collection(db, "jobApplications"), (querySnapshot) => {
        const jobApplications = [];
        querySnapshot.forEach((doc) => {
          jobApplications.push(doc.data());
        });

        let numberOfApplicants = jobApplications.length;
        setJobApplicationCounter(numberOfApplicants);
      });
    };
    getRealTimeCounters();
  }, []);
  return (
    <div className={styles["mobile-nav"]}>
      <button className={styles["back-btn"]} onClick={goBackHandler}>
        <span>Back</span>
      </button>
      <NavLink
        className={(navData) => (navData.isActive ? `${styles.active} ${styles.link}` : styles.link)}
        to='/admin/events'>
        <Events />
        <span className={styles.label}>Events</span>
      </NavLink>
      <NavLink
        onClick={resetReservationCounter}
        className={(navData) => (navData.isActive ? `${styles.active} ${styles.link}` : styles.link)}
        to='/admin/tableReservations'>
        <Calendar />
        {reservationCounter > 0 ? <span className={styles.indicator}>{reservationCounter}</span> : ""}
        <span className={styles.label}>Reservations</span>
      </NavLink>
      <NavLink
        onClick={resetHoldRequestsCounter}
        className={(navData) => (navData.isActive ? `${styles.active} ${styles.link}` : styles.link)}
        to='/admin/books'>
        <BooksAdmin />
        {bookHoldCounter > 0 ? <span className={styles.indicator}>{bookHoldCounter}</span> : ""}
        <span className={styles.label}>Books</span>
      </NavLink>
      <NavLink
        className={(navData) => (navData.isActive ? `${styles.active} ${styles.link}` : styles.link)}
        to='/admin/records'>
        <Records />
        <span className={styles.label}>Records</span>
      </NavLink>
      <NavLink
        onClick={resetJobApplicationCounter}
        className={(navData) => (navData.isActive ? `${styles.active} ${styles.link}` : styles.link)}
        to='/admin/jobApplications'>
        <JobApplicants />
        {jobApplicationCounter > 0 ? <span className={styles.indicator}>{jobApplicationCounter}</span> : ""}
        <span className={styles.label}>Jobs</span>
      </NavLink>
    </div>
  );
};

export default AdminMobileNav;
