import React, { useState, useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";

import BookAndRecords from "../../assets/images/booksAndRecords/books_and_party.jpg";
import Book from "../../assets/images/booksAndRecords/book4.jpg";
import Record from "../../assets/images/booksAndRecords/timbre700_700.jpg";

import BooksAndRecordsNav from "./BooksAndRecordsNav";
import Library from "./Library/Library";
import Books from "./Books/Books";
import BookDetails from "./Books/BookDetails";
import Records from "./Records/Records";
import RecordDetails from "./Records/RecordDetails";
import PageNotFound from "../404/404";

import styles from "./BooksAndRecords.module.css";

const BooksAndRecords = () => {
  const [skeleton, setSkeleton] = useState(true);

  const params = useParams();
  const itemId = params["*"].split("/")[1];

  useEffect(() => {
    if (window.innerWidth < 540) {
      setSkeleton(false);
    } else {
      setSkeleton(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 540) {
        setSkeleton(false);
      } else {
        setSkeleton(true);
      }
    });
  }, []);

  let headerImage =
    params["*"] === "library"
      ? BookAndRecords
      : params["*"] === "books"
      ? Book
      : params["*"] === "records"
      ? Record
      : params["*"] === `books/${itemId}`
      ? Book
      : params["*"] === `records/${itemId}`
      ? Record
      : "";

  return (
    <div className={styles.container}>
      <header>
        <div className={`${styles.background} ${skeleton ? "skeleton" : ""}`}>
          <div className={`${styles["header-image"]} skeleton`}>
            <img src={headerImage} className='skeleton' alt='Library Item' />
          </div>
        </div>
      </header>

      <div className={styles.body}>
        <BooksAndRecordsNav />
        <Routes>
          <Route path='/library' element={<Library />} />
          <Route path='/books' element={<Books />} />
          <Route path='/books/*' element={<BookDetails />} />
          <Route path='/records' element={<Records />} />
          <Route path='/records/*' element={<RecordDetails />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
};

export default BooksAndRecords;
