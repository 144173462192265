const getTranslatedMonth = (month) => {
  let monthALB;
  switch (month) {
    case "January":
      monthALB = "Janar";
      break;
    case "February":
      monthALB = "Shkurt";
      break;
    case "March":
      monthALB = "Mars";
      break;
    case "April":
      monthALB = "Prill";
      break;
    case "May":
      monthALB = "Maj";
      break;
    case "June":
      monthALB = "Qershor";
      break;
    case "July":
      monthALB = "Korrik";
      break;
    case "August":
      monthALB = "Gusht";
      break;
    case "September":
      monthALB = "Shtator";
      break;
    case "October":
      monthALB = "Tetor";
      break;
    case "November":
      monthALB = "Nëntor";
      break;
    case "December":
      monthALB = "Dhjetor";
      break;
  }
  return monthALB;
};

export default getTranslatedMonth;
