import { useEffect, useState, useContext } from "react";
import AuthContext from "../../../../contexts/AuthContext";
import { MultiLingualContent } from "../../../../contexts/Language/MultiLingualContent";
import { LanguageContext } from "../../../../contexts/Language/LanguageContext";
import getTranslatedMonth from "../../../../utils/getTranslatedMonth";
import getTranslatedWeekday from "../../../../utils/getTranslatedWeekday";
import ReservationDate from "../../../../assets/icons/ReservationDate";
import noImagePlaceHolder from "../../../../assets/images/No-Image-Placeholder.png";
import styles from "./BorrowedBook.module.css";
// FIREBASE
import {
  getFirestore,
  doc,
  updateDoc,
  Timestamp,
  getDoc,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const BorrowedBook = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const authCtx = useContext(AuthContext);
  const { language } = useContext(LanguageContext);

  const { uid } = authCtx.authUser;

  // Calculate days left to return book
  let due = new Date(props.book.dueDate.toMillis());
  let now = new Date();

  const daysLeft = (date_1, date_2) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };
  let numberOfDaysLeft = daysLeft(due, now);

  let dueInLabel = `Due in ${numberOfDaysLeft} days`;
  let overdueLabel = `Overdue by ${Math.abs(numberOfDaysLeft)}`;
  let dayPlural = "days";
  let daySingular = "day";

  // We convert timestamp to javascript date object
  // const dueDate = props.book.dueDate.toDate().toLocaleString("default", options);

  let dueDateWeekday = props.book.dueDate.toDate().toLocaleString("default", { weekday: "long" });
  let dueDateMonth = props.book.dueDate.toDate().toLocaleString("default", { month: "long" });
  let dueDateDay = props.book.dueDate.toDate().toLocaleString("default", { day: "numeric" });
  let dueDateYear = props.book.dueDate.toDate().toLocaleString("default", { year: "numeric" });

  if (language === "albanian") {
    dueInLabel = `Me u kthy mbas ${numberOfDaysLeft} ${numberOfDaysLeft === 1 ? "dite" : "ditësh"}`;
    overdueLabel = `I vonuem ${Math.abs(numberOfDaysLeft)}`;
    dayPlural = "ditë";
    daySingular = "ditë";
    dueDateWeekday = getTranslatedWeekday(dueDateWeekday);
    dueDateMonth = getTranslatedMonth(dueDateMonth);
  }

  // Get image reference from storage
  useEffect(() => {
    if (props.book.imageRef) {
      const storage = getStorage();
      const pathReference = ref(storage, props.book.imageRef);

      getDownloadURL(pathReference)
        .then((url) => {
          // `url` is the download URL for 'bookcovers/*'
          setImageUrl(url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
          setImageUrl(noImagePlaceHolder);
        });
    } else {
      setImageUrl(noImagePlaceHolder);
    }
  }, [props.book.imageRef]);

  const onRenewHandler = async (id) => {
    // Add numberOfRenewals property on user.borrowed object and set it to 1 and set a due date 1 month from now
    const db = getFirestore();
    let userRef = doc(db, "users", uid);

    // Due date a month from now
    let now = new Date();
    now.setDate(now.getDate() + 31);

    const userSnap = await getDoc(userRef);
    let borrowedBook = userSnap.data().borrowed[0];

    borrowedBook.dueDate = Timestamp.fromDate(now);
    borrowedBook.numberOfRenewals = 1;
    await updateDoc(userRef, {
      borrowed: [borrowedBook],
    });

    // Add numberOfRenewals property on borrowedBooks object and set it to 1. this might not be needed. We need to set a due date 1 month from now

    // Get doc from borrowedBooks collection and update it
    const borrowedBooksRef = collection(db, "borrowedBooks");
    // Create a query against the collection.
    const q = query(borrowedBooksRef, where("docId", "==", id));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (document) => {
      const holdRequestRef = doc(db, "borrowedBooks", document.id);
      await updateDoc(holdRequestRef, { dueDate: Timestamp.fromDate(now), numberOfRenewals: 1 });
    });

    // Re-render UI

    let updatedBook = {
      ...props.book,
      dueDate: Timestamp.fromDate(now),
      numberOfRenewals: 1,
    };
    props.onRenew(updatedBook);
  };

  return (
    <div className={styles["book-container"]}>
      <div className={styles["bookcover-container"]}>
        <img src={imageUrl} className='skeleton' alt='' />
      </div>
      <div className={styles["book-info-container"]}>
        <h3 className={styles["book-title"]}>{props.book.title}</h3>
        <span className={styles["book-author"]}>{props.book.author}</span>
        <div className={styles["return-info"]}>
          <div className={styles["return-date"]}>
            <ReservationDate />
            <span>
              <MultiLingualContent contentId='myLibrary' prop='label9' /> {dueDateWeekday}, {dueDateDay}{" "}
              {dueDateMonth} {dueDateYear}
            </span>
          </div>
          <p
            className={`${styles.numberOfDays} ${
              numberOfDaysLeft < 15 && numberOfDaysLeft >= 0
                ? styles.warm
                : numberOfDaysLeft < 0
                ? styles.hot
                : ""
            } `}>
            {numberOfDaysLeft >= 0
              ? dueInLabel
              : `${overdueLabel} ${Math.abs(numberOfDaysLeft) > 1 ? dayPlural : daySingular}`}
          </p>
        </div>
        <div className={styles.actions}>
          {numberOfDaysLeft < 0 ? (
            <small>
              <MultiLingualContent contentId='myLibrary' prop='note5' />
            </small>
          ) : (
            <button
              className={styles["button-80"]}
              disabled={numberOfDaysLeft > 7 || props.book.numberOfRenewals === 1 ? true : false}
              onClick={() => onRenewHandler(props.book.docId)}>
              <MultiLingualContent contentId='myLibrary' prop='button2' />
            </button>
          )}
          {numberOfDaysLeft > -1 && (
            <small>
              <MultiLingualContent contentId='myLibrary' prop='note6' />
            </small>
          )}
          {numberOfDaysLeft > -1 && (
            <small>
              <MultiLingualContent contentId='myLibrary' prop='label10' />{" "}
              {props.book.numberOfRenewals ? props.book.numberOfRenewals : 0}
            </small>
          )}
        </div>
      </div>
    </div>
  );
};

export default BorrowedBook;
