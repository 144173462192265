import styles from "./SaladsDesktop.module.css";

const SaladsDesktop = (props) => {
  return (
    <ul className={styles["item-list"]}>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='0' category='salads'>
        Leaf Salad w. iceberg, red cabbage,rocket, vinaigrette + bread stick .................... 3.50
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='1' category='salads'>
        Beetroot Salad w. honey walnuts, local white cheese and rocket .......................... 4.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='2' category='salads'>
        Steak Tuna w. spinach, rocket, potatoes, red pepper, sesame seeds + balsamic vinegar.....12.00
      </li>
      <li className={styles.item} onClick={props.onShowModalHandler} data-index='3' category='salads'>
        Chicken Caesar w. iceberg, baby tomatoes, parmesan, crutons, grilled chicken + dressing . 5.50
      </li>
    </ul>
  );
};

export default SaladsDesktop;
