export const translations = {
  english: {
    navigation: {
      navLink1: "About",
      navLink2: "Events",
      navLink3: "Reservations",
      navLink4: "Food and Drinks",
      navLink5: "Books and Records",
      navLink6: "Careers",
      navLink7: "Contact",
      navLink8: "Gallery",
    },
    about: {
      title: "ABOUT",
      bodyP1:
        "Whether you want to enjoy a good meal, kick back with some drinks or read a book, you’ve come to the right place.",
      bodyP2:
        "Soma is a versatile multi-functional space with a small library of books you can borrow, fun gigs, exhibitions, readings, talks, pop-up stores and of course great coffee.",
      bodyP3:
        "We also have a beer garden that can accommodate big and small groups alike. We have a great range of drinks + tap beers with our own brew included, signature cocktails and a kitchen serving delicious pub meals and weekly specials.",
      bodyP4: "There is something for everyone at Soma!",
    },

    events: {
      title: "EVENTS",
      bodyP1: "Soma offers its space to artists from different disciplines to present their work.",
      bodyP2:
        "Since opening our doors in 2015 we’ve had hundreds of events from international guest artists to home grown local favourites with live gigs, DJ’s, exhibitions, film screenings, readings, pop-up stores and talks, always completely free.",
      bodyP3: "If you would like to organize an event at Soma, get in contact with us",
      bodyP4:
        "Or just come over grab a seat and catch up with friends or get up and dance the night away – it’s up to you!",
      link1: "here",
      label1: "Upcoming Events",
      label2: "Past Events",
      label3: "DATE",
      label4: "TIME",
      note1: "There are no upcoming events",
      note2: "There are no past events",
      note3: "Loading...",
      button1: "Next Page",
      button2: "Previous Page",
      yearPlaceholder1: "CURRENT YEAR",
      yearPlaceholder2: "SELECT YEAR",
      button3: "RESET",
      button4: "FILTER BY",
    },

    reservations: {
      title: "TABLE RESERVATIONS",
      bodyP1:
        "Although we hold a few tables back for walk-ins, it is advisable booking a table. You can request a reservation via the button below and we'll get back to you shortly.",
      button1: "REQUEST A RESERVATION",
      button2: "Back",
      button3: "Submit Request",
      label1: "OPENING HOURS",
      label2: "CLOSED",
      formTitle: "Request a Reservation",
      formSubtitle: "What to know before you go",
      formNote1: "A note from the restaurant",
      formNote2:
        "Please know that we will hold your table for 15 minutes. If you're running late let us know so we can accomodate you!",
      formLabel1: "person",
      formLabel2: "people",
      legend: "Type of service",
      radioLabel1: "Food and Drinks",
      radioLabel2: "Drinks Only",
      message: "Thank you! We will get back to you shortly.",

      m: "Monday",
      t: "Tuesday",
      w: "Wednesday",
      th: "Thursday",
      f: "Friday",
      sat: "Saturday",
      sun: "Sunday",
    },

    foodAndDrinks: {
      navLink1: "KITCHEN & BAR",
      navLink2: "FOOD MENU",
      navLink3: "DRINKS MENU",
      navLink4: "SMALL BITES",
      navLink5: "SALADS",
      navLink6: "FROM THE SEA",
      navLink7: "PASTA AND RICE",
      navLink8: "BURGERS",
      navLink9: "TO SHARE",
      navLink10: "DESSERTS",
      navLink11: "SOFT DRINKS",
      navLink12: "BEERS",
      navLink13: "COCKTAILS",
      note1: "Click on the menu item to see an image of the dish",
      note2: "Latest update: 25 September, 2022",
      button1: "Download Menu",
      title1: "KITCHEN",
      title2: "BAR",
      kitchenAndBarP1:
        "At Soma we pride ourselves on serving delicious, high quality meals using only the freshest local produce whenever possible. Whether it’s a melt-in-the mouth burger served with hand cut fries,homemade sausages or homemade ravioli pasta, our highly experienced and passionate kitchen team makes it their mission to leave you completely satisfied.",
      kitchenAbdBarP2:
        "Whilst our menu is predominantly international cuisine, we’re open to creating dishes combining local ingredients with innovation in mind. We’ve recently added Shar Cheese, a distinctly flavoured local highland cheese to our repertoire used as a substitute for parmesan cheese in some of our dishes.",
      kitchenAndBarP3:
        "We grind our own meat mixture for our burgers and serve them with our homemade brioche, made with fresh yeast. We make our own sundried tomatoes using a local tomato variety when in season. Our ravioli is freshly made by hand on daily basis and we even make our own condiments. Try our version of chutney!",
      kitchenAndBarP4: "The kitchen is open every day, except on Sunday, for breakfast, lunch and dinner.",
      kitchenAndBarP5:
        "At the heart of Soma lies the main bar – the ultimate gathering place. Our bar has a carefully curated list of wines and whiskeys. We believe that the best whiskey comes from Scotland therefore we offer a selection of single malt Scottish Whiskey’s, smoked bay and highland barley varieties.",
      kitchenAbdBarP6:
        "We also have our own local craft beer – Sabaja, which we now proudly manage, with a selection of an IPA, a lager, porter, Weiss (German wheat ale) and we offer all of them in draft straight from our barrels.",
      kitchenAndBarP7:
        "If beer is not for you we also have a fantastic line-up of craft spirits. Rakija that we offer is made from our selection of fruits we regularly send to the distillery and we make sure that there are no colourings or additives.",
      kitchenAndBarP8:
        "With over 20 whiskeys, 10 gins and rums, including the infamous ‘dark as night’ Kraken Spiced Rum and a locally crafted Dukagjini Gin, plenty of classic cocktails and signature cocktails we’ve got all your drinking needs covered.",
    },
    booksAndRecords: {
      navLink1: "LIBRARY",
      navLink2: "BOOKS",
      navLink3: "RECORDS",
      title1: "LIBRARY",
      title2: "RECORDS",
      title3: "OVERVIEW",
      title4: "TRACKLIST",
      booksAndRecordsP1:
        "Since opening in 2015, Soma has always been a place for people who love books to meet, talk, drink excellent tea and coffee, consume delicious food and of course browse.",
      booksAndRecordsP2:
        "Our selection of around 2000 titles ranges from the classics of the world literature to the cutting edge of the contemporary literature fiction and poetry in English and Albanian, not forgetting a copious display of history, politics, philosophy, cookery, essays, science and natural history.",
      booksAndRecordsP3:
        "New titles are added to our collection occasionally so make sure to check those too.",
      booksAndRecordsP4:
        "You are more than welcome to read any of our books while on our premises or alternatively you can borrow them.",
      booksAndRecordsP5:
        "You can search and browse our collection through our website and also reserve any of the books you may be interested in.",
      booksAndRecordsP6:
        "Vinyl is a lossless format. The pressings are made straight from the masters and contain all of the details the artist intended. It’s for this reason that vinyl sounds better than digital.",
      booksAndRecordsP7:
        "Our library has a small collection of vinyls that are available to DJ's or artists performing at our venue. Occasionaly we also organize vinyl playing events.",
      booksAndRecordsP8:
        "We try to add new records to the collection whenever they become available. You can check for new arrivals or browse the collection in the records section of the website.",
      button1: "Membership and borrowing",
      button2: "Or browse by category",
      button3: "Recently Added",
      button4: "DETAILS",
      button5: "Back",
      button6: "WISHLIST",
      button7: "PLACE HOLD",
      button8: "Overview",
      button9: "Tracklist",
      modalIntro: "Everyone is welcome to borrow books from our library for a single euro:",
      modalBullet1:
        "You can search for a book and place it on hold through our website, after you have signed in. It will be on hold for the next 24 hours, for you to pick up at your convenience.",
      modalBullet2:
        "You can only place 1 book on hold at a time. You can cancel the hold in your library account.",
      modalBullet3:
        "You can only borrow 1 book at a time and can't place another hold until you have returned the borrowed book.",
      modalBullet4:
        "Before you borrow a book we require a 20 euro deposit which will be returned to you once you cancel your membership.",
      modalBullet5:
        "In case of a loss or damage to the book we will have to keep the deposit in order to replace it with a new copy.",
      modalBullet6:
        "Books can be kept for a month and renewed only once through your account. If you want to renew it again we will have to see the book beforehand.",
      searchBarOption1: "Title",
      searchBarOption2: "Author",
      searchBarOption3: "Artist",
      filterLabel1: "SORT BY",
      bookLabel1: "Available",
      bookLabel2: "Not Available",
      bookLabel3: "Available copies:",
      filterOption1: "Title, A-Z",
      filterOption2: "Title, Z-A",
      filterOption3: "Author, A-Z",
      filterOption4: "Author, Z-A",
      note1: "There are no results for your query",
      note2: "Oops! So sorry, it seem that someone borrowed the book just now! Please check back later.",
      note3: "To place a hold, please return previously borrowed book",
      note4: "There are no recent additions to our library.",
    },
    careers: {
      title1: "JOIN US",
      title2: "Job Application",
      legend: "Apply as:",
      label1: "Practician",
      label2: "Experienced",
      option1: "Bartender",
      option2: "Waiter",
      option3: "Librarian",
      option4: "Events Manager",
      option5: "Kitchen",
      option6: "Cleaner",
      option7: "Finances",
      option8: "Warehouse",
      option9: "Other",
      button1: "APPLY",
      button2: "Back",
      button3: "Submit",

      careersP1:
        "The Soma family has grown over the years to include Comandante Marcos, our Mexican Restaurant and recently Soma Slow Food.",
      careersP2:
        "Like a filmmaker that makes movies that he would like to see, we also aim to create places that we would like to go to. In order to do that we need you! We’re on an adventure and welcome you to join us.",
      careersP3:
        "Being part of our family requires commitment, charm, confidence and desire to learn. Enjoying what you do is key since you will be working in a busy and fast-paced environment. We believe that the foundation of hospitality is team culture. This is the realization that every role is crucial in what we do, day in, day out.",
      careersP4:
        "As part of our team you will have the opportunity to take part in training programs, which include one-on-one training, workshops and food tastings.",
      careersP5: "Our doors are open for anyone interested in joining us!",
      careersP6:
        "We are always on a lookout for front-of-house and back-of-house staff across each of our sites, and we have a flexible recruiting system.",
      careersP7:
        "If you are a beginner with no prior experience you can still apply as a practician. Here you will have the opportunity to learn your chosen craft. Upon completion you will be given a certificate of completion and/or a reference. You can also choose to continue working with us on a more permanent basis.",
      careersP8:
        "Either way, whether you are a beginner or a seasoned professional apply by clicking on the button below.",
      careersP91: "We conduct our interviews on",
      careersP92: "When you apply you will be allocated the first available time.",
      careersP9Strong: "Saturday and Monday between 9am - 10am.",
      careersP10:
        "If you don’t see what you are looking for but think you’d like to join us in some other manner please feel free to contact us!",
      careersP11: "We look forward to hearing from you!",
      messageP1: "Thank you for your application. We will see you on:",
      messageP2span1: "between 9:00am - 10:00am",
      messageP2span2: "at Soma Book Station.",
      messageP3: "Please let one of our staff know about your arrival.",
    },
    contact: {
      contactP1:
        "Soma is located on Fazli Grajçevci Street. If you know where the Kosova's government is located, we’re only a couple of steps down from there.",
      contactP2: "For general enquiries please call…",
      contactP3: "or email...",
      contactP4: "You can also find us on Instagram and Facebook…",
      title1: "WHERE TO FIND US",
      title2: "GET IN TOUCH",
      title3: "OPENING HOURS",
      link1: "GOOGLE MAPS",
    },
    authentication: {
      title1: "SIGN IN",
      label1: "Sign In",
      label2: "Sign Up",
      label3: "Reset your password",
      label4: "We will send you an email to reset your password.",
      button1: "Sign in with Google",
      button2: "Sign in with Facebook",
      button3: "LOGIN",
      button4: "SIGN UP",
      button5: "Forgot your password?",
      button6: "SUBMIT",
      button7: "CANCEL",
      note1: "Don't have an account?",
      note2: "Already have an account?",
      note3: "We've sent you an email with a link to update your password.",
      link1: "Sign Up",
      link2: "Sign In",
    },
    dropDownMenu: {
      link1: "My Library",
      link2: "Admin",
      link3: "Log Out",
    },
    myLibrary: {
      navLink1: "Borrowed",
      navLink2: "On Hold",
      navLink3: "History",
      navLink4: "Wishlist",
      navLink5: "Account",
      note1: "You haven't borrowed anything.",
      note2: "There are no books on hold.",
      note3: "There are no items in your history.",
      note4: "There are no items in your wishlist.",
      note5: "If you would like to renew the book please bring it back to Soma and we will renew it for you.",
      note6: "You can renew only once, 7 days before due date",
      label1: "Name:",
      label2: "Email:",
      label3: "Phone:",
      label4: "Member:",
      label5: "Email verified:",
      label6: "Deposit Amount:",
      label7: "Hold Placed:",
      label8: "Hold Expires:",
      label9: "Return:",
      label10: "Number of renewals:",
      label11: "Date Borrowed:",
      label12: "Date Returned:",
      label13: "Available",
      label14: "Not Available",
      value1: "true",
      value2: "false",
      value3: "null",
      button1: "Cancel Hold",
      button2: "Renew",
      button3: "Hold",
      button4: "Delete",
    },
    pageNotFound: {
      title1: "Oops! Page not found",
      title2: "We are sorry, but the page you requested was not found",
    },

    footer: {
      body: " Soma Book Station, 32 Fazli Grajçevci Str, 10000 Prishtinë Kosovë",
      link1: "Privacy Policy",
      link2: "Website Credits",
      creditsTitle: "Credits",
      creditsSubtitle: "Design and development",
    },
  },
  albanian: {
    navigation: {
      navLink1: "Rreth",
      navLink2: "Ngjarjet",
      navLink3: "Rezervimet",
      navLink4: "Ushqim e pije",
      navLink5: "Libra e pllaka",
      navLink6: "Karriera",
      navLink7: "Kontakt",
      navLink8: "Galeria",
    },
    about: {
      title: "Rreth",
      bodyP1:
        "Në qoftë se don me shiju ndoj shujtë të mirë, me u çlodhë me nji pije a me lexu farë libri, ke ardhë bash te vendi.",
      bodyP2:
        "Soma asht hapësinë e gjithanshme shumëfunksionale me nji bibliotekë të vogël librash që mundesh me i huazu, me ndeja argëtuese, ekspozita, lexime, biseda, dugajë të përkohshme edhe, merret vesh, kafe të mrekullueshme.",
      bodyP3:
        "E kemi edhe nji oborr birre që mundet me rahatu grupe të mëdhaja e të vogla. Kemi nji përzgjedhje të madhe pijesh + birrash me rubinetë, përfshi birrën tonë, kokteje veç të shpisë e nji kuzhinë që shërben ushqime të shijshme të pijetores dhe specialitete javore.",
      bodyP4: "Në Soma ka nga diçka për të tanë!",
    },

    events: {
      title: "NGJARJET",
      bodyP1: "Soma ia ofron hapësinën artistëve të shumë disiplinave për me prezentu punën e vet.",
      bodyP2:
        "Qysh kur i kemi hapë dyert në vitin 2015, kemi pasë qinda ngjarje prej artistëve ndërkombëtarë mysafirë e prej ma të preferuemve artistë vendorë, me ndeja live, DJ, ekspozita, ekranizime filmash, lexime, dugajë të përkohshme e biseda, gjithmonë krejt falas.",
      bodyP3: "Nëse don me organizu nji ngjarje në Soma, kontaktona",
      bodyP4:
        "Ose thjesht eja, zene nji vend e kënaqu me shoqni ja çohu e kce deri në sabah – ti e ke në dorë!",
      link1: "këtu.",
      label1: "Ngjarjet e ardhshme",
      label2: "Ngjarjet e kalueme",
      label3: "DATA",
      label4: "KOHA",
      note1: "Nuk ka ngjarje të ardhshme",
      note2: "Nuk ka ngjarje të kalueme",
      note3: "Duke shkarkuar...",
      button1: "e ardhshme",
      button2: "e mëparshme",
      yearPlaceholder1: "VITI AKTUAL",
      yearPlaceholder2: "ZGJIDH VITIN",
      button3: "RISET",
      button4: "FILTRO",
    },

    reservations: {
      title: "REZERVIME TË TAVOLINAVE",
      bodyP1:
        "Ani pse do tavolina i mbajmë për ata që veç hyjnë, këshilloheni me rezervu tavolinën. Mundeni me kërku rezervimin me butonin ma poshtë, e na ju kontaktojmë mbas pak.",
      button1: "KËRKO NJI REZERVIM",
      button2: "Mbrapa",
      button3: "Paraqit kërkesë",
      label1: "ORARI I PUNËS",
      label2: "MSHELË",
      formTitle: "Kërko nji rezervim",
      formSubtitle: "Çka me ditë para se me shku",
      formNote1: "Shënim prej restorantit",
      formNote2:
        "Dijeni se tavolinën jua ruejmë për 15 minuta. Nëse vonoheni, na njoftoni që t’ju akomodojmë!",
      formLabel1: "vetë",
      formLabel2: "veta",
      legend: "Lloji i shërbimit",
      radioLabel1: "Ushqim e pije",
      radioLabel2: "Veç pije",
      message: "Faleminderit! Së shpejti të kontaktojmë.",

      m: "E hanë",
      t: "E martë",
      w: "E mërkurë",
      th: "E ejte",
      f: "E premte",
      sat: "E shtunë",
      sun: "E diel",
    },

    foodAndDrinks: {
      navLink1: "KUZHINË & BAR",
      navLink2: "MENYJA E USHQIMIT",
      navLink3: "MENYJA E PIJEVE",
      navLink4: "PJATA TË VOGLA",
      navLink5: "SALLATAT",
      navLink6: "NGA DETI",
      navLink7: "SHPAGETA DHE ORIZ",
      navLink8: "BURGERS",
      navLink9: "TË PËRBASHKËTA",
      navLink10: "AMËLSINAT",
      navLink11: "PIJE TË SHTRYDHURA",
      navLink12: "BIRRAT",
      navLink13: "COCKTAILS",
      note1: "Kliko mbi artikullin e menysë për me pa pjatën",
      note2: "Përditësimi i fundit: 25 Shtator, 2022",
      button1: "Shkarkoje Menynë",
      title1: "KUZHINA",
      title2: "BARI",
      kitchenAndBarP1:
        "Na në Soma krenohemi që shërbejmë ushqime të shijshme me cilësi të naltë, tu i përdorë veç prodhimet ma të freskëta vendore sa herë që asht e mundshme. Pavarësisht a asht burger që të shkrihet në gojë, i shërbyem me patate të fërgueme të preme me dorë, virshlle shpie a pasta shpie me ravioli, stafi me shumë përvojë i kuzhinës e kryen misionin me pasion për me të lanë krejtësisht të kënaqun.",
      kitchenAbdBarP2:
        "Ndonëse menynë e kemi kryesisht me kuzhinë ndërkombëtare, jemi të hapun me kriju pjata që i kombinojnë përbamësit vendorë, duke u tregu inovativë. Së fundmi kemi shtu në repertor djathin e Sharrit, djathë mali me shije të veçantë, që e përdorim si zavendësim të djathit parmixhan në disa prej pjatave tona.",
      kitchenAndBarP3:
        "Për burgera, mishin e bluejmë vetë dhe i shërbejmë me kuleçë shpie, të bamë me tharm të freskët. Vetë i thajmë domatet duke përdorë nji varietet vendor kur e ka sezonin. Raviolin e përgatisim të freskët me dorë përditë dhe vetë i bajmë edhe erëzat. Provojeni versionin e chutney-it që e bajmë na!",
      kitchenAndBarP4: "Kuzhina asht e hapun për mëngjes, drekë e darkë, çdo ditë veç të dielave.",
      kitchenAndBarP5:
        "Bash në zemër të Somës ndodhet bari kryesor – vendtakim i përkryem. Bari e ka nji listë të përzgjedhun me merak të venave e të uiskive. Na besojmë se ma i miri uiski vjen prej Skotlandës, prandaj ofrojmë përzgjedhje të uiskive skocezë.",
      kitchenAbdBarP6:
        "E kemi edhe birrën tonë vendore – Sabaja, të cilën e menaxhojmë me krenari, bashkë me nji përzgjedhje të birrave IPA, Lager, Porter, Weiss (birrë gruni gjermane) dhe i ofrojmë të tana drejt e prej kace.",
      kitchenAndBarP7:
        "Nëse s’je fort i birrës, kemi edhe nji deng pijesh artizanale të mrekullueshme. Rakia që ofrojmë asht ba nga nji përzgjedhje pemësh që i çojmë rregullisht në distileri dhe sigurohemi mos të kenë ngjyrues as aditivë.",
      kitchenAndBarP8:
        "Me mbi 20 uiski, 10 xhine e rume, tu përfshi rumin famëkeq 'të zi si nata' Kraken Spiced Rum dhe xhinin Dukagjini të prodhuem në Kosovë, shumë kokteje klasike dhe kokteje veç tonat, t’i mbulojmë ty të tana nevojat për pije.",
    },
    booksAndRecords: {
      navLink1: "BIBLIOTEKA",
      navLink2: "LIBRA",
      navLink3: "PLLAKA",
      title1: "BIBLIOTEKA",
      title2: "PLLAKAT",
      title3: "PËRMBLEDHJE",
      title4: "LISTA E KANGËVE",
      booksAndRecordsP1:
        "Qysh kur asht hapë në vitin 2015, Soma ka qenë e mbetet vend ku dashamirët e librave takohen, bisedojnë, pijnë çaj e kafe të mrekullueshme, hanë ushqime të shijshme edhe kuptohet, shfletojnë.",
      booksAndRecordsP2:
        "Përzgjedhja jonë prej rreth 2000 titujsh shtrihet prej klasikëve të letërsisë botnore e te ma të fortat e trilleve e të poezive të letërsisë bashkëkohore, n’anglisht e në shqip, tu mos i harru vëllimet e shumta në histori, politikë, filozofi, gatim, ese, shkencë e histori natyrore.",
      booksAndRecordsP3:
        "Koleksionit herë mbas here i shtojmë tituj të rij, prandaj sigurohu me kontrollu edhe për ta.",
      booksAndRecordsP4:
        "Je krejt i mirëseardhun me lexu ndonji libër sa je në ambientet tona ose mundesh edhe me huazu.",
      booksAndRecordsP5:
        "Kërko e shfleto nëpër koleksionin tonë përmes uebfaqes dhe rezervo cilindo libër që të intereson.",
      booksAndRecordsP6:
        "Pllaka e vinilit asht format që nuk humb. Presimet në te bahen drejt prej mostrës master dhe i përmbajnë të tana detajet që i ka synu artisti. Prandaj vinili tingëllon ma mirë se digjitalja.",
      booksAndRecordsP7:
        "Në bibliotekë kemi nji koleksion të vogël pllakash në dispozicion të DJ-ve ose artistëve që performojnë në lokalin tonë. Herë mbas here organizojmë edhe ngjarje kur i lëshojmë pllakat.",
      booksAndRecordsP8:
        "Mundohemi me shtu pllaka të reja në koleksion sa herë që bahen të disponueshme. Mundesh me pa për pllaka të reja ose me na e shfletu koleksionin në seksionin e pllakave në uebsajt.",
      button1: "Anëtarësia dhe huazimi",
      button2: "ose shfleto sipas kategorisë",
      button3: "Të shtueme së fundmi",
      button4: "DETAJET",
      button5: "Mbrapa",
      button6: "LISTA E DËSHIRAVE",
      button7: "REZERVO",
      button8: "Përmbledhje",
      button9: "Lista e kangëve",
      modalIntro: "Gjithkush asht i mirëseardhun me na i huazu librat prej bibliotekës për veç nji euro.",
      modalBullet1:
        "Mundesh me kërku ndonji libër dhe me rezervu përmes uebfaqes sonë, mbasi të jesh regjistru. Ka me mbetë i rezervuem për 24 orë, që ti me marrë kur të përshtatet.",
      modalBullet2:
        "Mundesh me rezervu veç 1 libër në nji kohë. Rezervimin mundesh me anulu në llogarinë tande të bibliotekës.",
      modalBullet3:
        "Mundesh me huazu veç 1 libër në nji kohë, e nuk mundesh me rezervu nji tjetër derisa ta kesh kthy librin e huazuem.",
      modalBullet4:
        "Para se me huazu nji libër, ta kërkojmë nji depozitë prej 20 eurosh, e cila të kthehet kur ta anulosh anëtarësinë.",
      modalBullet5:
        "Në rast se e humb ose e damton librin, duhemi me mbajtë depozitën për me zëvendësu me nji kopje të re.",
      modalBullet6:
        "Librat munden me u mbajtë nji muej dhe me u rinovu veç nji herë përmes llogarisë tande. Nëse don me rinovu prap, duhemi së pari me e pa librin.",

      searchBarOption1: "Titulli",
      searchBarOption2: "Autori",
      searchBarOption3: "Artisti",
      filterLabel1: "RENDITI SIPAS",
      bookLabel1: "Disponueshëm",
      bookLabel2: "Padisponuëshem",
      bookLabel3: "Kopjet e disponueshme:",
      filterOption1: "Titullit, A-Zh",
      filterOption2: "Titullit, Zh-A",
      filterOption3: "Autorit, A-Zh",
      filterOption4: "Autorit, Zh-A",
      note1: "Nuk ka rezultate",
      note2:
        "Na vjen keq, duket se dikush e huazoi librin pikërisht tani! Ju lutemi kontrolloni përsëri më vonë.",
      note3: "Për të rezervuar librin, ju lutemi kthejeni librin e huazuar më parë",
      note4: "Nuk ka të shtueme së fundmi në bibliotekën tonë.",
    },
    careers: {
      title1: "BASHKANGJITU",
      title2: "Aplikim për punë",
      legend: "Apliko si:",
      label1: "Praktikant",
      label2: "Me përvojë",
      option1: "Banakier-e",
      option2: "Kamarier-e",
      option3: "Bibliotekist-e",
      option4: "Menaxher-e ngjarjesh",
      option5: "Kuzhinë",
      option6: "Pastrues-e",
      option7: "Financat",
      option8: "Magazina",
      option9: "Tjera",
      button1: "APLIKO",
      button2: "Mbrapa",
      button3: "Paraqit",

      careersP1:
        "Familja Soma asht rritë me kalimin e viteve për me përfshi restorantin tonë meksikan Comandante Marcos dhe rishtazi edhe tjetrin, Soma Slow Food.",
      careersP2:
        "Sikur ai regjisori që i ban filmat që kish dashtë me i pa, edhe na synojmë me kriju vende ku kishim dashtë me shku. Për me e ba këtë na duhesh edhe ti! I kemi hy nji aventure dhe je i/e mirëseardhun me na u bashkangjitë.",
      careersP3:
        "Për me qenë pjesë e familjes sonë lypet përkushtim, sharm, mirëbesim e dëshirë me mësu. Thelbësorja asht me u kënaqë me çka ban, mbasi ke me punu në nji mjedis të ngarkuem e dinamik. Na besojmë se tabani i mikpritjes asht te kultura ekipore. Kjo d.m.th. me kuptu se secili rol asht jetik për çkado që bajmë, ditë e për ditë.",
      careersP4:
        "Si pjesë e ekipit tonë, ke me pasë mundësi me marrë pjesë në programe trajnimi, që përfshijnë trajnime individuale, punëtori e degustime ushqimesh.",
      careersP5: "Dyert i kemi të hapëta për këdo që interesohet me na u bashkangjitë!",
      careersP6:
        "Gjithmonë jemi në kërkim të stafit të restorantit e të kuzhinës në secilin prej lokaleve tona dhe kemi nji sistem fleksibil rekrutimi.",
      careersP7:
        "Nëse je fillestar pa përvojë paraprake, prap mundesh me apliku si praktikant. Këtu ke me pasë mundësi me mësu zanatin që e zgjedh. Kur ta përfundosh, të jepet certifikata e përfundimit dhe/ose nji referencë. Mundesh me zgjedhë edhe me vazhdu me punu me ne për kohë ma të gjatë. ",
      careersP8:
        "Sido me qenë, në qofsh fillestar-e a profesionist-e me përvojë, apliko duke kliku në butonin e maposhtëm.",
      careersP91: "Intervistat i zhvillojmë të",
      careersP92: "Kur të aplikosh, të caktohet i pari termin i disponueshëm.",
      careersP9Strong: "shtunave dhe të haneve mes orës 9 e 10 paradite.",
      careersP10:
        "Nëse nuk sheh çka po lyp, por mendon se don me na u bashkangjitë në ndonji tjetër mënyrë, mos ngurro me na kontaktu!",
      careersP11: "Presim me padurim me të ndigju!",
      messageP1: "Faleminderit për aplikimin. Do të shihemi ditën:",
      messageP2span1: "mes orës 9 e 10",
      messageP2span2: "te Soma Book Station.",
      messageP3: "Të lutem njoftoje ndonji pjesëtar të stafit kur të mbërrish.",
    },

    contact: {
      contactP1:
        "Soma ndodhet në rrugën ‘Fazli Grajçevci’. Nëse e din ku ndodhet ndërtesa e kryeministrisë, na jemi veç pak hapa ma poshtë.",
      contactP2: "Për pyetje të përgjithshme të lutem thirr:",
      contactP3: "ose nis email:",
      contactP4: "Mundesh me na gjetë edhe në Instagram e Facebook.",
      title1: "KU ME NA GJETË",
      title2: "KONTAKTONA",
      title3: "ORARI I PUNËS",
      link1: "HARTA GOOGLE",
    },

    authentication: {
      title1: "FUTU",
      label1: "Futu",
      label2: "Regjistrohu",
      label3: "Risetoni fjalëkalimin tuaj",
      label4: "Ne do t'ju dërgojmë një email për të risetuar fjalëkalimin tuaj.",
      button1: "Regjistrohu me Google",
      button2: "Regjistrohu me Facebook",
      button3: "KYÇU",
      button4: "REGJISTROHU",
      button5: "Keni harruar fjalëkalimin tuaj?",
      button6: "PARAQIT",
      button7: "ANULO",
      note1: "Nuk ke llogari?",
      note2: "Ke llogari?",
      note3: "Ne ju kemi dërguar një email me një link për të përditësuar fjalëkalimin tuaj.",
      link1: "Regjistrohu",
      link2: "Futu",
    },
    dropDownMenu: {
      link1: "Biblioteka ime",
      link2: "Admin",
      link3: "Shkyçu",
    },
    myLibrary: {
      navLink1: "I huazuem",
      navLink2: "I rezervuem",
      navLink3: "Histori",
      navLink4: "Lista e dëshirave",
      navLink5: "Llogaria",
      note1: "Nuk ke huazu kurrgja.",
      note2: "Nuk ka libra të rezervuem.",
      note3: "Nuk ke asnji artikull në histori.",
      note4: "Nuk ke asnji artikull në listën e dëshirave.",
      note5: "Nëse don me rinovu librin, të lutem bjere në Soma dhe na ta rinovojmë.",
      note6: "Mundesh me rinovu veç nji herë, 7 ditë para datës së kthimit",
      label1: "Emni:",
      label2: "Email:",
      label3: "Telefoni:",
      label4: "Anëtar:",
      label5: "Email i verifikuem:",
      label6: "Shuma e depozitës:",
      label7: "Rezervimi u ba me:",
      label8: "Rezervimi Skadon:",
      label9: "Data e kthimit:",
      label10: "Numri i rinovimeve:",
      label11: "Data e huazimit:",
      label12: "Data e kthimit:",
      label13: "Disponueshëm",
      label14: "Padisponueshem",
      value1: "po",
      value2: "jo",
      value3: "zero",
      button1: "Anuloje Rezervimin",
      button2: "Rinovoje",
      button3: "Rezervo",
      button4: "Fshij",
    },

    pageNotFound: {
      title1: "Oops! Faqja nuk u gjet",
      title2: "Na vjen keq, por faqja që kërkuat nuk u gjet",
    },

    footer: {
      body: "Soma Book Station, rr. Fazli Grajçevci nr. 32, Prishtinë 10000 Kosovë",
      link1: "Politika e privatësisë",
      link2: "Kreditet e uebfaqes",
      creditsTitle: "Kreditet",
      creditsSubtitle: "Dizajni dhe zhvillimi",
    },
  },
};
