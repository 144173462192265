import { useEffect, useState, useContext } from "react";
import noImagePlaceHolder from "../../../../assets/images/No-Image-Placeholder.png";
import AuthContext from "../../../../contexts/AuthContext";

import { MultiLingualContent } from "../../../../contexts/Language/MultiLingualContent";

// FIREBASE
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import styles from "./WishListItem.module.css";

const WishListItem = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const [bookStatus, setBookStatus] = useState({});
  const authCtx = useContext(AuthContext);

  const { uid } = authCtx.authUser;

  // Get image image reference from storage
  useEffect(() => {
    if (props.imageRef) {
      const storage = getStorage();
      const pathReference = ref(storage, props.imageRef);

      getDownloadURL(pathReference)
        .then((url) => {
          // `url` is the download URL for 'bookcovers/*'
          setImageUrl(url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
          setImageUrl(noImagePlaceHolder);
        });
    } else {
      setImageUrl(noImagePlaceHolder);
    }
  }, [props.imageRef]);

  useEffect(() => {
    const checkBookStatus = async () => {
      const db = getFirestore();
      // Check if book is available in books collection
      const bookRef = doc(db, "books", props.docId);
      const bookSnap = await getDoc(bookRef);
      const isAvailable = bookSnap.data().available;

      // Check if user has a book onHold
      const userRef = doc(db, "users", uid);

      const userSnap = await getDoc(userRef);

      const hasBookOnHold = userSnap.data().onHold.length ? true : false;
      const hasBorrowedBook = userSnap.data().borrowed.length ? true : false;

      const status = {
        isAvailable: isAvailable,
        buttonStatus: !hasBookOnHold && isAvailable && !hasBorrowedBook ? true : false,
      };
      // Don't understand logic of bookStatus. Re-factors
      setBookStatus(status);
    };
    checkBookStatus();
  }, [props.docId, uid, props.onHold]);

  return (
    <li className={styles["wishlist-item"]}>
      <img src={imageUrl} className='skeleton' alt='' />
      <div className={styles["book-data"]}>
        <div>
          <h3 className={styles.title}>{props.title}</h3>
          <span className={styles.author}>{props.author}</span>
          {bookStatus.isAvailable ? (
            <span className={`${styles["book-status"]} ${styles["green"]}`}>
              <MultiLingualContent contentId='myLibrary' prop='label13' />
            </span>
          ) : (
            <span className={`${styles["book-status"]} ${styles["red"]}`}>
              <MultiLingualContent contentId='myLibrary' prop='label14' />
            </span>
          )}
        </div>

        <div className={styles.actions}>
          <button
            className={styles["button-80"]}
            disabled={!bookStatus.buttonStatus}
            onClick={() => props.onHold(props.docId)}>
            <MultiLingualContent contentId='myLibrary' prop='button3' />
          </button>
          <button className={styles["button-80"]} onClick={() => props.onDelete(props.docId)}>
            <MultiLingualContent contentId='myLibrary' prop='button4' />
          </button>
        </div>
      </div>
    </li>
  );
};

export default WishListItem;
