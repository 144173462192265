import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import isDateSupported from "../../../../utils/isDateSupported";
import DjGenresCheckboxGroup from "./DjGenresCheckBoxGroup";

import styles from "./EventsForm.module.css";

const EventsForm = (props) => {
  // For older versions of Safari that don't support date and time inputs
  const [dateSupported, setDateSupported] = useState(false);
  // Store type of event selected by user from select input
  const [typeOfEvent, setTypeOfEvent] = useState("");
  // An array of strings of selected checkboxes
  const [selectedDjCheckboxes, setSelectedDjCheckboxes] = useState([]);
  const [imageName, setImageName] = useState("");
  // The uploaded image to be used when form is submitted
  const [imageUrl, setImgUrl] = useState("");

  // Data coming from inputs collected with useRef() hook
  const eventNameRef = useRef();
  const eventDateRef = useRef();
  const eventTimeRef = useRef();
  const eventDescriptionRef = useRef();
  // uploadedImageRef is used to clear the file input if form is successfully submitted
  const uploadedImageRef = useRef();

  //  When page loads we check for browser support
  useEffect(() => {
    if (isDateSupported()) {
      setDateSupported(true);
    }
  }, []);

  //  onchange event we store value we get from select input
  const selectOnChange = (ev) => {
    setTypeOfEvent(ev.target.value);
  };

  // *********** use later ?
  // array of strings we get from DjGenresCheckBoxGroup component. This component is a bit involved
  // const selectedDjGenresHandler = (selectedGenres) => {
  //   setSelectedDjCheckboxes(selectedGenres);
  // };
  // ***********

  const onChangeImageHandler = (event) => {
    // As an added challenge see if you can also compress the image further
    const resize_width = 600; //without px

    //get the image selected
    let item = event.target.files[0];

    // URL.createObjectURL(ev.target.files[0]); this creates a blob whicj might be useful when storing in fire storage
    //create a FileReader
    const reader = new FileReader();

    //image turned to base64-encoded Data URI.
    reader.readAsDataURL(item);
    reader.name = item.name; //get the image's name
    reader.size = item.size; //get the image's size

    setImageName(uuidv4() + ".jpeg");

    reader.onload = function (event) {
      let img = new Image(); //create an image
      img.src = event.target.result; //result is base64-encoded Data URI

      img.size = event.target.size; //set size (optional)
      img.onload = function (el) {
        var elem = document.createElement("canvas"); //create a canvas

        //scale the image to 600 (width) and keep aspect ratio
        var scaleFactor = resize_width / el.target.width;
        elem.width = resize_width;
        elem.height = el.target.height * scaleFactor;

        //draw in canvas
        var ctx = elem.getContext("2d");
        ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

        //get the base64-encoded Data URI from the resize image
        var srcEncoded = ctx.canvas.toDataURL("image/jpeg", 0.5);
        //assign it to thumb src
        setImgUrl(srcEncoded);
      };
    };
  };

  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    const eventName = eventNameRef.current.value;
    const eventDate = eventDateRef.current.value;
    const eventTime = eventTimeRef.current.value;
    const eventType = typeOfEvent;
    const djGenres = selectedDjCheckboxes;
    const eventDescription = eventDescriptionRef.current.value;
    const dateTime = new Date(`${eventDate}T${eventTime}`);

    const eventData = {
      name: eventName.toLowerCase(),
      date: dateTime,
      type: eventType,
      djGenres: eventType === "DJ" ? djGenres : null,
      description: eventDescription.trim(),
      imageRef: imageName ? `eventImages/${imageName}` : "",
    };

    props.onSubmit(eventData, { imageUrl, imageName });

    // Clear form data
    eventNameRef.current.value = "";
    eventDateRef.current.value = "";
    eventTimeRef.current.value = "";
    eventDescriptionRef.current.value = "";
    uploadedImageRef.current.value = "";
    setTypeOfEvent("");
    setSelectedDjCheckboxes({});
  };

  return (
    <form className={styles.form} onSubmit={onSubmitHandler}>
      <div className={styles.controls}>
        <label htmlFor='eventTitle'>EVENT NAME</label>
        <input
          ref={eventNameRef}
          type='text'
          id='eventTitle'
          placeholder='Event Name'
          autoComplete='off'
          aria-label='Event Name'
          required
        />
      </div>
      <div className={styles["date-time-controls"]}>
        <div className={styles["date-controls"]}>
          <label htmlFor='date'>
            <span>DATE</span>
          </label>
          <label htmlFor='date'>
            {!dateSupported && (
              <span className={styles["description-date"]}>Please use the YYYY-DD-MM format.</span>
            )}
          </label>
          <input
            ref={eventDateRef}
            type='date'
            pattern='[0-9]{4}(/|-)[0-9]{1,2}(/|-)[0-9]{1,2}'
            title='Please type in the date in this format'
            id='date'
            min={new Date().toISOString().split("T")[0]}
            autoComplete='off'
            aria-label='Event date'
            required
          />
        </div>
        <div className={styles["time-controls"]}>
          <label htmlFor='time'>
            <span>TIME</span>
          </label>
          <label htmlFor='time'>
            {!dateSupported && (
              <span className={styles["description-time"]}>Please use the hh:mm (00 - 23) format.</span>
            )}
          </label>
          <input
            ref={eventTimeRef}
            type='time'
            pattern='(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9])'
            id='time'
            autoComplete='off'
            aria-label='Event Time'
            required
          />
        </div>
      </div>

      <div className={styles.controls}>
        <label htmlFor='typeOfEvent'>TYPE OF EVENT</label>
        <select aria-label='Type of Event' value={typeOfEvent || ""} onChange={selectOnChange} required>
          <option value='' disabled>
            -- select an option --
          </option>
          <option value='DJ'>DJ</option>
          <option value='CONCERT'>CONCERT</option>
          <option value='EXHIBITION'>EXHIBITION</option>
          <option value='SCREENING'>SCREENING</option>
          <option value='WORKSHOP'>WORKSHOP</option>
          <option value='BOOK SIGNING'>BOOK SIGNING</option>
          <option value='FOOD TASTING'>FOOD TASTING</option>
          <option value='WINE TASTING'>WINE TASTING</option>
          <option value='OTHER'>OTHER</option>
        </select>
      </div>
      {typeOfEvent === "DJ" && <DjGenresCheckboxGroup selectedDjGenres={setSelectedDjCheckboxes} />}

      <div className={styles["textarea-control"]}>
        <label>DESCRIPTION/SHORT BIO</label>
        <textarea
          ref={eventDescriptionRef}
          placeholder='Description or short biography'
          autoComplete='off'
          aria-label='Description/Biography'
          required
        />
      </div>
      <div className={styles["upload-file-controls"]}>
        <label className={styles["uploadLabel"]}>UPLOAD IMAGE</label>
        <input
          onChange={onChangeImageHandler}
          type='file'
          aria-label='Upload image'
          className={styles["uploadButton"]}
          accept='image/*'
          ref={uploadedImageRef}
        />
        <div className={styles["file-alert"]}></div>
      </div>
      <button className={styles["button-24"]} type='submit'>
        Create Event
      </button>
    </form>
  );
};

export default EventsForm;
