import { useState, useEffect } from "react";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import styles from "./YearSelector.module.css";

const YearSelector = ({ year, setYear, activeButton }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = 2023; i <= currentYear; i++) {
      years.push(i);
    }

    setOptions(years.sort((a, b) => parseInt(b) - parseInt(a)));
  }, []);
  return (
    <div className={styles.control}>
      <div className={styles["select-group-wrapper"]}>
        <select
          name='searchFilter'
          id='searchFilter'
          className={styles.select}
          value={year}
          onChange={(ev) => setYear(ev.target.value)}
          disabled={activeButton === "upcoming" ? true : false}>
          <option value='past' disabled>
            <MultiLingualContent contentId='events' prop='yearPlaceholder2' />
          </option>
          {activeButton === "upcoming" ? (
            <option value='currentYear'>
              <MultiLingualContent contentId='events' prop='yearPlaceholder1' />
            </option>
          ) : (
            ""
          )}
          {options.map((year) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default YearSelector;
