import { useEffect, useState, useContext } from "react";
import { MultiLingualContent } from "../../../../contexts/Language/MultiLingualContent";
import { LanguageContext } from "../../../../contexts/Language/LanguageContext";
import AuthContext from "../../../../contexts/AuthContext";
import OnHoldBook from "./OnHoldBook";

// FIREBASE
import { getFirestore, doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";

import styles from "./OnHold.module.css";

const OnHold = () => {
  const [book, setBook] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const authCtx = useContext(AuthContext);
  const { uid } = authCtx.authUser;

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const getOnHoldBook = async () => {
      const db = getFirestore();
      const userRef = doc(db, "users", uid);
      const userSnap = await getDoc(userRef);
      let onHoldBook = userSnap.data().onHold[0];

      if (!onHoldBook) {
        setIsPending(false);
        return;
      }
      setBook(onHoldBook);
      setIsPending(false);
    };
    getOnHoldBook();
  }, [uid]);

  // Removing hold from users doc, holdRequest collection, and updating the book on the books collection to true. And update UI
  const cancelHoldHandler = async () => {
    const db = getFirestore();
    const userRef = doc(db, "users", uid);

    // Get the book from onHold array

    const userSnap = await getDoc(userRef);
    let holdRequestId = userSnap.data().onHold[0].holdRequestId;

    //  Checking wether book was placed on bookHold by admin
    let holdId = userSnap.data().onHold[0].bookHoldId;
    if (!holdId) {
      await deleteDoc(doc(db, "holdRequests", holdRequestId));
    }

    // Update available copies of the book in books collection.
    const docId = book.docId;
    const docRef = doc(db, "books", docId);
    const docSnap = await getDoc(docRef);
    let copies = docSnap.data().copies;

    await updateDoc(docRef, { copies: Number(copies) + 1 });

    // Update book avaialbility in books collection
    if (copies === 0) {
      const bookRef = doc(db, "books", docId);
      await updateDoc(bookRef, { available: true });
    }

    // Remove doc from user.onHold array
    await updateDoc(userRef, { onHold: [] });

    // Instead of deleting doc from bookHolds collection we update the doc if it exists and add property holdCanceled:true, in order to inform the admin to place book back in shelf. We give admin control over deleting book from bookHolds

    // Update doc in bookHolds collection with property holdCanceled: true, if it was placed by admin
    let bookHoldId = userSnap.data().onHold[0].bookHoldId;
    if (bookHoldId) {
      await updateDoc(doc(db, "bookHolds", bookHoldId), { holdCanceled: true });
    }

    // Update UI
    setBook(null);
  };

  return isPending ? (
    language === "albanian" ? (
      "Duke shkarkuar..."
    ) : (
      "Loading..."
    )
  ) : book ? (
    <OnHoldBook onCancelHold={cancelHoldHandler} book={book} />
  ) : (
    <p className={styles.note}>
      <MultiLingualContent contentId='myLibrary' prop='note2' />
    </p>
  );
};

export default OnHold;
