import { Fragment, useState } from "react";

import { v4 as uuid } from "uuid";
import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import TrackItem from "./TrackItem";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

import styles from "./RecordDetails.module.css";

// FIREBASE

const RecordDetails = (props) => {
  const { overview, tracklist, artist, title, url } = props.recordDetails;
  const [activeTabButton, setActiveTabButton] = useState("overview");

  const tabButtonHandler = (ev) => {
    setActiveTabButton(ev.target.id);
  };
  const description = (
    <Fragment>
      <h3>
        <MultiLingualContent contentId='booksAndRecords' prop='title3' />
      </h3>
      <p>{overview}</p>
    </Fragment>
  );

  const trackList = (
    <Fragment>
      <div className={styles.tracklist}>
        <h3>
          <MultiLingualContent contentId='booksAndRecords' prop='title4' />
        </h3>
        {tracklist &&
          tracklist.map((track) => {
            return <TrackItem key={uuid()} track={track} />;
          })}
      </div>
    </Fragment>
  );

  let tabContent = activeTabButton === "overview" ? description : trackList;

  return (
    <Fragment>
      <div className={styles["record-details"]}>
        <header className={styles["record-details-header"]}>
          <div className={styles["recordcover-container"]}>
            <img src={url} className='skeleton' alt='' />
          </div>
          <div className={styles["record-info-container"]}>
            <div>
              <h3 className={styles["record-title"]}>{capitalizeFirstLetter(title)}</h3>
              <span className={styles["record-author"]}>{capitalizeFirstLetter(artist)}</span>
            </div>
          </div>
        </header>
        <div className={styles["details-container"]}>
          <nav className={styles["nav-tab"]}>
            <button
              type='button'
              className={`${styles["tab-button"]} ${activeTabButton === "overview" ? styles.active : ""}`}
              id='overview'
              onClick={tabButtonHandler}>
              <MultiLingualContent contentId='booksAndRecords' prop='button8' />
            </button>
            <button
              type='button'
              className={`${styles["tab-button"]} ${activeTabButton === "tracklist" ? styles.active : ""}`}
              id='tracklist'
              onClick={tabButtonHandler}>
              <MultiLingualContent contentId='booksAndRecords' prop='button9' />
            </button>
          </nav>
          <div className={styles.container}>{tabContent}</div>
        </div>
      </div>
    </Fragment>
  );
};

export default RecordDetails;
