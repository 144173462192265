import Trash from "../../../../assets/icons/Trash";
import styles from "./EventRow.module.css";

const EventRow = (props) => {
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const date = props.date.toDate().toLocaleString("default", options);
  return (
    <tr className={styles.tr}>
      <td data-label='NAME'>{props.name}</td>
      <td data-label='DATE'>{date}</td>
      <td data-label='TYPE'>{props.type}</td>
      <td data-label='OPERATIONS'>
        <button className={styles.btn} type='button' onClick={() => props.onDeleteEvent(props.docId)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default EventRow;
