import styles from "./BooksAdmin.module.css";

const BooksAdmin = () => {
  return (
    <svg className={styles.svg} version='1.1' id='圖層_1' x='0' y='0' viewBox='0 0 100 100'>
      <path
        d='M85.413 54.446a.863.863 0 0 0-.516-.629L49.93 39.157a.864.864 0 0 0-.959.195L15.934 73.185a.86.86 0 0 0-.23.781.862.862 0 0 0 .516.629l5.738 2.406 3.039 1.274 26.19 10.98c.331.14.708.062.96-.194l23.975-24.553 2.302-2.357 6.76-6.923a.866.866 0 0 0 .229-.782z'
        fill='#333'
      />
      <path
        d='M85.803 40.186a.863.863 0 0 0-.516-.629L50.32 24.897a.864.864 0 0 0-.959.195L16.323 58.924a.86.86 0 0 0-.23.781.862.862 0 0 0 .516.629l5.738 2.406 3.039 1.274 26.19 10.98c.331.14.708.062.96-.194L76.51 50.247l2.302-2.357 6.76-6.923a.864.864 0 0 0 .231-.781z'
        fill='#333'
      />
      <path
        d='M79.549 51.521l-24.822 25.42a3.915 3.915 0 0 1-4.336.88L23.084 66.372l-3.039-1.274-2.152-.902-1.56-.654c-.477-.2-.974-.619-1.434-.154-.204.207-.304.493-.376.774-.254.989-.202 2.071-.163 3.084.042 1.082.197 2.158.452 3.21.153.632.339 1.256.555 1.869.208.589.428 1.203.762 1.735.195.311.465.663.815.793.079.029.158.059.236.092l.479.201 1.339.561 1.786.749 2.16.905 2.46 1.031 2.686 1.126 2.839 1.19 2.919 1.224 2.925 1.226 2.858 1.198 2.717 1.139 2.503 1.049 2.215.929 1.853.777 1.419.595.91.382.329.138c.33.139.708.063.959-.195L83.19 57.777v-9.985l-1.339 1.372-2.302 2.357z'
        fill='#e0e0e0'
      />
      <path d='M87.764 43.107a3.932 3.932 0 0 0-1.293-6.377L51.504 22.07a3.917 3.917 0 0 0-4.336.88l-.491.503-2.283 2.338-3.719 3.808-4.681 4.794-5.17 5.294-5.186 5.31-4.728 4.842-3.798 3.889c-1.077 1.103-2.154 2.089-3.021 3.39-.596.895-.963 1.825-1.335 2.831a20.31 20.31 0 0 0-.725 2.385 19.248 19.248 0 0 0-.484 3.149 19.26 19.26 0 0 0 .633 6.317c.44 1.636 1.002 3.311 2.089 4.64.518.633 1.068 1.107 1.83 1.372.208.072.41.172.613.257l4.035 1.692 5.862 2.458 6.745 2.828 6.683 2.802 5.678 2.38 3.727 1.563.948.398a3.917 3.917 0 0 0 4.336-.88l33.037-33.833a3.932 3.932 0 0 0-1.294-6.377l-.217-.091v-6.355l1.512-1.547zm-4.574 6.618v8.052L52.535 89.17a.865.865 0 0 1-.959.195l-.329-.138-.91-.382-1.419-.595-1.853-.777-2.215-.929-2.503-1.049-2.717-1.139-2.858-1.198-2.925-1.226-2.919-1.224-2.839-1.19-2.686-1.126-2.46-1.031-2.16-.905-1.786-.749-1.339-.561-.479-.201a5.23 5.23 0 0 0-.236-.092c-.35-.13-.621-.482-.815-.793-.333-.532-.554-1.145-.762-1.735a19.268 19.268 0 0 1-.555-1.869 16.4 16.4 0 0 1-.452-3.21c-.039-1.014-.091-2.095.163-3.084.072-.281.172-.568.376-.774.459-.465.957-.046 1.434.154l1.56.654 2.152.902 3.039 1.274L50.391 77.82a3.915 3.915 0 0 0 4.336-.88l24.822-25.42 2.302-2.357 1.339-1.372v1.934zm2.382-8.759l-6.76 6.923-2.302 2.357L52.535 74.8a.866.866 0 0 1-.96.194l-26.19-10.98-3.039-1.274-5.738-2.406a.862.862 0 0 1-.467-1.124l.049-.1a.839.839 0 0 1 .132-.186L49.36 25.091a.864.864 0 0 1 .959-.195l34.967 14.66c.383.16.488.494.516.629a.859.859 0 0 1-.23.781z' />
      <path
        d='M44.588 51.418l-2.126-5.455-8.505 1.083c-.757.096-1.21-.45-.758-.914l21.966-22.514 12.984 5.34-21.966 22.515c-.452.463-1.406.431-1.595-.055z'
        fill='#c33737'
      />
      <path
        d='M85.413 25.924a.863.863 0 0 0-.516-.629L49.93 10.635a.864.864 0 0 0-.959.195L15.934 44.662a.86.86 0 0 0-.23.781.862.862 0 0 0 .516.629l5.738 2.406 3.039 1.274 26.19 10.98c.331.14.708.062.96-.194l23.975-24.553 2.302-2.357 6.76-6.923a.864.864 0 0 0 .229-.781z'
        fill='#333'
      />
      <path
        d='M79.159 37.259l-24.822 25.42a3.915 3.915 0 0 1-4.336.88L22.694 52.11l-3.039-1.274-2.152-.902-1.56-.654c-.477-.2-.974-.619-1.434-.154-.204.207-.304.493-.376.774-.254.989-.202 2.071-.163 3.084.042 1.082.197 2.158.452 3.21.153.632.339 1.256.555 1.869.208.589.428 1.203.762 1.735.195.311.465.663.815.793.079.029.158.059.236.092l.479.201 1.339.561 1.786.749 2.16.905 2.46 1.031 2.686 1.126 2.839 1.19 2.919 1.224 2.925 1.226 2.858 1.198 2.717 1.139 2.503 1.049 2.215.929 1.853.777 1.419.595.91.382.329.138c.33.139.708.063.959-.195L82.8 43.515V33.53l-1.339 1.372-2.302 2.357z'
        fill='#e0e0e0'
      />
      <path d='M87.375 28.845a3.932 3.932 0 0 0-1.293-6.377L51.115 7.809a3.917 3.917 0 0 0-4.336.88l-.491.503-2.283 2.338-3.719 3.808-4.681 4.794-5.17 5.294-5.186 5.31-4.728 4.842-3.798 3.889c-1.077 1.103-2.154 2.089-3.021 3.39-.596.895-.963 1.825-1.335 2.831a20.31 20.31 0 0 0-.725 2.385 19.248 19.248 0 0 0-.484 3.149 19.26 19.26 0 0 0 .633 6.317c.44 1.636 1.002 3.311 2.089 4.64.518.633 1.068 1.107 1.83 1.372.208.072.41.172.613.257l4.035 1.692 5.862 2.458 6.745 2.828 6.683 2.802 5.678 2.38 3.727 1.563.948.398a3.917 3.917 0 0 0 4.336-.88l33.037-33.833a3.932 3.932 0 0 0-1.294-6.377l-.217-.091v-6.355l1.512-1.548zM82.8 35.463v8.052L52.146 74.908a.865.865 0 0 1-.959.195l-.329-.138-.91-.382-1.419-.595-1.853-.777-2.215-.929-2.503-1.049-2.717-1.139-2.858-1.198-2.925-1.226-2.919-1.224-2.839-1.19-2.686-1.126-2.46-1.031-2.16-.905-1.786-.749-1.339-.561-.479-.201a5.23 5.23 0 0 0-.236-.092c-.35-.13-.621-.482-.815-.793-.333-.532-.554-1.145-.762-1.735a19.268 19.268 0 0 1-.555-1.869 16.4 16.4 0 0 1-.452-3.21c-.039-1.014-.091-2.095.163-3.084.072-.281.172-.568.376-.774.459-.465.957-.046 1.434.154l1.56.654 2.152.902 3.039 1.274 27.307 11.449a3.915 3.915 0 0 0 4.336-.88l24.822-25.42 2.302-2.357L82.8 33.53v1.933zm2.383-8.758l-6.76 6.923-2.302 2.357-23.975 24.553a.866.866 0 0 1-.96.194l-26.19-10.98-3.039-1.274-5.738-2.406a.862.862 0 0 1-.467-1.124l.049-.1a.839.839 0 0 1 .132-.186L48.97 10.829a.864.864 0 0 1 .959-.195l34.967 14.66c.383.16.488.494.516.629a.86.86 0 0 1-.229.782z' />
      <path
        d='M44.198 37.156l-2.126-5.455-8.505 1.083c-.757.096-1.21-.45-.758-.914L54.776 9.357l12.984 5.34-21.966 22.514c-.452.463-1.407.431-1.596-.055z'
        fill='#c33737'
      />
      <path
        d='M36.859 92.116l-2.126-5.455-8.505 1.083c-.757.096-1.21-.45-.758-.914l9.915-10.153 12.984 5.34-9.915 10.153c-.452.464-1.406.432-1.595-.054z'
        fill='#c33737'
      />
    </svg>
  );
};

export default BooksAdmin;
