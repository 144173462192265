import Trash from "../../../../assets/icons/Trash";

import styles from "./ExpiredHoldsRow.module.css";

const ExpiredHoldsRow = (props) => {
  const now = new Date().getTime(); // now in miliseconds
  const holdExp = props.holdUntil.toMillis();
  const isPickupLate = now > holdExp;

  let isHoldCanceled = props.isHoldCanceled ? true : false;

  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const holdExpires = props.holdUntil.toDate().toLocaleString("default", options);
  return (
    <tr className={isPickupLate ? `${styles.tr} ${styles.flagged}` : styles.tr}>
      <td data-label='BOOK TITLE'>{props.title}</td>
      <td data-label='BOOK ID'>{props.id}</td>
      <td data-label='NAME'>{props.fullName}</td>
      <td data-label='PHONE'>{props.phone}</td>
      <td data-label='EMAIL'>{props.email}</td>
      <td data-label='MEMBER'>{props.member ? "Yes" : "No"}</td>
      <td data-label='HOLD UNTIL'>
        {isPickupLate
          ? "Please return book to shelf. Hold has expired"
          : isHoldCanceled
          ? "Hold was canceled. Please return book to shelf"
          : holdExpires}
      </td>
      <td data-label='OPERATIONS'>
        <button
          className={styles.btn}
          type='button'
          onClick={() => props.onDeleteExpiredHold(props.expiredHoldId)}>
          <Trash />
        </button>
      </td>
    </tr>
  );
};

export default ExpiredHoldsRow;
