import React, { useState, useEffect, useContext } from "react";

import { LanguageContext } from "../../contexts/Language/LanguageContext";

import getTranslatedTypeOfEvent from "../../utils/getTranslateTypeOfEvent";
import getTranslatedMonth from "../../utils/getTranslatedMonth";
import getTranslatedWeekday from "../../utils/getTranslatedWeekday";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import noImagePlaceHolder from "../../assets/images/noImagePlaceholderSquare.jpeg";
import DotsVertical from "../../assets/icons/DotsVertical";

import { MultiLingualContent } from "../../contexts/Language/MultiLingualContent";
import styles from "./EventCard.module.css";

// FIREBASE

import { getStorage, ref, getDownloadURL } from "firebase/storage";

const EventCard = ({ name, date, imageRef, djGenres, description, type }) => {
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const { language } = useContext(LanguageContext);

  let classes = showEventDetails ? `${styles.overlay} ${styles["show-overlay"]}` : `${styles.overlay}`;

  const showEventDetailsHandler = () => {
    setShowEventDetails(!showEventDetails);
  };

  let weekday = date.toDate().toLocaleString("default", { weekday: "long" });
  let month = date.toDate().toLocaleString("default", { month: "long" });
  let day = date.toDate().toLocaleString("default", { day: "numeric" });
  let year = date.toDate().toLocaleString("default", { year: "numeric" });

  const time = date.toDate().toLocaleTimeString("default", { hour: "2-digit", minute: "2-digit" });

  if (language === "albanian") {
    type = getTranslatedTypeOfEvent(type);
    month = getTranslatedMonth(month);
    weekday = getTranslatedWeekday(weekday);
  }

  useEffect(() => {
    if (imageRef) {
      const storage = getStorage();
      const pathReference = ref(storage, imageRef);

      getDownloadURL(ref(storage, pathReference))
        .then((url) => {
          // `url` is the download URL for 'bookcovers/*'
          setImageUrl(url);
        })
        .catch((error) => {
          // Handle any errors
          setImageUrl(noImagePlaceHolder);
        });
    } else {
      setImageUrl(noImagePlaceHolder);
    }
  }, [imageRef]);
  return (
    <div className={`${styles["event-card"]} skeleton`}>
      <div className={styles["event-image"]} onClick={showEventDetailsHandler}>
        <img alt='' src={imageUrl} />
      </div>
      <div className={styles["event-body"]}>
        <DotsVertical onClick={showEventDetailsHandler} />
        <h3 className={styles["event-title"]}>{capitalizeFirstLetter(name)}</h3>
        <p className={styles["event-date"]}>
          <span>
            <MultiLingualContent contentId='events' prop='label3' />: {weekday}, {day} {month} {year}
          </span>
          <span>
            <MultiLingualContent contentId='events' prop='label4' />: {time}
          </span>
        </p>
        <div className={styles["event-genres"]}>
          {type === "DJ" && djGenres.length > 0 ? (
            djGenres.map((genre, i) => {
              return <span key={i + "a"}>{genre}</span>;
            })
          ) : (
            <span>{type}</span>
          )}
        </div>
      </div>
      <div className={classes}>
        <div className={styles["overlay-body"]}>
          <div className={styles["close-btn"]} onClick={showEventDetailsHandler}>
            <svg width='15' height='15' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <line x1='1.35355' y1='0.646447' x2='25.3536' y2='24.6464' stroke='#F7F8FA'></line>
              <line x1='0.646447' y1='24.6464' x2='24.6464' y2='0.646448' stroke='#F7F8FA'></line>
            </svg>
          </div>
          <h3 className={styles["overlay-title"]}>{capitalizeFirstLetter(name)}</h3>
          <p className={styles["overlay-date"]}>
            <span>
              <MultiLingualContent contentId='events' prop='label3' />: {weekday}, {day} {month} {year}
            </span>
            <span>
              <MultiLingualContent contentId='events' prop='label4' />: {time}
            </span>
          </p>
          <div className={styles["event-genres"]}>
            {type === "DJ" && djGenres.length > 0 ? (
              djGenres.map((genre, i) => {
                return <span key={i + "c"}>{genre}</span>;
              })
            ) : (
              <span>{type}</span>
            )}
          </div>
          <div className={styles["event-description"]}>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
