import React, { Fragment, useState, useEffect, useContext } from "react";
import { MultiLingualContent } from "../../../contexts/Language/MultiLingualContent";
import { LanguageContext } from "../../../contexts/Language/LanguageContext";
import SearchBar from "../../../components/UI/SearchBar";
import BookList from "./BookList";
import SwiperBooks from "./SwiperBooks";
import Info from "../../../assets/icons/Info";
import LibraryModal from "../../../Modals/LibraryModal";
import BookItemModal from "../../../Modals/BookItemModal";
import { bookCategoriesDataALB, bookCategoriesData } from "./bookCategoriesData";
import ItemCategoriesNavBooks from "../../../components/UI/ItemCategoriesNavBooks";
import FilterToolbar from "./FilterToolbar";

import Loader from "../../../components/UI/Loader";

import styles from "./Books.module.css";

// FIREBASE
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  orderBy,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const Books = () => {
  const [browseByCategory, setBrowseByCategory] = useState(false);
  const [libraryInfoModal, setlibraryInfoModal] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  // PAGINATION STATE
  const [last, setLast] = useState();
  const [first, setFirst] = useState();
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disablePrevButton, setDisablePrevButton] = useState(false);

  const { language } = useContext(LanguageContext);

  // RECENT BOOKS

  const [searchObj, setSearchObj] = useState(null);

  const [categorySearchStr, setCategorySearchStr] = useState("");
  const [noResult, setNoResult] = useState(false);

  const [showNewArrivals, setShowNewArrivals] = useState(false);

  const [bookSortOrder, setBookSortOrder] = useState("titleAsc");

  const [isLoading, setIsLoading] = useState(false);

  const [bookDetailsModal, setBookDetailsModal] = useState(false);
  const [bookDetails, setBookDetails] = useState({});

  // This is for remembering wether user has closed the recently added book or left it opened for the session
  useEffect(() => {
    if (sessionStorage.getItem("showNewArrivalsBooks") !== null) {
      setShowNewArrivals(JSON.parse(sessionStorage.showNewArrivalsBooks));
    }
  }, []);

  useEffect(() => {
    const closeModal = (ev) => {
      if (ev.code === "Escape") {
        setlibraryInfoModal(false);
        setBookDetailsModal(false);
      }
    };
    window.addEventListener("keyup", closeModal);
    return () => window.removeEventListener("keyup", closeModal);
  }, []);

  // SORT ORDER
  useEffect(() => {
    const sortBooks = (type) => {
      const types = {
        titleAsc: "title",
        titleDesc: "title",
        authorAsc: "author",
        authorDesc: "author",
      };

      const sortProperty = types[type];

      function sortAsc(a, b) {
        if (a[sortProperty].toLowerCase() > b[sortProperty].toLowerCase()) {
          return 1;
        } else if (b[sortProperty] > a[sortProperty]) {
          return -1;
        } else {
          return 0;
        }
      }

      if (type === "titleAsc") {
        setSearchResult((prevState) => {
          return [...prevState].sort(sortAsc);
        });
      }

      if (type === "titleDesc") {
        setSearchResult((prevState) => {
          return [...prevState].sort(sortAsc).reverse();
        });
      }
      if (type === "authorAsc") {
        setSearchResult((prevState) => {
          return [...prevState].sort(sortAsc);
        });
      }

      if (type === "authorDesc") {
        setSearchResult((prevState) => {
          return [...prevState].sort(sortAsc).reverse();
        });
      }
    };

    sortBooks(bookSortOrder);
  }, [bookSortOrder]);

  const browseByCategoryHandler = () => {
    setBrowseByCategory(!browseByCategory);
  };

  const onShowLibraryModal = () => {
    setlibraryInfoModal(true);
  };

  const onCancelLibraryModal = () => {
    setlibraryInfoModal(false);
  };

  const newArrivalsHandler = () => {
    setShowNewArrivals(!showNewArrivals);
    sessionStorage.setItem("showNewArrivalsBooks", JSON.stringify(!showNewArrivals));
  };

  function loadBookData(querySnapshot) {
    const storage = getStorage();
    querySnapshot.forEach(async (doc) => {
      if (doc.data().imageRef) {
        const pathReference = ref(storage, doc.data().imageRef);
        try {
          let url = await getDownloadURL(pathReference);
          setSearchResult((prevState) => {
            return [
              ...prevState,
              {
                ...doc.data(),
                docId: doc.id,
                url,
              },
            ];
          });
        } catch (error) {
          setSearchResult((prevState) => {
            return [
              ...prevState,
              {
                ...doc.data(),
                docId: doc.id,
                url: "not available",
              },
            ];
          });
        }
      } else {
        setSearchResult((prevState) => {
          return [
            ...prevState,
            {
              ...doc.data(),
              docId: doc.id,
              url: "not available",
            },
          ];
        });
      }
    });
  }

  useEffect(() => {
    const searchBooksHandler = async () => {
      window.sessionStorage.removeItem("searchCategoryBooks");
   
      // REMOVE HTML ELEMENTS FROM PREVIOUS SEARCH
      setNoResult(false);
      setSearchResult([]);
      if (searchObj === null) {
        return;
      }
      if (searchObj) {
        setCategorySearchStr("");
        setBrowseByCategory(false);

        let { searchCriteria, searchValue } = searchObj;

        // Allowing search with empty string returns all the books, that is why we prevent it
        if (searchValue === "") {
          setNoResult(true);
          return;
        }

        const db = getFirestore();
        const q = query(
          collection(db, "books"),
          where(searchCriteria, ">=", searchValue.toLowerCase()),
          where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff"),
          orderBy(searchCriteria),
          limit(6)
        );

        setIsLoading(true);
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setIsLoading(false);
          return setNoResult(true);
        }

        // FOR PAGINATION
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const firstVisible = querySnapshot.docs[0];
        setLast(lastVisible);
        setFirst(firstVisible);
        loadBookData(querySnapshot);
        setIsLoading(false);
      }
    };
    searchBooksHandler();
  }, [searchObj]);

  useEffect(() => {
    const searchByCategoryHandler = async () => {
      setSearchResult([]);
      setSearchObj(null);
      setNoResult(false);

      const db = getFirestore();

      if (!categorySearchStr) {
        return;
      }

      const q = query(
        collection(db, "books"),
        where("category", "array-contains", categorySearchStr),
        orderBy("title"),
        limit(6)
      );
      setIsLoading(true);
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setIsLoading(false);
        return setNoResult(true);
      }

      // FOR PAGINATION
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const firstVisible = querySnapshot.docs[0];
      setLast(lastVisible);
      setFirst(firstVisible);

      setIsLoading(false);
      loadBookData(querySnapshot);
    };
    searchByCategoryHandler();
  }, [categorySearchStr]);

  async function nextPage() {
    const db = getFirestore();
    setDisablePrevButton(false);
    let next;
    if (searchObj) {
      const { searchValue, searchCriteria } = searchObj;
      next = query(
        collection(db, "books"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff"),
        orderBy(searchCriteria),
        startAfter(last),
        limit(6)
      );
    } else {
      next = query(
        collection(db, "books"),
        where("category", "array-contains", categorySearchStr),
        orderBy("title"),
        startAfter(last),
        limit(6)
      );
    }

    setIsLoading(true);
    const querySnapshot = await getDocs(next);

    if (querySnapshot.empty) {
      setIsLoading(false);
      setDisableNextButton(true);
      return;
    }
    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const firstVisible = querySnapshot.docs[0];

    setLast(lastVisible);
    setFirst(firstVisible);
    setSearchResult([]);

    loadBookData(querySnapshot);
    setIsLoading(false);
  }

  async function prevPage() {
    const db = getFirestore();
    setDisableNextButton(false);

    let prev;
    if (searchObj) {
      const { searchValue, searchCriteria } = searchObj;
      prev = query(
        collection(db, "books"),
        where(searchCriteria, ">=", searchValue.toLowerCase()),
        where(searchCriteria, "<=", searchValue.toLowerCase() + "\uf7ff"),
        orderBy(searchCriteria),
        endBefore(first),
        limitToLast(6)
      );
    } else {
      console.log("yes");
      prev = query(
        collection(db, "books"),
        where("category", "array-contains", categorySearchStr),
        orderBy("title"),
        endBefore(first),
        limitToLast(6)
      );
    }

    setIsLoading(true);
    const querySnapshot = await getDocs(prev);

    if (querySnapshot.empty) {
      setIsLoading(false);
      setDisablePrevButton(true);
      return;
    }
    // FOR PAGINATION
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const firstVisible = querySnapshot.docs[0];

    setLast(lastVisible);
    setFirst(firstVisible);
    setSearchResult([]);

    loadBookData(querySnapshot);
    setIsLoading(false);
  }

  const onUpdateCategoryString = (str) => {
    setDisablePrevButton(false);
    setDisableNextButton(false);
    setCategorySearchStr(str);
  };

  const onSubmitSearchQuery = (obj) => {
    setDisablePrevButton(false);
    setDisableNextButton(false);
    setSearchObj(obj);
  };

  const onShowBookDetailsModal = (docId) => {
    let book = searchResult.find((book) => book.docId === docId);
    setBookDetails(book);
    setBookDetailsModal(true);
  };

  const onCancelBookDetailsModal = () => {
    setBookDetailsModal(false);
  };

  return (
    <Fragment>
      <LibraryModal showModal={libraryInfoModal} onCancel={onCancelLibraryModal} />
      {bookDetailsModal && (
        <BookItemModal
          onCancel={onCancelBookDetailsModal}
          bookDetails={bookDetails}
          setSearchResult={setSearchResult}
          setBookDetails={setBookDetails}
        />
      )}
      <div className={styles.container}>
        <button type='button' className={styles["button-80"]} onClick={onShowLibraryModal}>
          <Info />
          <MultiLingualContent contentId='booksAndRecords' prop='button1' />
        </button>
        <SearchBar onSubmit={onSubmitSearchQuery} />
        {!browseByCategory && (
          <button type='button' className={styles["browse-btn"]} onClick={browseByCategoryHandler}>
            <MultiLingualContent contentId='booksAndRecords' prop='button2' />
          </button>
        )}
        {browseByCategory && (
          <ItemCategoriesNavBooks
            onSubmit={onUpdateCategoryString}
            categories={language === "albanian" ? bookCategoriesDataALB : bookCategoriesData}
            onCancel={browseByCategoryHandler}
          />
        )}
        {!showNewArrivals && (
          <button type='button' className={styles["browse-btn"]} onClick={newArrivalsHandler}>
            <MultiLingualContent contentId='booksAndRecords' prop='button3' />
          </button>
        )}
        {showNewArrivals && (
          <div className={styles["new-books-container"]}>
            <div className={styles["books-carousel-container"]}>
              <svg
                className={styles.svg}
                viewBox='0 0 26 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={newArrivalsHandler}>
                <line x1='1.35355' y1='0.646447' x2='25.3536' y2='24.6464' stroke='black'></line>
                <line x1='0.646447' y1='24.6464' x2='24.6464' y2='0.646448' stroke='black'></line>
              </svg>
              <SwiperBooks />
            </div>
          </div>
        )}

        {searchObj || categorySearchStr ? (
          <FilterToolbar
            numberOfBooks={searchResult.length}
            sortType={bookSortOrder}
            onSortOrderChange={setBookSortOrder}
          />
        ) : (
          ""
        )}

        <div className={searchObj || categorySearchStr ? styles["book-list-container"] : ""}>
          {noResult ? (
            <p className={styles["no-result"]}>
              <MultiLingualContent contentId='booksAndRecords' prop='note1' />
            </p>
          ) : isLoading ? (
            <Loader />
          ) : (
            <BookList
              sortType={bookSortOrder}
              onSortOrderChange={setBookSortOrder}
              searchResult={searchResult}
              showMoreItems={nextPage}
              prevPage={prevPage}
              buttonState={{ nextButton: disableNextButton, prevButton: disablePrevButton }}
              onShowBookDetailsModal={onShowBookDetailsModal}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Books;
