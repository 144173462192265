import { useEffect, useState } from "react";

import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import noImagePlaceHolder from "../../../assets/images/No-Image-Placeholder.png";
import styles from "./Slide.module.css";
// FIREBASE
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const Slide = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setIsLoading] = useState(false);

  // Get image reference from storage
  useEffect(() => {
    if (props.imageRef) {
      const storage = getStorage();
      const pathReference = ref(storage, props.imageRef);
      setIsLoading(true);
      getDownloadURL(ref(storage, pathReference))
        .then((url) => {
          // `url` is the download URL for 'bookcovers/*'
          setIsLoading(false);
          setImageUrl(url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
        });
    } else {
      setImageUrl(noImagePlaceHolder);
    }
  }, [props.imageRef]);
  return (
    <div className={styles.slide}>
      <img src={loading ? noImagePlaceHolder : imageUrl} alt='' className={styles.img} />
      <div className={styles.info}>
        <h4 className={styles.title}>{capitalizeFirstLetter(props.title)}</h4>
        <span>by</span>
        <p className={styles.artist}>{capitalizeFirstLetter(props.author)}</p>
      </div>
    </div>
  );
};

export default Slide;
