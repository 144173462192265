import img1 from "../../../../assets/images/foodAndDrinks/menuPhotos/small bites/1.jpg";
import img2 from "../../../../assets/images/foodAndDrinks/menuPhotos/small bites/2.jpg";
import img3 from "../../../../assets/images/foodAndDrinks/menuPhotos/small bites/3.jpg";
import img4 from "../../../../assets/images/foodAndDrinks/menuPhotos/small bites/4.jpg";
import img5 from "../../../../assets/images/foodAndDrinks/menuPhotos/small bites/5.jpg";
import img6 from "../../../../assets/images/foodAndDrinks/menuPhotos/small bites/6.jpg";
import img7 from "../../../../assets/images/foodAndDrinks/menuPhotos/small bites/7.jpg";
import img8 from "../../../../assets/images/foodAndDrinks/menuPhotos/small bites/8.jpg";
import img9 from "../../../../assets/images/foodAndDrinks/menuPhotos/small bites/9.jpg";

export const AppetizerImages = [
  {
    url: img1,
    alt: "Three bowls seen from above on a table. The one on the lower left is full of large green olives marinated in oil with cubes of white cheese. On the upper right is a bowl of whole roasted potatoes.Upper left side we see grill toasted pita bread",
  },
  {
    url: img2,
    alt: "A plate of small pieces of toasted bread covered with green spinach leaves, grated cheese and a thin slice of pear, drizzeled with thick and dark balsamic cream",
  },
  {
    url: img3,
    alt: "A plate with a golden colored grilled cheese and two slices of a toasted golden colored bread in the background",
  },
  {
    url: img4,
    alt: "A small plate of white cream cheese spread on the plate and covered with red pomegranate seeds, raisins and drizzeled with green olive oil",
  },
  {
    url: img5,
    alt: "A plate with hummus, sprinkled with black seeds and a lemon wedge on the side. In the forground a plate of couple of quarters of thin pita bread placed on tip of each other",
  },
  { url: img6, alt: "A plate of 5 small round yellow peppers standing upright" },
  {
    url: img7,
    alt: "An oven proof bowl half filled with tomato sauce, sprinlkled with small pieces of white cheese and a whole single shrimp at the center",
  },
  {
    url: img8,
    alt: "A bowl of medium sized whole oven rosated potatoes with skin peeling. And a side bowl sour curds with couple of leaves of parsley at the center ",
  },
  { url: img9, alt: "A hand dipping with a thin pitta bread on white tzatiki mixture" },
];
