import BeersMobile from "./BeersMobile";
import BeersDesktop from "./BeersDesktop";
import styles from "./Beers.module.css";

const Beers = (props) => {
  return (
    <div className={styles.container}>
      <BeersMobile />
      <BeersDesktop />
    </div>
  );
};

export default Beers;
